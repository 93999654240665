import React from 'react';
import {
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import * as R from 'ramda';
import { HIDBlob } from '@house-id/houseid-types/dist/common';

import {
  FCC,
} from '../../../../../../types/common';
import FileListItem from './FileListItem';
import HIDButton from '../../../../../../components/buttons/HIDButton';
import { ContentFile } from '../../modules/ContentFile/types.contentFile';
import {
  FileMimeType,
  ImageMimeTypes,
} from '../../../../../../constants/mimeTypes';
import {
  ExternalMedia,
  ExternalMediaType,
} from '../../modules/Product/types.product';
import ExternalMediaListItem from './ExternalMediaListItem';
import useDialog from '../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../hooks/useDialog/DialogNames';
import { downloadFile } from '../../../../../../utils/download';
import { resolveImageUrl } from '../../../../../../utils/env';
import { openFileInNewTab } from '../../../../../../utils/file';
import useGetPropertyPermissions from '../../../../hooks/useGetPropertyPermissions';
import FilesSection from './FilesSection';

type FilesListProps = {
  blobs: Array<HIDBlob>;
  externalMedia?: Array<ExternalMedia>;
  mimeTypes?: Array<FileMimeType>;
  condense?: boolean;
  isLoading?: boolean;
  isUpdating?: boolean;
  showTitle?: boolean;
  onContentFilesSelected?: (files: Array<ContentFile>) => void;
  onDeleteBlob?: (id: string) => void;
  onMoveBlob?: (blob: HIDBlob) => void;
  onDeleteExternalMedia?: (url: string) => void;
  onMoveExternalMedia?: (url: string, mediaType: ExternalMediaType) => void;
};

const FilesList: FCC<FilesListProps> = ({
  blobs,
  externalMedia,
  mimeTypes,
  condense = false,
  isLoading = false,
  isUpdating = false,
  showTitle = true,
  sx,
  onContentFilesSelected,
  onDeleteBlob,
  onMoveBlob,
  onDeleteExternalMedia,
  onMoveExternalMedia,
}) => {
  const { t } = useTranslation(['entities', 'photos', 'common']);

  const { data: { canCreate, canUpdate } = {} } = useGetPropertyPermissions();

  const [openFilePickerDialog] = useDialog(DialogNames.CONTENT_FILE_PICKER_DIALOG);
  const handleOpenFilePicker = () => {
    openFilePickerDialog({
      onSelect: onContentFilesSelected,
    });
  };

  const onlyImages = R.equals(mimeTypes, ImageMimeTypes);

  const handleOpen = (url: string) => openFileInNewTab(url);

  const handleDownload = (url: string) => downloadFile(resolveImageUrl(url));

  const hasBlobs = Boolean(blobs.length);
  const hasExternalMedia = Boolean(externalMedia?.length);

  return (
    <Stack spacing={2} sx={sx}>
      <FilesSection
        isLoading={isLoading}
        showContent={hasBlobs}
        showTitle={showTitle}
        title={onlyImages ? t('entities:photo_plural') : t('common:attachments')}
      >
        {blobs.map((blob) => (
          <FileListItem
            condense={condense}
            key={blob.id}
            loading={isUpdating}
            mime={blob.mime}
            name={blob.name}
            onDelete={onDeleteBlob ? () => onDeleteBlob(blob.id) : undefined}
            onDownload={() => handleDownload(blob.downloadUrl)}
            onMove={onMoveBlob ? () => onMoveBlob(blob) : undefined}
            onOpen={() => handleOpen(blob.downloadUrl)}
          />
        ))}
      </FilesSection>
      {!isLoading && onContentFilesSelected && canCreate && canUpdate && (
        <HIDButton
          Icon={Add}
          loading={isUpdating}
          sx={{
            alignSelf: {
              sm: 'stretch',
              xxs: 'center',
            },
            width: {
              sm: 'unset',
              xxs: '100%',
            },
          }}
          onClick={handleOpenFilePicker}
        >
          {onlyImages ? t('photos:upload_photos') : t('common:add_new_attachments')}
        </HIDButton>
      )}
      <FilesSection
        isLoading={isLoading}
        showContent={hasExternalMedia}
        showTitle={showTitle}
        title={t('common:external_media')}
      >
        {externalMedia?.map((media) => (
          <ExternalMediaListItem
            isExternal
            condense={condense}
            key={media.url}
            loading={isUpdating}
            mediaType={media.type}
            mime={media.contentType}
            url={media.url}
            onDelete={onDeleteExternalMedia ? () => onDeleteExternalMedia(media.url) : undefined}
            onMove={onMoveExternalMedia ? () => onMoveExternalMedia(media.url, media.type) : undefined}
          />
        ))}
      </FilesSection>
    </Stack>
  );
};

export default FilesList;
