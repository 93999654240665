import {
  useDispatch,
  useSelector,
} from 'react-redux';

import EntityType from '../constants/entityType';
import { RootState } from '../store/store';
import { toggleSearchVisibility } from '../store/layoutReducer';

type SearchVisibilityParams = {
  isFocusedSearch?: boolean;
  isVisibleSearch?: boolean;
  entityType?: EntityType;
};

type UseSearchReturnType = {
  entityType?: EntityType;
  isVisible: boolean;
  isFocused: boolean;
  setSearchState: (params: SearchVisibilityParams) => void;
  displaySearch: () => void;
  hideSearch: () => void;
};

const useSearch = (type?: EntityType): UseSearchReturnType => {
  const dispatch = useDispatch();
  const layoutState = useSelector((state: RootState) => state.layout);

  const setSearchState = (params: SearchVisibilityParams) => {
    dispatch(toggleSearchVisibility(params));
  };

  const displaySearch = () => {
    dispatch(toggleSearchVisibility({ isVisibleSearch: true, isFocusedSearch: true, entityType: type }));
  };

  const hideSearch = () => {
    dispatch(toggleSearchVisibility({ isVisibleSearch: false, isFocusedSearch: false, entityType: undefined }));
  };

  return {
    entityType: layoutState.entityType,
    isVisible: layoutState.isVisibleSearch,
    isFocused: layoutState.isFocusedSearch,
    setSearchState,
    displaySearch,
    hideSearch,
  };
};

export default useSearch;
