import {
  DocumentData,
  QueryDocumentSnapshot,
  QuerySnapshot,
  collection,
  doc,
  getDocs,
  setDoc,
} from 'firebase/firestore';

import * as R from 'ramda';
import { propertyApi } from '../../Property/api/property.api';
import { HIDApiTags } from '../../../api/HIDApiTags';

import {
  getActiveAuth,
  firestore,
} from '../../../external-services/firebase';
import {
  ShowDialogItemSource,
  ShowDialogItemTargetType,
} from '../../../types/dialogs';
import { PropertyId } from '../../Property/types/property.types';

export type SaveDialogSettings = {
  name: string,
  shownCount?: number,
  disabled?: boolean,
  propertyId?: string,
  target: ShowDialogItemTargetType
};

export type DialogSettings = Record<string, Record<string, ShowDialogItemSource>>;

function mapDialogSettings(docs: QueryDocumentSnapshot<DocumentData, DocumentData>[]) {
  return R.fromPairs(
    docs.map((doc) => {
      const value = doc.data();
      return [value.name, value as ShowDialogItemSource];
    }),
  );
}

const loadPropertyDialogSettings = async (currentUserUid: string, propertyId: string) => {
  const propertyDocument: QuerySnapshot<DocumentData, DocumentData> = await getDocs(
    collection(firestore, 'users', currentUserUid, 'properties', propertyId, 'dialogs'),
  );
  const propertyDialogSettings = propertyDocument.docs;
  return mapDialogSettings(propertyDialogSettings);
};

const loadUserDialogSettings = async (currentUserUid: string) => {
  const userDocument = await getDocs(collection(firestore, 'users', currentUserUid, 'dialogs'));
  const userDialogSettings = userDocument.docs;
  return mapDialogSettings(userDialogSettings);
};

export const dialogSettingsApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getDialogsSettings: builder.query<DialogSettings | undefined, PropertyId>({
      queryFn: async ({ propertyId }) => {
        const currentUserUid = getActiveAuth().currentUser?.uid;
        if (currentUserUid) {
          const propertyDialogSettings = await loadPropertyDialogSettings(currentUserUid, propertyId);
          const userDialogSettings = await loadUserDialogSettings(currentUserUid);

          const data: Record<string, Record<string, ShowDialogItemSource>> = {
            [ShowDialogItemTargetType.PROPERTY]: propertyDialogSettings,
            [ShowDialogItemTargetType.USER]: userDialogSettings,
          };

          return { data };
        }
        return { data: undefined };
      },
      providesTags: [HIDApiTags.DIALOG_SETTINGS],
    }),
    setDialogsSettings: builder.mutation<void, { data: SaveDialogSettings }>({
      queryFn: async ({ data }) => {
        const currentUserUid = getActiveAuth().currentUser?.uid;
        if (currentUserUid) {
          const settings = {
            name: data.name,
            disabled: Boolean(data.disabled),
            shownCount: data.shownCount,
          } as ShowDialogItemSource;

          if (data.target === ShowDialogItemTargetType.PROPERTY && data.propertyId) {
            await setDoc(
              doc(firestore, 'users', currentUserUid, 'properties', data.propertyId, 'dialogs', settings.name),
              settings,
              { merge: true },
            );
          } else {
            await setDoc(
              doc(firestore, 'users', currentUserUid, 'dialogs', settings.name),
              settings,
              { merge: true },
            );
          }
        }
        return { data: undefined };
      },
      invalidatesTags: [HIDApiTags.DIALOG_SETTINGS],
    }),
  }),
});

export const {
  useGetDialogsSettingsQuery,
  useSetDialogsSettingsMutation,
} = dialogSettingsApi;
