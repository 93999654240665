import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';

import PropertyProgressContentItem from '../../../components/PropertyProgressContentItem';
import PropertyProgressCategorySectionContent from '../../../components/PropertyProgressCategorySectionContent';
import { formatMoney } from '../../../../../../../utils/string';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { getReceiptCategoriesPath } from '../../../../Content/modules/Receipt/navigation.receipt';
import PropertyProgressCategorySectionContainer from '../../../components/PropertyProgressCategorySectionContainer';
import {

  PropertyProgressCategorySectionProps,
} from '../../../types.propertyProgress';
import useGetPropertyProgressCategoryContentText from '../../../hooks/useGetPropertyProgressCategoryContentText';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import { PropertyProgressCategoryId } from '@house-id/houseid-types/dist/propertyProgress';

type PropertyDocumentationReceiptsData = {
  totalAmount: number;
};

const PropertyDocumentationReceiptsSection: FC<PropertyProgressCategorySectionProps<PropertyDocumentationReceiptsData>> = ({
  section,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['forms_common', 'property']);
  const { data: propertyId } = useGetCurrentPropertyId();

  const handleNavigateToReceipts = () => navigate(getPathWithPropertyIdOrInit(getReceiptCategoriesPath, { propertyId }));

  const getPropertyProgressCategoryContentDescription = useGetPropertyProgressCategoryContentText();
  const description = getPropertyProgressCategoryContentDescription({
    percent: section.data.totalAmount ? 100 : 0,
    categoryId: PropertyProgressCategoryId.DOCUMENTS,
    sectionId: section.id,
  });

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          description={description}
          viewAllButtonText={t('property:property_progress_property_documentation_receipts_view_all')}
          onViewAllClick={handleNavigateToReceipts}
        >
          <PropertyProgressContentItem
            noIcon
            DescriptionComponent={
              <Typography variant="h3">
                {formatMoney(section.data.totalAmount)}
              </Typography>
            }
            title={t('forms_common:total')}
            onNavigate={handleNavigateToReceipts}
          />
        </PropertyProgressCategorySectionContent>
      }
      section={section}
    />
  );
};

export default PropertyDocumentationReceiptsSection;
