import React, {
  FC,
  useState,
} from 'react';
import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Tab,
} from '@mui/material';
import {
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { ExpenseBankAccount } from '@house-id/houseid-types/dist/finances/recurringExpenses';
import * as R from 'ramda';

import HIDTypography from '../../../../../../../components/HIDTypography';
import HIDFormSelect, { HIDSelectItem } from '../../../../../../../components/HIDFormSelect';
import TimeIntervalsSection from '../../../../Content/components/sections/TimeIntervalsSection';
import { TimeIntervalsGroupingType } from '../../../../Content/types/content.type';
import HIDMonthRangePicker from '../../../../../../../components/datePicker/HIDMonthRangePicker';
import HIDDateRangePicker from '../../../../../../../components/datePicker/HIDDateRangePicker';
import HIDFormMultiSelect from '../../../../../../../components/HIDFormMultiSelect';
import { FinancesFilterSectionMode } from '../../../types.finances';
import { getBankAccountName } from '../utils.recurringExpenses';

export type RecurringExpensesFilters = {
  mode: FinancesFilterSectionMode;
  bankAccountIds?: Array<string>;
  budgetId?: string;
  fromDate?: Date;
  toDate?: Date;
  fromYear?: number;
  toYear?: number;
  fromMonth?: number;
  toMonth?: number;
  timeIntervalGroupingType: TimeIntervalsGroupingType;
  includeIncompleteMonths?: boolean;
};

type RecurringExpensesFilterSectionProps = {
  showComparison?: boolean;
  filters: RecurringExpensesFilters;
  bankAccounts?: Array<ExpenseBankAccount>;
  timeIntervalGroupingType?: TimeIntervalsGroupingType;
  yearsList?: Array<number>;
  budgetsList?: Array<HIDSelectItem>;
  onChange: (filters: RecurringExpensesFilters) => void;
};

const RecurringExpensesFilterSection: FC<RecurringExpensesFilterSectionProps> = ({
  showComparison = true,
  filters,
  bankAccounts,
  yearsList,
  budgetsList,
  onChange,
}) => {
  const { t } = useTranslation(['common', 'forms_common', 'finances']);

  const [activeMode, setActiveMode] = useState<FinancesFilterSectionMode>(filters.mode || FinancesFilterSectionMode.Filter);

  const usersMap: Record<string, { id: string; name: string; }> = Object.fromEntries(
    (bankAccounts || []).map(
      (bankAccount: ExpenseBankAccount) => [bankAccount.user.id, bankAccount.user],
    ),
  );

  const bankAccountsMap = R.groupBy((bankAccount) => bankAccount.user.id, bankAccounts || []);

  const bankAccountsListItems = Object.keys(usersMap)
    .flatMap((userId) => (
      [
        {
          id: userId,
          name: usersMap[userId].name,
          isCategory: true,
        },
        ...(bankAccountsMap[userId] || [])
          .map((bankAccount) => ({
            id: bankAccount.id,
            name: getBankAccountName(bankAccount),
          })),
      ]
    ));

  const handleChange = (_event: React.SyntheticEvent, newMode: FinancesFilterSectionMode) => {
    setActiveMode(newMode);
    onChange({ ...filters, mode: newMode });
  };

  const yearsSelectList = yearsList?.map((year) => ({ id: year.toString(), name: year.toString() }));

  const budgetAndYearList = [
    { id: 'budgets', name: t('finances:budgets'), isCategory: true },
    ...(budgetsList || []),
    { id: 'years', name: t('finances:fixed_expenses'), isCategory: true },
    ...(yearsSelectList || []),
  ] as Array<HIDSelectItem>;

  return (
    <Stack sx={{ mt: showComparison ? -1 : 0 }}>
      <TabContext value={activeMode}>
        {
          showComparison
            ? (
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange}>
                  <Tab
                    label={<HIDTypography variant="h6">{t('common:filter')}</HIDTypography>}
                    value={FinancesFilterSectionMode.Filter}
                  />
                  <Tab
                    label={<HIDTypography variant="h6">{t('finances:comparison')}</HIDTypography>}
                    value={FinancesFilterSectionMode.Compare}
                  />
                </TabList>
              </Box>
            )
            : (
              <HIDTypography variant="h6">{t('common:filter')}</HIDTypography>
            )
        }
        <TabPanel sx={{ p: 0 }} value={FinancesFilterSectionMode.Filter}>
          <Stack gap={2}>
            <HIDDateRangePicker
              from={filters.fromDate}
              to={filters.toDate}
              onChange={({ from, to }) => onChange({ ...filters, fromDate: from, toDate: to })}
            />
            <HIDFormMultiSelect
              items={bankAccountsListItems || []}
              label={t('finances:select_accounts')}
              value={filters.bankAccountIds || []}
              onChange={(event) => onChange({ ...filters, bankAccountIds: event.target.value as Array<string> })}
            />
            <TimeIntervalsSection
              showQuarterly
              timeIntervalGroupingType={filters.timeIntervalGroupingType}
              onChange={(timeIntervalGroupingType) => onChange({ ...filters, timeIntervalGroupingType })}
            />
            <FormControlLabel
              control={<Switch checked={filters.includeIncompleteMonths} />}
              label={<HIDTypography variant="subtitle1">{t('finances:show_incomplete_months')}</HIDTypography>}
              labelPlacement="start"
              sx={{ justifyContent: 'space-between', ml: 0, px: 1 }}
              onChange={() => onChange({ ...filters, includeIncompleteMonths: !filters.includeIncompleteMonths })}
            />
          </Stack>
        </TabPanel>
        {showComparison && (
          <TabPanel sx={{ p: 0 }} value={FinancesFilterSectionMode.Compare}>
            <Stack gap={2}>
              <HIDMonthRangePicker
                from={filters.fromMonth}
                sx={{ mt: -1 }}
                to={filters.toMonth}
                onChange={({ from, to }) => onChange({ ...filters, fromMonth: from, toMonth: to })}
              />
              <HIDFormSelect
                items={yearsSelectList || []}
                label={t('finances:select_year')}
                showHelperText={false}
                value={filters.fromYear?.toString()}
                onChange={(event) => onChange({ ...filters, fromYear: Number.parseInt(event.target.value, 10) })}
              />
              <HIDFormSelect
                showClear
                items={budgetAndYearList}
                label={t('common:compare')}
                showHelperText={false}
                value={filters.budgetId || filters.toYear?.toString()}
                onChange={(event) => {
                  const yearOrBudget = event.target.value;
                  if (!yearOrBudget) {
                    onChange({ ...filters, budgetId: undefined, toYear: undefined });
                  } else if (yearOrBudget.length <= 4) {
                    onChange({ ...filters, budgetId: undefined, toYear: Number.parseInt(event.target.value, 10) });
                  } else {
                    onChange({ ...filters, budgetId: event.target.value, toYear: undefined });
                  }
                }}
              />
              <HIDFormMultiSelect
                multilevel
                items={bankAccountsListItems || []}
                label={t('finances:select_accounts')}
                value={filters.bankAccountIds || []}
                onChange={(event) => onChange({ ...filters, bankAccountIds: event.target.value as Array<string> })}
              />
              <TimeIntervalsSection
                showQuarterly
                timeIntervalGroupingType={filters.timeIntervalGroupingType}
                onChange={(timeIntervalGroupingType) => onChange({ ...filters, timeIntervalGroupingType })}
              />
              <FormControlLabel
                control={<Switch checked={filters.includeIncompleteMonths} />}
                label={<HIDTypography variant="subtitle1">{t('finances:show_incomplete_months')}</HIDTypography>}
                labelPlacement="start"
                sx={{ justifyContent: 'space-between', ml: 0, px: 1 }}
                onChange={() => onChange({ ...filters, includeIncompleteMonths: !filters.includeIncompleteMonths })}
              />
            </Stack>
          </TabPanel>
        )}
      </TabContext>
    </Stack>
  );
};

export default RecurringExpensesFilterSection;
