import React, { FC } from 'react';
import {
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  SpecificationCategory,
  ParsedSpecificationCategory,
  ParseSpecificationItem,
} from '../../../types.product';
import { useParseSpecificationCategory } from '../../../utils.products';
import HIDLink from '../../../../../../../../../components/HIDLink';
import { EMPTY_VALUE } from '../../../../../../../../../utils/string';
import { FCC } from '../../../../../../../../../types/common';
import { hidSpacing } from '../../../../../../../../../utils/number';

type SpecificationItemSectionProps = {
  item: ParseSpecificationItem;
  long?: boolean;
};

const SpecificationItemSection: FCC<SpecificationItemSectionProps> = ({
  item,
  long = false,
}) => (
  <Grid item sm={long ? 12 : 6} xxs={12}>
    <Typography variant="subtitle2">{item.name}</Typography>
    <Stack>
      {item.isLink && item.value && item.value !== EMPTY_VALUE
        ? <HIDLink href={item.value} label={item.value} target="_blank" />
        : <Typography variant="body2">{item.value || EMPTY_VALUE}</Typography>}
    </Stack>
  </Grid>
);

type ProductSpecificationSectionCategoryProps = {
  category: ParsedSpecificationCategory;
  nestedLevel?: number;
};

// eslint-disable-next-line react/no-multi-comp
const ProductSpecificationCategorySection: FCC<ProductSpecificationSectionCategoryProps> = ({
  category,
  nestedLevel = 0,
  sx,
}) => (
  <Grid
    container
    item
    spacing={1.5}
    style={{
      marginLeft: hidSpacing(nestedLevel),
      width: '100%',
    }}
    sx={sx}
  >
    <Grid
      container
      item
      style={{ paddingLeft: 0 }}
      xxs={12}
    >
      <Typography variant="subtitle1">
        {category.name}
      </Typography>
    </Grid>
    <Grid
      container
      item
      spacing={1}
      style={{ paddingLeft: 0 }}
      xxs={12}
    >
      {
        category.children
          ?.map(
            (item, index) => (item as ParsedSpecificationCategory).children
              ? (
                <ProductSpecificationCategorySection
                  category={item as ParsedSpecificationCategory}
                  key={index}
                  nestedLevel={nestedLevel + 1}
                />
              )
              : (
                <SpecificationItemSection
                  item={item as ParseSpecificationItem}
                  key={index}
                  long={nestedLevel > 0 || (item as ParseSpecificationItem).isLink}
                />
              ),
          )
      }
    </Grid>
  </Grid>
);

type ProductSpecificationSectionProps = {
  specifications?: Array<SpecificationCategory>;
};

// eslint-disable-next-line react/no-multi-comp
const ProductSpecificationSection: FC<ProductSpecificationSectionProps> = ({
  specifications,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['products']);
  const parseSpecificationCategory = useParseSpecificationCategory();

  const categoryWithValues = (specifications || [])
    .map((category) => parseSpecificationCategory(category))
    .filter((category) => category.children && category.children?.length);

  return (
    <Stack
      spacing={3}
      sx={{
        marginTop: 2.5,
        padding: 2.5,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
        borderRadius: '10px',
      }}
    >
      <Typography variant="h6">
        {t('products:specification')}
      </Typography>
      <Stack spacing={2}>
        {categoryWithValues.map((category, index) => (
          <ProductSpecificationCategorySection
            category={category}
            key={index}
            sx={{
              backgroundColor: index % 2 === 0 ? theme.palette.grey[100] : theme.palette.common.white,
              padding: theme.spacing(0, 1, 1, 1),
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default ProductSpecificationSection;
