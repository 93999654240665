import qs from 'query-string';
import { skipToken } from '@reduxjs/toolkit/query';

import { HIDApiTags } from '../../../../../../../api/HIDApiTags';
import {
  TimelineEvent,
  CustomTimelineEvent,
  CustomTimelineEventCategory,
  TimelineFilter,
  GetStartedWizardData,
} from '../types.timeline';
import { PropertyId } from '../../../../../types/property.types';
import {
  CreateEntity,
  HIDEntityId,
  PaginationParams,
} from '../../../../../../../types/common';
import { propertyApi } from '../../../../../api/property.api';
import { getMutationFixedCacheKey } from '../../../utils/cacheKeys';
import { provideArrayTags } from '../../../../../../../api/HIDBaseQuery';

type TimelineEventsRequestParams = PropertyId & Partial<PaginationParams> & {
  from?: string;
  to?: string;
  types?: Array<string>;
  orderBy?: 'start_asc' | 'start_desc';
  connections?: boolean;
  key?: string;
};

export const timelineApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<{ events: Array<TimelineEvent>, totalCount: number }, TimelineEventsRequestParams>({
      query: ({
        propertyId,
        from,
        to,
        types,
        orderBy = 'start_desc',
        connections = false,
        pageSize,
        offset,
      }) => {
        const queryParams = qs.stringify({
          from,
          to,
          types,
          orderBy,
          connections,
          pageSize,
          offset,
        });

        return `/properties/${propertyId}/timeline?${queryParams}`;
      },
      providesTags: (result) => provideArrayTags(HIDApiTags.TIMELINE_EVENT, result?.events?.map(({ key }) => ({ id: key }))),
    }),
    getCustomEvent: builder.query<CustomTimelineEvent, PropertyId & HIDEntityId>({
      query: ({ propertyId, id }) => `/properties/${propertyId}/timeline/entries/${id}`,
      providesTags: (_result, _error, arg) => [{ type: HIDApiTags.TIMELINE_EVENT as const, id: arg.id }],
    }),
    getEventTypes: builder.query<Array<TimelineFilter>, PropertyId>({
      query: ({ propertyId }) => ({
        url: `/properties/${propertyId}/timeline/types`,
        method: 'GET',
      }),
      providesTags: () => [{ type: HIDApiTags.TIMELINE_FILTER }],
    }),
    getGetStartedWizardData: builder.query<GetStartedWizardData, PropertyId>({
      query: ({ propertyId }) => ({
        url: `/properties/${propertyId}/timeline/wizard`,
        method: 'GET',
      }),
      providesTags: () => [{ type: HIDApiTags.TIMELINE_GET_STARTED_WIZARD }],
    }),
    getCustomEventCategories: builder.query<Array<CustomTimelineEventCategory>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/timeline/entries/categories`,
    }),
    createCustomEvent: builder.mutation<CustomTimelineEvent, CreateEntity<CustomTimelineEvent>>({
      query: (event) => ({
        url: `/properties/${event.propertyId}/timeline/entries`,
        method: 'POST',
        body: event,
      }),
      invalidatesTags: () => [
        HIDApiTags.TIMELINE,
        HIDApiTags.PROPERTY_PLANNING,
        HIDApiTags.SELLING_PROPERTY_ARCHIVE,
        HIDApiTags.SELLING_PROPERTY_TRANSFER,
      ],
    }),
    updateCustomEvent: builder.mutation<CustomTimelineEvent, Partial<CustomTimelineEvent> & HIDEntityId & PropertyId>({
      query: (event) => ({
        url: `/properties/${event.propertyId}/timeline/entries/${event.id}`,
        method: 'PATCH',
        body: event,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: HIDApiTags.TIMELINE_EVENT as const, id: arg.id },
        HIDApiTags.PROPERTY_PLANNING,
        HIDApiTags.SELLING_PROPERTY_ARCHIVE,
        HIDApiTags.SELLING_PROPERTY_TRANSFER,
      ],
    }),
    deleteEvents: builder.mutation<Array<string>, PropertyId & { keys: Array<string> }>({
      query: ({ keys, propertyId }) => ({
        url: `/properties/${propertyId}/timeline/entries?${qs.stringify({ keys })}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...(arg.keys.map((key) => ({ type: HIDApiTags.TIMELINE_EVENT as const, id: key })) || []),
        HIDApiTags.TIMELINE,
        HIDApiTags.PROPERTY_PLANNING,
        HIDApiTags.SELLING_PROPERTY_ARCHIVE,
        HIDApiTags.SELLING_PROPERTY_TRANSFER,
      ],
    }),
    updateGetStartedWizardData: builder.mutation<Partial<GetStartedWizardData>, GetStartedWizardData & PropertyId>({
      query: ({ propertyId, ...data }) => ({
        url: `/properties/${propertyId}/timeline/wizard`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: () => [HIDApiTags.TIMELINE_GET_STARTED_WIZARD, HIDApiTags.TIMELINE, HIDApiTags.PROPERTY_PLANNING],
    }),
  }),
});

export const {
  useGetEventsQuery,
  useLazyGetEventsQuery,
  useGetEventTypesQuery,
  useGetGetStartedWizardDataQuery,
  useGetCustomEventCategoriesQuery,
  useCreateCustomEventMutation,
  useUpdateCustomEventMutation,
  useDeleteEventsMutation,
  useUpdateGetStartedWizardDataMutation,
} = timelineApi;

export const useGetCustomEvent = ({
  propertyId,
  eventId,
}: {
  propertyId?: string;
  eventId?: string;
}) => {
  const [
    _deleteEvents,
    { isLoading: isDeleting, isSuccess: isDeleted },
  ] = useDeleteEventsMutation({
    fixedCacheKey: getMutationFixedCacheKey(eventId),
  });
  const isDeletingOrDeleted = isDeleting || isDeleted;

  const {
    data: fetchedEvent,
    isLoading,
    error,
    refetch,
  } = timelineApi.useGetCustomEventQuery(
    propertyId && eventId && !isDeletingOrDeleted
      ? { propertyId, id: eventId }
      : skipToken,
  );

  return {
    customEvent: fetchedEvent,
    isLoading,
    error,
    refetch,
  };
};
