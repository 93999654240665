import React, {
  FC,
  useState,
} from 'react';
import {
  Card,
  Grid,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { signInWithCustomToken } from 'firebase/auth';
import {
  ScreenShareOutlined,
  LoginSharp,
} from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';

import HIDTextField from '../../../../components/HIDTextField';
import { getHandleSetField } from '../../../../utils/form';
import {
  useNavigateBackOr,
} from '../../../../utils/routes';
import CreateContentPageBottomToolbar from '../../../Property/modules/Content/components/CreateContentPageBottomToolbar';
import HomeLayout from '../../../Property/pages/Home/components/HomeLayout';
import {
  getAdminHomePath,
  getImitateAccountPath,
} from '../../navigation/navigation.admin';
import {
  useGetTokenDataQuery,
  useLazyGetCustomTokenQuery,
} from '../../../Auth/api/user.api';
import { useImitateUserAccountMutation } from '../../api/admin.api';
import { getInitAppPath } from '../../../Auth/navigation/navigation.auth';
import {
  getActiveAuth,
  useGetActiveAuthUser,
} from '../../../../external-services/firebase';
import { useGetSignOut } from '../../../Auth/hooks/useGetSignOut';
import ViewEntityActions from '../../../Property/modules/Content/components/actions/ViewEntityActions';
import ApplySharedTokenDialog from './components/ApplySharedTokenDialog';
import useBankId from '../../../Auth/hooks/useBankId';
import { copyToClipboard } from '../../../../utils/clipboard';
import { UserTokenData } from '../../../Auth/types/auth.types';

type ImitateAccountFormValues = {
  email: string;
};

const validate2FA = (tokenData: UserTokenData) => {
  const adminClaim = tokenData.admin;
  const bankidClaim = tokenData.bankIdClaim;

  if (adminClaim?.personalNumber && bankidClaim?.personalNumber && adminClaim.personalNumber === bankidClaim.personalNumber) {
    return true;
  }

  if (adminClaim?.phoneNumber && tokenData.firebase.sign_in_second_factor === 'phone'
    && tokenData.firebase.identities
    && tokenData.firebase.identities.phone?.includes(adminClaim.phoneNumber) && Math.abs(tokenData.auth_time - tokenData.iat) < 10) {
    return true;
  }

  return false;
};

const ImitateAccount: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'forms_common', 'admin']);
  const navigateBackOr = useNavigateBackOr();
  const navigate = useNavigateBackOr();
  const { user: currentUser } = useGetActiveAuthUser();

  const [isApplySharedTokenVisible, setIsApplySharedTokenVisible] = useState(false);

  const [signOut] = useGetSignOut(getActiveAuth());

  const { data: tokenData } = useGetTokenDataQuery({}, { skip: !currentUser });

  const handleGoBack = () => navigateBackOr(getAdminHomePath());

  const [imitateAccount, { isLoading: isAccountImitating }] = useImitateUserAccountMutation();

  const [getCustomToken] = useLazyGetCustomTokenQuery();

  const handleFormSubmit = (values: ImitateAccountFormValues) => {
    imitateAccount(values)
      .unwrap()
      .then(async (data) => {
        await signOut();
        await signInWithCustomToken(getActiveAuth(), data.customToken);
      })
      .then(() => navigate(getInitAppPath()));
  };
  const schema = Yup.object({
    email: Yup.string().required(t('forms_common:field_mandatory')).email(t('forms_common:email_format_wrong')),
    token: Yup.string(),
  });

  const formik = useFormik<ImitateAccountFormValues>({
    initialValues: {
      email: '',
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  const openBankIdModalToImitateAccount = useBankId({
    sessionKey: 'user',
    onSuccess: () => {
      formik.submitForm();
    },
  });

  const handleSave = () => {
    if (tokenData && validate2FA(tokenData)) {
      formik.submitForm();
    } else {
      openBankIdModalToImitateAccount();
    }
  };

  const handleSetField = getHandleSetField<ImitateAccountFormValues>(formik);

  const openBankIdModalToShareToken = useBankId({
    sessionKey: 'shareToken',
    onSuccess: () => {
      getCustomToken()
        .unwrap()
        .then((customTokenToSignIn) => {
          copyToClipboard(customTokenToSignIn);
          enqueueSnackbar(t('admin:impersonate_token_is_copied'), {
            variant: 'success',
          });
        });
    },
  });

  const handleShareCustomToken = () => openBankIdModalToShareToken();

  const handleApplySharedCustomToken = async (customToken: string) => {
    await signOut();
    await signInWithCustomToken(getActiveAuth(), customToken)
      .then(() => {
        navigate(getImitateAccountPath());
        enqueueSnackbar(t('admin:impersonate_token_is_applied'), {
          variant: 'success',
        });
      });
  };

  const customActions = [
    {
      id: 'share',
      Icon: ScreenShareOutlined,
      label: t('admin:impersonate_share_token'),
      onClick: handleShareCustomToken,
    },
    {
      id: 'apply',
      Icon: LoginSharp,
      label: t('admin:impersonate_apply_token'),
      onClick: () => setIsApplySharedTokenVisible(true),
    },
  ].filter(Boolean);

  return (
    <HomeLayout
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <ViewEntityActions
            customActions={customActions}
          />
        </Card>
      }
      sideDrawerElements={[
        <ViewEntityActions
          customActions={customActions}
          key={ViewEntityActions.name}
        />,
      ]}
      title={t('admin:admin_menu_imitate_account')}
      onBack={handleGoBack}
    >
      <Grid
        container
        columnSpacing={2.5}
        justifyContent="flex-start"
        rowSpacing={1}
      >
        <Grid item lg={12} xxs={12}>
          <HIDTextField
            required
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={formik.touched.email ? formik.errors.email : undefined}
            id="email"
            label={t('forms_common:email_title')}
            value={formik.values.email}
            onBlur={formik.handleBlur('email')}
            onChange={handleSetField('email')}
          />
        </Grid>
      </Grid>
      <CreateContentPageBottomToolbar
        disabled={!formik.isValid || !formik.dirty}
        loading={isAccountImitating}
        saveButtonLabel={t('forms_common:continue')}
        sx={{ marginTop: theme.spacing(2) }}
        onCancel={handleGoBack}
        onSave={handleSave}
      />
      <ApplySharedTokenDialog
        open={isApplySharedTokenVisible}
        onClose={() => setIsApplySharedTokenVisible(false)}
        onSubmit={handleApplySharedCustomToken}
      />
    </HomeLayout>

  );
};

export default ImitateAccount;
