import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Error } from '@mui/icons-material';
import { FCC } from '../../../types/common';
import { useGetPropertiesQuery } from '../api/property.api';
import useGetPropertyAccessDate from '../hooks/useGetPropertyAccessDateText';
import useGetSellingPropertyStageBasedText from '../modules/SellingProperty/hooks/useGetSellingPropertyStageBasedText';

type PropertyRowAddressProps = {
  propertyId: string;
  showAddress?: boolean;
};

const PropertyRowAddress: FCC<PropertyRowAddressProps> = ({
  propertyId,
  showAddress = true,
}) => {
  const { t } = useTranslation(['property']);
  const theme = useTheme();
  const { data: properties } = useGetPropertiesQuery();
  const property = properties?.find((p) => p.id === propertyId);

  const propertyAccessDate = useGetPropertyAccessDate({ propertyId });

  const sellingPropertyText = useGetSellingPropertyStageBasedText({
    property,
  });

  const isVerified = property?.isVerified;
  const propertyName = property?.displayName;

  const VerifiedComponent = isVerified
    ? (
      <Stack>
        {showAddress && property.alias && (
          <Typography variant="body1">
            {property.address?.streetAddress}
          </Typography>
        )}
        {property.address?.addressLocality && (
          <Typography color={theme.palette.grey[500]} variant="body1">
            {property.address?.addressLocality}
          </Typography>
        )}
      </Stack>
    )
    : null;

  return (
    <Stack>
      <Typography variant="subtitle1">
        {propertyName}
      </Typography>
      {propertyAccessDate
        ? (
          <Stack alignItems="center" direction="row" spacing={0.25}>
            <Error color="warning" />
            <Typography
              color={theme.palette.grey[400]}
              variant="body1"
            >
              {t('property:property_access_date_title', { date: propertyAccessDate })}
            </Typography>
          </Stack>
        )
        : (
          <>
            {VerifiedComponent}
            {Boolean(sellingPropertyText.label) && (
              <Typography variant="body1">
                {sellingPropertyText.label}
              </Typography>
            )}
          </>
        )}
    </Stack>
  );
};

export default PropertyRowAddress;
