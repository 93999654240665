import { useAuthState } from 'react-firebase-hooks/auth';

import EntityType from '../../../constants/entityType';
import { getActiveAuth } from '../../../external-services/firebase';
import { useGetTokenDataQuery } from '../../Auth/api/user.api';
import useGetCurrentPropertyId from '../../Property/hooks/useGetCurrentPropertyId';
import { useReportEventMutation } from '../api/analytics.api';
import { logCustomEvent } from '../external-services/googleAnalytics';
import {
  PartnersSource,
  EventReportSource,
  AnalyticsEvent,
} from '../types.analytics';

export type ReportEventParams = {
  event: AnalyticsEvent;
  source?: PartnersSource | string,
  entityType?: EntityType,
  eventReportSource?: EventReportSource,
  params?: object
};

type UseGetReportEvent = () => (params: ReportEventParams) => void;

const useGetLogAnalyticsEvent: UseGetReportEvent = () => {
  const [reportEventMutation] = useReportEventMutation();

  const [currentUser] = useAuthState(getActiveAuth());
  const { data: propertyId } = useGetCurrentPropertyId(undefined, { skip: !currentUser });

  const { data: tokenData } = useGetTokenDataQuery({}, { skip: !currentUser });

  const logEvent = (
    params: ReportEventParams,
  ) => {
    const defaultQueryParams = {
      propertyId,
      userId: tokenData?.userId,
      subscriptionPlanId: tokenData?.subscriptionPlanId,
    };
    const eventReportSource = params.eventReportSource || EventReportSource.GA;
    const category = params.entityType || 'unknown';
    const source = params.source || PartnersSource.HOUSE_ID;

    if ([EventReportSource.ALL, EventReportSource.DATABASE].includes(eventReportSource)) {
      reportEventMutation({
        type: params.event,
        source,
        category,
        ...defaultQueryParams,
        ...params.params,
      });
    }
    if ([EventReportSource.ALL, EventReportSource.GA].includes(eventReportSource)) {
      logCustomEvent(params.event, source, category, defaultQueryParams);
    }
  };

  return logEvent;
};

export default useGetLogAnalyticsEvent;
