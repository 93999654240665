import React, {
  FC,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Stack,
  Tab,
} from '@mui/material';
import {
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { ExpenseBankAccount } from '@house-id/houseid-types/dist/finances/recurringExpenses';
import {
  BudgetShortModel,
  BudgetTemplateCategoryId,
} from '@house-id/houseid-types/dist/finances/budgets';

import HIDTypography from '../../../../../../../components/HIDTypography';
import HIDFormSelect, { HIDSelectItem } from '../../../../../../../components/HIDFormSelect';
import TimeIntervalsSection from '../../../../Content/components/sections/TimeIntervalsSection';
import { TimeIntervalsGroupingType } from '../../../../Content/types/content.type';
import HIDMonthRangePicker from '../../../../../../../components/datePicker/HIDMonthRangePicker';
import HIDFormMultiSelect from '../../../../../../../components/HIDFormMultiSelect';
import { FinancesFilterSectionMode } from '../../../types.finances';
import { getBudgetName } from '../utils/budget.utils';

export type BudgetsFilters = {
  mode: FinancesFilterSectionMode;
  bankAccountIds?: Array<string>;
  primaryBudgetId?: string;
  secondaryBudgetId?: string;
  compareToYear?: number;
  fromMonth?: number;
  toMonth?: number;
  timeIntervalGroupingType: TimeIntervalsGroupingType;
  categoryId?: BudgetTemplateCategoryId
};

type BudgetsFilterSectionProps = {
  showComparison?: boolean;
  filters: BudgetsFilters;
  bankAccounts?: Array<ExpenseBankAccount>;
  timeIntervalGroupingType?: TimeIntervalsGroupingType;
  years?: Array<number>;
  budgets?: Array<BudgetShortModel>;
  onChange: (filters: BudgetsFilters) => void;
};

const BudgetsFilterSection: FC<BudgetsFilterSectionProps> = ({
  showComparison = true,
  filters,
  bankAccounts,
  years,
  budgets,
  onChange,
}) => {
  const { t } = useTranslation(['common', 'finances']);

  const [activeMode, setActiveMode] = useState<FinancesFilterSectionMode>(filters.mode || FinancesFilterSectionMode.Filter);

  const bankAccountsItems = bankAccounts?.map(({ id, name, bban }) => ({ id, name: name || bban }));

  const budgetItems = useMemo(() => budgets?.map((budget) => ({ id: budget.id, name: getBudgetName(budget) })), [budgets]);

  const handleChange = (_event: React.SyntheticEvent, newMode: FinancesFilterSectionMode) => {
    setActiveMode(newMode);
    onChange({ ...filters, mode: newMode });
  };

  const budgetAndYearList = [
    { id: 'budgets', name: t('finances:budgets'), isCategory: true },
    ...(budgetItems || []),
    { id: 'years', name: t('finances:fixed_expenses'), isCategory: true },
    ...(years || []).map((year) => ({ id: year.toString(), name: year.toString() })),
  ] as Array<HIDSelectItem>;

  return (
    <Stack sx={{ mt: -1 }}>
      <TabContext value={activeMode}>
        {
          showComparison
            ? (
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange}>
                  <Tab
                    label={<HIDTypography variant="h6">{t('common:filter')}</HIDTypography>}
                    value={FinancesFilterSectionMode.Filter}
                  />
                  <Tab
                    label={<HIDTypography variant="h6">{t('finances:comparison')}</HIDTypography>}
                    value={FinancesFilterSectionMode.Compare}
                  />
                </TabList>
              </Box>
            )
            : (
              <HIDTypography variant="h6">{t('common:filter')}</HIDTypography>
            )
        }
        <TabPanel sx={{ p: 0 }} value={FinancesFilterSectionMode.Filter}>
          <HIDMonthRangePicker
            from={filters.fromMonth}
            to={filters.toMonth}
            onChange={({ from, to }) => onChange({ ...filters, fromMonth: from, toMonth: to })}
          />
          <HIDFormSelect
            items={budgetItems || []}
            label={t('finances:budget_selector_label')}
            showHelperText={false}
            sx={{ mt: 2 }}
            value={filters.primaryBudgetId || ''}
            onChange={(event) => onChange({ ...filters, primaryBudgetId: event.target.value })}
          />
          <TimeIntervalsSection
            showQuarterly
            sx={{ mt: 2 }}
            timeIntervalGroupingType={filters.timeIntervalGroupingType}
            onChange={(timeIntervalGroupingType) => onChange({ ...filters, timeIntervalGroupingType })}
          />
        </TabPanel>
        {showComparison && (
          <TabPanel sx={{ p: 0 }} value={FinancesFilterSectionMode.Compare}>
            <HIDMonthRangePicker
              from={filters.fromMonth}
              to={filters.toMonth}
              onChange={({ from, to }) => onChange({ ...filters, fromMonth: from, toMonth: to })}
            />
            <HIDFormSelect
              items={budgets || []}
              label={t('finances:budget_selector_label')}
              showHelperText={false}
              sx={{ mt: 2 }}
              value={filters.primaryBudgetId || ''}
              onChange={(event) => onChange({ ...filters, primaryBudgetId: event.target.value })}
            />
            <HIDFormSelect
              showClear
              items={budgetAndYearList}
              label={t('common:compare')}
              showHelperText={false}
              sx={{ mt: 2 }}
              value={filters.secondaryBudgetId || filters.compareToYear?.toString()}
              onChange={(event) => {
                const yearOrBudget = event.target.value;
                if (!yearOrBudget) {
                  onChange({ ...filters, secondaryBudgetId: undefined, compareToYear: undefined });
                } else if (yearOrBudget.length <= 4) {
                  onChange({ ...filters, secondaryBudgetId: undefined, compareToYear: Number.parseInt(event.target.value, 10) });
                } else {
                  onChange({ ...filters, secondaryBudgetId: event.target.value, compareToYear: undefined });
                }
              }}
            />
            <HIDFormMultiSelect
              items={bankAccountsItems || []}
              label={t('finances:select_accounts')}
              sx={{ mt: 2 }}
              value={filters.bankAccountIds || []}
              onChange={(event) => onChange({ ...filters, bankAccountIds: event.target.value as Array<string> })}
            />
            <TimeIntervalsSection
              showQuarterly
              sx={{ mt: 2 }}
              timeIntervalGroupingType={filters.timeIntervalGroupingType}
              onChange={(timeIntervalGroupingType) => onChange({ ...filters, timeIntervalGroupingType })}
            />
          </TabPanel>
        )}
      </TabContext>
    </Stack>
  );
};

export default BudgetsFilterSection;
