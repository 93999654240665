enum EntityType {
  ARTICLE = 'article',
  BUILDING = 'building',
  BUILDING_PART = 'building-part',
  BONUS_OFFER = 'bonus_offer',
  BUDGET = 'budget',
  CONTACT = 'contact',
  CONTENT_FILE = 'content-file',
  DOCUMENT = 'document',
  RECURRING_EXPENSE = 'recurring-expense',
  MESSAGE = 'message',
  NOTE = 'note',
  OUTDOOR = 'outdoor',
  PHOTO = 'photo',
  PRODUCT = 'product',
  PROPERTY = 'property',
  RECEIPT = 'receipt',
  ROOM = 'room',
  TASK = 'task',
  TIMELINE_ENTRY = 'timeline-entry',
  VALUATION = 'valuation',
  EXTERNAL_SERVICE = 'external-service',
}

export default EntityType;
