import React, {
  FC,
  useEffect,
} from 'react';
import {
  Card,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { useNavigate } from 'react-router';
import { SmsOutlined } from '@mui/icons-material';

import { useGetCurrentUserQuery } from '../../../../Auth/api/user.api';
import HIDButton from '../../../../../components/buttons/HIDButton';
import HIDImage from '../../../../../components/image/HIDImage';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import HorizontalOvalImageCut from '../../../../../components/svg/HorizontalOvalImageCut';
import VerticalOvalImageCut from '../../../../../components/svg/VerticalOvalImageCut';
import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import { WelcomeDialogProps } from './WelcomeDialog';
import {
  HomeWidgetType,
  useGetWidgetHeight,
} from '../utils/useGetWidgetHeight';
import PropertyImage from '../../../../../assets/images/property.jpg';
import useGetCurrentProperty from '../../../hooks/useCurrentProperty';
import {
  ThumbnailSize,
  getFirstLargeImageBlob,
  getImageThumbnailUrl,
} from '../../../../../utils/image';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import {
  getFeedbackPath,
  getPathWithPropertyIdOrInit,
} from '../../../../Auth/navigation/navigation.auth';
import useGetPropertyPermissions from '../../../hooks/useGetPropertyPermissions';
import { InfoDialogDialogProps } from '../../../components/dialogs/InfoDialog';
import HorizontalImagePlaceholder from '../../../../../components/svg/HorizontalImagePlaceholder';
import useAreasVisibility from '../hooks/useAreasVisibility';
import useGetSellingCurrentPropertyStageBasedText from '../../../modules/SellingProperty/hooks/useGetSellingCurrentPropertyStageBasedText';

const WelcomeWidget: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'home']);
  const theme = useTheme();

  const { data: currentProperty, isLoading: isPropertyLoading } = useGetCurrentProperty();
  const { data: { canCreate, canUpdate } = {}, isLoading: isLoadingPermissions } = useGetPropertyPermissions();

  const { data: propertyId } = useGetCurrentPropertyId();
  const [updateVisibleAreasMap, areasVisibilityMap, isLoadingAreasVisibility] = useAreasVisibility();

  const { data: user } = useGetCurrentUserQuery();

  const isLoading = isPropertyLoading
    || isLoadingPermissions
    || isLoadingAreasVisibility;

  useEffect(() => () => {
    if (!areasVisibilityMap.isHiddenInitialGreeting) {
      updateVisibleAreasMap({ isHiddenInitialGreeting: true });
    }
  }, []);

  const userName = user?.givenName || '';

  const firstBlob = currentProperty?.blobs
    ? R.head(currentProperty?.blobs?.filter(getFirstLargeImageBlob)) || R.head(currentProperty?.blobs)
    : undefined;

  const {
    isDownMd,
    isDownSm,
    isDownXs,
  } = useBreakPointsSizes();

  const imageAndWidgetHeight = useGetWidgetHeight(HomeWidgetType.WELCOME);

  const [openWelcomeDialog] = useDialog<WelcomeDialogProps>(DialogNames.WELCOME_DIALOG);

  const [openInfoDialog] = useDialog<InfoDialogDialogProps>(DialogNames.INFO_DIALOG);

  const currentText = useGetSellingCurrentPropertyStageBasedText();

  const handleReadMore = () => {
    if (canCreate && canUpdate) {
      openWelcomeDialog({ userName });
    } else {
      openInfoDialog({
        title: currentText.title,
        description: currentText.description,
      });
    }
  };

  if (isLoading) {
    const buttonHeight = isDownSm ? 34 : 40;

    return (
      <Card sx={{ height: imageAndWidgetHeight || 'unset' }}>
        <Stack
          direction={isDownSm ? 'column' : 'row'}
          spacing={1}
          sx={{
            padding: theme.spacing(4),
            height: 'inherit',
          }}
        >
          <Stack spacing={2}>
            <Skeleton sx={{ marginBottom: theme.spacing(1) }} variant="rounded" width="200px" />
            <Stack>
              <Skeleton variant="text" width="50%" />
              <Skeleton variant="text" width="40%" />
            </Stack>
            <Stack
              alignItems="flex-end"
              direction="row"
              flexGrow={1}
              spacing={2.5}
            >
              <Skeleton
                height={buttonHeight}
                sx={{ borderRadius: 20 }}
                variant="rounded"
                width="110px"
              />
              <Skeleton
                height={buttonHeight}
                sx={{ borderRadius: 20 }}
                variant="rounded"
                width="170px"
              />
            </Stack>
          </Stack>
          {!isDownSm && (
            <Skeleton
              sx={{
                display: 'flex',
                color: theme.palette.shadow[100],
                backgroundColor: 'unset',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              variant="rounded"
              width="100%"
            >
              <HorizontalImagePlaceholder
                style={{
                  maxWidth: '150px',
                }}
              />
            </Skeleton>
          )}
        </Stack>
      </Card>
    );
  }

  const bgColor = canCreate && canUpdate ? theme.palette.primary.main : theme.palette.warning.main;
  const isPersistentGreeting = areasVisibilityMap.isHiddenInitialGreeting;

  const welcomeWidgetTitle = isPersistentGreeting
    ? t('home:home_welcome_widget_title', { name: userName })
    : t('home:home_welcome_widget_initial_title');

  const welcomeWidgetSubTitle = isPersistentGreeting
    ? t('home:home_welcome_widget_subtitle')
    : t('home:home_welcome_widget_initial_subtitle');

  const Description = (
    <Stack
      spacing={isPersistentGreeting ? 2 : 1}
      sx={{
        padding: isDownXs
          ? theme.spacing(1.5)
          : theme.spacing(2.5, 0, 2.5, 2.5),
        height: imageAndWidgetHeight || 'unset',
      }}
    >
      <Typography
        sx={{
          lineHeight: 1.2,
          color: theme.palette.common.white,
        }}
        variant="h5"
      >
        {welcomeWidgetTitle}
      </Typography>
      <Typography
        sx={{
          lineHeight: 1.2,
          color: theme.palette.common.white,
          maxWidth: 300,
        }}
        variant="body1"
      >
        {
          canCreate && canUpdate
            ? welcomeWidgetSubTitle
            : t('home:home_property_read_only_access_label')
        }
      </Typography>
      <Stack
        direction="row"
        flexGrow={1}
        flexWrap="nowrap"
        spacing={isDownMd ? 1 : 2}
      >
        <HIDButton
          noWrap
          color="blank"
          size={isDownXs ? 'small' : 'medium'}
          sx={{
            alignSelf: 'flex-end',
            '&:hover': canCreate && canUpdate
              ? undefined
              : {
                backgroundColor: theme.palette.warning.light,
              },
            '&:active': canCreate && canUpdate
              ? undefined
              : {
                backgroundColor: theme.palette.warning.dark,
              },
          }}
          onClick={handleReadMore}
        >
          {t('common:read_more')}
        </HIDButton>
        <HIDButton
          noWrap
          Icon={() => <SmsOutlined sx={{ marginTop: 0.25 }} />}
          childrenContainerSx={
            canCreate && canUpdate
              ? undefined
              : {
                color: theme.palette.warning.dark,
              }
          }
          color="secondary"
          size={isDownXs ? 'small' : 'medium'}
          sx={
            canCreate && canUpdate
              ? {
                alignSelf: 'flex-end',
              }
              : {
                alignSelf: 'flex-end',
                color: theme.palette.warning.dark,
                borderColor: theme.palette.warning.dark,
              }
          }
          onClick={() => navigate(getPathWithPropertyIdOrInit(getFeedbackPath, { propertyId }))}
        >
          {t('home:home_give_feedback')}
        </HIDButton>
      </Stack>
    </Stack>
  );

  return (
    <Card
      sx={{
        backgroundColor: bgColor,
      }}
    >
      <Stack
        direction={isDownSm ? 'column' : 'row'}
        justifyContent="space-between"
        spacing={1}
      >
        {!isDownSm && Description}
        <Stack
          direction={isDownSm ? 'column' : 'row'}
          sx={{
            position: 'relative',
            width: isDownMd ? 'unset' : '55%',
          }}
        >
          <HIDImage
            showSkeleton
            containerSx={{ width: '100%' }}
            iconColor={theme.palette.common.white}
            skeletonSx={{
              width: '100%',
              height: imageAndWidgetHeight,
            }}
            sx={{
              width: '100%',
              height: imageAndWidgetHeight,
              objectFit: 'cover',
              borderTopRightRadius: '5px',
              borderBottomRightRadius: '5px',
            }}
            url={
              !isPropertyLoading && !firstBlob?.thumbnailUrlTemplate
                ? PropertyImage
                : firstBlob?.thumbnailUrlTemplate
                  ? getImageThumbnailUrl(firstBlob.thumbnailUrlTemplate, ThumbnailSize.MAX_2600)
                  : undefined
            }
          />
          {
            isDownSm
              ? <HorizontalOvalImageCut color={bgColor} />
              : <VerticalOvalImageCut color={bgColor} />
          }
        </Stack>
        {isDownSm && Description}
      </Stack>
    </Card>
  );
};

export default WelcomeWidget;
