import * as R from 'ramda';

export const BASE_URL = `${import.meta.env.VITE_APP_BACKEND_URL}`;

export const getUrlSearchParams = (params: Record<string, string | number | undefined>) => {
  const cleanParams = R.map((value) => value === undefined ? '' : value, params);
  // @ts-ignore
  return new URLSearchParams(cleanParams).toString();
};

export const capitalize = R.replace(/^./, R.toUpper);
