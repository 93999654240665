import qs from 'query-string';

import {
  CreateEntity,
  HIDEntityId,
  HIDUploadFileContainer,
  PaginationParams,
} from '../../../types/common';
import { propertyApi } from '../../Property/api/property.api';
import {
  ForumQuestion,
  ForumRepliesOrder,
  ForumReply,
  ForumThread,
  ForumThreadsSortOrder,
  ForumTopic,
  ForumVote,
} from '../forum.types';
import { nullify } from '../../../utils/object';
import { HIDApiTags } from '../../../api/HIDApiTags';
import { provideArrayTags } from '../../../api/HIDBaseQuery';

type GetForumThreadsRequestParams = Partial<PaginationParams> & {
  sort?: ForumThreadsSortOrder;
  createdByMe?: boolean;
  followed?: boolean;
};

type GetForumTopicThreadsRequestParams = Partial<PaginationParams> & {
  sort?: ForumThreadsSortOrder;
  topicId: string;
};

type GetForumThreadsResponse = {
  threads: Array<ForumThread>;
  count: number;
};

type GetForumRepliesResponse = {
  replies: Array<ForumReply>;
  count: number;
  sort: ForumRepliesOrder;
};

export const forumApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getForumTopics: builder.query<Array<ForumTopic>, void>({
      query: () => '/forum/topics',
    }),
    getForumThreads: builder.query<GetForumThreadsResponse, GetForumThreadsRequestParams>({
      query: (params) => `/forum/threads?${qs.stringify(params)}`,
      providesTags: (result) => provideArrayTags(HIDApiTags.FORUM_THREAD, result?.threads),
    }),
    getForumTopicThreads: builder.query<GetForumThreadsResponse, GetForumTopicThreadsRequestParams>({
      query: ({
        topicId,
        sort,
        pageSize,
        offset,
      }) => `/forum/topics/${topicId}/threads?${qs.stringify({ sort, pageSize, offset })}`,
      providesTags: (result) => provideArrayTags(HIDApiTags.FORUM_THREAD, result?.threads),
    }),
    getForumThread: builder.query<ForumThread, { threadId: string }>({
      query: ({ threadId }) => `/forum/threads/${threadId}`,
      providesTags: (_result, _error, arg) => [{ type: HIDApiTags.FORUM_THREAD, id: arg.threadId }],
    }),
    getForumReplies: builder.query<GetForumRepliesResponse, { threadId: string, sort?: ForumRepliesOrder }>({
      query: ({ threadId, sort }) => `/forum/threads/${threadId}/replies?${qs.stringify({ sort })}`,
      providesTags: (result) => provideArrayTags(HIDApiTags.FORUM_REPLY, result?.replies),
    }),
    getExpertThreads: builder.query<GetForumThreadsResponse, { notAnswered: boolean }>({
      query: ({ notAnswered }) => `/forum/expert/threads?${qs.stringify({ notAnswered })}`,
      providesTags: (result) => provideArrayTags(HIDApiTags.FORUM_THREAD, result?.threads),
    }),
    createThread: builder.mutation<ForumThread, CreateEntity<ForumQuestion>>({
      query: (thread) => ({
        url: '/forum/threads',
        method: 'POST',
        body: thread,
      }),
      invalidatesTags: [HIDApiTags.FORUM_THREAD],
    }),
    updateThread: builder.mutation<ForumThread, Partial<ForumThread> & HIDEntityId>({
      query: (thread) => ({
        url: `/forum/threads/${thread.id}`,
        method: 'PATCH',
        body: nullify(thread),
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: HIDApiTags.FORUM_THREAD, id: arg.id }],
    }),
    deleteThread: builder.mutation<ForumThread, HIDEntityId>({
      query: ({ id }) => ({
        url: `/forum/threads/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: HIDApiTags.FORUM_THREAD, id: arg.id }],
    }),
    createReply: builder.mutation<ForumReply, CreateEntity<ForumReply>>({
      query: (reply) => ({
        url: `/forum/threads/${reply.threadId}/replies`,
        method: 'POST',
        body: reply,
      }),
      invalidatesTags: [HIDApiTags.FORUM_REPLY, HIDApiTags.FORUM_THREAD],
    }),
    updateReply: builder.mutation<ForumReply, Partial<ForumReply> & HIDEntityId>({
      query: (reply) => ({
        url: `/forum/threads/${reply.threadId}/replies/${reply.id}`,
        method: 'PATCH',
        body: reply,
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: HIDApiTags.FORUM_REPLY, id: arg.id }],
    }),
    deleteReply: builder.mutation<ForumReply, { threadId: string } & HIDEntityId>({
      query: ({ threadId, id }) => ({
        url: `/forum/threads/${threadId}/replies/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [HIDApiTags.FORUM_REPLY],
    }),
    updateForumReplyVote: builder.mutation<void, { threadId: string, replyId: string, vote: ForumVote }>({
      query: ({ threadId, replyId, vote }) => ({
        url: `/forum/threads/${threadId}/replies/${replyId}/vote`,
        method: 'PUT',
        body: { vote },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: HIDApiTags.FORUM_REPLY, id: arg.replyId }],
    }),
    deleteForumReplyVote: builder.mutation<void, { threadId: string, replyId: string }>({
      query: ({ threadId, replyId }) => ({
        url: `/forum/threads/${threadId}/replies/${replyId}/vote`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: HIDApiTags.FORUM_REPLY, id: arg.replyId }],
    }),
    createBlobUploadUrl: builder.mutation<Array<HIDUploadFileContainer>, { files: Array<File> }>({
      query: ({ files }) => ({
        url: '/forum/blobs',
        method: 'POST',
        body: files.map((file) => ({
          name: file.name,
          size: file.size,
          mime: file.type,
        })),
      }),
    }),
    uploadFileBlob: builder.mutation<void, { signedUploadUrl: string, file: File, type: string }>({
      queryFn: async ({ signedUploadUrl, file, type }) => {
        try {
          await fetch(signedUploadUrl, {
            method: 'PUT',
            body: file,
            credentials: 'same-origin',
            headers: {
              'Content-Type': type,
              'Content-Disposition': `attachment; filename="${file.name}"`,
            },
          });
        } catch (e) {
          return { error: { status: 500, statusText: 'Internal Server Error', data: undefined } };
        }

        return { data: undefined };
      },
    }),
    addToBookmarks: builder.mutation<void, { threadId: string }>({
      query: ({ threadId }) => ({
        url: `/forum/threads/${threadId}/follow`,
        method: 'POST',
      }),
      invalidatesTags: [HIDApiTags.FORUM_THREAD],
    }),
    removeFromBookmarks: builder.mutation<void, { threadId: string }>({
      query: ({ threadId }) => ({
        url: `/forum/threads/${threadId}/follow`,
        method: 'DELETE',
      }),
      invalidatesTags: [HIDApiTags.FORUM_THREAD],
    }),
  }),
});

export const {
  useGetForumTopicsQuery,
  useGetForumThreadsQuery,
  useGetForumTopicThreadsQuery,
  useGetForumThreadQuery,
  useGetForumRepliesQuery,
  useGetExpertThreadsQuery,
  useCreateThreadMutation,
  useUpdateThreadMutation,
  useDeleteThreadMutation,
  useCreateReplyMutation,
  useUpdateReplyMutation,
  useDeleteReplyMutation,
  useUpdateForumReplyVoteMutation,
  useDeleteForumReplyVoteMutation,
  useCreateBlobUploadUrlMutation,
  useUploadFileBlobMutation,
  useAddToBookmarksMutation,
  useRemoveFromBookmarksMutation,
} = forumApi;
