import {
  Stack,
  useTheme,
  Skeleton,
} from '@mui/material';
import React, { FC } from 'react';
import { getTypographyHeight } from '../../utils/style';
import { FCC } from '../../types/common';

const SkeletonRow: FC<{ spacing: number; height: number; }> = ({
  spacing,
  height,
}) => {
  const theme = useTheme();

  return (
    <Skeleton
      height={height}
      sx={{ marginLeft: theme.spacing(spacing), marginTop: theme.spacing(1.5) }}
      variant="rounded"
      width={`calc(100% - ${theme.spacing(spacing)})`}
    />
  );
};

// eslint-disable-next-line react/no-multi-comp
const TreeStructureLoaderSkeleton: FCC<{ isLoading: boolean }> = ({
  isLoading,
  children,
}) => {
  const theme = useTheme();

  if (isLoading) {
    const height = getTypographyHeight(theme.typography.body2);

    return (
      <Stack direction="column" sx={{ padding: theme.spacing(0.5, 1) }}>
        <Skeleton height={height} variant="rounded" width="50%" />
        <SkeletonRow height={height} spacing={2} />
        <SkeletonRow height={height} spacing={5} />
        <SkeletonRow height={height} spacing={8} />
        <SkeletonRow height={height} spacing={2} />
        <SkeletonRow height={height} spacing={5} />
        <SkeletonRow height={height} spacing={8} />
        <Skeleton
          height={height}
          sx={{ marginTop: theme.spacing(1.5) }}
          variant="rounded"
          width="100%"
        />
      </Stack>
    );
  }

  return children;
};

export default TreeStructureLoaderSkeleton;
