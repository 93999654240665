import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import {
  FormControl,
  FormLabel,
  Grid,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import * as R from 'ramda';
import { skipToken } from '@reduxjs/toolkit/query';

import HomeLayout from '../../../../../../pages/Home/components/HomeLayout';
import { getTimelinePath } from '../../navigation.timeline';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import {
  useNavigateBackOr,
  useNavigationParamsAndState,
} from '../../../../../../../../utils/routes';
import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import HIDTextField from '../../../../../../../../components/HIDTextField';
import CreateContentPageBottomToolbar, {
  CreateContentSaveMode,
  WithSaveMode,
} from '../../../../components/CreateContentPageBottomToolbar';
import { IdPropRoute } from '../../../../../../../../types/route';
import { CreateEntity } from '../../../../../../../../types/common';
import { CustomTimelineEvent } from '../../types.timeline';
import { getHandleSetField } from '../../../../../../../../utils/form';
import HIDFormDatePicker from '../../../../../../../../components/datePicker/HIDFormDatePicker';
import {
  useGetCustomEvent,
  useCreateCustomEventMutation,
  useGetCustomEventCategoriesQuery,
  useUpdateCustomEventMutation,
} from '../../api/timeline.api';
import { EntityConnectionsCommonRouteState } from '../../../../types/content.type';
import EntityType from '../../../../../../../../constants/entityType';
import useManageConnectionAfterCreateOrUpdate from '../../../../hooks/useManageConnectionAfterCreateOrUpdate';
import HIDYesNoGroup from '../../../../../../../../components/HIDYesNoGroup';
import HIDFormSelect from '../../../../../../../../components/HIDFormSelect';
import { DEFAULT_CUSTOM_EVENT_CATEGORY } from '../../constants.timeline';
import useGetLogAnalyticsEvent from '../../../../../../../Analytics/hooks/useGetLogAnalyticsEvent';
import { AnalyticsEvent } from '../../../../../../../Analytics/types.analytics';
import { getPathWithPropertyIdOrInit } from '../../../../../../../Auth/navigation/navigation.auth';

const CreateUpdateTimelineEvent: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'forms_common', 'timeline']);
  const navigateBackOr = useNavigateBackOr();
  const { isDownXl } = useBreakPointsSizes();

  const {
    routeParams: {
      id: eventId,
    },
    state: {
      connections,
    },
  } = useNavigationParamsAndState<IdPropRoute, unknown, EntityConnectionsCommonRouteState>();

  const hasConnections = connections && !R.isEmpty(connections);

  const { data: propertyId } = useGetCurrentPropertyId();

  const isUpdate = Boolean(eventId);

  const {
    customEvent,
    isLoading: eventIsLoading,
  } = useGetCustomEvent({ propertyId, eventId });

  const [isRange, setIsRange] = useState(Boolean(customEvent?.end));

  useEffect(() => {
    if (customEvent !== undefined && Boolean(customEvent.end)) {
      setIsRange(true);
    }
  }, [customEvent]);

  const { data: categories = [] } = useGetCustomEventCategoriesQuery(propertyId ? { propertyId } : skipToken);

  const [createEvent, { isLoading: eventIsCreating }] = useCreateCustomEventMutation();
  const [updateEvent, { isLoading: eventIsUpdating }] = useUpdateCustomEventMutation();

  const handleGoBack = () => navigateBackOr(getPathWithPropertyIdOrInit(getTimelinePath, { propertyId }));

  const logAnalyticsEvent = useGetLogAnalyticsEvent();

  const {
    afterUpdate,
    afterCreate,
  } = useManageConnectionAfterCreateOrUpdate({
    entityType: EntityType.TIMELINE_ENTRY,
    connections,
    onGoBack: handleGoBack,
  });

  const handleFormSubmit = (values: WithSaveMode<CreateEntity<CustomTimelineEvent>>) => {
    const { saveMode } = values;

    if (!propertyId) {
      return;
    }

    const eventFields = {
      propertyId,
      title: values.title,
      categoryId: values.categoryId,
      annotation: values.annotation,
      start: values.start,
      end: values.end || undefined,
    };

    if (isUpdate && eventId) {
      updateEvent({ id: eventId, ...eventFields })
        .unwrap()
        .then((updatedEvent) => afterUpdate(updatedEvent, saveMode));
    } else {
      createEvent(eventFields)
        .unwrap()
        .then((createdEvent) => {
          logAnalyticsEvent({
            event: AnalyticsEvent.CREATE_TIMELINE_CUSTOM_EVENT,
            entityType: EntityType.TIMELINE_ENTRY,
          });
          afterCreate(createdEvent, saveMode);
        });
    }
  };

  const schema = Yup.object({
    title: Yup.string().required(t('forms_common:field_mandatory')),
    categoryId: Yup.string().required(t('forms_common:field_mandatory')),
    annotation: Yup.string().optional().nullable(),
    start: Yup.string().required(t('forms_common:field_mandatory')),
    end: Yup.string().test('required', t('forms_common:field_mandatory'), (value) => Boolean(value || !isRange)),
    saveMode: Yup.string().optional().nullable(),
  });

  const formik = useFormik<CreateEntity<CustomTimelineEvent>>({
    initialValues: {
      propertyId: propertyId || '',
      title: customEvent?.title || '',
      categoryId: customEvent?.categoryId || DEFAULT_CUSTOM_EVENT_CATEGORY,
      annotation: customEvent?.annotation,
      start: customEvent?.start || '',
      end: customEvent?.end || '',
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  const handleSave = (saveMode: CreateContentSaveMode) => {
    formik.setFieldValue('saveMode', saveMode);
    formik.submitForm();
  };

  const handleSetField = getHandleSetField<CreateEntity<CustomTimelineEvent>>(formik);

  return (
    <HomeLayout
      SideColumn={isDownXl ? undefined : <div />}
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getTimelinePath, { propertyId }),
          name: t('timeline:timeline'),
        },
      ].filter(Boolean)}
      title={isUpdate ? t('timeline:edit_event') : t('timeline:add_event')}
      onBack={handleGoBack}
    >
      <Grid
        container
        columnSpacing={2.5}
        justifyContent="flex-start"
        rowSpacing={1}
      >
        <Grid item sm={6} xxs={12}>
          <HIDTextField
            required
            error={Boolean(formik.touched.title && formik.errors.title)}
            helperText={formik.touched.title ? formik.errors.title : undefined}
            id="title"
            label={t('timeline:name_event')}
            value={formik.values.title}
            onBlur={formik.handleBlur('title')}
            onChange={handleSetField('title')}
          />
        </Grid>
        <Grid item sm={6} xxs={12}>
          <HIDFormSelect
            required
            error={Boolean(formik.touched.categoryId && formik.errors.categoryId)}
            helperText={formik.touched.categoryId ? formik.errors.categoryId : undefined}
            items={categories}
            label={t('common:category')}
            value={formik.values.categoryId}
            onBlur={formik.handleBlur('categoryId')}
            onChange={handleSetField('categoryId')}
          />
        </Grid>
        <Grid item xxs={12}>
          <FormControl>
            <FormLabel id="range-group-label">
              {t('timeline:enter_both_start_and_end_dates')}
            </FormLabel>
            <HIDYesNoGroup
              direction="row"
              value={isRange}
              onChange={setIsRange}
            />
          </FormControl>
        </Grid>
        {
          isRange
            ? (
              <>
                <Grid item sm={6} xxs={12}>
                  <HIDFormDatePicker
                    required
                    error={Boolean(formik.touched.start && formik.errors.start)}
                    helperText={formik.touched.start ? formik.errors.start : undefined}
                    label={t('timeline:start_date')}
                    value={formik.values.start ? new Date(formik.values.start) : undefined}
                    onBlur={formik.handleBlur('start')}
                    onChange={(date) => formik.setFieldValue('start', date?.toISOString())}
                  />
                </Grid>
                <Grid item sm={6} xxs={12}>
                  <HIDFormDatePicker
                    required
                    error={Boolean(formik.touched.end && formik.errors.end)}
                    helperText={formik.touched.end ? formik.errors.end : undefined}
                    label={t('timeline:end_date')}
                    value={formik.values.end ? new Date(formik.values.end) : undefined}
                    onBlur={formik.handleBlur('end')}
                    onChange={(date) => formik.setFieldValue('end', date?.toISOString())}
                  />
                </Grid>
              </>
            )
            : (
              <Grid item sm={6} xxs={12}>
                <HIDFormDatePicker
                  required
                  error={Boolean(formik.touched.start && formik.errors.start)}
                  helperText={formik.touched.start ? formik.errors.start : undefined}
                  label={t('timeline:enter_date')}
                  value={formik.values.start ? new Date(formik.values.start) : undefined}
                  onBlur={formik.handleBlur('start')}
                  onChange={(date) => formik.setFieldValue('start', date?.toISOString())}
                />
              </Grid>
            )
        }
        <Grid item xxs={12}>
          <HIDTextField
            multiline
            id="annotation"
            label={t('timeline:description')}
            value={formik.values.annotation || ''}
            variant="outlined"
            onBlur={formik.handleBlur('annotation')}
            onChange={handleSetField('annotation')}
          />
        </Grid>
      </Grid>
      <CreateContentPageBottomToolbar
        disabled={eventIsLoading}
        loading={eventIsCreating || eventIsUpdating}
        showAddConnections={!isUpdate && !hasConnections}
        sx={{ marginTop: theme.spacing(2) }}
        onCancel={handleGoBack}
        onSave={handleSave}
      />
    </HomeLayout>
  );
};

export default CreateUpdateTimelineEvent;
