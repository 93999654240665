import ReactGA from 'react-ga4';

import { UserTokenData } from '../../Auth/types/auth.types';
import {
  PartnersSource,
} from '../types.analytics';

export const SUBSCRIPTION_PLAN = 'subscription-plan';
export const HAS_PROPERTY = 'has_property';
export const HAS_MULTIPLE_PROPERTIES = 'has_multiple_properties';
export const IS_ADMIN = 'is_admin';

export const initGoogleAnalytics = () => ReactGA.initialize(import.meta.env.VITE_APP_GA_MEASUREMENT_ID);

export const logCustomEvent = (
  eventName: string,
  source: PartnersSource | string,
  category: string,
  params?: object,
) => {
  const eventPrams = {
    category,
    label: window.location.pathname,
    source,
    ...params,
  };

  ReactGA.event(eventName, eventPrams);
};

export const setGAUserValues = (values: UserTokenData) => {
  ReactGA
    .set({
      [SUBSCRIPTION_PLAN]: values.subscriptionPlanId,
      [HAS_PROPERTY]: values.properties.length > 0,
      [HAS_MULTIPLE_PROPERTIES]: values.properties.length > 1,
      [IS_ADMIN]: values.isAdmin,
      userId: values.userId,
    });
};

export const clearGAUserValues = () => ReactGA.reset();
