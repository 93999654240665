import EntityType from '../../../../../../constants/entityType';
import { EntityContentConnectionsMap } from '../../types/content.type';

export enum TaskExpirationTypes {
  DATE = 'date',
  TIME = 'time',
}

export type RelatesToItemType = EntityType;

export type RelatesToItem = {
  id: string,
  name: string,
  type: RelatesToItemType,
};

export enum TaskType {
  CUSTOM = 'custom',
  TEMPLATE = 'template',
  PARTNER = 'partner',
}

export type Task = {
  id: string,
  propertyId: string,
  type: TaskType,
  templateId?: string,
  name: string,
  relatesTo: Array<RelatesToItem>,
  classifications: Array<string>,
  duration?: {
    actual?: number
  },
  cost: {
    estimated?: number,
    actual?: number,
    currencyCode: string,
  },
  order: number,
  color?: string,
  createdAt: string,
  updatedAt: string,
  completedAt?: string,
  description?: string,
  consequence?: string,
  noteAndConsequence?: string,
  recurringScheduleConfiguration?: {
    rrule?: any,
    templateId: string,
  },
  reminderConfiguration?: {
    enabled: boolean,
  },
  expiration: {
    type: TaskExpirationTypes,
    date: string,
    label?: string,
  } | null,
  previousTasks?: Array<{ id: string, completedAt: string }>,
  groupIds?: Array<string>,
  youtubeId?: string,
  descriptionArticleId?: string,
  consequenceArticleId?: string,
  tags?: {
    propertyCheckSeverity: Array<any>,
  },
  additionalInfo?: Array<{ label: string, value: string }>,
  _meta: {
    partial: boolean
  },
  connections?: EntityContentConnectionsMap,
};
