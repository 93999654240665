import EntityType from '../../../constants/entityType';

export type PropertyId = { propertyId: string };

export type PropertyIdNavigation = PropertyId;

type BonusPoints = {
  available: number;
  earned: number;
  used: number;
};

type Notifications = {
  total: number;
};

type Messages = {
  total: number;
  unread: number;
};

export type PropertyStatus = {
  activeMaintenanceTasks: number;
  activeTasks: number;
  bonusPoints: BonusPoints;
  notifications: Notifications;
  messages: Messages;
  suggestions: number;
  files: number;
};

export type PropertyInboundEmail = {
  inboundEmail: string;
  inboundEmailDomain: string;
  token: string;
};

export type UserRemovalRequest = PropertyId & {
  id: string;
  userId: string;
  createdByUserId: string;
  state: 'pending' | 'completed';
  createdAt: string;
};

export type PropertyBonusOffers = {
  availablePoints?: number;
  earnedBonusPoints?: {
    from: string;
    points: number;
  },
  bonusOffers?: {
    count?: number;
    readyToActivate?: number;
  },
};

export type StorageSummaryItem = {
  _meta: {
    entity: {
      type: EntityType;
      propertyId: string;
    },
  };
  type: string;
  count: number;
  totalSize: number;
};

export type StorageUsage = {
  quota: number;
  quotaExceeded: boolean;
  totalSize: number;
};

export type PropertyAddress = {
  postalCode: string;
  streetAddress: string;
  addressCountry: string;
  addressLocality: string;
};

export type PropertyStorageUsage = {
  propertyId: string;
  address: PropertyAddress;
  alias?: string;
  totalSize: number;
  summary: Array<StorageSummaryItem>;
};

export type UserStorageUsage = StorageUsage & {
  properties: Array<PropertyStorageUsage>;
};

export enum UserSyncDataActionType {
  REFRESH_TOKEN = 'reloadAuthToken',
  RESET_PROPERTY_DATA = 'resetPropertyData',
  RELOAD_PROPERTY_PROGRESS = 'reloadPropertyProgress',
}

export type InviteCode = {
  type: string;
  code: string;
  propertyId: string;
  propertyAddress?: PropertyAddress;
  inviterName: string;
};

export type Building = PropertyId & {
  id: string;
  numberOfBathrooms?: number;
  numberOfToilets?: number;
  numberOfBedrooms?: number;
  numberOfFloors?: number;
  numberOfOtherRooms?: number;
  area?: number;
  secondaryArea?: number;
  // NOTE: more fields here if we need
};

export enum FormOfOwnership {
  OWNERSHIP = 'ownership',
  LAND_LEASE = 'land-lease',
  COOPERATIVE_LAW = 'cooperative-law',
  TENANCY_LAW = 'tenancy-law',
  RENT = 'rent',
  CONDOMINIUM = 'condominium',
  SHARE_IN_HOUSING_ASSOCIATION = 'share-in-housing-association',
  SHARE_IN_HOUSING_LIMITED_COMPANY = 'share-in-housing-limited-company',
}

export enum HousingType {
  VILLA_DETACHED = 'villa-detached',
  CHAIN_HOUSE = 'chain-house',
  SEMI_DETACHED_HOUSE = 'semi-detached-house',
  TOWNHOUSE = 'townhouse',
  APARTMENT = 'apartment',
  SUMMER_HOUSE = 'summer-house',
  WINTER_HOUSE = 'winter-house',
  PLOT = 'plot',
  AGRICULTURE = 'agriculture',
}

export type PropertySpecification = PropertyId & {
  formOfOwnership?: FormOfOwnership;
  housingType?: HousingType;
  apartmentNumber?: string;
  livingArea?: number;
  secondaryArea?: number;
};
