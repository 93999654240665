import React, {
  FC,
  useState,
} from 'react';
import {
  Collapse,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';
import {
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';

import {
  EMPTY_VALUE,
  htmlDecode,
} from '../../../../../../../../../utils/string';
import { Product } from '../../../types.product';
import HIDLink from '../../../../../../../../../components/HIDLink';
import { useGetBoxShadows } from '../../../../../../../../../hooks/useScrollWithShadow';

type ProductDescriptionSectionProps = {
  product: Product;
};

const ProductDescriptionSection: FC<ProductDescriptionSectionProps> = ({
  product,
}) => {
  const theme = useTheme();
  const { bottomInsetBoxShadow } = useGetBoxShadows();
  const { t } = useTranslation(['common', 'products']);

  const [expanded, setExpanded] = useState(false);

  const descriptionItem = product.descriptions
    ?.find(({ classifications }) => classifications.includes('houseid.se/product/description/standard'));

  const isHtml = descriptionItem?.type === 'html';

  if (!descriptionItem?.text) {
    return null;
  }

  return (
    <Stack
      sx={{
        padding: 2.5,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
        borderRadius: '10px',
      }}
    >
      <Typography variant="subtitle1">
        {t('products:product_description')}
      </Typography>
      <Collapse
        collapsedSize={3 * 30}
        in={expanded}
        sx={{
          boxShadow: expanded ? 'none' : bottomInsetBoxShadow,
        }}
      >
        {
          isHtml
            ? <div
              /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(htmlDecode(descriptionItem.text)) }}
                style={{ marginTop: theme.spacing(2) }}
              />
            : (
              <Typography sx={{ marginTop: 0.5 }} variant="body1">
                {descriptionItem?.text || EMPTY_VALUE}
              </Typography>
            )
        }
      </Collapse>
      <Stack direction="row" justifyContent="flex-end">
        <HIDLink
          Icon={expanded ? ExpandLess : ExpandMore}
          label={expanded ? t('common:show_less') : t('common:show_more')}
          underline="none"
          onClick={() => setExpanded(!expanded)}
        />
      </Stack>
    </Stack>
  );
};

export default ProductDescriptionSection;
