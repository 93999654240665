import * as R from 'ramda';
import {
  ComparisonListItem,
  ExpenseBankAccount,
} from '@house-id/houseid-types/dist/finances/recurringExpenses';
import { ChartDataItem } from '@house-id/houseid-types/dist/finances/finances';
import {
  addMonths,
  endOfDay,
  endOfMonth,
  startOfDay,
  startOfMonth,
} from 'date-fns';

import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../utils/date';
import { TimeIntervalsGroupingType } from '../../../Content/types/content.type';
import {
  ChartDateLength,
  formatChartDate,
} from '../../utils.finances';
import { range } from '../../../../../../utils/array';

const shiftMonth = (firstMonthNumber: number, monthNumber: number) => (monthNumber + (12 - firstMonthNumber - 1)) % 12;

export const getComparisonList = (
  chartData: Array<ChartDataItem>,
  compareToYear: number | undefined,
  groupingType: TimeIntervalsGroupingType,
): Array<ComparisonListItem> => {
  const currentMonth = new Date().getMonth() + 1;

  const primaryItemsWithData = chartData.filter((chartDataItem) => chartDataItem.primary.value);
  const primaryLastMonthWithData = primaryItemsWithData.length > 0
    ? Math.max(...primaryItemsWithData.map((chartDataItem) => chartDataItem.date.month))
    : undefined;

  const secondaryItemsWithData = chartData.filter((chartDataItem) => chartDataItem.secondary?.value);
  const secondaryLastMonthWithData = secondaryItemsWithData.length > 0
    ? Math.max(...secondaryItemsWithData.map((chartDataItem) => chartDataItem.date.month))
    : undefined;

  const lastMonthWithData = primaryLastMonthWithData || secondaryLastMonthWithData || currentMonth;

  return R.sortBy((chartDataItem) => -shiftMonth(lastMonthWithData, chartDataItem.date.month), chartData)
    .flatMap((chartDataItem) => ([
      chartDataItem.primary.items.length && {
        id: `${chartDataItem.date.month}_${chartDataItem.date.year}_primary_title`,
        isTitleRow: true,
        label: formatChartDate(chartDataItem.date, groupingType, ChartDateLength.LONG),
        lastDate: undefined,
        lastValue: undefined,
        value: chartDataItem.primary.value,
      },
      ...(
        chartDataItem.primary.items.map((summaryItem, index) => ({
          id: `${chartDataItem.date.month}_${chartDataItem.date.year}_primary_${index}`,
          isTitleRow: false,
          label: summaryItem.label,
          lastDate: formatDate(new Date(summaryItem.last.date), DateTimeFormats.DAY_AND_MONTH),
          lastValue: summaryItem.last.value,
          value: summaryItem.total,
        }))
      ),
      ...(
        compareToYear
          ? [
            chartDataItem.secondary?.items?.length && {
              id: `${chartDataItem.date.month}_${compareToYear}_primary`,
              isTitleRow: true,
              label: formatChartDate({ month: chartDataItem.date.month, year: compareToYear }, groupingType, ChartDateLength.LONG),
              lastDate: undefined,
              lastValue: undefined,
              value: chartDataItem.secondary?.value || 0,
            },
            ...(
              (chartDataItem.secondary?.items || []).map((summaryItem, index) => ({
                id: `${chartDataItem.date.month}_${compareToYear}_primary_${index}`,
                isTitleRow: false,
                label: summaryItem.label,
                lastDate: formatDate(new Date(summaryItem.last.date), DateTimeFormats.DAY_AND_MONTH),
                lastValue: summaryItem.last.value,
                value: summaryItem.total,
              }))
            ),
          ].filter(Boolean)
          : []
      ),
    ].filter(Boolean)));
};

export const getYearsListFromBankAccounts = (bankAccounts: Array<ExpenseBankAccount>) => {
  const now = new Date();
  const initial: { from?: string, to?: string } = { from: undefined, to: undefined };

  const { from, to } = bankAccounts
    .reduce(
      (acc, bankAccount) => {
        const {
          firstTransactionDate,
          lastTransactionDate,
        } = bankAccount;

        return {
          from: !acc.from || firstTransactionDate === undefined
            ? acc.from || firstTransactionDate
            : new Date(acc.from) < new Date(firstTransactionDate)
              ? acc.from
              : firstTransactionDate,
          to: !acc.to || lastTransactionDate === undefined
            ? acc.to || lastTransactionDate
            : new Date(acc.to) > new Date(lastTransactionDate)
              ? acc.to
              : lastTransactionDate,
        };
      },
      initial,
    );

  const fromDate = from ? new Date(from) : undefined;
  const toDate = to ? new Date(to) : undefined;

  return fromDate !== undefined && toDate !== undefined
    ? range(fromDate.getFullYear(), toDate.getFullYear())
    : fromDate !== undefined
      ? [fromDate.getFullYear()]
      : toDate !== undefined
        ? [toDate.getFullYear()]
        : [now.getFullYear()];
};

export const getLastSyncedFromBankAccounts = (bankAccounts: Array<ExpenseBankAccount>, selectedAccountIds?: Array<string>) => bankAccounts
  .filter((bankAccount) => !selectedAccountIds?.length || selectedAccountIds.includes(bankAccount.id))
  .reduce(
    (acc: string | undefined, bankAccount: ExpenseBankAccount) => acc !== undefined && bankAccount.lastSyncedAt !== undefined
      ? new Date(acc) < new Date(bankAccount.lastSyncedAt)
        ? bankAccount.lastSyncedAt
        : acc
      : acc || bankAccount.lastSyncedAt,
    undefined,
  );

export const getFromToFullMonthDateRange = (numberOfMonth: number) => {
  const now = new Date();

  return {
    from: startOfMonth(startOfDay(addMonths(now, -(numberOfMonth - 1)))),
    to: endOfMonth(endOfDay(now)),
  };
};

export const getBankAccountName = (bankAccount: ExpenseBankAccount) => bankAccount.name || bankAccount.bban;
