import React, { FC } from 'react';
import {
  Card,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  PropertyProgressCategoryId,
  PropertyProgressSection,
} from '@house-id/houseid-types/dist/propertyProgress';

import { useNavigateBackOr } from '../../../../../../utils/routes';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import HomeLayout from '../../../../pages/Home/components/HomeLayout';
import { useGetPropertyProgressDocumentationQuery } from '../../api/propertyProgress.api';
import PropertyProgressPageContent from '../../components/PropertyProgressPageContent';
import PropertyProgressQuickNavigation from '../../components/PropertyProgressQuickNavigation';
import { getPropertyProgressPath } from '../../navigation.propertyProgress';
import {
  PropertyProgressCategorySectionProps,
  PropertyBlueprintSectionData,
  PropertyDocumentationSectionId,
} from '../../types.propertyProgress';
import PropertyDocumentationSection from './components/PropertyDocumentationDocumentsSection';
import PropertyDocumentationReceiptsSection from './components/PropertyDocumentationReceiptsSection';
import PropertyExternalService from '../../../../constants/constants.externalServices';
import { BoughtSfPropertyYearAgoBanner } from '../../components/sf/BoughtSfPropertyYearAgoBanner';
import PropertyExternalServicesSection from '../../../../components/PropertyExternalServicesSection';
import { getPathWithPropertyIdOrInit } from '../../../../../Auth/navigation/navigation.auth';

const PropertyDocumentationSectionsContent: Record<string, FC<PropertyProgressCategorySectionProps>> = {
  [PropertyDocumentationSectionId.PROPERTY_DOCUMENTATION_DOCUMENT_TYPES]: PropertyDocumentationSection,
  [PropertyDocumentationSectionId.PROPERTY_DOCUMENTATION_RECEIPT_SUMMARY]: PropertyDocumentationReceiptsSection,
};

const PropertyDocumentation: FC = () => {
  const { t } = useTranslation(['property']);
  const navigateBackOr = useNavigateBackOr();

  const { data: propertyId } = useGetCurrentPropertyId();
  const {
    data: propertyDocumentation,
    isLoading: isPropertySpecificationLoading,
  } = useGetPropertyProgressDocumentationQuery(propertyId ? { propertyId } : skipToken);

  const sections = propertyDocumentation?.sections || [];

  const handleRenderSectionContent = (section: PropertyProgressSection<PropertyBlueprintSectionData>) => {
    const Content = PropertyDocumentationSectionsContent[section.id];

    return Content ? <Content section={section} /> : null;
  };

  return (
    <HomeLayout
      SideColumn={
        <Stack spacing={2}>
          <Card sx={{ padding: 2 }}>
            <PropertyProgressQuickNavigation
              currentPropertyProgressCategoryId={PropertyProgressCategoryId.DOCUMENTS}
            />
          </Card>
          <BoughtSfPropertyYearAgoBanner />
          <PropertyExternalServicesSection
            externalServicesIds={[PropertyExternalService.JS_ENERGY]}
            key={PropertyExternalServicesSection.name}
          />
        </Stack>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getPropertyProgressPath, { propertyId }),
          name: t('property:property_progress_title'),
        },
      ]}
      sideDrawerElements={[
        <PropertyProgressQuickNavigation
          currentPropertyProgressCategoryId={PropertyProgressCategoryId.DOCUMENTS}
          key={PropertyProgressQuickNavigation.name}
        />,
        <BoughtSfPropertyYearAgoBanner key={BoughtSfPropertyYearAgoBanner.name} />,
        <PropertyExternalServicesSection
          externalServicesIds={[PropertyExternalService.JS_ENERGY]}
          key={PropertyExternalServicesSection.name}
        />,
      ]}
      title={t('property:property_progress_property_documentation')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getPropertyProgressPath, { propertyId }))}
    >
      <PropertyProgressPageContent
        loading={isPropertySpecificationLoading}
        progress={propertyDocumentation?.progress}
        renderSectionContent={handleRenderSectionContent}
        sections={sections}
      />
    </HomeLayout>
  );
};

export default PropertyDocumentation;
