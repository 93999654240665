import React, { FC } from 'react';

import { t } from 'i18next';
import { Stack } from '@mui/material';
import PropertyProgressCategorySectionContainer from '../../../components/PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from '../../../components/PropertyProgressCategorySectionContent';
import {
  PropertyInsuranceSectionData,
  PropertyProgressCategorySectionProps,
} from '../../../types.propertyProgress';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import InsuranceCard from '../../../../Content/modules/Insurance/components/InsuranceCard';
import NoInsuranceCard from '../../../../Content/modules/Insurance/components/NoInsuranceCard';

const PropertyInsuranceSection: FC<PropertyProgressCategorySectionProps<PropertyInsuranceSectionData>> = ({
  section,
}) => {
  const { insurances } = section.data;

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const handleViewInsurances = () => openMobileAppDialog();
  const handleAddInsurance = () => openMobileAppDialog();

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('property:property_progress_property_insurance_add_insurance')}
          viewAllButtonText={t('property:property_progress_property_insurance_view_all_insurances')}
          onAddClick={handleAddInsurance}
          onViewAllClick={handleViewInsurances}
        >
          {
            insurances.length
              ? (
                <Stack spacing={2}>
                  {insurances.map((insurance) => (
                    <InsuranceCard
                      insurance={insurance}
                      key={insurance.insuranceNumber}
                      lastSyncTime={section.data.synchronizeTimestamp}
                    />
                  ))}
                </Stack>
              )
              : (
                <NoInsuranceCard />
              )
          }

        </PropertyProgressCategorySectionContent>
      }
      section={section}
    />
  );
};

export default PropertyInsuranceSection;
