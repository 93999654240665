import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PropertyFinancesValuationSectionData,
  PropertyProgressCategorySectionProps,
} from '../../../types.propertyProgress';
import PropertyProgressCategorySectionContainer from '../../../components/PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from '../../../components/PropertyProgressCategorySectionContent';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import ValuationWidget from '../../../../../pages/PropertyHome/components/ValuationWidget';

const PropertyFinancesValuationSection: FC<PropertyProgressCategorySectionProps<PropertyFinancesValuationSectionData>> = ({
  section,
}) => {
  const { t } = useTranslation(['forms_common', 'property']);

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const handleSeeMore = () => openMobileAppDialog();

  const handleAdd = () => openMobileAppDialog();

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('property:property_progress_property_valuation_section_add_valuation')}
          viewAllButtonText={t('property:property_progress_property_valuation_section_view_valuations')}
          onAddClick={handleAdd}
          onViewAllClick={handleSeeMore}
        >
          <ValuationWidget />
        </PropertyProgressCategorySectionContent>
      }
      key={section.id}
      section={section}
    />
  );
};

export default PropertyFinancesValuationSection;
