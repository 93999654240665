import { useTranslation } from 'react-i18next';
import {
  MailOutline,
} from '@mui/icons-material';

import EntityType from '../../../../../constants/entityType';
import {
  Bonus,
  Building,
  BuildingPart,
  ChartIcon,
  Database,
  Document,
  Home,
  Image,
  Layers,
  Note,
  Outdoor,
  Product,
  Receipt,
  Room,
  Task,
  Timeline,
  PiggyBank,
  Wallet,
} from '../../../../../components/icons/Icons';
import {
  getProductPath,
  getProductsPath,
  getUpdateProductPath,
} from '../modules/Product/navigation.product';
import {
  getReceiptCategoryPath,
  getReceiptPath,
  getUpdateReceiptPath,
} from '../modules/Receipt/navigation.receipt';
import {
  getDocumentCategoryPath,
  getDocumentPath,
  getUpdateDocumentPath,
} from '../modules/Document/navigation.document';
import {
  getNotePath,
  getNotesPath,
  getUpdateNotePath,
} from '../modules/Note/navigation.note';
import {
  getTimelineEventPath,
  getTimelinePath,
  getUpdateTimelineEventPath,
} from '../modules/Timeline/navigation.timeline';
import {
  getPropertySpecificationPath,
  getPropertyProgressPath,
} from '../../PropertyProgress/navigation.propertyProgress';
import { getPhotosPath } from '../modules/Photo/navigation.photo';
import {
  getMessagePath,
  getMessagesPath,
} from '../modules/Message/navigation.message';
import { getContentFilesPath } from '../modules/ContentFile/navigation.contentFile';
import ArticleIcon from '../../../../../components/icons/ArticleIcon';
import ContactsIcon from '../../../../../components/icons/ContactsIcon';
import { EntityInfo } from '../types/content.type';
import { getExternalServicesPath } from '../../../navigation/navigation.property';
import {
  getBudgetsOverviewPath,
  getUpdateBudgetPath,
} from '../../Finances/modules/Budgets/navigation.budgets';
import {
  getRecurringExpense,
  getRecurringExpensesCategoriesPath,
  getRecurringExpensesUpdatePath,
} from '../../Finances/modules/RecurringExpenses/navigation.recurringExpenses';

const useGetEntitiesInfo = (): Record<EntityType, EntityInfo> => {
  const { t } = useTranslation(['common', 'entities']);

  const entitiesMap: Record<EntityType, EntityInfo> = {
    [EntityType.PRODUCT]: {
      Icon: Product,
      isSearchable: true,
      type: EntityType.PRODUCT,
      name: t('entities:product'),
      namePlural: t('entities:product_plural'),
      getViewLink: getProductPath,
      getUpdateLink: getUpdateProductPath,
      getListLink: getProductsPath,
    },
    [EntityType.RECEIPT]: {
      Icon: Receipt,
      isSearchable: true,
      type: EntityType.RECEIPT,
      name: t('entities:receipt'),
      namePlural: t('entities:receipt_plural'),
      getViewLink: getReceiptPath,
      getUpdateLink: getUpdateReceiptPath,
      getListLink: getReceiptCategoryPath,
    },
    [EntityType.DOCUMENT]: {
      Icon: Document,
      isSearchable: true,
      type: EntityType.DOCUMENT,
      name: t('entities:document'),
      namePlural: t('entities:document_plural'),
      getViewLink: getDocumentPath,
      getUpdateLink: getUpdateDocumentPath,
      getListLink: getDocumentCategoryPath,
    },
    [EntityType.PHOTO]: {
      Icon: Image,
      isSearchable: true,
      type: EntityType.PHOTO,
      name: t('entities:photo'),
      namePlural: t('entities:photo_plural'),
      getListLink: getPhotosPath,
      getViewLink: ({ id, propertyId }) => getPhotosPath({ propertyId, photoId: id }),
    },
    [EntityType.NOTE]: {
      Icon: Note,
      isSearchable: true,
      type: EntityType.NOTE,
      name: t('entities:note'),
      namePlural: t('entities:note_plural'),
      getViewLink: getNotePath,
      getUpdateLink: getUpdateNotePath,
      getListLink: getNotesPath,
    },
    [EntityType.TASK]: {
      Icon: Task,
      isSearchable: false,
      type: EntityType.TASK,
      name: t('entities:task'),
      namePlural: t('entities:note_plural'),
    },
    [EntityType.MESSAGE]: {
      Icon: MailOutline,
      isSearchable: true,
      type: EntityType.MESSAGE,
      name: t('entities:message'),
      namePlural: t('entities:message_plural'),
      getListLink: getMessagesPath,
      getViewLink: getMessagePath,
      getUpdateLink: getMessagePath,
    },
    [EntityType.BUILDING]: {
      Icon: Building,
      isSearchable: false,
      type: EntityType.BUILDING,
      name: t('entities:building'),
      namePlural: t('entities:building_plural'),
    },
    [EntityType.ROOM]: {
      Icon: Room,
      isSearchable: false,
      type: EntityType.ROOM,
      name: t('entities:room'),
      namePlural: t('entities:room_plural'),
    },
    [EntityType.OUTDOOR]: {
      Icon: Outdoor,
      isSearchable: false,
      type: EntityType.OUTDOOR,
      name: t('entities:outdoor'),
      namePlural: t('entities:outdoor_plural'),
    },
    [EntityType.BUILDING_PART]: {
      Icon: BuildingPart,
      isSearchable: false,
      type: EntityType.BUILDING_PART,
      name: t('entities:building-part'),
      namePlural: t('entities:building-part_plural'),
    },
    [EntityType.RECURRING_EXPENSE]: {
      Icon: Wallet,
      isSearchable: false,
      type: EntityType.RECURRING_EXPENSE,
      name: t('entities:fixed_expense'),
      namePlural: t('entities:fixed_expense_plural'),
      getViewLink: getRecurringExpense,
      getListLink: getRecurringExpensesCategoriesPath,
      getUpdateLink: getRecurringExpensesUpdatePath,
    },
    [EntityType.TIMELINE_ENTRY]: {
      Icon: Timeline,
      isSearchable: true,
      type: EntityType.TIMELINE_ENTRY,
      name: t('entities:timeline-entry'),
      namePlural: t('entities:timeline-entry_plural'),
      getViewLink: getTimelineEventPath,
      getUpdateLink: getUpdateTimelineEventPath,
      getListLink: getTimelinePath,
    },
    [EntityType.PROPERTY]: {
      Icon: Home,
      isSearchable: false,
      type: EntityType.PROPERTY,
      name: t('entities:property'),
      namePlural: t('entities:property_plural'),
      getViewLink: getPropertySpecificationPath,
      getUpdateLink: getPropertyProgressPath,
      getListLink: getPropertyProgressPath,
    },
    [EntityType.CONTENT_FILE]: {
      Icon: Layers,
      isSearchable: true,
      type: EntityType.CONTENT_FILE,
      name: t('entities:content-file'),
      namePlural: t('entities:content-file_plural'),
      getViewLink: getContentFilesPath,
      getUpdateLink: getContentFilesPath,
      getListLink: getContentFilesPath,
    },
    [EntityType.ARTICLE]: {
      Icon: ArticleIcon,
      isSearchable: false,
      type: EntityType.ARTICLE,
      name: t('entities:article'),
      namePlural: t('entities:article_plural'),
    },
    [EntityType.CONTACT]: {
      Icon: ContactsIcon,
      isSearchable: false,
      type: EntityType.CONTACT,
      name: t('entities:contact'),
      namePlural: t('entities:contact_plural'),
    },
    [EntityType.VALUATION]: {
      Icon: ChartIcon,
      isSearchable: false,
      type: EntityType.VALUATION,
      name: t('entities:valuation'),
      namePlural: t('entities:valuation_plural'),
    },
    [EntityType.EXTERNAL_SERVICE]: {
      Icon: Database,
      isSearchable: false,
      type: EntityType.EXTERNAL_SERVICE,
      name: t('entities:external_service'),
      namePlural: t('entities:external_service_plural'),
      getViewLink: getExternalServicesPath,
      getUpdateLink: getExternalServicesPath,
      getListLink: getExternalServicesPath,
    },
    [EntityType.BONUS_OFFER]: {
      Icon: Bonus,
      isSearchable: false,
      type: EntityType.BONUS_OFFER,
      name: t('entities:bonus_offer'),
      namePlural: t('entities:bonus_offers'),
    },
    [EntityType.BUDGET]: {
      Icon: PiggyBank,
      isSearchable: false,
      type: EntityType.BUDGET,
      name: t('entities:budget'),
      namePlural: t('entities:budgets'),
      getViewLink: getBudgetsOverviewPath,
      getUpdateLink: getUpdateBudgetPath,
      getListLink: getBudgetsOverviewPath,
    },
  };

  return entitiesMap;
};

export default useGetEntitiesInfo;
