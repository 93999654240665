import { useNavigate } from 'react-router-dom';

import { NavigationLink } from '../../../types/common';
import useGetEntityInfo from '../modules/Content/hooks/useGetEntityInfo';
import useGetCurrentPropertyId from './useGetCurrentPropertyId';
import { openUrlNewTab } from '../../../utils/file';
import useGetNavigationAction from './useGetNavigationAction';
import useDialog from '../../../hooks/useDialog';
import DialogNames from '../../../hooks/useDialog/DialogNames';
import { ExpertsCommunicationFormDialogProps } from '../modules/Content/modules/Message/components/ExpertsCommunicationFormDialog';

const useNavigateFromLink = (link?: NavigationLink) => {
  const navigate = useNavigate();
  const { data: propertyId } = useGetCurrentPropertyId();

  const getEntityInfo = useGetEntityInfo();
  const getNavigationAction = useGetNavigationAction();

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);
  const [openExpertsCommunicationFormDialog] = useDialog<ExpertsCommunicationFormDialogProps>(
    DialogNames.EXPERTS_COMMUNICATION_FORM_DIALOG,
  );

  const {
    navigationData,
    url,
    form,
    // source,
    // affiliate,
    // modalData,
    _meta,
  } = link || {};

  // TODO: Add event logging when analytics is ready
  const navigateToScreenFromLink = () => {
    if (navigationData?.actionId) {
      const getActionPath = getNavigationAction(navigationData?.actionId);
      if (getActionPath) {
        navigate(getActionPath(navigationData.params));
      } else {
        openMobileAppDialog();
      }

      return;
    }

    if (_meta) {
      const meta = _meta;
      const entityInfo = getEntityInfo(meta.entity.type);
      if (entityInfo?.getViewLink && propertyId) {
        navigate(entityInfo.getViewLink({ propertyId, id: meta.entity.id }));
      }
      return;
    }

    if (url) {
      openUrlNewTab(url);
    }

    if (form) {
      const { dynamicContentId, ...rest } = form || {};
      openExpertsCommunicationFormDialog({
        id: dynamicContentId,
        params: rest,
      });
    }
  };

  return navigateToScreenFromLink;
};

export default useNavigateFromLink;
