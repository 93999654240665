import { HIDApiTags } from '../../../../../api/HIDApiTags';
import { PropertyId } from '../../../types/property.types';
import {
  EntityContentConnectionAction,
  EntityContentConnectionsMap,
  PurchasePlace,
} from '../types/content.type';
import EntityType from '../../../../../constants/entityType';
import { propertyApi } from '../../../api/property.api';

type GetContentConnections = PropertyId & {
  entityId: string;
  entityType: EntityType;
};

type PatchContentConnections = GetContentConnections & {
  actions: Array<EntityContentConnectionAction>;
};

export const contentApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getPurchasePlaces: builder.query<Array<PurchasePlace>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/products-saved-places`,
      transformResponse: (places: Array<PurchasePlace>) => places.map(({ name }) => ({ id: name, name })),
      providesTags: [HIDApiTags.PLACE],
    }),
    getEntityConnections: builder.query<EntityContentConnectionsMap, GetContentConnections>({
      query: ({ propertyId, entityId, entityType }) => `/properties/${propertyId}/connections/${entityType}/${entityId}?group=true`,
      providesTags: (_result, _error, arg) => [{ type: HIDApiTags.CONTENT_CONNECTIONS, id: arg.entityId, entityType: arg.entityType }],
    }),
    patchEntityConnections: builder.mutation<void, PatchContentConnections>({
      query: ({
        propertyId,
        entityId,
        entityType,
        actions,
      }) => ({
        url: `/properties/${propertyId}/connections/${entityType}/${entityId}`,
        method: 'PATCH',
        body: { actions },
      }),
      invalidatesTags: (_result, _error, arg) => [
        {
          type: HIDApiTags.CONTENT_CONNECTIONS,
          id: arg.entityId,
          entityType: arg.entityType,
        },
        ...arg.actions.map((connection) => ({
          type: HIDApiTags.CONTENT_CONNECTIONS as const,
          id: connection.entityId,
          entityType: connection.type,
        })),
        arg.entityType === EntityType.RECEIPT && { type: HIDApiTags.RECEIPT_FILTERS },
      ].filter(Boolean),
    }),
  }),
});

export const {
  usePatchEntityConnectionsMutation,
  useGetPurchasePlacesQuery,
  useGetEntityConnectionsQuery,
} = contentApi;
