import React from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import HIDLinearProgressWithLabel from '../../../../../components/progress/HIDLinearProgressWithLabel';
import { FCC } from '../../../../../types/common';
import { getPropertyProgressPercent } from '../utils/utils.propertyProgress';
import { Progress } from '@house-id/houseid-types/dist/propertyProgress';

type PropertyProgressCategoryBadgeDescriptionProps = {
  progress?: Progress;
  description?: string;
  hideProgressBar?: boolean;
  loading?: boolean;
};

const PropertyProgressCategoryBadgeDescription: FCC<PropertyProgressCategoryBadgeDescriptionProps> = ({
  progress,
  description,
  hideProgressBar = false,
  loading,
}) => {
  const propertyProgressPercent = getPropertyProgressPercent(progress);

  return (
    <Stack spacing={2}>
      {!hideProgressBar && (
        <HIDLinearProgressWithLabel
          sx={
            {
              width: '100%',
              height: 12,
              borderRadius: '5px',
            }
          }
          value={propertyProgressPercent}
          variant={loading ? 'indeterminate' : 'determinate'}
        />
      )}
      {description && (
        <Typography variant="body1">
          {description}
        </Typography>
      )}
    </Stack>
  );
};
export default PropertyProgressCategoryBadgeDescription;
