import React, { PropsWithChildren } from 'react';
import {
  Theme,
  useTheme,
  Stack,
  SxProps,
} from '@mui/material';
import {
  Bar,
  Line,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { ContentType } from 'recharts/types/component/DefaultLegendContent';
import * as R from 'ramda';

import HIDTypography from '../../../../../components/HIDTypography';
import BaseChartLayout from '../../../../../components/charts/BaseChartLayout';
import StyledChartWrapper from '../../../../../components/charts/StyledChartWrapper';
import SummaryChartInfo from '../../../../../components/charts/SummaryChartInfo';
import SummaryChartTooltip from '../../../../../components/charts/SummaryChartTooltip';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../utils/date';
import { TimeIntervalsGroupingType } from '../../Content/types/content.type';
import {
  ChartDateLength,
  ChartDateLength as Length,
  formatChartDate,
} from '../utils.finances';
import ChartLegend from '../../../../../components/charts/ChartLegend';

export const useGetFinancesChartHeight = () => {
  const {
    isDownXl,
    isDownLg,
    isDownMd,
    isDownSm,
    isDownXs,
  } = useBreakPointsSizes();

  // eslint-disable-next-line operator-linebreak
  const height =
    isDownXs ? 220
      : isDownSm ? 260
        : isDownMd ? 280
          : isDownLg ? 320
            : isDownXl ? 360
              : 420;

  return height;
};

const formatFinanceValue = (value: number) => value > 9999
  ? `${Math.round(value / 1000).toLocaleString()}\``
  : value.toLocaleString();

type BarDataModel = {
  dataKey: string;
  color: string;
  name: string;
};

type LineDataModel = {
  dataKey: string;
  color?: string;
  name?: string;
};

type SummaryInfoModel = {
  Icon?: React.ReactNode;
  infoLabel: string;
  infoSubLabel: string;
  infoDescription?: string;
  label: string;
  value: string;
};

type FinancesChartProps<TData> = {
  chartData: Array<TData>;
  lineData?: LineDataModel;
  bars: Array<BarDataModel>;
  summaryInfo?: Array<SummaryInfoModel>;
  groupingType?: TimeIntervalsGroupingType;
  isLoading?: boolean;
  footerInfo?: React.ReactNode,
  headerInfo?: React.ReactNode,
  lastSyncedAt?: string;
  minHeight?: number;
  dateLength?: ChartDateLength;
  showXAxis?: boolean;
  showYAxis?: boolean;
  showLegend?: boolean;
  showGrid?: boolean;
  showTooltip?: boolean;
  sx?: SxProps<Theme>;
};

const FinancesChart = <TData extends { date: { year: number; month: number; }; }>({
  chartData,
  lineData,
  bars,
  summaryInfo,
  groupingType = TimeIntervalsGroupingType.Monthly,
  isLoading = false,
  footerInfo,
  headerInfo,
  lastSyncedAt,
  minHeight: minHeightProp,
  dateLength: dateLengthProp,
  showXAxis = true,
  showYAxis = true,
  showLegend = true,
  showGrid = true,
  showTooltip = true,
  sx,
}: PropsWithChildren<FinancesChartProps<TData>>) => {
  const theme = useTheme();
  const { t } = useTranslation(['forms_common']);

  const {
    isDownXl,
    isDownLg,
    isDownMd,
    isDownSm,
    isDownXs,
  } = useBreakPointsSizes();

  const renderLegend = ({ payload }: { payload: Array<{ id: string, value: string, color: string }> }) => (
    <Stack gap={1}>
      <Stack
        alignItems={isDownXl ? 'flex-start' : 'center'}
        direction={isDownXl ? 'column' : 'row'}
        flexWrap="wrap"
        justifyContent={isDownXl ? 'flex-start' : 'space-between'}
        sx={{ marginTop: 2 }}
      >
        <ChartLegend payload={payload} />
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={isDownXl ? { width: '100%', mt: 1 } : undefined}
        >
          {lastSyncedAt !== undefined && (
            <HIDTypography
              isLoading={isLoading}
              sx={{ color: theme.palette.grey[500] }}
              variant="body1"
            >
              {`${t('forms_common:last_updated')} ${formatDate(new Date(lastSyncedAt), DateTimeFormats.DATE_ONLY_CALENDAR)}`}
            </HIDTypography>
          )}
          {isDownXl && (
            <Stack sx={{ marginLeft: 'auto' }}>{footerInfo}</Stack>
          )}
        </Stack>
      </Stack>
      {!isDownXl && <Stack sx={{ marginLeft: 'auto' }}>{footerInfo}</Stack>}
    </Stack>
  );

  const numberOfBars = chartData.length;

  const defaultMinHeight = useGetFinancesChartHeight();

  const minHeight = minHeightProp || defaultMinHeight;

  const dateLength = dateLengthProp
    || (
      R.cond([
        [() => isDownXs, R.always(numberOfBars > 6 ? Length.SHORT : numberOfBars > 4 ? Length.MEDIUM : Length.LONG)],
        [() => isDownSm, R.always(numberOfBars > 6 ? Length.SHORT : numberOfBars > 5 ? Length.MEDIUM : Length.LONG)],
        [() => isDownMd, R.always(numberOfBars > 10 ? Length.SHORT : numberOfBars > 8 ? Length.MEDIUM : Length.LONG)],
        [() => isDownLg, R.always(numberOfBars > 6 ? Length.SHORT : numberOfBars > 4 ? Length.MEDIUM : Length.LONG)],
        [() => isDownXl, R.always(numberOfBars > 8 ? Length.SHORT : numberOfBars > 6 ? Length.MEDIUM : Length.LONG)],
        [R.T, R.always(numberOfBars > 12 ? Length.SHORT : numberOfBars > 8 ? Length.MEDIUM : Length.LONG)],
      ])()
    );

  const xAxisInterval = Math.floor(
    numberOfBars / (
      R.cond([
        [() => isDownXs, R.always(8)],
        [() => isDownSm, R.always(9)],
        [() => isDownMd, R.always(12)],
        [() => isDownLg, R.always(9)],
        [() => isDownXl, R.always(11)],
        [R.T, R.always(15)],
      ])()
    ),
  );

  const chartDataFormatted = chartData
    .map((item) => ({
      ...item,
      name: formatChartDate(item.date, groupingType, dateLength),
    }));

  return (
    <Stack direction="column" gap={2}>
      <StyledChartWrapper gap={2} sx={sx}>
        <Stack direction="row" gap={isDownLg ? 1 : 2}>
          {summaryInfo?.map((info, index) => (
            <SummaryChartInfo
              Icon={info.Icon}
              infoDescription={info.infoDescription}
              infoLabel={info.infoLabel}
              infoSubLabel={info.infoSubLabel}
              isLoading={isLoading}
              key={index}
              label={info.label}
              sx={{ flex: 1 }}
              value={info.value}
            />
          ))}
          {headerInfo}
        </Stack>
        <BaseChartLayout
          ChartTooltipComponent={<SummaryChartTooltip />}
          data={chartDataFormatted}
          fontSize={isDownMd ? 12 : isDownXl ? 14 : undefined}
          isLoading={isLoading}
          minHeight={minHeight}
          renderLegend={renderLegend as ContentType}
          showGrid={showGrid}
          showLegend={showLegend}
          showTooltip={showTooltip}
          showXAxis={showXAxis}
          showYAxis={showYAxis}
          xAxisInterval={xAxisInterval}
          yAxisFormatter={formatFinanceValue}
          yAxisWidth={isDownMd ? 38 : isDownXl ? 44 : undefined}
        >
          {bars.map((bar) => (
            <Bar
              dataKey={bar.dataKey}
              fill={bar.color}
              key={bar.dataKey}
              maxBarSize={55}
              name={bar.name}
            />
          ))}
          {lineData && (
            <Line
              dataKey={lineData.dataKey}
              name={lineData.name}
              stroke={lineData.color || theme.palette.warning.main}
              strokeWidth={2}
              type="linear"
            />
          )}
        </BaseChartLayout>
      </StyledChartWrapper>
    </Stack>
  );
};

export default FinancesChart;
