import React, {
  FC,
  useMemo,
  useState,
} from 'react';
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  useTheme,
} from '@mui/material';
import * as R from 'ramda';
import { useUpdateEffect } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import { skipToken } from '@reduxjs/toolkit/query';

import HIDTypography from '../../../../../../components/HIDTypography';
import useGetEntityInfo from '../../hooks/useGetEntityInfo';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import { arrToMap } from '../../../../../../utils/array';
import EntityType from '../../../../../../constants/entityType';
import { useGetReceiptFiltersQuery } from '../../modules/Receipt/api/receipt.api';

const LIST_ITEM_HEIGHT = 48;
const LIST_MAX_HEIGHT = 8 * LIST_ITEM_HEIGHT + 2;

const EntitiesLoaderItem = (
  <ListItemButton>
    <ListItemText>
      <HIDTypography isLoading skeletonWidth="100%" />
    </ListItemText>
  </ListItemButton>
);

const EntitiesLoader = (
  <>
    {EntitiesLoaderItem}
    {EntitiesLoaderItem}
    {EntitiesLoaderItem}
  </>
);

export type EntityTypeFiltersSectionProps = {
  entityTypes: Array<EntityType>;
  onChange: (entityIdsMap: Record<EntityType, Array<string>>) => void;
};

const EntityTypeFiltersSection: FC<EntityTypeFiltersSectionProps> = ({
  entityTypes,
  onChange,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  const [expandedEntityTypesMap, setExpandedEntityTypesMap] = useState<Record<EntityType, boolean>>({} as Record<EntityType, boolean>);
  const [selectedEntitiesMap, setSelectedEntitiesMap] = useState<Record<EntityType, Record<string, boolean>>>(
    {} as Record<EntityType, Record<string, boolean>>,
  );
  const [allSelectedMap, setAllSelectedMap] = useState<Record<EntityType, boolean>>({} as Record<EntityType, boolean>);

  const { data: propertyId } = useGetCurrentPropertyId();

  const getEntityInfo = useGetEntityInfo();

  useUpdateEffect(() => {
    const entityIdsMap = R.map(
      (record: Record<string, boolean>) => R.toPairs(record)
        .filter(([_entityId, selected]) => selected)
        .map(([entityId]) => entityId),
      selectedEntitiesMap,
    );

    onChange(entityIdsMap);
  }, [selectedEntitiesMap]);

  // TODO: move to generic endpoint when needed
  const { data: entitiesMap, isLoading: filtersIsLoading } = useGetReceiptFiltersQuery(
    propertyId ? { propertyId } : skipToken,
  );

  const filteredEntities = useMemo(
    () => {
      const entityTypesWithItems = Object.entries(entitiesMap || {})
        .filter(([_entityType, entities]) => entities.length > 0)
        .map(([entityType]) => entityType) as Array<EntityType>;

      return entityTypes
        .filter((entityType) => entityTypesWithItems.includes(entityType))
        .map((entityType) => getEntityInfo(entityType));
    },
    [entitiesMap],
  );

  const handleEntityTypeSelect = (entityType: EntityType) => {
    setExpandedEntityTypesMap((map) => ({ ...map, [entityType]: !map[entityType] }));
  };

  const handleEntitySelect = (entityType: EntityType, entityId: string) => {
    const newSelectedEntitiesMap = ({
      ...selectedEntitiesMap,
      [entityType]: {
        ...selectedEntitiesMap[entityType],
        [entityId]: !selectedEntitiesMap[entityType]?.[entityId],
      },
    });

    setSelectedEntitiesMap(newSelectedEntitiesMap);

    const entities = entitiesMap?.[entityType];

    if (entities) {
      const selectedCount = Object.values(newSelectedEntitiesMap[entityType])
        .filter(Boolean)
        .length;

      setAllSelectedMap((map) => ({ ...map, [entityType]: selectedCount === entities.length }));
    }
  };

  const handleSelectAllEntities = (entityType: EntityType) => {
    const newAllSelected = !allSelectedMap[entityType];
    setAllSelectedMap((map) => ({ ...map, [entityType]: newAllSelected }));

    const entities = entitiesMap?.[entityType];

    if (entities) {
      setSelectedEntitiesMap(
        {
          ...selectedEntitiesMap,
          [entityType]: arrToMap(({ id }) => ([id, newAllSelected]), entities),
        },
      );
    }
  };

  return (
    <Stack>
      <HIDTypography sx={{ marginTop: 2 }} variant="subtitle1">
        {`${t('common:entity_type_filter')}:`}
      </HIDTypography>
      <List>
        {filteredEntities
          .map((entityInfo) => (
            <React.Fragment key={entityInfo.type}>
              <ListItemButton onClick={() => handleEntityTypeSelect(entityInfo.type)}>
                <ListItemIcon>
                  <entityInfo.Icon />
                </ListItemIcon>
                <ListItemText primary={entityInfo.name} />
                {expandedEntityTypesMap[entityInfo.type] ? <ExpandMore /> : <ExpandLess />}
              </ListItemButton>
              <Collapse unmountOnExit in={Boolean(expandedEntityTypesMap[entityInfo.type])} timeout="auto">
                <Divider />
                <List
                  disablePadding
                  component="div"
                  style={{
                    maxHeight: LIST_MAX_HEIGHT,
                    overflowY: 'scroll',
                  }}
                >
                  {
                    filtersIsLoading
                      ? EntitiesLoader
                      : (
                        <>
                          <ListItemButton sx={{ pl: 4 }} onClick={() => handleSelectAllEntities(entityInfo.type)}>
                            <ListItemText primary={t('common:all_label')} />
                            {
                              allSelectedMap[entityInfo.type]
                                ? <CheckBox sx={{ color: theme.palette.primary.main }} />
                                : <CheckBoxOutlineBlank />
                            }
                          </ListItemButton>
                          {entitiesMap?.[entityInfo.type]?.map((entity) => (
                            <ListItemButton
                              key={entity.id}
                              sx={{ pl: 4 }}
                              onClick={() => handleEntitySelect(entityInfo.type, entity.id)}
                            >
                              <ListItemText
                                primary={entity.name}
                                primaryTypographyProps={{ noWrap: true, paddingRight: 2 }}
                              />
                              {
                                selectedEntitiesMap[entityInfo.type]?.[entity.id]
                                  ? <CheckBox sx={{ color: theme.palette.primary.main }} />
                                  : <CheckBoxOutlineBlank />
                              }
                            </ListItemButton>
                          ))}
                        </>
                      )
                  }
                </List>
                <Divider />
              </Collapse>
            </React.Fragment>
          ))}
      </List>
    </Stack>
  );
};

export default EntityTypeFiltersSection;
