import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';

import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import {
  useDeleteEventsMutation,
  useGetCustomEvent,
  useGetCustomEventCategoriesQuery,
} from '../../api/timeline.api';
import HomeLayout from '../../../../../../pages/Home/components/HomeLayout';
import BasicInfoSection from '../../../../components/sections/BasicInfoSection';
import { EMPTY_VALUE } from '../../../../../../../../utils/string';
import ViewEntityActions from '../../../../components/actions/ViewEntityActions';
import {
  getTimelinePath,
  getCreateTimelineEventPath,
  getUpdateTimelineEventPath,
} from '../../navigation.timeline';
import {
  useNavigateBackOr,
  useRouteParams,
} from '../../../../../../../../utils/routes';
import ContentConnections from '../../../../components/ContentConnections';
import EntityType from '../../../../../../../../constants/entityType';
import useDialog from '../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../hooks/useDialog/DialogNames';
import {
  ManageContentConnectionsDialogProps,
} from '../../../../components/dialogs/manageContentConnectionsDialog/ManageContentConnectionsDialog';
import ContentConnectionsTabContext from '../../../../components/ContentConnectionsTabContext';
import { OVERVIEW_TAB_ID } from '../../../../constants/content.constants';
import AnnotationSection from '../../../../components/sections/AnnotationSection';
import {
  DateTimeFormats,
  formatDate,
  formatDateRange,
} from '../../../../../../../../utils/date';
import { DEFAULT_CUSTOM_EVENT_CATEGORY } from '../../constants.timeline';
import { arrToMap } from '../../../../../../../../utils/array';
import { HIDEntityId } from '../../../../../../../../types/common';
import { getMutationFixedCacheKey } from '../../../../utils/cacheKeys';
import TimelineEventLoaderSkeleton from './TimelineEventLoaderSkeleton';
import { getPathWithPropertyIdOrInit } from '../../../../../../../Auth/navigation/navigation.auth';

const ViewTimelineEvent: FC = () => {
  const { t } = useTranslation(['common', 'timeline']);
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();

  const { id: eventId } = useRouteParams<HIDEntityId>();

  const { data: propertyId } = useGetCurrentPropertyId();

  const { customEvent, isLoading: isLoadingEvent } = useGetCustomEvent({ propertyId, eventId });
  const {
    data: categories = [],
    isLoading: isLoadingEventCategories,
  } = useGetCustomEventCategoriesQuery(propertyId ? { propertyId } : skipToken);

  const categoriesMap = arrToMap((category) => [category.id, category.name], categories);

  const [deleteEvent] = useDeleteEventsMutation({
    fixedCacheKey: getMutationFixedCacheKey(eventId),
  });

  const fields = [
    {
      label: t('common:date'),
      value: customEvent
        ? customEvent.start && customEvent.end
          ? formatDateRange(new Date(customEvent.start), new Date(customEvent.end))
          : formatDate(new Date(customEvent.start), DateTimeFormats.DATE_ONLY_TEXT) || ''
        : EMPTY_VALUE,
    },
    { label: t('common:category'), value: categoriesMap[customEvent?.categoryId || DEFAULT_CUSTOM_EVENT_CATEGORY] || EMPTY_VALUE },
    { label: t('timeline:author'), value: customEvent?.author?.name || EMPTY_VALUE },
  ];

  const handleAdd = () => navigate(getPathWithPropertyIdOrInit(getCreateTimelineEventPath, { propertyId }));

  const handleUpdate = () => navigate(getPathWithPropertyIdOrInit(getUpdateTimelineEventPath, { propertyId, id: eventId }));

  const handleDelete = () => {
    if (propertyId && customEvent) {
      deleteEvent({ propertyId, keys: [customEvent.key] })
        .then(() => navigateBackOr(getTimelinePath({ propertyId })));
    }
  };

  const [openManageConnectionsDialog] = useDialog<ManageContentConnectionsDialogProps>(DialogNames.MANAGE_CONTENT_CONNECTIONS_DIALOG);
  const handleManageConnections = () => {
    if (customEvent) {
      openManageConnectionsDialog({
        entityType: EntityType.TIMELINE_ENTRY,
        entity: customEvent,
      });
    }
  };

  const isLoading = isLoadingEvent || isLoadingEventCategories;

  return (
    <HomeLayout
      BodyLoaderSkeleton={TimelineEventLoaderSkeleton}
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <ViewEntityActions
            onAdd={handleAdd}
            onDelete={handleDelete}
            onManageConnections={handleManageConnections}
            onUpdate={handleUpdate}
          />
        </Card>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getTimelinePath, { propertyId }),
          name: t('timeline:timeline'),
        },
      ]}
      isLoading={isLoading}
      sideDrawerElements={[
        <ViewEntityActions
          key={ViewEntityActions.name}
          onAdd={handleAdd}
          onDelete={handleDelete}
          onManageConnections={handleManageConnections}
          onUpdate={handleUpdate}
        />,
      ]}
      title={customEvent?.title}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getTimelinePath, { propertyId }))}
    >
      {customEvent && (
        <ContentConnectionsTabContext
          sourceEntity={customEvent}
          sourceEntityType={EntityType.TIMELINE_ENTRY}
          tabs={[
            {
              id: OVERVIEW_TAB_ID,
              label: t('common:overview'),
              TabComponent: (
                <Stack spacing={3}>
                  <Stack spacing={1.5}>
                    <Typography variant="h6">
                      {t('timeline:event_details')}
                    </Typography>
                    <BasicInfoSection items={fields} />
                  </Stack>
                  <Stack spacing={1.5}>
                    <Typography variant="h6">
                      {t('timeline:description')}
                    </Typography>
                    <AnnotationSection entity={customEvent} />
                  </Stack>
                  {customEvent && (
                    <ContentConnections
                      entity={customEvent}
                      entityType={EntityType.TIMELINE_ENTRY}
                    />
                  )}
                </Stack>
              ),
            },
          ]}
        />
      )}
    </HomeLayout>
  );
};

export default ViewTimelineEvent;
