import React, { FC } from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';

import LineChart from '../../../../../components/icons/LineChart';
import { useGetLoansSummaryQuery } from '../../../modules/Finances/api/finance.api';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { formatPercentage } from '../../../../../utils/number';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../utils/date';
import HIDButton from '../../../../../components/buttons/HIDButton';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import {
  HomeWidgetType,
  useGetWidgetHeight,
} from '../utils/useGetWidgetHeight';
import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';

const LoansRateWidget: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['home', 'forms_common']);

  const { isDownLg, isDownXs } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: loansSummary } = useGetLoansSummaryQuery(propertyId ? { propertyId } : skipToken);

  const {
    numberOfLoans,
    weightedAverageInterestRate,
    synchronizeTimestamp,
  } = loansSummary || {};

  const isEmpty = weightedAverageInterestRate === undefined || !synchronizeTimestamp;

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const handleGetStarted = openMobileAppDialog;

  const widgetHeight = useGetWidgetHeight(HomeWidgetType.LOANS_RATE);

  const lastUpdatedDateFormatted = synchronizeTimestamp
    ? formatDate(new Date(synchronizeTimestamp), DateTimeFormats.DATE_ONLY)
    : undefined;

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2.5),
        minHeight: widgetHeight,
        height: '100%',
      }}
    >
      <Stack
        alignItems="center"
        alignSelf="center"
        direction="row"
        spacing={1.5}
        sx={{ height: '100%' }}
      >
        <Stack
          alignSelf="center"
          sx={{
            padding: 2,
            backgroundColor: theme.palette.warning.lighter,
            borderRadius: theme.spacing(6),
          }}
        >
          <LineChart iconColor={theme.palette.warning.main} />
        </Stack>
        {
          !isEmpty
            ? (
              <Stack spacing={0.5} sx={{ width: '100%' }}>
                <Typography variant="body1">
                  {t('home:home_average_interest_rate_from_loans', { count: numberOfLoans })}
                </Typography>
                <Typography variant="h4">
                  {formatPercentage(weightedAverageInterestRate)}
                </Typography>
                {lastUpdatedDateFormatted && (
                  <Typography>
                    {t('forms_common:last_updated_date', { date: lastUpdatedDateFormatted })}
                  </Typography>
                )}
              </Stack>
            )
            : (
              <Stack
                direction={isDownXs ? 'column' : 'row'}
                justifyContent="space-between"
                spacing={isDownXs ? 1 : 0}
                sx={{ width: '100%' }}
              >
                <Stack sx={{ flex: 1 }}>
                  <Typography variant="body1">
                    {t('home:home_get_a_handle_on_yours')}
                  </Typography>
                  <Typography variant="subtitle1">
                    {t('home:home_interest_rate')}
                  </Typography>
                </Stack>
                <HIDButton
                  noWrap
                  size={isDownLg ? 'small' : 'medium'}
                  sx={{
                    alignSelf: isDownXs ? 'flex-start' : 'center',
                    maxWidth: 128,
                    minWidth: 104,
                  }}
                  onClick={handleGetStarted}
                >
                  {t('home:home_get_started')}
                </HIDButton>
              </Stack>
            )
        }
      </Stack>
    </Card>
  );
};

export default LoansRateWidget;
