import React from 'react';
import { match } from 'ts-pattern';
import {
  IconButton,
  IconButtonProps,
  useTheme,
} from '@mui/material';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import { FCC } from '../../types/common';
import { ICON_SIZE } from '../../constants/layout';
import HIDBadge from './HIDBadge';

const PrimaryIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: theme.palette.grey[50],
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
  '&:active': {
    backgroundColor: theme.palette.grey[200],
  },
}));

const BlankIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.black,
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
  '&:active': {
    backgroundColor: theme.palette.grey[200],
  },
}));

const SecondaryIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  '&:active': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const YellowIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.warning.light,
  },
  '&:active': {
    backgroundColor: theme.palette.warning.dark,
  },
}));

const RedIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.error.main,
  '&:hover': {
    backgroundColor: theme.palette.error.light,
  },
  '&:active': {
    backgroundColor: theme.palette.error.dark,
  },
}));

const WhiteIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  borderStyle: 'solid',
  borderWidth: 1,
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.lightest,
  },
  '&:active': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const AlternateIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.shadow[300],
  '&:hover': {
    backgroundColor: theme.palette.shadow[200],
  },
  '&:active': {
    backgroundColor: theme.palette.shadow[200],
  },
}));

export type HIDIconButtonProps = {
  Icon: React.ElementType;
  badgeColor?: 'error' | 'warning';
  badgeCount?: number;
  color?: 'primary' | 'secondary' | 'yellow' | 'red' | 'white' | 'alternate' | 'blank';
  disabled?: IconButtonProps['disabled'];
  iconButtonColor?: IconButtonProps['color'];
  size?: IconButtonProps['size'];
  tabIndex?: IconButtonProps['tabIndex'];
  title?: string;
  titlePlacement?: TooltipProps['placement'],
  onClick: IconButtonProps['onClick'];
};

const HIDIconButton: FCC<HIDIconButtonProps> = ({
  Icon,
  badgeColor = 'error',
  badgeCount,
  color = 'primary',
  disabled = false,
  iconButtonColor,
  size = 'medium',
  style,
  sx,
  tabIndex,
  title,
  titlePlacement,
  onClick,
}) => {
  const theme = useTheme();

  const IconButtonColor = match(color)
    .with('primary', () => PrimaryIconButton)
    .with('blank', () => BlankIconButton)
    .with('secondary', () => SecondaryIconButton)
    .with('yellow', () => YellowIconButton)
    .with('red', () => RedIconButton)
    .with('white', () => WhiteIconButton)
    .with('alternate', () => AlternateIconButton)
    .exhaustive();

  const iconSx = match(size)
    .with('small', () => ({ width: ICON_SIZE * (4 / 5), height: ICON_SIZE * (4 / 5) }))
    .with('medium', () => ({ width: ICON_SIZE, height: ICON_SIZE }))
    .with('large', () => ({ width: ICON_SIZE * (4 / 3), height: ICON_SIZE * (4 / 3) }))
    .exhaustive();

  const IconButtonContent = (
    <IconButtonColor
      disableRipple
      color={iconButtonColor}
      disabled={disabled}
      size={size}
      style={style}
      sx={sx}
      tabIndex={tabIndex}
      onClick={onClick}
    >
      {
        badgeCount !== undefined && badgeCount > 0
          ? (
            <HIDBadge
              badgeContent={badgeCount}
              color={badgeColor}
            >
              <Icon sx={iconSx} />
            </HIDBadge>
          )
          : (
            <Icon color={iconButtonColor} sx={iconSx} />
          )
      }
    </IconButtonColor>
  );

  return title
    ? (
      <Tooltip
        arrow
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -4],
              },
            },
          ],
        }}
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: theme.palette.grey[900],
            },
          },
          arrow: {
            sx: {
              color: theme.palette.grey[900],
            },
          },
        }}
        enterDelay={500}
        placement={titlePlacement}
        title={title}
      >
        {IconButtonContent}
      </Tooltip>
    )
    : IconButtonContent;
};

export default HIDIconButton;
