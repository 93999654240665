import React, { ReactNode } from 'react';
import {
  Stack,
} from '@mui/material';
import {
  Progress,
  PropertyProgressSection,
} from '@house-id/houseid-types/dist/propertyProgress';

import PropertyProgressCategoryBadgeDescription from './PropertyProgressCategoryBadgeDescription';

type PropertyProgressPageContentProps<TSectionData> = {
  description?: string;
  progress?: Progress;
  loading?: boolean;
  hideProgressBar?: boolean;
  sections: Array<PropertyProgressSection<TSectionData>>
  renderSectionContent: (section: PropertyProgressSection<TSectionData>) => ReactNode;
};

const PropertyProgressPageContent = <TSectionData extends object>({
  children,
  description,
  hideProgressBar,
  progress,
  loading,
  sections,
  renderSectionContent,
}: React.PropsWithChildren<PropertyProgressPageContentProps<TSectionData>>) => (
  <Stack spacing={2}>
    <PropertyProgressCategoryBadgeDescription
      description={description}
      hideProgressBar={hideProgressBar}
      loading={loading}
      progress={progress}
    />
    {children}
    <Stack
      spacing={2}
    >
      {
        sections.map(renderSectionContent)
      }
    </Stack>
  </Stack>
  );

export default PropertyProgressPageContent;
