import React, {
  FC,
  useMemo,
} from 'react';
import {
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Add,
} from '@mui/icons-material';
import {
  FieldArray,
  useFormikContext,
} from 'formik';
import * as R from 'ramda';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  PaymentTypeBasedComponentProps,
  MortgageEntity,
  CreateUpdateBudgetValues,
} from '@house-id/houseid-types/dist/finances/budgets';

import useBreakPointsSizes from '../../../../../../../../../hooks/useBreakpointsSizes';
import HIDButton from '../../../../../../../../../components/buttons/HIDButton';
import MonthlyAllocatedBudget from './MonthlyAllocatedBudget';
import BudgetMortgageCard from './BudgetMortgageCard';
import { useGetLoansSummaryQuery } from '../../../../../api/finance.api';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import {
  getPaymentMonthsDataPath,
  getMortgagesDataPath,
} from '../utils/formikFieldDataPath.utils';

const BudgetMortgageTypeForm: FC<PaymentTypeBasedComponentProps> = ({
  data,
  categoryId,
  subcategoryIndex,
}) => {
  const { t } = useTranslation(['common', 'finances']);
  const { isDownSm } = useBreakPointsSizes();

  const { setFieldValue } = useFormikContext<CreateUpdateBudgetValues>();

  const paymentMonthsPath = getPaymentMonthsDataPath(categoryId, subcategoryIndex);
  const mortgagesPath = getMortgagesDataPath(categoryId, subcategoryIndex);

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: loansSummary } = useGetLoansSummaryQuery(propertyId ? { propertyId } : skipToken);

  const existingLoans: Array<MortgageEntity<number> & { isCopied?: boolean; }> = useMemo(() => {
    if (loansSummary?.loans?.length) {
      return loansSummary.loans.map((loan) => ({
        isCopied: true,
        averageInterestRate: Number((loan.interest * 100).toFixed(2)),
        debt: Math.abs(loan.balance),
        amortization: loan.monthlyAmortization,
      }));
    }

    return [];
  }, [loansSummary]);

  const getCalculatedPaymentMonths = (mortgages: Array<MortgageEntity<number | undefined>>) => {
    const mortgageMonthlyPayment = R.sum((mortgages || []).map(
      (item) => (((item.debt || 0) * ((item.averageInterestRate || 0) / 100)) / 12) + (item.amortization || 0),
    ));

    return (data.paymentMonths || []).map(
      ({ month }) => ({ month, amount: Number(mortgageMonthlyPayment.toFixed(2)) }),
    );
  };

  const handleClearPaymentMonths = () => {
    const paymentMonths = (data.paymentMonths || []).map(({ month }) => ({ month, amount: undefined }));
    setFieldValue(paymentMonthsPath, paymentMonths);
  };

  const handleCalculatePaymentMonths = () => {
    if (data.mortgages) {
      const paymentMonths = getCalculatedPaymentMonths(data.mortgages);
      setFieldValue(paymentMonthsPath, paymentMonths);
    }
  };

  const isCopiedMortgages = Boolean(data.mortgages?.some((item) => item.isCopied));

  const handleUseCopiedMortgages = (isCopied: boolean) => {
    const mortgages = isCopied
      ? [...(data.mortgages || []), ...existingLoans]
      : data.mortgages?.filter((item) => !item.isCopied);

    setFieldValue(mortgagesPath, mortgages);
  };

  return (
    <Stack spacing={2}>
      <FieldArray
        name={mortgagesPath}
        render={({ push, remove }) => (
          <>
            {Boolean(existingLoans.length) && (
              <FormControlLabel
                control={<Switch checked={isCopiedMortgages} />}
                label={
                  <Typography variant="subtitle1">
                    {t('finances:budgets_payment_use_mortgage_details')}
                  </Typography>
                }
                labelPlacement="start"
                sx={{ justifyContent: 'space-between', marginLeft: 0 }}
                onChange={() => handleUseCopiedMortgages(!isCopiedMortgages)}
              />
            )}
            {
              data.mortgages?.map((item, index) => (
                <BudgetMortgageCard
                  categoryId={categoryId}
                  index={index}
                  isCopied={item.isCopied}
                  key={`mortgage-${index}`}
                  name={`${data.name} ${index + 1}`}
                  subcategoryIndex={subcategoryIndex}
                  onDelete={() => remove(index)}
                />
              ))
            }
            <Stack
              direction={{ sm: 'column', md: 'row' }}
              justifyContent="flex-end"
            >
              <HIDButton
                Icon={Add}
                onClick={() => push({
                  debt: undefined,
                  averageInterestRate: undefined,
                  amortization: undefined,
                })}
              >
                {t('finances:budgets_payment_add_mortgage_label')}
              </HIDButton>
            </Stack>
          </>
        )}
      />
      <Stack
        direction="row"
        spacing={isDownSm ? 2.5 : 2}
      >
        <HIDButton onClick={handleCalculatePaymentMonths}>
          {t('finances:budgets_payment_calculate_label')}
        </HIDButton>
        <HIDButton
          color="secondary"
          onClick={handleClearPaymentMonths}
        >
          {t('common:clear_remove')}
        </HIDButton>
      </Stack>
      <MonthlyAllocatedBudget
        categoryId={categoryId}
        label={t('finances:budgets_payment_mortgages_average_per_month')}
        paymentMonths={data.paymentMonths}
        subcategoryIndex={subcategoryIndex}
      />
    </Stack>
  );
};

export default BudgetMortgageTypeForm;
