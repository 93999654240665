import EntityType from '../../../../../../constants/entityType';
import RouteNames from '../../../../../../routes/RouteNames';
import { generateRoutePath } from '../../../../../../utils/routes';
import { PropertyIdNavigation } from '../../../../types/property.types';

type GetSearchResultsPathParams = {
  query?: string;
  entityType?: EntityType;
  filters?: Record<string, string>;
} & PropertyIdNavigation;

export const getSearchResultsPath = ({
  propertyId,
  query,
  entityType,
}: GetSearchResultsPathParams) => generateRoutePath(
  RouteNames.SEARCH_RESULTS,
  { propertyId },
  { query, entityType },
);
