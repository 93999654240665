import { PropertySource } from '@house-id/houseid-types/dist/property';

import { useGetProperty } from '../api/property.api';
import useGetCurrentPropertyId from './useGetCurrentPropertyId';
import useGetIsCurrentUserPropertyOwner from './useGetIsCurrentUserPropertyOwner';
import useDialog from '../../../hooks/useDialog';
import DialogNames from '../../../hooks/useDialog/DialogNames';

const useGetPropertyVerify = ({ propertyId }: { propertyId?: string }) => {
  const { data: property } = useGetProperty({ propertyId });

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const { data: currentPropertyId } = useGetCurrentPropertyId();

  const isPropertyVerified = property?.isVerified || property?.source?.toLowerCase() === PropertySource.SVENSKFAST.toLowerCase();
  const readOnly = property?.readOnly;

  const isCurrentUserOwner = useGetIsCurrentUserPropertyOwner({ propertyId });

  const verifyProperty = openMobileAppDialog;

  return {
    canVerify: isCurrentUserOwner && !isPropertyVerified && currentPropertyId === propertyId && !readOnly,
    verify: verifyProperty,
  };
};

export default useGetPropertyVerify;
