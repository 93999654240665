import * as Sentry from '@sentry/react';
import { enqueueSnackbar } from 'notistack';
import { HIDUser } from '@house-id/houseid-types/dist/common';

import { sentryConfig } from './configs/sentry-config';
import {
  ActionError,
  ReportErrorMessage,
} from '../types/common';
import {
  getEmptyTokenAttributes,
  getTokenAttributes,
} from '../modules/Auth/utils/userTokenAttibutes';
import { UserTokenData } from '../modules/Auth/types/auth.types';
import {
  EnvoyDecoratorOperation,
  RtkEndpoint,
} from '../utils/errors';

export type ReportErrorData = {
  showToaster?: boolean;
  attributes?: Record<string, string>
  message?: ReportErrorMessage;
};

export const getActionErrorFingerprint = (actionError: ActionError) => [
  actionError.error.message,
  actionError.code,
  actionError.status?.toString(),
  actionError.attributes?.[RtkEndpoint],
  actionError.attributes?.[EnvoyDecoratorOperation],
].filter(Boolean);

export const initSentry = () => Sentry.init(sentryConfig);

export const reportError = (error: Error, data?: ReportErrorData) => {
  Sentry.captureException(error);
  if (data?.showToaster && data.message) {
    enqueueSnackbar(data.message.title, {
      description: data.message.description,
      variant: 'error',
    });
  }
};

export const reportErrorToSentry = (actionError: ActionError, data?: ReportErrorData) => {
  try {
    Sentry.captureException(actionError?.error || new Error(), {
      tags: { ...actionError.attributes, ...data?.attributes },
      fingerprint: getActionErrorFingerprint(actionError),
    });
  } catch (e: any) {
    Sentry.captureException(e);
  } finally {
    if (data?.showToaster && data.message) {
      enqueueSnackbar(data.message.title, {
        description: data.message.description,
        variant: 'error',
      });
    }
  }
};

export const setSentryTags = (tokenData: UserTokenData, user: HIDUser) => {
  const tags: Record<string, string> = getTokenAttributes(tokenData, user.utm);

  const { userId } = tokenData;
  if (userId) {
    Sentry.setUser({
      id: userId,
    });
  }

  Sentry.setTags(tags);
};

export const clearSentryTags = () => {
  Sentry.setTags(getEmptyTokenAttributes());
  Sentry.setUser(null);
};
