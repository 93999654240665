import React, {
  memo,
  useState,
} from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import {
  Suggestion,
  SuggestionType,
} from '../types.suggestion';
import ContentImage from '../../../components/ContentImage';
import useGetEntityInfo from '../../../hooks/useGetEntityInfo';
import { hidSpacing } from '../../../../../../../utils/number';
import HIDLink from '../../../../../../../components/HIDLink';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import SuggestionsList from './SuggestionsList';
import { FCC } from '../../../../../../../types/common';
import { useIgnoreSuggestionMutation } from '../api/suggestion.api';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';

type SuggestionCardProps = {
  suggestion: Suggestion;
};

const SuggestionCard: FCC<SuggestionCardProps> = ({
  suggestion,
  sx,
}) => {
  const theme = useTheme();
  const [ignoredSuggestions, setIgnoredSuggestions] = useState<string[]>([]);

  const { data: propertyId } = useGetCurrentPropertyId();

  const [ignoreSuggestion] = useIgnoreSuggestionMutation();
  const handleIgnoreSuggestion = (suggestionKey: string) => {
    if (propertyId) {
      ignoreSuggestion({
        propertyId,
        entityId: suggestion.entityId,
        entityType: suggestion.entityType,
        entitySuggestions: { [suggestionKey as SuggestionType]: { muted: true } },
      })
        .then(() => setIgnoredSuggestions((prev) => [...prev, suggestionKey]));
    }
  };

  const getEntityInfo = useGetEntityInfo();

  const { entity } = suggestion;

  const entityInfo = getEntityInfo(suggestion.entityType);

  const suggestions = suggestion.suggestions.filter((suggestion) => !ignoredSuggestions.includes(suggestion));

  if (!suggestions.length) {
    return null;
  }

  return (
    <Stack
      sx={{
        background: theme.palette.warning.lightest,
        padding: 2.5,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
        borderRadius: '10px',
        ...sx,
      }}
    >
      <Stack
        direction="row"
        spacing={1.5}
      >
        {(entity.blobs || entity.externalMainImage?.thumbnailUrl) ? (
          <ContentImage
            blobs={entity.blobs}
            externalImage={entity.externalMainImage?.thumbnailUrl}
            size={hidSpacing(8)}
            sx={{ minHeight: theme.spacing(8) }}
          />) : entityInfo.Icon
          ? (
            <Stack sx={{
              minHeight: theme.spacing(8),
              minWidth: hidSpacing(8),
              justifyContent: 'center',
              alignItems: 'center',
              borderStyle: 'solid',
              borderWidth: 1,
              borderRadius: '10px',
              borderColor: theme.palette.grey[300],
            }}
            >
              <entityInfo.Icon />
            </Stack>
          )
          : null}
        <Stack style={{ overflow: 'hidden' }}>
          {
            entityInfo?.getViewLink !== undefined
              ? (
                <HIDLink
                  href={getPathWithPropertyIdOrInit(entityInfo.getViewLink, { id: suggestion.entityId, propertyId })}
                  label={entity.name}
                  variant="h6"
                />
              )
              : <Typography variant="h6">{entity.name}</Typography>
          }
          <Typography noWrap variant="body1">
            {entityInfo?.name}
          </Typography>
        </Stack>
      </Stack>
      <div
        style={{
          borderStyle: 'solid',
          borderWidth: 0,
          borderBottomWidth: 1,
          borderColor: theme.palette.grey[300],
          margin: theme.spacing(2, 0),
        }}
      />
      <SuggestionsList
        entityId={suggestion.entityId}
        entityType={suggestion.entityType}
        suggestions={suggestions}
        onIgnoreSuggestion={handleIgnoreSuggestion}
      />
    </Stack>
  );
};

export default memo(SuggestionCard, (prev, next) => prev.suggestion.entityId === next.suggestion.entityId);
