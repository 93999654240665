import { HIDBlobModify } from '@house-id/houseid-types/dist/common';
import { FileMimeType } from '../../../../../../constants/mimeTypes';
import {
  IconResource,
} from '../../../../../../types/common';
import { PartnersSource } from '../../../../../Analytics/types.analytics';
import { DynamicInputField } from '../DynamicContent/types/types.dynamicContent';

export enum ExternalMediaType {
  DOCUMENT = 'document',
  IMAGE = 'image',
  LINK = 'link',
  VIDEO = 'video',
}

export type ExternalMedia = {
  id?: string;
  type: ExternalMediaType;
  contentType?: FileMimeType;
  thumbnailUrl?: string;
  url: string;
  source?: string;
};

export type ProductCategory = {
  id: string;
  name: string;
  children?: Array<{ id: string, name: string }>;
};

export type ProductSummaryItem = {
  date: string;
  totalPrice: number;
  count: number
};

export enum SpecificationDataType {
  STRING = 'string',
  INTEGER = 'integer',
  DECIMAL = 'decimal',
  LINK = 'link',
  LIST = 'list',
  BOOLEAN = 'boolean',
  INTERVAL = 'interval',
  RANGE = 'range',
}

export type SpecificationItemLink = { url: string, languages: Array<string> };
export type SpecificationItemInterval = { from: string | number, to: string | number };
export type SpecificationItemRange = [string | number, string | number];

export type SpecificationItem = {
  type: string;
  name: string;
  description?: string;
  unit?: string;
  dataType: SpecificationDataType,
  value: number
  | string
  | boolean
  | Array<string>
  | SpecificationItemLink
  | Array<SpecificationItemLink>
  | SpecificationItemInterval
  | SpecificationItemRange;
};

export type SpecificationCategory = {
  type: string;
  name: string;
  children?: Array<SpecificationCategory | SpecificationItem>;
};

export type ParseSpecificationItem = {
  name: string;
  value: string | undefined;
  isLink: boolean;
};

export type ParsedSpecificationCategory = {
  name: string;
  children?: Array<ParsedSpecificationCategory | ParseSpecificationItem>;
};

export type ProductSourceLink = {
  url: string;
  source: PartnersSource;
  affiliate: string;
  name?: string;
  classifications: Array<string>;
};

export type Product = {
  id: string;
  propertyId: string;
  name: string;
  annotation?: string;
  descriptions?: Array<{
    text: string;
    classifications: Array<string>;
    type: 'text' | 'html';
  }>;
  identifiers?: Record<string, string>;
  blobs?: Array<HIDBlobModify>;
  purchaseDate?: string;
  type?: string;
  price?: number;
  brand?: string;
  place?: string;
  externals?: Array<ProductExternals>;
  externalMedia?: Array<ExternalMedia>;
  specification?: Array<SpecificationCategory>;
  serialNumber?: string;
  syncOnUpdate?: boolean;
  productLink?: string;
  movable?: boolean;
  lifetimeInMonth?: number;
  warrantyInMonth?: number;
  links?: {
    resellerCompanyLink: ProductSourceLink;
    resellerBrandLink: ProductSourceLink;
    similarProductsLink: ProductSourceLink;
    categoryProductLink: ProductSourceLink;
    brandProductBrandLink: ProductSourceLink;
  };
  createdAt: string;
  updatedAt: string;
};

export enum ProductIdentifier {
  GTIN = 'gtin',
  RSK = 'rsk',
}

export type ProductExternals = {
  sourceId: string;
  externalId: string;
};

export type ProductSearchResultHit = {
  id: string;
  name: string;
  category: {
    id: string;
    name: string;
  };
  brand: string;
  productLink?: string;
  place?: string;
  image?: {
    thumbnailUrl?: string;
    url?: string;
  };
  identifiers: Record<string, string | Array<string> | undefined>;
  score: number;
  source?: {
    id: string;
    name: string;
  };
  priority?: number;
  externals?: Array<ProductExternals>;
  merged: Array<ProductSearchResultHit>;
};

export type ProductExternalImage = { url?: string, thumbnailUrl: string } | { url: string, thumbnailUrl?: string };

export type ProductInitialData = {
  name: Product['name'];
  brand: Product['brand'];
  place?: Product['place'];
  type?: Product['type'];
  purchaseDate?: Product['purchaseDate'];
  price?: Product['price'];
  annotation?: Product['annotation'];
  productLink?: Product['productLink'];
  externals?: Array<ProductExternals>;
  gtin?: string;
  image?: ProductExternalImage;
  searchMetadata?: {
    hitIndex: number;
    hitId: string,
    searchedText: string,
    score: number,
    searchId?: string
  }
};

export type ProductTypeGuide = {
  type: string;
  name: string;
  icon: IconResource;
  fields: Array<DynamicInputField>
};

export type ProductExternalMediaSummary = {
  hasDescription: boolean;
  hasSpecification: boolean;
  accessories: Array<ProductSourceLink>;
  documents: Array<ProductSourceLink>;
  images: Array<ProductSourceLink>;
};

export type ProductSearchResults = { hits: Array<ProductSearchResultHit>, searchId?: string };
