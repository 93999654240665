import React, { FC } from 'react';
import {
  Card,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  PropertyProgressCategoryId,
  PropertyProgressSection,
} from '@house-id/houseid-types/dist/propertyProgress';

import { useNavigateBackOr } from '../../../../../../utils/routes';
import { getHomePath } from '../../../../navigation/navigation.property';
import HomeLayout from '../../../../pages/Home/components/HomeLayout';
import { useGetPropertyProgressBlueprintsQuery } from '../../api/propertyProgress.api';
import PropertyProgressPageContent from '../../components/PropertyProgressPageContent';
import PropertyProgressQuickNavigation from '../../components/PropertyProgressQuickNavigation';
import PropertyProspectLinksSection from '../../components/PropertyProspectLinksSection';
import {
  PropertyProgressCategorySectionProps,
  PropertyBlueprintSectionData,
  PropertyBlueprintsSectionId,
} from '../../types.propertyProgress';
import PropertyBlueprintsCoverImagesSection from './components/PropertyBlueprintsCoverImagesSection';
import PropertyBlueprintsDocumentsSection from './components/PropertyBlueprintsDocumentsSection';
import PropertyBlueprintsPhotosSection from './components/PropertyBlueprintsPhotosSection';
import { getPropertyProgressPath } from '../../navigation.propertyProgress';
import PropertyExternalService from '../../../../constants/constants.externalServices';
import useGetCurrentProperty from '../../../../hooks/useCurrentProperty';
import { BoughtSfPropertyYearAgoBanner } from '../../components/sf/BoughtSfPropertyYearAgoBanner';
import PropertyExternalServicesSection from '../../../../components/PropertyExternalServicesSection';
import useGetPropertyProgressCategoryContentText from '../../hooks/useGetPropertyProgressCategoryContentText';
import { getPropertyProgressPercent } from '../../utils/utils.propertyProgress';

const BlueprintsSectionsContent: Record<string, FC<PropertyProgressCategorySectionProps>> = {
  [PropertyBlueprintsSectionId.PROPERTY_COVER_PHOTOS]: PropertyBlueprintsCoverImagesSection,
  [PropertyBlueprintsSectionId.PROPERTY_PHOTOS]: PropertyBlueprintsPhotosSection,
  [PropertyBlueprintsSectionId.BLUEPRINT_DOCUMENT_TYPES]: PropertyBlueprintsDocumentsSection,
  [PropertyBlueprintsSectionId.PROSPECT_LINKS]: PropertyProspectLinksSection,
};

const PropertyBlueprints: FC = () => {
  const { t } = useTranslation(['property']);
  const navigateBackOr = useNavigateBackOr();

  const { data: property } = useGetCurrentProperty();
  const propertyId = property?.id || '';

  const {
    data: propertyBlueprints,
    isLoading: isPropertySpecificationLoading,
  } = useGetPropertyProgressBlueprintsQuery(
    propertyId ? { propertyId } : skipToken,
    // NOTE: for updating photos if changed
    { refetchOnMountOrArgChange: true },
  );

  const handleRenderSectionContent = (section: PropertyProgressSection<PropertyBlueprintSectionData>) => {
    const Content = BlueprintsSectionsContent[section.id];

    return Content ? <Content section={section} /> : null;
  };

  const sections = propertyBlueprints?.sections || [];
  const getPropertyProgressCategoryContentDescription = useGetPropertyProgressCategoryContentText();
  const description = getPropertyProgressCategoryContentDescription({
    percent: getPropertyProgressPercent(propertyBlueprints?.progress),
    categoryId: PropertyProgressCategoryId.BLUEPRINTS,
  });

  return (
    <HomeLayout
      SideColumn={
        <Stack spacing={2}>
          <Card sx={{ padding: 2 }}>
            <PropertyProgressQuickNavigation
              currentPropertyProgressCategoryId={PropertyProgressCategoryId.BLUEPRINTS}
            />
          </Card>
          <BoughtSfPropertyYearAgoBanner />
          <PropertyExternalServicesSection externalServicesIds={[PropertyExternalService.EMAIL_SWEDISH_MUNICIPALITY]} />
        </Stack>
      }
      breadcrumbsLinks={[
        {
          link: getPropertyProgressPath({ propertyId }),
          name: t('property:property_progress_title'),
        },
      ]}
      sideDrawerElements={[
        <PropertyProgressQuickNavigation
          currentPropertyProgressCategoryId={PropertyProgressCategoryId.BLUEPRINTS}
          key={PropertyProgressQuickNavigation.name}
        />,
        <BoughtSfPropertyYearAgoBanner key={BoughtSfPropertyYearAgoBanner.name} />,
        <PropertyExternalServicesSection
          externalServicesIds={[PropertyExternalService.EMAIL_SWEDISH_MUNICIPALITY]}
          key={PropertyExternalServicesSection.name}
        />,
      ]}
      title={t('property:property_progress_pictures_and_drawings_title')}
      onBack={() => navigateBackOr(getHomePath({ propertyId }))}
    >
      <PropertyProgressPageContent
        description={description}
        loading={isPropertySpecificationLoading}
        progress={propertyBlueprints?.progress}
        renderSectionContent={handleRenderSectionContent}
        sections={sections}
      />
    </HomeLayout>
  );
};

export default PropertyBlueprints;
