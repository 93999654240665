import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PropertyTasksSectionData,

  PropertyProgressCategorySectionProps,
} from '../../../types.propertyProgress';
import PropertyTasksSection from '../../../components/PropertyTasksSection';
import useGetPropertyProgressCategoryContentText from '../../../hooks/useGetPropertyProgressCategoryContentText';
import { getPropertyProgressPercent } from '../../../utils/utils.propertyProgress';
import { PropertyProgressCategoryId } from '@house-id/houseid-types/dist/propertyProgress';

type ConnectedToRoomTasksSection = FC<PropertyProgressCategorySectionProps<PropertyTasksSectionData>>;
const PropertyPlanningNotConnectedToRoomTasksSection: ConnectedToRoomTasksSection = ({
  section,
}) => {
  const { t } = useTranslation(['property']);

  const getPropertyProgressCategoryContentDescription = useGetPropertyProgressCategoryContentText();
  const description = getPropertyProgressCategoryContentDescription({
    percent: getPropertyProgressPercent(section.progress),
    categoryId: PropertyProgressCategoryId.PLANNING,
    sectionId: section.id,
  });

  return (
    <PropertyTasksSection
      description={description}
      section={section}
      taskSectionTitle={t('property:property_progress_property_planning_not_connected_to_room_tasks_title')}
    />
  );
};

export default PropertyPlanningNotConnectedToRoomTasksSection;
