import {
  HIDUser,
  HIDBlob,
  ModifyActionType,
} from '@house-id/houseid-types/dist/common';

import { StorageUsage } from '../Property/types/property.types';

export enum SubscriptionPlanId {
  FREE = 'free',
  BASE = 'base',
  PLUS = 'plus',
}

export enum SubscriptionFeature {
  MULTI_PLATFORMS = 'multiPlatforms',
  MULTIPLE_PROPERTIES = 'multiProperties',
  STORAGE_SPACE = 'storageSpace',
  EXTERNAL_SERVICES_SYNC_INFO = 'externalServicesSync',
  ADVANCED_RECEIPT_READING = 'advancedReceiptReading',
  EXPERTS_PANEL_AND_FORUM = 'expertsPanelAndForum',
  TWO_FACTOR_AUTHENTICATION = 'twoFactorAuthentication',
  PREVIOUSLY_OWNED_PROPERTY = 'previouslyOwnedProperty',
  INVITE_MEMBERS = 'inviteMembers',
  SMART_SUGGESTIONS = 'smartSuggestions',
  BONUS_POINTS = 'bonusPoints',
  LOANS = 'loans',
  INSURANCES = 'insurances',
  RECURRING_EXPENSES = 'recurringExpenses',
  VALUATION_INDICATIONS = 'valuationIndications',
  // PROPERTY_SUPPORT = 'property_support',
}

export type FeatureValue<TValue = string, TTrialValue = string> = {
  value?: TValue;
  enabled: boolean;
  trial?: {
    value?: TTrialValue;
    enabled: boolean;
  }
};

type UnlimitedFeatureValue = 'unlimited';

export type SubscriptionPlanFeatures = {
  [SubscriptionFeature.MULTI_PLATFORMS]: FeatureValue<undefined, { trialPeriodDays: number; }>;
  [SubscriptionFeature.MULTIPLE_PROPERTIES]: FeatureValue<{ additionalProperties: number | UnlimitedFeatureValue; }>;
  [SubscriptionFeature.STORAGE_SPACE]: FeatureValue<{ storageQuotaInBytes: number; }>;
  [SubscriptionFeature.EXTERNAL_SERVICES_SYNC_INFO]: FeatureValue<undefined, { numberOfExports: number | UnlimitedFeatureValue; }>;
  [SubscriptionFeature.ADVANCED_RECEIPT_READING]: FeatureValue<undefined, { numberOfInferences: number; }>;
  [SubscriptionFeature.EXPERTS_PANEL_AND_FORUM]: FeatureValue<{ canPostForumQuestionToExpert: boolean; }>;
  [SubscriptionFeature.TWO_FACTOR_AUTHENTICATION]: FeatureValue;
  [SubscriptionFeature.PREVIOUSLY_OWNED_PROPERTY]:
  FeatureValue<{ copyOfPreviouslyOwnedHomeAccessForDays: number | UnlimitedFeatureValue; }>;
  [SubscriptionFeature.INVITE_MEMBERS]: FeatureValue<{ additionalMembers: number | UnlimitedFeatureValue; }>;
  [SubscriptionFeature.SMART_SUGGESTIONS]: FeatureValue;
  [SubscriptionFeature.BONUS_POINTS]: FeatureValue<{ bonusMultiplier: number }>;
  [SubscriptionFeature.LOANS]: FeatureValue;
  [SubscriptionFeature.INSURANCES]: FeatureValue;
  [SubscriptionFeature.RECURRING_EXPENSES]: FeatureValue;
  [SubscriptionFeature.VALUATION_INDICATIONS]: FeatureValue;
};

export enum FeatureStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  TRIAL_AVAILABLE = 'TRIAL_AVAILABLE',
  TRIAL = 'TRIAL',
  ENABLED_BUT_EXHAUSTED = 'ENABLED_BUT_EXHAUSTED',
}

export type SubscriptionPlan = {
  id: SubscriptionPlanId;
  pricePerYear: number;
  pricePerMonth: number;
  pricePerYearInApp: number;
  features: SubscriptionPlanFeatures;
};

export type PropertyStorageFeatureInfo = StorageUsage & { status: FeatureStatus, planId: SubscriptionPlanId };

export type CurrentSubscriptionPlan = {
  startDateUsingWebApp: string;
  plan: SubscriptionPlan;
  me: Record<SubscriptionFeature, { status: FeatureStatus }>;
  properties: Array<{
    id: string,
    storageSpace: PropertyStorageFeatureInfo,
  }>;
};

export enum SubscriptionState {
  EXPIRED = 'expired',
  ACTIVE = 'active',
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  FUTURE = 'future',
  BILLING_GRACE_PERIOD = 'billing-grace-period',
  BILLING_RETRY_PERIOD = 'billing-retry-period',
  PAUSED = 'paused',
  NOT_FOUND = 'not-found',
  UNKNOWN = 'unknown',
}

export enum SubscriptionType {
  IAP = 'iap',
  MDD = 'mdd',
}

export enum SubscriptionPlatform {
  IOS = 'ios',
  ANDROID = 'android',
  WEB = 'web',
}

export type HIDSubscription = {
  planId: SubscriptionPlanId;
  type: SubscriptionType;
  state: SubscriptionState;
  platform: SubscriptionPlatform;
  productId: string;
  provider: SubscriptionPlanProviderId;
  mandate?: SubscriptionPlanOptionOfferMddMandate;
  expireDate?: string;
  version: number;
  cancelledDate?: string;
  nextRenewal?: {
    offer?: SubscriptionPlanPeriodOptionOffer
    basePrices: SubscriptionPrices;
    pay: SubscriptionPrices;
    productId: string;
    planId: SubscriptionPlanId;
    date?: string;
  };
  nextCapture?: {
    offer?: SubscriptionPlanPeriodOptionOffer
    basePrices: SubscriptionPrices;
    pay: SubscriptionPrices;
    productId: string;
    planId: SubscriptionPlanId;
    date?: string;
  };
};

export type HIDSharedSubscription = {
  user: HIDUser;
  plan: {
    planId: SubscriptionPlanId;
    source: {
      type: SubscriptionType;
      platform: SubscriptionPlatform;
      paymentReference: string;
    },
    expirationTime: string;
  }
};

export type SubscriptionInfo = {
  subscriptions: Array<HIDSubscription>;
  sharedByOthers: Array<HIDSharedSubscription>;
  legacySubscription?: {
    planId: SubscriptionPlanId;
    expireDate: string;
  }
};

enum SubscriptionPurchaseType {
  RENEW = 'renew',
  NEW = 'new',
}

export type SubscriptionHistoryItem = {
  id: string;
  planId?: SubscriptionPlanId;
  amount?: number;
  type: SubscriptionType;
  platform: SubscriptionPlatform;
  productId: string;
  purchaseDate: string;
  subscriptionId: string;
  purchaseType: SubscriptionPurchaseType;
  duration: SubscriptionPlanDuration;
};

export enum SubscriptionSharingStatus {
  HAS_SUBSCRIPTION = 'has-subscription',
  SHARED_BY_OTHER = 'shared-by-other-user',
  SHARED_BY_ME = 'shared-by-me',
  NO_SUBSCRIPTION = 'no-subscription',
}

export type SubscriptionSharingMember = {
  user: {
    id: string;
    name: string;
    givenName: string;
    surname: string;
    blobs: Array<HIDBlob>;
  };
  properties?: Array<{ id: string; streetAddress: string; alias?: string; }>;
  status: SubscriptionSharingStatus;
};

export type SubscriptionSharingActionItem = {
  userId: string;
  action: ModifyActionType;
};

export type SubscriptionPlanDuration = {
  years?: number;
  months?: number;
};

export enum SubscriptionPlanProviderId {
  MDD = 'mdd-trustly',
  IAP_APPLE = 'iap-apple',
  IAP_GOOGLE = 'iap-google',
}

type SubscriptionPlanOptionCommonProviderData<TProviderId extends SubscriptionPlanProviderId> = {
  providerId: TProviderId;
  productId: string;
};

export type SubscriptionPlanOptionMddProviderData = SubscriptionPlanOptionCommonProviderData<SubscriptionPlanProviderId.MDD> & {
  prices: SubscriptionPrices;
  buySettings: SubscriptionPlanOptionOfferMddBuySettings
  signed: string;
};

export type SubscriptionPlanOptionIapAppleProviderData =
  SubscriptionPlanOptionCommonProviderData<SubscriptionPlanProviderId.IAP_APPLE>;

export type SubscriptionPlanOptionIapGoogleProviderData =
  SubscriptionPlanOptionCommonProviderData<SubscriptionPlanProviderId.IAP_GOOGLE>;

export type SubscriptionPlanOptionProviderData =
  SubscriptionPlanOptionMddProviderData;// | SubscriptionPlanOptionIapAppleProviderData | SubscriptionPlanOptionIapGoogleProviderData;

export type SubscriptionPlanOptionOfferMddMandate = {
  id: string;
  state: string;
  bankName: string;
  providerId: string;
  accountName: string;
  accountLastDigits: string;
};

export enum SubscriptionPlanOptionMddBuySettingType {
  REGULAR = 'regular',
  FUTURE = 'future',
  UPGRADE = 'upgrade',
  NOT_AVAILABLE = 'no',
  EXISTING_PLAN = 'existing-plan',
}

export type SubscriptionPlanOptionOfferMddRegularBuySettings = {
  type: SubscriptionPlanOptionMddBuySettingType.REGULAR;
  nextCaptureDate: string;
  nextCapturePrices: SubscriptionPrices;
  mandate: SubscriptionPlanOptionOfferMddMandate;
  payNow: SubscriptionPrices
};

export type SubscriptionPlanOptionOfferMddFutureBuySettings =
  {
    type: SubscriptionPlanOptionMddBuySettingType.FUTURE;
    mandate: SubscriptionPlanOptionOfferMddMandate;
    nextRenewalDate: string;
    nextCaptureDate: string;
    nextCapturePrices?: SubscriptionPrices
  };

export type SubscriptionPlanOptionOfferMddUpgradeBuySettings =
  {
    type: SubscriptionPlanOptionMddBuySettingType.UPGRADE;
    mandate: SubscriptionPlanOptionOfferMddMandate;
    refundDays: number;
    nextRenewalDate: string;
    nextCaptureDate: string;
    refunds: SubscriptionPrices;
    payNow: SubscriptionPrices
  };

export type SubscriptionPlanOptionOfferMddNotAvailableBuySettings =
  {
    type: SubscriptionPlanOptionMddBuySettingType.NOT_AVAILABLE;
  };

export type SubscriptionPlanOptionOfferMddExistingBuySettings =
  {
    type: SubscriptionPlanOptionMddBuySettingType.EXISTING_PLAN;
    nextRenewalDate: string;
    nextCaptureDate: string;
    nextCapturePrices: SubscriptionPrices;
    mandate?: SubscriptionPlanOptionOfferMddMandate;
  };

export type SubscriptionPlanOptionOfferMddBuySettings =
  SubscriptionPlanOptionOfferMddRegularBuySettings
  | SubscriptionPlanOptionOfferMddNotAvailableBuySettings
  | SubscriptionPlanOptionOfferMddFutureBuySettings
  | SubscriptionPlanOptionOfferMddUpgradeBuySettings
  | SubscriptionPlanOptionOfferMddExistingBuySettings;

export type SubscriptionPlanOptionOfferMddProviderData = {
  providerId: SubscriptionPlanProviderId.MDD;
  signed: string;
};

export type SubscriptionPlanOptionOfferIapAppleProviderData = {
  providerId: SubscriptionPlanProviderId.IAP_APPLE,
  id: string;
  signature?: string,
  keyIdentifier: string,
  nonce?: string,
  timestamp?: number
};

export type SubscriptionPlanOptionOfferIapGoogleProviderData = {
  providerId: SubscriptionPlanProviderId.IAP_GOOGLE;
  productId: string;
};

export type SubscriptionPlanOptionOfferProviderData =
  SubscriptionPlanOptionOfferMddProviderData;// |
// SubscriptionPlanOptionOfferIapAppleProviderData |
// SubscriptionPlanOptionOfferIapGoogleProviderData;

export enum SubscriptionPlanPeriodOptionOfferType {
  TRIAL = 'trial',
  DISCOUNT = 'discount',
  TRIAL_AND_DISCOUNT = 'trial-and-discount',
}

export type SubscriptionPlanPeriodOptionCommonOfferConfig<TOfferType extends SubscriptionPlanPeriodOptionOfferType> = {
  id: string;
  type: TOfferType;
  duration: SubscriptionPlanDuration;
};

export type SubscriptionPlanPeriodOptionTrialOfferConfig =
  SubscriptionPlanPeriodOptionCommonOfferConfig<SubscriptionPlanPeriodOptionOfferType.TRIAL> &
  {
    duration: SubscriptionPlanDuration;
  };

export type SubscriptionPlanPeriodOptionDiscountOfferConfig =
  SubscriptionPlanPeriodOptionCommonOfferConfig<SubscriptionPlanPeriodOptionOfferType.DISCOUNT> &
  {
    percent: number;
  };

export type SubscriptionPlanPeriodOptionTrialAndDiscountOfferConfig =
  SubscriptionPlanPeriodOptionCommonOfferConfig<SubscriptionPlanPeriodOptionOfferType.TRIAL_AND_DISCOUNT> &
  {
    percent: number;
    duration: SubscriptionPlanDuration;
  };

export type SubscriptionPlanPeriodOptionOfferConfig =
  SubscriptionPlanPeriodOptionTrialOfferConfig |
  SubscriptionPlanPeriodOptionDiscountOfferConfig |
  SubscriptionPlanPeriodOptionTrialAndDiscountOfferConfig;

export type SubscriptionPlanPeriodOptionOffer = {
  id: string;
  metadata: {
    type: 'win-back' | 'introductory' | 'loyalty';
    partner: string;
  }
  config: SubscriptionPlanPeriodOptionOfferConfig;
  expirationTime?: string;
  providerData: SubscriptionPlanOptionOfferProviderData;
};

export type SubscriptionPlanPeriodOption = {
  id: string;
  duration: SubscriptionPlanDuration;
  providerData: SubscriptionPlanOptionProviderData;
  offer?: SubscriptionPlanPeriodOptionOffer
};

export type SubscriptionPlanOffer = {
  id: SubscriptionPlanId;
  periods: Array<SubscriptionPlanPeriodOption>;
};

export enum SubscriptionPlanOfferPeriodType {
  YEAR = 'year',
  MONTH = 'month',
}

export type SubscriptionPlanOfferByPeriod = {
  planId: SubscriptionPlanId;
  periods: Record<SubscriptionPlanOfferPeriodType, SubscriptionPlanPeriodOption>;
};

export type SubscriptionPlanOptionPeriodOfferPrices = {
  planId: SubscriptionPlanId;
  period: SubscriptionPlanOfferPeriodType;
  offer?: SubscriptionPlanPeriodOptionOffer;
  basePricePerMonth: number;
  currentPricePerMonth: number;
  basePricePerYear: number;
  currentPricePerYear: number;
  providerData?: SubscriptionPlanOptionProviderData;
  canActivate: boolean;
};

export type SubscriptionPrices = {
  SEK: number;
};

export type SubscriptionOrder = {
  id: string;
  state: 'completed' | 'cancelled' | 'failed' | 'await-mandate';
  createdAt: string;
  subscription: {
    plan: {
      id: SubscriptionPlanId;
      prices: SubscriptionPrices;
      productId: string;
      duration: SubscriptionPlanDuration;
      buySettings: SubscriptionPlanOptionOfferMddBuySettings;
      userId: string;
      provider: SubscriptionPlanProviderId;
      subscriptionVersion: number;
    };
  };
  offer?: SubscriptionPlanPeriodOptionOffer;
  result: {
    status: 'UPGRADED' | 'CHANGED' | 'PAYMENT_CREATED';
    success: boolean;
  };
  payment: {
    id: number;
    amount: number;
    mandate: SubscriptionPlanOptionOfferMddMandate;
    currency: string;
  }
};
