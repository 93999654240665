import { HIDApiTags } from '../../../../../../../api/HIDApiTags';
import { PropertyId } from '../../../../../types/property.types';
import { Task } from '../types.task';
import { propertyApi } from '../../../../../api/property.api';
import { provideArrayTags } from '../../../../../../../api/HIDBaseQuery';

export const taskApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query<Array<Task>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/tasks`,
      providesTags: (result) => provideArrayTags(HIDApiTags.TASK, result),
      transformResponse: (result: { tasks: Array<Task> }) => result?.tasks || [],
    }),
  }),
});

export const {
  useGetTasksQuery,
  useLazyGetTasksQuery,
} = taskApi;
