import { skipToken } from '@reduxjs/toolkit/query';

import {
  useGetPropertyUsersQuery,
} from '../api/property.api';
import { useGetCurrentUserQuery } from '../../Auth/api/user.api';
import { isOwner } from '../utils/utils.user';
import { PropertyUser } from '../../../types/common';

const useGetIsCurrentUserPropertyOwner = ({ propertyId }: { propertyId?: string }) => {
  const { data: users = [] } = useGetPropertyUsersQuery(propertyId ? { propertyId } : skipToken);

  const { data: currentUser } = useGetCurrentUserQuery();

  const isCurrentUserOwner = users.find((user: PropertyUser) => currentUser?.id === user.id && isOwner(user));

  return Boolean(isCurrentUserOwner);
};

export default useGetIsCurrentUserPropertyOwner;
