import React from 'react';
import {
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import * as R from 'ramda';
// TODO: remove when backend will sanitize
import sanitizeHtml from 'sanitize-html';

import { FCC } from '../../../../../../types/common';
import HIDLink from '../../../../../../components/HIDLink';
import { EMPTY_VALUE } from '../../../../../../utils/string';

export type BasicInfoItem = {
  label: string;
  value: string;
  isLong?: boolean;
  isLink?: boolean;
  isHtml?: boolean;
};
const InfoItem: FCC<BasicInfoItem> = ({
  label,
  value,
  isLink,
  isHtml,
  sx,
}) => (
  <Stack>
    <Typography sx={sx} variant="subtitle1">
      {label}
    </Typography>
    {
      isLink && value !== EMPTY_VALUE
        ? (
          <HIDLink href={value} label={value} target="_blank" />
        )
        : isHtml && value !== EMPTY_VALUE
          ? (
            /* eslint-disable-next-line react/no-danger */
            <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(value) }} />
          )
          : (
            <Typography
              sx={{
                wordBreak: 'break-word',
                whiteSpace: 'pre-line',
              }}
              variant="body1"
            >
              {value}
            </Typography>
          )
    }
  </Stack>
);

type BasicInfoSectionProps = {
  items: Array<BasicInfoItem>;
};
// eslint-disable-next-line react/no-multi-comp
const BasicInfoSection: FCC<BasicInfoSectionProps> = ({
  items,
}) => {
  const theme = useTheme();

  const itemsPairs = R.splitEvery(2, items);

  return (
    <Grid
      container
      direction="row"
      sx={{
        marginTop: 2.5,
        padding: 2.5,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
        borderRadius: '10px',
      }}
    >
      {
        itemsPairs
          .map(([first, second], index) => (
            <React.Fragment key={index}>
              <Grid
                item
                key={`${first.label}_${index * 2}`}
                sm={first.isLong ? 12 : 6}
                sx={{ marginBottom: index < itemsPairs.length - 1 ? 1.5 : 0 }}
                xxs={12}
              >
                <InfoItem {...first} />
              </Grid>
              {second && (
                <Grid
                  item
                  key={`${second.label}_${index * 2 + 1}`}
                  sm={second.isLong ? 12 : 6}
                  sx={{ marginBottom: index < itemsPairs.length - 1 ? 1.5 : 0 }}
                  xxs={12}
                >
                  <InfoItem {...second} />
                </Grid>
              )}
            </React.Fragment>
          ))
      }
    </Grid>
  );
};

export default BasicInfoSection;
