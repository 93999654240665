import React, {
  FC,
  JSX,
} from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Close,
} from '@mui/icons-material';
import { PropertyProgress } from '@house-id/houseid-types/dist/propertyProgress';

import {
  HomeWidgetType,
  useGetWidgetHeight,
} from '../../utils/useGetWidgetHeight';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import { useGetPropertyProgressQuery } from '../../../../modules/PropertyProgress/api/propertyProgress.api';
import HIDIconButton from '../../../../../../components/buttons/HIDIconButton';
import { getActiveAuth } from '../../../../../../external-services/firebase';
import {
  useGetPropertySettingsQuery,
  useSetPropertySettingsMutation,
} from '../../../../../Auth/api/settings.api';
import { getPropertyProgressPercent } from '../../../../modules/PropertyProgress/utils/utils.propertyProgress';
import OverviewSlide, { DoneStages } from './OverviewSlide';
import CategoriesSlide from './CategoriesSlide';
import ContentSliderNavigation from '../../../../../../components/contentSlider/ContentSliderNavigation';
import ContentSliderContainer from '../../../../../../components/contentSlider/ContentSliderContainer';

enum PropertyProgressWidgetSlideId {
  OVERVIEW_SLIDE,
  CATEGORIES_SLIDE,
}

const PropertyProgressWidgetSlideMap: Record<PropertyProgressWidgetSlideId, React.FC<{
  propertyProgress?: PropertyProgress; SlideHeader: JSX.Element;
}>> = {
  [PropertyProgressWidgetSlideId.OVERVIEW_SLIDE]: OverviewSlide,
  [PropertyProgressWidgetSlideId.CATEGORIES_SLIDE]: CategoriesSlide,
};

const PropertyProgressWidget: FC = () => {
  const { t } = useTranslation(['property']);
  const theme = useTheme();

  const auth = getActiveAuth();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: propertyProgress, isLoading: progressLoading } = useGetPropertyProgressQuery(propertyId ? { propertyId } : skipToken);
  const propertyProgressPercent = getPropertyProgressPercent(propertyProgress?.progress);

  const { currentData: propertySettingsData } = useGetPropertySettingsQuery(
    auth.currentUser?.uid && propertyId
      ? { userUId: auth.currentUser.uid, propertyId }
      : skipToken,
  );

  const [setPropertySettings] = useSetPropertySettingsMutation();

  const isDoneState = propertyProgressPercent >= DoneStages.FORTH_STAGE;

  const widgetHeight = useGetWidgetHeight(HomeWidgetType.PROPERTY_PROGRESS);

  const isAutoStartPrevented = isDoneState && !progressLoading;

  const handleClose = () => {
    if (propertyId) {
      setPropertySettings({
        propertyId,
        settings: {
          propertySettings: {
            ...propertySettingsData?.propertySettings,
            hideHomePropertyProgressWidget: true,
          },
        },
      });
    }
  };

  const SlideHeader = (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      sx={{
        marginTop: isDoneState ? -1.5 : -1,
      }}
    >
      <Typography variant="h5">
        {t('property:property_progress_title')}
      </Typography>
      {
        isDoneState
          ? (
            <HIDIconButton
              Icon={Close}
              color="blank"
              sx={{ marginRight: -1.5 }}
              onClick={handleClose}
            />
          )
          : (
            <ContentSliderNavigation />
          )
      }
    </Stack>
  );

  const handleRenderSlide = (slideId: PropertyProgressWidgetSlideId) => {
    const SlideComponent = PropertyProgressWidgetSlideMap[slideId];
    return (
      <SlideComponent
        SlideHeader={SlideHeader}
        propertyProgress={propertyProgress}
      />
    );
  };

  const slideIds = isDoneState
    ? [PropertyProgressWidgetSlideId.OVERVIEW_SLIDE]
    : Object.keys(PropertyProgressWidgetSlideMap) as unknown as Array<PropertyProgressWidgetSlideId>;

  return (
    <Card
      sx={{
        backgroundColor: isDoneState ? theme.palette.warning.lightest : theme.palette.common.white,
        padding: theme.spacing(2.5),
        height: widgetHeight || '100%',
        minHeight: 215,
      }}
    >
      <ContentSliderContainer
        isAutoStartPrevented={isAutoStartPrevented}
        renderSlide={handleRenderSlide}
        slides={slideIds}
      />
    </Card>
  );
};

export default PropertyProgressWidget;
