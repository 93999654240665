import { Stack } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import * as R from 'ramda';

import { DynamicContentDocumentInput } from '../../../types/types.dynamicContent';
import DynamicContentSource from './DynamicContentSource';
import useGetEntityInfo from '../../../../../hooks/useGetEntityInfo';
import EntityType from '../../../../../../../../../constants/entityType';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import PropertyProgressContentItem from '../../../../../../PropertyProgress/components/PropertyProgressContentItem';
import { getPathWithPropertyIdOrInit } from '../../../../../../../../Auth/navigation/navigation.auth';

type DynamicContentDocumentInputProps = DynamicContentDocumentInput;

const DynamicContentDocument: FC<DynamicContentDocumentInputProps> = ({
  value,
  source,
}) => {
  const navigate = useNavigate();
  const document = value;
  const { data: propertyId } = useGetCurrentPropertyId();

  const getEntityInfo = useGetEntityInfo();
  const documentInfo = getEntityInfo(EntityType.DOCUMENT);

  const handleOpenDocument = () => {
    if (documentInfo?.getViewLink && document?.id) {
      navigate(getPathWithPropertyIdOrInit(documentInfo.getViewLink, { id: document?.id, propertyId }));
    }
  };

  return document
    ? (
      <Stack spacing={1}>
        <PropertyProgressContentItem
          blob={R.head(document.blobs || [])}
          description={document.name}
          title={document.classificationType?.name || ''}
          onNavigate={handleOpenDocument}
        />
        <DynamicContentSource source={source} />
      </Stack>
    ) : null;
};

export default DynamicContentDocument;
