import React, { FC } from 'react';
import {
  Card,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  PropertyProgressCategoryId,
  PropertyProgressSection,
} from '@house-id/houseid-types/dist/propertyProgress';

import { useNavigateBackOr } from '../../../../../../utils/routes';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import HomeLayout from '../../../../pages/Home/components/HomeLayout';
import { useGetPropertyProgressPropertyPurchaseQuery } from '../../api/propertyProgress.api';
import PropertyProgressPageContent from '../../components/PropertyProgressPageContent';
import PropertyProgressQuickNavigation from '../../components/PropertyProgressQuickNavigation';
import PropertyProspectLinksSection from '../../components/PropertyProspectLinksSection';
import { getPropertyProgressPath } from '../../navigation.propertyProgress';
import {
  PropertyProgressCategorySectionProps,
  PropertyPurchaseData,
  PropertyPurchaseSectionId,
} from '../../types.propertyProgress';
import PropertyPurchaseDocumentsSection from './components/PropertyPurchaseDocumentsSection';
import { getPathWithPropertyIdOrInit } from '../../../../../Auth/navigation/navigation.auth';

const PropertyDocumentationSectionsContent: Record<string, FC<PropertyProgressCategorySectionProps>> = {
  [PropertyPurchaseSectionId.PROPERTY_PURCHASE_DOCUMENT_TYPES]: PropertyPurchaseDocumentsSection,
  [PropertyPurchaseSectionId.PROSPECT_LINKS]: PropertyProspectLinksSection,
};

const PropertyPurchase: FC = () => {
  const { t } = useTranslation(['property']);
  const navigateBackOr = useNavigateBackOr();

  const { data: propertyId } = useGetCurrentPropertyId();
  const {
    data: propertyPurchase,
    isLoading: isPropertySpecificationLoading,
  } = useGetPropertyProgressPropertyPurchaseQuery(propertyId ? { propertyId } : skipToken);

  const sections = propertyPurchase?.sections || [];

  const handleRenderSectionContent = (section: PropertyProgressSection<PropertyPurchaseData>) => {
    const Content = PropertyDocumentationSectionsContent[section.id];

    return Content ? <Content section={section} /> : null;
  };

  return (
    <HomeLayout
      SideColumn={
        <Stack spacing={2}>
          <Card sx={{ padding: 2 }}>
            <PropertyProgressQuickNavigation
              currentPropertyProgressCategoryId={PropertyProgressCategoryId.PURCHASE}
              key={PropertyProgressQuickNavigation.name}
            />
          </Card>
        </Stack>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getPropertyProgressPath, { propertyId }),
          name: t('property:property_progress_title'),
        },
      ]}
      sideDrawerElements={[
        <PropertyProgressQuickNavigation
          currentPropertyProgressCategoryId={PropertyProgressCategoryId.PURCHASE}
          key={PropertyProgressQuickNavigation.name}
        />,
      ]}
      title={t('property:property_progress_property_purchase')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getPropertyProgressPath, { propertyId }))}
    >
      <PropertyProgressPageContent
        loading={isPropertySpecificationLoading}
        progress={propertyPurchase?.progress}
        renderSectionContent={handleRenderSectionContent}
        sections={sections}
      />
    </HomeLayout>
  );
};

export default PropertyPurchase;
