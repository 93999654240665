import { EnumType } from '../../../types/common';
import { propertyApi } from '../../Property/api/property.api';

export const enumApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getEnums: builder.query<Record<EnumType, Record<string, string>>, void>({
      query: () => '/enums',
    }),
  }),
});

export const {
  useGetEnumsQuery,
} = enumApi;
