import { createApi } from '@reduxjs/toolkit/query/react';
import { HIDApiTags } from '../../../api/HIDApiTags';
import { HIDAuthQuery } from '../../../api/HIDBaseQuery';

const adminApiTags = [
  HIDApiTags.LABELING_QUEUE,
  HIDApiTags.LABELING_TEMPLATES_TASK,
  HIDApiTags.LABELING_TEMPLATES_TASK_ATTACHMENT,
  HIDApiTags.EMAIL,
  HIDApiTags.EMAIL_ATTACHMENT,
];

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: HIDAuthQuery,
  tagTypes: adminApiTags,
  endpoints: (builder) => ({
    imitateUserAccount: builder.mutation<{ customToken: string }, { email: string }>({
      query: ({ email }) => ({
        url: '/admin/impersonate',
        method: 'POST',
        body: { email },
      }),
    }),
  }),
});

export const {
  useImitateUserAccountMutation,
} = adminApi;
