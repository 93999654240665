import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Card,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import { Stack } from '@mui/system';
import { useDebounce } from 'usehooks-ts';
import { useSearchParams } from 'react-router-dom';
import * as R from 'ramda';
import {
  BudgetCategory,
  BudgetShortModel,
  BudgetTemplateCategoryId,
} from '@house-id/houseid-types/dist/finances/budgets';

import HIDLink from '../../../../../../../../components/HIDLink';
import HIDCircularProgress from '../../../../../../../../components/progress/HIDCircularProgress';
import { LIST_CHEVRON_COLUMN_CONFIG } from '../../../../../../../../constants/columns';
import {
  useRouteQueryParams,
  useNavigateBackOr,
} from '../../../../../../../../utils/routes';
import {
  formatMoney,
  EMPTY_VALUE,
} from '../../../../../../../../utils/string';
import { getPathWithPropertyIdOrInit } from '../../../../../../../Auth/navigation/navigation.auth';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../../../../../navigation/navigation.property';
import HomeListLayout from '../../../../../../pages/Home/components/HomeListLayout';
import { TimeIntervalsGroupingType } from '../../../../../Content/types/content.type';
import FinancesQuickNavigation from '../../../../components/FinancesQuickNavigation';
import { getFinancesPath } from '../../../../navigation.finances';
import {
  FinancePageNames,
  FinancesFilterSectionMode,
} from '../../../../types.finances';
import {
  useGetBudgetComparisonQuery,
  useGetBudgetFiltrationQuery,
  useGetBudgetsQuery,
} from '../../api/budgets.api';
import BudgetActions from '../../components/BudgetActions';
import BudgetsEmptyStateCard from '../../components/BudgetsEmptyStateCard';
import BudgetComparisonChart from '../../components/BudgetComparisonChart';
import { getBudgetCategoryPath } from '../../navigation.budgets';
import BudgetsFilterSection, { BudgetsFilters } from '../../components/BudgetsFilterSection';
import { useGetRecurringExpensesBankAccountsQuery } from '../../../RecurringExpenses/api/recurringExpenses.api';
import { FILTER_DEBOUNCE_TIME } from '../../../../../../../../constants/layout';
import useDialog from '../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../hooks/useDialog/DialogNames';
import HIDTypography from '../../../../../../../../components/HIDTypography';
import { TutorialDialogProps } from '../../../../../../components/dialogs/tutorialDialog/TutorialDialog';
import { TutorialDialogTypes } from '../../../../../../components/dialogs/tutorialDialog/useGetTutorialDialogSections';
import { getBudgetName } from '../../utils/budget.utils';
import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import useGetCurrentBudget from '../../hooks/useGetCurrentBudget';
import BudgetsQuickNavigation from './components/BudgetsQuickNavigation';
import useGetComparisonColumns from '../../../RecurringExpenses/hooks/useGetComparisonColumns';
import { getComparisonList } from '../../../RecurringExpenses/utils.recurringExpenses';
import { ALL_BUDGET_CATEGORY } from '../../constants.budgets';
import { ChartDataItem } from '@house-id/houseid-types/dist/finances/finances';

const getBudgetById = (budgetId?: string, budgets?: Array<BudgetShortModel>) => budgets?.find((budget) => budget.id === budgetId);

const now = new Date();
const currentYear = now.getFullYear();
const yearBefore = currentYear - 1;

const useGetBudgetOverviewColumns = (columnParams: {
  budgetTotal: number;
  timeIntervalGroupingType: TimeIntervalsGroupingType
}) => {
  const { t } = useTranslation(['finances', 'common']);

  const { isDownSm } = useBreakPointsSizes();

  const isYearly = columnParams.timeIntervalGroupingType === TimeIntervalsGroupingType.Yearly;
  const isQuarterly = columnParams.timeIntervalGroupingType === TimeIntervalsGroupingType.Quarterly;

  const valueColumnLabel = columnParams.timeIntervalGroupingType === TimeIntervalsGroupingType.Yearly
    ? t('finances:budget_yearly_label')
    : columnParams.timeIntervalGroupingType === TimeIntervalsGroupingType.Monthly
      ? t('finances:budget_monthly_label')
      : t('finances:budget_quarterly_label');

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: t('common:categories'),
      flex: 0.5,
      type: 'string',
      sortable: true,
      valueGetter: (params: GridValueGetterParams) => params?.row as BudgetCategory,
      sortComparator: (a: BudgetCategory, b: BudgetCategory) => a.label.localeCompare(b.label),
      renderCell: (params: GridRenderCellParams) => {
        const { label, totalAmount } = params?.row as BudgetCategory;

        return (
          <Stack alignItems="center" direction="row" spacing={2}>
            <HIDCircularProgress value={Math.round((totalAmount / columnParams.budgetTotal) * 100)} />
            <Typography variant="body2">
              {label}
            </Typography>
          </Stack>
        );
      },
    },
    !isDownSm && {
      field: 'numberOfOperations',
      headerName: t('common:amount'),
      flex: 0.25,
      type: 'number',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { numberOfPayments } = params?.row as BudgetCategory;

        return (
          <Typography variant="body2">
            {numberOfPayments || EMPTY_VALUE}
          </Typography>
        );
      },
    },
    {
      field: 'value',
      headerName: valueColumnLabel,
      flex: 0.25,
      type: 'number',
      sortable: true,
      valueGetter: (params: GridValueGetterParams<BudgetCategory>) => params.row.averagePerMonth,
      renderCell: (params: GridRenderCellParams<BudgetCategory, number | undefined>) => (
        <Typography noWrap variant="subtitle2">
          {
            params.value !== undefined
              ? formatMoney(isYearly
                ? params.value * 12
                : isQuarterly
                  ? params.value * 3
                  : params.value || 0)
              : EMPTY_VALUE
          }
        </Typography>
      ),
    },
    LIST_CHEVRON_COLUMN_CONFIG,
  ].filter(Boolean);
  return columns;
};

const BudgetOverview: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['forms_common', 'common', 'finances']);

  const [_, setSearchParams] = useSearchParams();
  const { budgetId } = useRouteQueryParams<{ budgetId?: string; }>();

  const navigateBackOr = useNavigateBackOr();
  const [openTutorialDialog] = useDialog<TutorialDialogProps>(DialogNames.TUTORIAL_DIALOG);

  const { data: propertyId } = useGetCurrentPropertyId();

  const [filters, setFilters] = useState<BudgetsFilters>({
    mode: FinancesFilterSectionMode.Filter,
    primaryBudgetId: budgetId,
    timeIntervalGroupingType: TimeIntervalsGroupingType.Monthly,
  });

  const isFiltrationMode = filters.mode === FinancesFilterSectionMode.Filter;

  const handleBudgetIdChange = useCallback((budgetId: string | undefined) => setSearchParams(budgetId ? { budgetId } : {}), []);

  const { data: currentBudget } = useGetCurrentBudget();

  const {
    data: budgets = [],
    isLoading: isBudgetsLoading,
    isFetching: isBudgetsFetching,
  } = useGetBudgetsQuery(propertyId ? { propertyId } : skipToken);

  const selectedBudget = budgets.find(({ id }) => id === budgetId);

  useEffect(() => {
    if ((currentBudget && !budgetId) || !selectedBudget) {
      handleBudgetIdChange(currentBudget?.id);
    }
  }, [budgetId, selectedBudget, currentBudget]);

  useEffect(() => {
    if (filters.primaryBudgetId && filters.primaryBudgetId !== budgetId) {
      handleBudgetIdChange(filters.primaryBudgetId);
    }
  }, [filters.primaryBudgetId]);

  useEffect(() => {
    if (budgetId && budgetId !== filters.primaryBudgetId) {
      if (selectedBudget) {
        setFilters({ ...filters, primaryBudgetId: budgetId });
      }
    }
  }, [budgetId]);

  const debouncedFilters = useDebounce(
    filters,
    FILTER_DEBOUNCE_TIME,
  );

  const {
    data: filtration,
    isLoading: isFiltrationLoading,
    isFetching: isFiltrationFetching,
  } = useGetBudgetFiltrationQuery(
    propertyId
      && selectedBudget
      && debouncedFilters.primaryBudgetId
      && debouncedFilters.mode === FinancesFilterSectionMode.Filter
      ? {
        propertyId,
        budgetId: debouncedFilters.primaryBudgetId,
        fromMonth: debouncedFilters.fromMonth,
        toMonth: debouncedFilters.toMonth,
        grouping: debouncedFilters.timeIntervalGroupingType,
      }
      : skipToken,
  );

  const {
    data: comparison,
    isLoading: isComparisonLoading,
    isFetching: isComparisonFetching,
  } = useGetBudgetComparisonQuery(
    propertyId
      && selectedBudget
      && debouncedFilters.primaryBudgetId
      && debouncedFilters.mode === FinancesFilterSectionMode.Compare
      ? {
        propertyId,
        budgetId: debouncedFilters.primaryBudgetId,
        fromMonth: debouncedFilters.fromMonth,
        toMonth: debouncedFilters.toMonth,
        grouping: debouncedFilters.timeIntervalGroupingType,
        compareToBudgetId: debouncedFilters.secondaryBudgetId,
        expenseYear: debouncedFilters.compareToYear,
        accounts: debouncedFilters.bankAccountIds,
      }
      : skipToken,
  );

  const hasBudgets = Boolean(budgets?.length || 0);

  const isLoading = isFiltrationLoading || isComparisonLoading || isBudgetsLoading;
  const isFetching = isFiltrationFetching || isComparisonFetching || isBudgetsFetching;
  const isLoadingOrFetching = (!debouncedFilters.primaryBudgetId && hasBudgets) || isLoading || isFetching;

  const comparisonChartData = comparison?.chart?.map(
    debouncedFilters.compareToYear
      ? (chartDataItem: ChartDataItem) => ({ ...chartDataItem, secondary: chartDataItem.line })
      : R.identity,
  );

  const chartData = debouncedFilters.mode === FinancesFilterSectionMode.Filter
    ? filtration?.chart
    : comparisonChartData;

  const { data: bankAccounts = [] } = useGetRecurringExpensesBankAccountsQuery();

  const primaryBudget = getBudgetById(budgetId, budgets);
  const secondaryBudget = filters.secondaryBudgetId ? getBudgetById(filters.secondaryBudgetId, budgets) : undefined;

  const yearsList = [currentYear, yearBefore];

  const title = budgetId
    ? getBudgetName(filtration?.budget) || t('finances:budgets')
    : t('finances:budgets');

  const budgetTotal = useMemo(
    () => R.sum(filtration?.budget?.categories?.map(({ totalAmount }) => totalAmount) || []),
    [filtration?.budget?.categories],
  );

  const filtrationRows = useMemo(
    () => selectedBudget && filtration?.budget?.categories?.length
      ? [
        {
          categoryId: ALL_BUDGET_CATEGORY as unknown as BudgetTemplateCategoryId,
          totalAmount: budgetTotal,
          averagePerMonth: budgetTotal / 12,
          numberOfPayments: R.sum(filtration?.budget?.categories?.map((category) => category.numberOfPayments) || []),
          label: t('common:all_label'),
          subcategories: [],
        } as BudgetCategory,
        ...(filtration?.budget?.categories || []),
      ]
      : [],
    [filtration?.budget?.categories, budgetTotal, selectedBudget],
  );

  const comparisonRows = isFiltrationMode
    ? []
    : getComparisonList(
      comparisonChartData || [],
      debouncedFilters.compareToYear || secondaryBudget?.year,
      debouncedFilters.timeIntervalGroupingType,
    );

  const rows = isFiltrationMode ? filtrationRows : comparisonRows;

  const filtrationColumns = useGetBudgetOverviewColumns({
    budgetTotal,
    timeIntervalGroupingType: debouncedFilters.timeIntervalGroupingType,
  });
  const comparisonColumns = useGetComparisonColumns({ showDate: false });
  const columns = isFiltrationMode ? filtrationColumns : comparisonColumns;

  const getBudgetListNameById = (budgetId: string | undefined, budgetsList: Array<BudgetShortModel> | undefined) =>
    getBudgetName(budgetsList?.find((budgetListItem) => budgetListItem.id === budgetId));

  const handleRowClick: GridEventListener<'rowClick'> = useCallback(
    (params) => {
      if (propertyId && budgetId) {
        const item = params?.row as BudgetCategory;
        navigate(getBudgetCategoryPath({
          propertyId, categoryId: item.categoryId, id: budgetId,
        }));
      }
    },
    [propertyId, budgetId, navigate],
  );

  return (
    <HomeListLayout
      hideDataGridIfNoData
      ListHeaderComponent={
        <Stack spacing={3}>
          <Stack direction="row">
            <HIDTypography>
              {t('finances:learn_more_about_budgets')}
            </HIDTypography>
            {' '}
            <HIDLink
              label={t('common:here')}
              sx={{ flexShrink: 0, ml: 0.5 }}
              onClick={() => openTutorialDialog({ type: TutorialDialogTypes.BUDGET })}
            />
            {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
            {'.'}
          </Stack>
          {
            hasBudgets || isLoadingOrFetching
              ? (
                <BudgetComparisonChart
                  data={chartData || []}
                  groupingType={debouncedFilters.timeIntervalGroupingType}
                  isLoading={isLoadingOrFetching}
                  mode={filters.mode}
                  primaryLabel={
                    filters.primaryBudgetId
                      ? `${t('forms_common:total')} ${getBudgetListNameById(primaryBudget?.id, budgets)}`
                      : undefined
                  }
                  secondaryLabel={
                    filters.secondaryBudgetId
                      ? `${t('forms_common:total')} ${getBudgetListNameById(secondaryBudget?.id, budgets)}`
                      : filters.compareToYear
                        ? `${t('forms_common:total')} ${filters.compareToYear}`
                        : undefined
                  }
                />
              )
              : <BudgetsEmptyStateCard />
          }
        </Stack>
      }
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <BudgetActions selectedBudget={primaryBudget} />
          </Card>
          <Card sx={{ padding: 2 }}>
            <FinancesQuickNavigation currentPage={FinancePageNames.BUDGET_CATEGORIES} />
          </Card>
          {hasBudgets && (
            <Card sx={{ padding: 2 }}>
              <BudgetsFilterSection
                bankAccounts={bankAccounts}
                budgets={budgets}
                filters={filters}
                years={yearsList}
                onChange={setFilters}
              />
            </Card>
          )}
          {hasBudgets && (
            <Card sx={{ padding: 2 }}>
              <BudgetsQuickNavigation />
            </Card>
          )}
        </>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getFinancesPath, { propertyId }),
          name: t('finances:finances'),
        },
      ]}
      columns={columns}
      getRowId={isFiltrationMode ? (category: BudgetCategory) => category.categoryId : undefined}
      initialState={{
        pagination: {},
        sorting: isFiltrationMode
          ? {
            sortModel: [
              {
                field: 'value',
                sort: 'desc',
              },
            ],
          }
          : {},
      }}
      isLoading={isLoadingOrFetching}
      rows={rows}
      sideDrawerElements={[
        <BudgetActions
          key={BudgetActions.name}
          selectedBudget={primaryBudget}
        />,
        <FinancesQuickNavigation
          currentPage={FinancePageNames.BUDGET_CATEGORIES}
          key={FinancesQuickNavigation.name}
        />,
        hasBudgets && (
          <BudgetsFilterSection
            bankAccounts={bankAccounts}
            budgets={budgets}
            filters={filters}
            key={BudgetsFilterSection.name}
            years={yearsList}
            onChange={setFilters}
          />
        ),
        hasBudgets && (<BudgetsQuickNavigation />),
      ].filter(Boolean)}
      title={title}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
      onRowClick={isFiltrationMode ? handleRowClick : undefined}
    />
  );
};

export default BudgetOverview;
