import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import AdminRouteNames from './AdminRouteNames';
import AdminProtectedRoute from './AdminProtectedRoute';
import Emails from '../modules/Emails/pages/Emails';
import UploadEmail from '../modules/Emails/pages/UploadEmail';
import ImitateAccount from '../pages/ImitateAccount/ImitateAccount';

const LabelingQueues = lazy(() => import('../modules/Labeling/pages/LabelingQueue'));
const LabelingQueueTasks = lazy(() => import('../modules/Labeling/pages/LabelingQueueTasks'));
const ViewLabelingQueueTask = lazy(() => import('../modules/Labeling/pages/ViewLabelingQueueTask/ViewLabelingQueueTask'));
const CreateUpdateLabelingCashierReceiptData = lazy(() => import(
  '../modules/Labeling/pages/CreateUpdateLabelingCashierReceiptData/CreateUpdateLabelingCashierReceiptData'
));
const CreateUpdateLabelingInvoiceData = lazy(() => import(
  '../modules/Labeling/pages/CreateUpdateLabelingInvoiceData/CreateUpdateLabelingInvoiceData'
));

const AdminRouteConfig: Array<RouteObject> = [
  {
    path: AdminRouteNames.ADMIN_HOME,
    element: (
      <AdminProtectedRoute>
        <ImitateAccount />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.LABELING_QUEUES,
    element: (
      <AdminProtectedRoute>
        <LabelingQueues />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.LABELING_QUEUE_TASKS,
    element: (
      <AdminProtectedRoute>
        <LabelingQueueTasks />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.VIEW_LABELING_TEMPLATES_TASK,
    element: (
      <AdminProtectedRoute>
        <ViewLabelingQueueTask />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.ADD_LABELING_QUEUE_TASK_INVOICE_DATA,
    element: (
      <AdminProtectedRoute>
        <CreateUpdateLabelingInvoiceData />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.ADD_LABELING_QUEUE_TASK_CASHIER_RECEIPT_DATA,
    element: (
      <AdminProtectedRoute>
        <CreateUpdateLabelingCashierReceiptData />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.EMAILS,
    element: (
      <AdminProtectedRoute>
        <Emails />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.UPLOAD_EMAIL,
    element: (
      <AdminProtectedRoute>
        <UploadEmail />
      </AdminProtectedRoute>
    ),
  },
];

export default AdminRouteConfig;
