import qs from 'query-string';
import {
  BudgetShortModel,
  BudgetPayload,
  BudgetTemplatesMap,
  BudgetSummary,
  BudgetTemplateCategory,
  CopyFromBudgetPayload,
  Budget,
  ElectricityCalculatedData,
  ElectricityCalculationPayload,
  GetBudgetFiltrationProps,
  BudgetId,
  GetBudgetComparisonProps,
  PatchBudgetProps,
} from '@house-id/houseid-types/dist/finances/budgets';

import { HIDApiTags } from '../../../../../../../api/HIDApiTags';
import { propertyApi } from '../../../../../api/property.api';
import { PropertyId } from '../../../../../types/property.types';

export const budgetsApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getBudgets: builder.query<Array<BudgetShortModel>, PropertyId>({
      query: ({ propertyId }) => `/v3/properties/${propertyId}/budgets`,
      transformResponse: (response: { budgets: Array<BudgetShortModel> }) => response.budgets,
      providesTags: () => [HIDApiTags.BUDGET_LIST],
    }),
    getBudgetFiltration: builder.query<BudgetSummary, GetBudgetFiltrationProps & PropertyId>({
      query: ({ propertyId, budgetId, ...params }) => `/v3/properties/${propertyId}/budgets/${budgetId}/summary?${qs.stringify(params)}`,
      providesTags: () => [HIDApiTags.BUDGET_FILTRATION],
    }),
    getBudgetComparison: builder.query<BudgetSummary, GetBudgetComparisonProps & PropertyId>({
      query: ({ propertyId, budgetId, ...params }) => `/v3/properties/${propertyId}/budgets/${budgetId}/summary?${qs.stringify(params)}`,
      providesTags: () => [HIDApiTags.BUDGET_COMPARISON],
    }),
    getBudget: builder.query<Budget, PropertyId & { budgetId: string; }>({
      query: ({ propertyId, budgetId }) => ({
        url: `/v3/properties/${propertyId}/budgets/${budgetId}`,
        method: 'GET',
      }),
      transformResponse: (response: { budget: Budget }) => response.budget,
    }),
    getBudgetTemplates: builder.query<BudgetTemplatesMap, PropertyId>({
      query: ({ propertyId }) => ({
        url: `/v3/properties/${propertyId}/budgets/template`,
        method: 'GET',
      }),
      transformResponse: (response: { categories: Array<BudgetTemplateCategory> }) =>
        response.categories.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {} as BudgetTemplatesMap),
    }),
    calculateElectricityPrices: builder.mutation<ElectricityCalculatedData, PropertyId & { payload: ElectricityCalculationPayload }>({
      query: ({ propertyId, payload }) => ({
        url: `/v3/properties/${propertyId}/budgets/calculators/energy`,
        method: 'POST',
        body: payload,
      }),
    }),
    createFromExistingBudget: builder.mutation<{ budget: BudgetShortModel }, PropertyId & { payload: CopyFromBudgetPayload }>({
      query: ({ propertyId, payload }) => ({
        url: `/v3/properties/${propertyId}/budgets`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [
        HIDApiTags.BUDGET,
        HIDApiTags.BUDGET_COMPARISON,
        HIDApiTags.BUDGET_FILTRATION,
        HIDApiTags.BUDGET_LIST,
      ],
    }),
    createBudget: builder.mutation<BudgetShortModel, PropertyId & { budget: BudgetPayload; }>({
      query: ({ propertyId, budget }) => ({
        url: `/v3/properties/${propertyId}/budgets`,
        method: 'POST',
        body: budget,
      }),
      invalidatesTags: (result) => [
        { type: HIDApiTags.BUDGET as const, id: result?.id },
        HIDApiTags.BUDGET_COMPARISON,
        HIDApiTags.BUDGET_FILTRATION,
        HIDApiTags.BUDGET_LIST,
      ],
    }),
    updateBudget: builder.mutation<BudgetShortModel, PropertyId & { budgetId: string, budget: BudgetPayload; }>({
      query: ({ propertyId, budget, budgetId }) => ({
        url: `/v3/properties/${propertyId}/budgets/${budgetId}`,
        method: 'PUT',
        body: budget,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: HIDApiTags.BUDGET as const, id: arg.budgetId },
        HIDApiTags.BUDGET_COMPARISON,
        HIDApiTags.BUDGET_FILTRATION,
        HIDApiTags.BUDGET_LIST,
      ],
    }),
    deleteBudget: builder.mutation<void, PropertyId & { budgetId: string; }>({
      query: ({ propertyId, budgetId }) => ({
        url: `/v3/properties/${propertyId}/budgets/${budgetId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: HIDApiTags.BUDGET as const, id: arg.budgetId },
        HIDApiTags.BUDGET_LIST,
      ],
    }),
    patchBudget: builder.mutation<void, PropertyId & BudgetId & PatchBudgetProps>({
      query: ({ propertyId, budgetId, ...rest }) => ({
        url: `/v3/properties/${propertyId}/budgets/${budgetId}`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: (_result, _error, arg) => [
        HIDApiTags.BUDGET_LIST,
        { type: HIDApiTags.BUDGET as const, id: arg.budgetId },
        HIDApiTags.BUDGET_COMPARISON,
        HIDApiTags.BUDGET_FILTRATION,
      ],
    }),
  }),
});

export const {
  useGetBudgetsQuery,
  useGetBudgetFiltrationQuery,
  useGetBudgetComparisonQuery,
  useGetBudgetQuery,
  useGetBudgetTemplatesQuery,
  useCalculateElectricityPricesMutation,
  useCreateFromExistingBudgetMutation,
  useCreateBudgetMutation,
  useUpdateBudgetMutation,
  useDeleteBudgetMutation,
  usePatchBudgetMutation,
} = budgetsApi;
