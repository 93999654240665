import React from 'react';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import {
  PropertyProgressCategoryId,
  PropertyProgressSection,
} from '@house-id/houseid-types/dist/propertyProgress';

import {
  PropertyBlobsSectionData,
  PropertyBlueprintSectionData,
} from '../../../types.propertyProgress';
import { ImageMimeTypes } from '../../../../../../../constants/mimeTypes';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import { FCC } from '../../../../../../../types/common';
import { useUpdatePropertyMutation } from '../../../../../api/property.api';
import { PROPERTY_PHOTO_COVER_CLASSIFICATION } from '../../../../../constants/constants.property';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { useDeleteContentFilesMutation } from '../../../../Content/modules/ContentFile/api/contentFile.api';
import { ContentFilePickerDialogProps } from '../../../../Content/modules/ContentFile/component/ContentFilePickerDialog';
import { ContentFile } from '../../../../Content/modules/ContentFile/types.contentFile';
import PropertyProgressCategorySectionContainer from '../../../components/PropertyProgressCategorySectionContainer';
import PropertyBlueprintsImagesSection from './PropertyBlueprintsImagesSection';
import useGetPropertyProgressCategoryContentText from '../../../hooks/useGetPropertyProgressCategoryContentText';
import { getPropertyProgressPercent } from '../../../utils/utils.propertyProgress';

type PropertyBlueprintsCoverImagesSectionProps = {
  section: PropertyProgressSection<PropertyBlueprintSectionData>;
};

const PropertyBlueprintsCoverImagesSection: FCC<PropertyBlueprintsCoverImagesSectionProps> = ({
  section,
}) => {
  const { t } = useTranslation(['property']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const { blobs = [] } = section.data as PropertyBlobsSectionData;

  const [updateProperty, { isLoading: isPropertyImagesUpdating }] = useUpdatePropertyMutation();
  const [deleteContentFiles] = useDeleteContentFilesMutation();

  const handleContentFilesSelected = (files: Array<ContentFile>) => {
    const firstFile = R.head(files);
    if (propertyId && firstFile) {
      updateProperty({
        id: propertyId,
        blobs: [{ ...firstFile.blob, classifications: [PROPERTY_PHOTO_COVER_CLASSIFICATION] }],
      })
        .then(() => deleteContentFiles({ propertyId, ids: [firstFile.id] }));
    }
  };

  const [openFilePickerDialog] = useDialog<ContentFilePickerDialogProps>(DialogNames.CONTENT_FILE_PICKER_DIALOG);

  const handleOpenFilePicker = () => {
    openFilePickerDialog({
      mimeTypes: ImageMimeTypes,
      isMultiple: false,
      onSelect: handleContentFilesSelected,
    });
  };

  const handleAddImage = handleOpenFilePicker;

  const getPropertyProgressCategoryContentDescription = useGetPropertyProgressCategoryContentText();
  const description = getPropertyProgressCategoryContentDescription({
    percent: getPropertyProgressPercent(section.progress),
    categoryId: PropertyProgressCategoryId.BLUEPRINTS,
    sectionId: section.id,
  });

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyBlueprintsImagesSection
          blobs={blobs}
          buttonText={t('property:property_progress_property_blueprints_cover_images_add')}
          description={description}
          onAddImage={handleAddImage}
          onContentFilesSelected={handleContentFilesSelected}
        />
      }
      isLoading={isPropertyImagesUpdating}
      key={section.id}
      section={section}
    />
  );
};

export default PropertyBlueprintsCoverImagesSection;
