import React, {
  FC,
  useState,
} from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { HIDDialogProps } from '../../../../../../../types/common';
import HIDButton from '../../../../../../../components/buttons/HIDButton';
import PhotoForm, { getPhotoSchema } from './PhotoForm';
import {
  Photo,
  PhotosFormFields,
} from '../types.photo';
import { useUpdatePhotoMutation } from '../api/photo.api';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import HIDDialogTitle from '../../../../../../../components/dialogs/HIDDialogTitle';
import HIDDialogContent from '../../../../../../../components/dialogs/HIDDialogContent';
import HIDDialogActions from '../../../../../../../components/dialogs/HIDDialogActions';

type UpdatePhotoDialogProps = HIDDialogProps & {
  photo: Photo;
};

const UpdatePhotoDialog: FC<UpdatePhotoDialogProps> = ({
  open,
  photo,
  onClose,
}) => {
  const { t } = useTranslation(['common', 'forms_common', 'photos']);
  const { isDownSm } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();

  const schema = Yup.object({
    photos: Yup.array<Photo>()
      .of(getPhotoSchema(t('forms_common:field_mandatory')))
      .optional()
      .nullable(),
  });

  const [
    updatePhoto,
    { isLoading: isUpdatingPhotos },
  ] = useUpdatePhotoMutation();

  const [touched, setTouched] = useState(false);

  const handleFormSubmit = (values: PhotosFormFields) => {
    const photoValue = values.photos?.[0];
    if (photoValue && propertyId) {
      updatePhoto({
        propertyId,
        id: photoValue.id,
        name: photoValue.name,
        date: photoValue.date,
        place: photoValue.place,
      })
        .then(() => onClose());
    }
  };

  const formik = useFormik<PhotosFormFields>({
    initialValues: {
      photos: [photo],
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  const handleSave = () => {
    formik.submitForm();
    setTouched(true);
  };

  return (
    <Dialog
      fullScreen={isDownSm}
      open={open}
      onClose={onClose}
    >
      <HIDDialogTitle
        title={t('photos:update_photo')}
        onClose={onClose}
      />
      <HIDDialogContent
        sx={
          isDownSm
            ? {
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }
            : {}
        }
      >
        <PhotoForm
          formik={formik}
          index={0}
          photo={photo}
          touched={touched}
        />
      </HIDDialogContent>
      <HIDDialogActions>
        <HIDButton
          color="secondary"
          disabled={isUpdatingPhotos}
          onClick={onClose}
        >
          {t('common:cancel')}
        </HIDButton>
        <HIDButton
          loading={isUpdatingPhotos}
          onClick={handleSave}
        >
          {t('common:save')}
        </HIDButton>
      </HIDDialogActions>
    </Dialog>
  );
};

export default UpdatePhotoDialog;
