import React, {
  useMemo,
  useState,
} from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';

import HomeListLayout from '../../../../../pages/Home/components/HomeListLayout';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import { toggleSideDrawerOpen } from '../../../../../../../store/layoutReducer';
import {
  formatDate,
  DateTimeFormats,
  isAfterOrEqual,
  isBeforeOrEqual,
} from '../../../../../../../utils/date';
import { EMPTY_VALUE } from '../../../../../../../utils/string';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import ContentImage from '../../../components/ContentImage';
import {
  useGetAllDocumentCategoriesQuery,
  useGetAllDocumentsQuery,
  useDeleteDocumentsMutation,
} from '../api/document.api';
import { ALL_DOCUMENTS_CATEGORY } from '../constants.document';
import {
  HIDDocument,
  DocumentCategory,
} from '../types.document';
import DocumentsQuickNavigation from '../components/DocumentsQuickNavigation';
import { LIST_CHEVRON_COLUMN_CONFIG } from '../../../../../../../constants/columns';
import ListEntitiesToolbarActions, { SelectionModeType } from '../../../components/actions/ListEntitiesToolbarActions';
import ListEntitiesActions from '../../../components/actions/ListEntitiesActions';
import {
  getCreateDocumentPath,
  getDocumentCategoriesPath,
  getDocumentPath,
} from '../navigation.document';
import {
  getDocumentCategory,
  getDocumentType,
} from '../utils.document';
import {
  useNavigateBackOr,
  useNavigationParams,
} from '../../../../../../../utils/routes';
import EntityType from '../../../../../../../constants/entityType';
import FiltersSection, { ContentFilters } from '../../../components/sections/FiltersSection';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import useSearch from '../../../../../../../hooks/useSearch';

const mapDocument = (document: HIDDocument, categories: Array<DocumentCategory>) => ({
  id: document.id,
  name: document.name,
  blobs: document.blobs,
  categoryId: document.categoryId,
  categoryName: getDocumentCategory(categories, document.categoryId)?.name || EMPTY_VALUE,
  typeName: getDocumentType(categories, document.categoryId, document.classificationType)?.name || EMPTY_VALUE,
  updatedAt: document.updatedAt,
});

type UseGetDocumentsDataGridColumns = (props: {
  isSelectionMode: boolean,
  classificationVisible: boolean,
  categoryVisible: boolean
}) => Array<GridColDef>;

export const useGetDocumentsDataGridColumns: UseGetDocumentsDataGridColumns = ({
  isSelectionMode,
  classificationVisible,
  categoryVisible,
}) => {
  const { t } = useTranslation(['common', 'documents']);
  const theme = useTheme();
  const { isDownSm, isDownMd } = useBreakPointsSizes();

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: t('common:name'),
      flex: isDownSm ? 0.6 : 0.4,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { name, blobs } = params?.row as HIDDocument;
        return (
          <Stack
            alignItems="center"
            direction="row"
            sx={{ minWidth: 0 }}
          >
            <ContentImage
              blobs={blobs}
              imageSx={{ maxHeight: theme.spacing(8) }}
              sx={{ marginRight: theme.spacing(1.5) }}
            />
            <Stack
              direction="column"
              justifyContent="center"
              sx={{ minWidth: 0 }}
            >
              <Stack direction="row" sx={{ minWidth: 0 }}>
                <Typography noWrap variant="subtitle1">
                  {name}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        );
      },
    },
    categoryVisible && {
      field: 'categoryName',
      headerName: t('common:category'),
      flex: isDownSm ? 0.3 : 0.15,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { categoryName } = params?.row as { categoryName: string };
        return (
          <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
            {categoryName}
          </Typography>
        );
      },
    },
    !isDownSm && classificationVisible && {
      field: 'classificationType',
      headerName: t('common:type'),
      flex: 0.15,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { typeName } = params?.row as { typeName: string };
        return (
          <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
            {typeName}
          </Typography>
        );
      },
    },
    !isDownMd && {
      field: 'updatedAt',
      headerName: t('documents:documents_documents_updated_at_column_name'),
      flex: 0.15,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { updatedAt } = params?.row as HIDDocument;
        return (
          <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
            {updatedAt ? formatDate(new Date(updatedAt), DateTimeFormats.DATE_ONLY) || EMPTY_VALUE : EMPTY_VALUE}
          </Typography>
        );
      },
    },
    !isSelectionMode && LIST_CHEVRON_COLUMN_CONFIG,
  ].filter(Boolean);

  return columns;
};

const Documents = () => {
  const { t } = useTranslation(['common', 'documents']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();

  const { displaySearch } = useSearch(EntityType.DOCUMENT);

  const {
    routeParams: {
      category,
    },
    queryParams: {
      typeId: typeIdSearchParam,
    },
  } = useNavigationParams<{ category: string }, { typeId?: string }>();

  const { data: propertyId } = useGetCurrentPropertyId();
  const [selectionModeType, setSelectionModeType] = useState<SelectionModeType | undefined>();
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<Array<string>>([]);

  const isSelectionMode = Boolean(selectionModeType);

  const categoryId = category ? decodeURIComponent(category) : undefined;
  const typeId = typeIdSearchParam ? decodeURIComponent(typeIdSearchParam) : undefined;

  const isAllDocumentsCategory = categoryId === ALL_DOCUMENTS_CATEGORY;

  const { data: categories = [] } = useGetAllDocumentCategoriesQuery(propertyId ? { propertyId } : skipToken);

  const {
    data: documents = [],
    isLoading,
  } = useGetAllDocumentsQuery(propertyId ? { propertyId } : skipToken);

  const [deleteDocuments, { isLoading: isDeleting }] = useDeleteDocumentsMutation();

  const [filters, setFilters] = useState<ContentFilters>({
    from: undefined,
    to: undefined,
    columnsVisibilityMap: {
      category: { label: t('common:category'), visible: true },
      classification: { label: t('common:type'), visible: true },
    },
  });

  const categoryVisible = Boolean(filters.columnsVisibilityMap?.category?.visible);
  const classificationVisible = Boolean(filters.columnsVisibilityMap?.classification?.visible);

  const categoryName = isAllDocumentsCategory
    ? t('documents:documents_documents_categories_all_documents')
    : categories
      ?.find((category) => category.id === categoryId)
      ?.name || t('documents:documents_documents_categories_title');

  const categoryDocuments = useMemo(
    () => {
      const currentDocuments = isAllDocumentsCategory
        ? documents
        : typeId
          ? documents.filter((document) => typeId && document.classificationType === typeId)
          : documents.filter((document) => categoryId && document.categoryId === categoryId);

      // TODO: remove when back is ready
      const filteredDocuments = filters.from || filters.to
        ? currentDocuments.filter(
          (document) => (filters.from ? isAfterOrEqual(new Date(document.updatedAt), new Date(filters.from)) : true)
            && (filters.to ? isBeforeOrEqual(new Date(document.updatedAt), new Date(filters.to)) : true),
        )
        : currentDocuments;

      return filteredDocuments.map((document) => mapDocument(document, categories));
    },
    [
      isAllDocumentsCategory,
      documents,
      categoryId,
      categories,
      typeId,
      filters?.from,
      filters?.to,
    ],
  );

  const columns = useGetDocumentsDataGridColumns({
    isSelectionMode,
    categoryVisible,
    classificationVisible,
  });

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    if (isSelectionMode) {
      return;
    }
    const document = params?.row as HIDDocument;
    navigate(
      getPathWithPropertyIdOrInit(
        getDocumentPath,
        {
          propertyId,
          categoryId: encodeURIComponent(categoryId || ALL_DOCUMENTS_CATEGORY),
          id: document.id,
        },
      ),
    );
  };

  const handleRowSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) =>
    setSelectedDocumentIds(rowSelectionModel as Array<string>);

  const handleAdd = () => {
    navigate(getPathWithPropertyIdOrInit(getCreateDocumentPath, { propertyId, categoryId, typeId }));
    dispatch(toggleSideDrawerOpen(false));
  };

  const handleEnterDeleteMode = () => {
    dispatch(toggleSideDrawerOpen(false));
    setSelectionModeType(SelectionModeType.DELETE);
    setSelectedDocumentIds([]);
  };

  const handleDelete = () => {
    if (propertyId) {
      deleteDocuments({ propertyId, ids: selectedDocumentIds });
    }

    setSelectionModeType(undefined);
    setSelectedDocumentIds([]);
  };

  return (
    <HomeListLayout
      DataGridToolbar={
        <ListEntitiesToolbarActions
          count={categoryDocuments.length}
          entity={EntityType.DOCUMENT}
          isFetching={isDeleting}
          isLoading={isLoading}
          selectedCount={selectedDocumentIds.length}
          selectionModeType={selectionModeType}
          onCancel={() => setSelectionModeType(undefined)}
          onDelete={handleDelete}
        />
      }
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ListEntitiesActions
              disabled={isSelectionMode}
              onAdd={handleAdd}
              onDelete={handleEnterDeleteMode}
              onSearch={displaySearch}
            />
          </Card>
          {!isSelectionMode && (
            <Card sx={{ padding: 2 }}>
              <FiltersSection
                showColumnsVisibilityFilters
                showDateFilers
                filters={filters}
                onChange={setFilters}
              />
            </Card>
          )}
          {!isSelectionMode && (
            <Card sx={{ padding: 2 }}>
              <DocumentsQuickNavigation />
            </Card>
          )}
        </>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getDocumentCategoriesPath, { propertyId }),
          name: t('documents:documents_documents_title'),
        },
      ]}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'name',
              sort: 'asc',
            },
          ],
        },
      }}
      isLoading={isLoading}
      isSelectionMode={isSelectionMode}
      rows={categoryDocuments}
      sideDrawerElements={[
        <ListEntitiesActions
          disabled={isSelectionMode}
          key={ListEntitiesActions.name}
          onAdd={handleAdd}
          onDelete={handleEnterDeleteMode}
          onSearch={displaySearch}
        />,
        !isSelectionMode && (
          <FiltersSection
            showColumnsVisibilityFilters
            showDateFilers
            filters={filters}
            key={FiltersSection.name}
            onChange={setFilters}
          />
        ),
        !isSelectionMode && (
          <DocumentsQuickNavigation
            key={DocumentsQuickNavigation.name}
          />
        ),
      ].filter(Boolean)}
      title={categoryName}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getDocumentCategoriesPath, { propertyId }))}
      onRowClick={handleRowClick}
      onRowSelectionModelChange={handleRowSelectionModelChange}
    />
  );
};

export default Documents;
