import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { Card } from '@mui/material';
import {
  PropertyProgressCategoryId,
  PropertyProgressSection,
} from '@house-id/houseid-types/dist/propertyProgress';

import { useNavigateBackOr } from '../../../../../../utils/routes';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../../../navigation/navigation.property';
import HomeLayout from '../../../../pages/Home/components/HomeLayout';
import { getPathWithPropertyIdOrInit } from '../../../../../Auth/navigation/navigation.auth';
import { getPropertyProgressPath } from '../../../PropertyProgress/navigation.propertyProgress';
import {
  SellingPropertyArchiveNotesSectionData,
  SellingPropertyArchiveSectionData,
  SellingPropertyArchiveSectionId,
} from '../../types.sellingProperty';
import {
  PropertyDocumentationData,
  PropertyPhotosSectionData,
  PropertyTimelineSectionData,
  PropertyProductTypesSectionData,
  PropertyProgressCategorySectionProps,
} from '../../../PropertyProgress/types.propertyProgress';
import PropertyProgressPageContent from '../../../PropertyProgress/components/PropertyProgressPageContent';
import { useGetPropertyArchiveDataQuery } from '../../api/sellingProperty.api';
import SellingPropertyArchivePhotos from './components/SellingPropertyArchivePhotos';
import SellingPropertyArchiveProducts from './components/SellingPropertyArchiveProducts';
import SellingPropertyArchiveTimeline from './components/SellingPropertyArchiveTimeline';
import SellingPropertyArchiveDocuments from './components/SellingPropertyArchiveDocuments';
import SellingPropertyArchiveNotes from './components/SellingPropertyArchiveNotes';
import HIDInfo from '../../../../../../components/HIDInfo';
import HIDTypography from '../../../../../../components/HIDTypography';
import SellingPropertySectionLoaderSkeleton from '../../components/SellingPropertySectionLoaderSkeleton';
import PropertyProgressQuickNavigation from '../../../PropertyProgress/components/PropertyProgressQuickNavigation';

type SellingPropertyArchiveSectionsContentType = {
  [SellingPropertyArchiveSectionId.PHOTOS]: FC<PropertyProgressCategorySectionProps<PropertyPhotosSectionData>>;
  [SellingPropertyArchiveSectionId.NOTES]: FC<PropertyProgressCategorySectionProps<SellingPropertyArchiveNotesSectionData>>;
  [SellingPropertyArchiveSectionId.DOCUMENTS]: FC<PropertyProgressCategorySectionProps<PropertyDocumentationData>>;
  [SellingPropertyArchiveSectionId.PRODUCTS]: FC<PropertyProgressCategorySectionProps<PropertyProductTypesSectionData>>;
  [SellingPropertyArchiveSectionId.TIMELINE_ENTRIES]: FC<PropertyProgressCategorySectionProps<PropertyTimelineSectionData>>;
};

const SellingPropertyArchiveSectionsContent: SellingPropertyArchiveSectionsContentType = {
  [SellingPropertyArchiveSectionId.PHOTOS]: SellingPropertyArchivePhotos,
  [SellingPropertyArchiveSectionId.NOTES]: SellingPropertyArchiveNotes,
  [SellingPropertyArchiveSectionId.DOCUMENTS]: SellingPropertyArchiveDocuments,
  [SellingPropertyArchiveSectionId.PRODUCTS]: SellingPropertyArchiveProducts,
  [SellingPropertyArchiveSectionId.TIMELINE_ENTRIES]: SellingPropertyArchiveTimeline,
};

const SellingPropertyArchive: FC = () => {
  const { t } = useTranslation(['property', 'selling_property']);
  const navigateBackOr = useNavigateBackOr();

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: propertyArchiveData,
    isLoading: isLoadingPropertyArchiveData,
  } = useGetPropertyArchiveDataQuery(propertyId ? { propertyId } : skipToken);

  const handleRenderSectionContent = (section: PropertyProgressSection<SellingPropertyArchiveSectionData>) => {
    const Content = SellingPropertyArchiveSectionsContent[section.id as keyof typeof SellingPropertyArchiveSectionsContent];

    // @ts-ignore
    return Content ? <Content section={section} /> : null;
  };

  const sectionsData = propertyArchiveData?.sections || [];
  const isLoading = !sectionsData.length && isLoadingPropertyArchiveData;

  return (
    <HomeLayout
      BodyLoaderSkeleton={SellingPropertySectionLoaderSkeleton}
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <PropertyProgressQuickNavigation
            currentPropertyProgressCategoryId={PropertyProgressCategoryId.ARCHIVE}
            key={PropertyProgressQuickNavigation.name}
          />
        </Card>
      }
      breadcrumbsLinks={[
        {
          link: getPropertyProgressPath({ propertyId: propertyId || '' }),
          name: t('property:property_progress_title'),
        },
      ]}
      isLoading={isLoading}
      sideDrawerElements={[
        <PropertyProgressQuickNavigation
          currentPropertyProgressCategoryId={PropertyProgressCategoryId.SALE_CHECKLIST}
          key={PropertyProgressQuickNavigation.name}
        />,
      ]}
      title={t('selling_property:selling_property_archive_screen_title')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
    >
      <HIDTypography variant="body1">
        {t('selling_property:selling_property_archive_screen_description')}
      </HIDTypography>
      <HIDInfo
        isMarkdown
        bodyTextSx={{ whiteSpace: 'pre-line' }}
        description={t('selling_property:selling_property_archive_screen_info_description')}
        label={t('selling_property:selling_property_archive_screen_info_title')}
      />
      <PropertyProgressPageContent<SellingPropertyArchiveSectionData>
        hideProgressBar
        loading={isLoadingPropertyArchiveData}
        renderSectionContent={handleRenderSectionContent}
        sections={sectionsData}
      />
    </HomeLayout>
  );
};

export default SellingPropertyArchive;
