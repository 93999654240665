import React, { FC } from 'react';
import {
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReceiptLine } from '../../modules/Receipt/types.receipt';
import { formatMoney } from '../../../../../../utils/string';
import { getNoWrapStyle } from '../../../../../../utils/style';

type LinesSectionProps = {
  lines: Array<ReceiptLine>;
};

const LinesSection: FC<LinesSectionProps> = ({
  lines,
}) => {
  const { t } = useTranslation(['common', 'forms_common', 'receipts']);
  const theme = useTheme();

  // TODO: Extract to hook
  const units = [
    { id: 'count', name: t('common:pieces_abr') },
    { id: 'pack', name: t('receipts:receipts_pack') },
    { id: 'h', name: 'h' },
    { id: 'roll', name: t('receipts:receipts_roll') },
    { id: 'm', name: 'm' },
    { id: 'm²', name: 'm²' },
    { id: 'm³', name: 'm³' },
    { id: 'L', name: 'L' },
    { id: 'kg', name: 'kg' },
  ];

  const formatUnit = (unit?: string) => unit ? ` ${units.find(({ id }) => id === unit)?.name || unit}` : '';

  return (
    <Grid
      container
      sx={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
        borderRadius: '10px',
        padding: 1.5,
      }}
    >
      <Grid item sx={{ marginBottom: 2 }} xxs={12}>
        <Typography variant="subtitle1">
          {t(['receipts:receipts_lines'])}
        </Typography>
      </Grid>
      {lines.map((line) => (
        <Grid
          item
          key={line.id}
          xxs={12}
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={1}
            sx={{
              borderRadius: theme.spacing(1),
              padding: 1,
              margin: 0.5,
              backgroundColor: theme.palette.grey[100],
            }}
          >
            <Typography flex={2} sx={getNoWrapStyle(1)} variant="subtitle1">
              {line.name}
            </Typography>
            <Typography flex={0.5} sx={{ textWrap: 'nowrap' }} variant="body1">
              {`${line.quantity}${formatUnit(line.unit)} x ${formatMoney(line.pricePerUnit)}`}
            </Typography>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

export default LinesSection;
