import React, { useEffect } from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import {
  Add,
  BookmarkBorderOutlined,
  PersonOutline,
} from '@mui/icons-material';
import { skipToken } from '@reduxjs/toolkit/query';

import HomeLayout from '../../Property/pages/Home/components/HomeLayout';
import FeaturedTopicListItem from '../components/FeaturedTopicListItem';
import ThreadListItem from '../components/ThreadListItem';
import {
  useGetForumThreadsQuery,
  useGetForumTopicsQuery,
} from '../api/forum.api';
import {
  ForumThreadsSortOrder,
  ForumTopic,
} from '../forum.types';
import TopicsQuickNavigation from '../components/TopicsQuickNavigation';
import {
  getExpertPath,
  getForumTopicPath,
  getMyQuestionsPath,
  getMyBookmarksPath,
} from '../navigation/navigation.forum';
import ReadMoreAboutExperts from '../components/ReadMoreAboutExperts';
import { arrToMap } from '../../../utils/array';
import PopularThreads from '../components/PopularThreads';
import useDialog from '../../../hooks/useDialog';
import DialogNames from '../../../hooks/useDialog/DialogNames';
import ListEntitiesActions from '../../Property/modules/Content/components/actions/ListEntitiesActions';
import { createFakeForumThread } from '../utils/fakeForumEntities';
import {
  useGetUserSettingsQuery,
  useSetUserSettingsMutation,
} from '../../Auth/api/settings.api';
import { useGetActiveAuthUser } from '../../../external-services/firebase';
import ForumRules from '../components/ForumRules';
import useTryOpenAskForumQuestionDialog from '../hooks/useTryOpenAskForumQuestionDialog';
import useTryNavigateToThread from '../hooks/useTryNavigateToThread';

const ForumOverview = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation(['forum', 'common']);

  const { user: currentUser } = useGetActiveAuthUser();

  const { data: topics = [], isLoading: topicsIsLoading } = useGetForumTopicsQuery();
  const {
    data: { threads: latestThreads = [] } = {},
    isLoading: threadsIsLoading,
  } = useGetForumThreadsQuery({ sort: ForumThreadsSortOrder.LAST_UPDATE_DESC, pageSize: 10 });

  const topicsMap = arrToMap(({ id, name }) => ([id, name]), topics);
  const popularTopics = topicsIsLoading
    ? R.times((n) => ({ id: n.toString(), name: n.toString(), threadCount: 0 }), 3)
    : R.slice(0, 3, R.sortBy(({ threadCount }: ForumTopic) => -threadCount, topics));

  const threadsList = threadsIsLoading
    ? R.times((n) => createFakeForumThread(n.toString()), 10)
    : latestThreads;

  const handleNavigateToTopic = (topicId: string) => navigate(getForumTopicPath({ topicId }));

  const handleTryNavigateToThread = useTryNavigateToThread();

  const [setUserSettings] = useSetUserSettingsMutation();

  const {
    data: { excludedDialogsToShow = [] } = {},
    isLoading,
  } = useGetUserSettingsQuery(
    currentUser && currentUser.uid
      ? { userUId: currentUser.uid }
      : skipToken,
  );

  const handleWelcomeDialogClose = () => {
    setUserSettings({
      excludedDialogsToShow: [...excludedDialogsToShow, DialogNames.ASK_FORUM_QUESTION_DIALOG],
    });
  };

  const [openWelcomeToForumDialog] = useDialog(DialogNames.WELCOME_TO_FORUM_DIALOG, handleWelcomeDialogClose);
  const [tryOpenAskForumQuestionDialog, isTryOpenAskForumQuestionDialogLoading] = useTryOpenAskForumQuestionDialog();

  useEffect(() => {
    if (!isLoading && !excludedDialogsToShow.includes(DialogNames.ASK_FORUM_QUESTION_DIALOG)) {
      openWelcomeToForumDialog();
    }
  }, [isLoading]);

  const handleGoToMyQuestions = () => navigate(getMyQuestionsPath());
  const handleGoToMyBookmarks = () => navigate(getMyBookmarksPath());
  const handleAskExpert = () => tryOpenAskForumQuestionDialog({ isAskExpert: true });
  const handleAskForum = () => tryOpenAskForumQuestionDialog({ isAskExpert: false });

  const customActions = [
    {
      id: 'my_questions',
      Icon: PersonOutline,
      label: t('forum:forum_my_questions'),
      onClick: handleGoToMyQuestions,
    },
    {
      id: 'my_bookmarks',
      Icon: BookmarkBorderOutlined,
      label: t('forum:forum_my_bookmarks'),
      onClick: handleGoToMyBookmarks,
    },
    {
      id: 'ask_forum',
      Icon: Add,
      label: t('forum:forum_ask_the_forum'),
      onClick: handleAskForum,
    },
    {
      id: 'ask_expert',
      Icon: Add,
      label: t('forum:forum_ask_the_expert'),
      disabled: isTryOpenAskForumQuestionDialogLoading,
      onClick: handleAskExpert,
    },
  ];

  const handleNavigateToExperts = () => navigate(getExpertPath());

  return (
    <HomeLayout
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ListEntitiesActions customActions={customActions} />
          </Card>
          <Card sx={{ padding: 2 }}>
            <TopicsQuickNavigation />
          </Card>
          <Card sx={{ padding: 2 }}>
            <ReadMoreAboutExperts onClick={handleNavigateToExperts} />
          </Card>
          <Card sx={{ padding: 2 }}>
            <PopularThreads />
          </Card>
          <Card sx={{ padding: 2 }}>
            <ForumRules />
          </Card>
        </>
      }
      sideDrawerElements={[
        <ListEntitiesActions customActions={customActions} key={ListEntitiesActions.name} />,
        <TopicsQuickNavigation key={TopicsQuickNavigation.name} />,
        <ReadMoreAboutExperts key={ReadMoreAboutExperts.name} style={{ marginTop: theme.spacing(1) }} onClick={handleNavigateToExperts} />,
        <PopularThreads key={PopularThreads.name} />,
        <ForumRules key={ForumRules.name} />,
      ]}
      title={t('forum:forum_title')}
    >
      <Stack>
        <Typography sx={{ marginBottom: 2.5 }} variant="h5">
          {t('forum:forum_popular_sections_in_the_forum')}
        </Typography>
        <Stack spacing={1.5}>
          {popularTopics.map(({ id, name, threadCount }) => (
            <FeaturedTopicListItem
              isLoading={topicsIsLoading}
              key={name}
              name={name}
              threadsCount={threadCount}
              onClick={() => handleNavigateToTopic(id)}
            />
          ))}
        </Stack>
      </Stack>
      <Stack sx={{ marginTop: 4 }}>
        <Typography sx={{ marginBottom: 2.5 }} variant="h5">
          {t('forum:forum_latest_questions_in_the_forum')}
        </Typography>
        <Stack spacing={1.5}>
          {threadsList.map((thread, index) => (
            <ThreadListItem
              author={thread.author}
              isLoading={threadsIsLoading}
              key={index}
              thread={thread}
              threadTopicName={topicsMap[thread.topicId]}
              onClick={() => handleTryNavigateToThread(thread)}
            />
          ))}
        </Stack>
      </Stack>
    </HomeLayout>
  );
};

export default ForumOverview;
