import React, { FC } from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  XAxis,
} from 'recharts';
import { format } from 'date-fns';
import { useNavigate } from 'react-router';
import { ArrowForward } from '@mui/icons-material';
import { skipToken } from '@reduxjs/toolkit/query';

import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { useGetReceiptsSummaryQuery } from '../../../modules/Content/modules/Receipt/api/receipt.api';
import { TimeIntervalsGroupingType } from '../../../modules/Content/types/content.type';
import {
  formatChartNumbers,
  formatMoney,
} from '../../../../../utils/string';
import ChartLegend from '../../../../../components/charts/ChartLegend';
import { hidSpacing } from '../../../../../utils/number';
import ReceiptEmptyImage from '../../../../../assets/images/receipts_empty.png';
import HIDButton from '../../../../../components/buttons/HIDButton';
import {
  getCreateReceiptPath,
  getReceiptCategoriesPath,
} from '../../../modules/Content/modules/Receipt/navigation.receipt';
import {
  HomeWidgetType,
  useGetWidgetHeight,
} from '../utils/useGetWidgetHeight';
import useGetChartLabelFontSize from '../utils/useGetChartLabelFontSize';
import { getPathWithPropertyIdOrInit } from '../../../../Auth/navigation/navigation.auth';

const YEARS_COUNT = 5;

const getBoundaries = () => {
  const now = new Date();
  return {
    from: format(new Date(now.getFullYear() - YEARS_COUNT, 0, 1), 'yyyy-MM-dd'),
    to: format(now, 'yyyy-MM-dd'),
  };
};

const ReceiptsEmpty: FC = () => {
  const { t } = useTranslation(['home']);
  const theme = useTheme();
  const navigate = useNavigate();

  const { data: propertyId } = useGetCurrentPropertyId();

  const handleNavigateToCreateReceipts = () => navigate(getPathWithPropertyIdOrInit(getCreateReceiptPath, { propertyId }));

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
        borderRadius: '5px',
        padding: theme.spacing(3, 2),
        height: '100%',
      }}
    >
      <img
        src={ReceiptEmptyImage}
        style={{ width: 97, height: 99.5 }}
      />
      <Typography variant="h6">
        {t('home:home_add_receipt')}
      </Typography>
      <Typography style={{ marginTop: theme.spacing(1) }} variant="body1">
        {t('home:home_collect_your_receipts_in_one_place')}
      </Typography>
      <HIDButton onClick={handleNavigateToCreateReceipts}>
        {t('home:home_get_started')}
      </HIDButton>
    </Stack>
  );
};

type SummaryWidgetChartItem = {
  year: number;
  amount: number;
  deductible: number | undefined;
};

// eslint-disable-next-line react/no-multi-comp
const ReceiptsSummaryWidgetChart: FC<{ data: Array<SummaryWidgetChartItem> }> = ({
  data,
}) => {
  const { t } = useTranslation(['home']);
  const theme = useTheme();

  const chartLabelFontSize = useGetChartLabelFontSize();

  return (
    <ResponsiveContainer
      height={280}
      width="100%"
    >
      <BarChart
        data={data}
        margin={{ top: hidSpacing(2) }}
        maxBarSize={80}
      >
        <XAxis
          dataKey="year"
          fontSize={chartLabelFontSize}
          stroke={theme.palette.common.transparent}
          tick={{ fill: theme.palette.grey[500] }}
          tickLine={{ stroke: theme.palette.common.transparent }}
        />
        <Legend
          align="left"
          content={({ payload }) => <ChartLegend payload={payload || []} sx={{ marginTop: 2 }} />}
        />
        <Bar
          dataKey="amount"
          fill={theme.palette.primary.main}
          isAnimationActive={false}
          name={t('home:home_receipts_amount')}
          stackId="stack"
        />
        <Bar
          dataKey="deductible"
          fill={theme.palette.warning.main}
          isAnimationActive={false}
          name={t('home:home_receipts_deductible')}
          stackId="stack"
        >
          <LabelList
            dataKey="amount"
            fontSize={chartLabelFontSize}
            formatter={formatChartNumbers}
            position="top"
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

// eslint-disable-next-line react/no-multi-comp
const ReceiptsSummaryWidget: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['home']);
  const theme = useTheme();

  const boundaries = getBoundaries();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: receiptsSummary, isLoading } = useGetReceiptsSummaryQuery(
    propertyId
      ? {
        propertyId,
        from: boundaries.from,
        to: boundaries.to,
        groupingType: TimeIntervalsGroupingType.Yearly,
      }
      : skipToken,
  );

  const chartData = receiptsSummary?.summary?.values
    ?.map(
      ({
        date,
        totalAmount,
        totalDeductible,
      }) => ({
        year: new Date(date).getFullYear(),
        amount: totalAmount,
        deductible: totalDeductible || undefined,
      }),
    ) || [];

  const handleGoToReceipts = () => navigate(getPathWithPropertyIdOrInit(getReceiptCategoriesPath, { propertyId }));

  const widgetHeight = useGetWidgetHeight(HomeWidgetType.RECEIPTS_SUMMARY);

  const hasReceipts = isLoading || Boolean(receiptsSummary?.summary?.totalAmount);

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2.5),
        height: widgetHeight,
      }}
    >
      <Stack spacing={2} sx={{ height: '100%' }}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Typography variant="h5">
            {t('home:home_receipts_summary')}
          </Typography>
          {hasReceipts && (
            <HIDButton
              Icon={ArrowForward}
              size="small"
              onClick={handleGoToReceipts}
            >
              {t('home:home_detailed_history')}
            </HIDButton>
          )}
        </Stack>
        {
          !hasReceipts
            ? <ReceiptsEmpty />
            : (
              <>
                <Stack spacing={0.5}>
                  <Typography variant="body1">
                    {t('home:home_total_amount')}
                  </Typography>
                  <Typography variant="h3">
                    {formatMoney(receiptsSummary?.summary.totalAmount || 0, false)}
                  </Typography>
                  <Typography>
                    {
                      receiptsSummary?.summary.totalDeductible
                        ? t('home:home_deductible_amount', { amount: formatMoney(receiptsSummary?.summary.totalDeductible || 0, false) })
                        : ' '
                    }
                  </Typography>
                </Stack>
                <Stack
                  alignSelf="center"
                  justifyContent="flex-end"
                  style={{ maxWidth: 600, marginTop: 'auto' }}
                  sx={{ width: '100%', height: '100%' }}
                >
                  <ReceiptsSummaryWidgetChart data={chartData} />
                </Stack>
              </>
            )
        }
      </Stack>
    </Card>
  );
};

export default ReceiptsSummaryWidget;
