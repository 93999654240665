import EntityType from '../../../../../constants/entityType';

const ENTITY_POSSIBLE_CONNECTION_TYPES: Array<EntityType> = [
  EntityType.PRODUCT,
  EntityType.DOCUMENT,
  EntityType.RECEIPT,
  EntityType.PHOTO,
  EntityType.NOTE,
  EntityType.TASK,
  EntityType.RECURRING_EXPENSE,
  EntityType.BUILDING,
  EntityType.ROOM,
  EntityType.BUILDING_PART,
  EntityType.OUTDOOR,
  EntityType.TIMELINE_ENTRY,
];

type UseGetEntityPossiblyConnectionTypes = (entityType: EntityType) => Array<EntityType>;

const getEntityPossiblyConnectionTypes: UseGetEntityPossiblyConnectionTypes = (entityType) =>
  ENTITY_POSSIBLE_CONNECTION_TYPES.includes(entityType)
    ? ENTITY_POSSIBLE_CONNECTION_TYPES.filter((possibleEntityType) => possibleEntityType !== entityType)
    : [];

export default getEntityPossiblyConnectionTypes;
