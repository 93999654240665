import React from 'react';
import {
  Menu,
  MenuItem,
  Stack,
  useTheme,
} from '@mui/material';
import {
  ExpandMore,
  ExpandLess,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { FCC } from '../../../types/common';
import HIDLink from '../../../components/HIDLink';
import { ForumThreadsSortOrder } from '../forum.types';

type ForumThreadsOrderSelectProps = {
  value?: ForumThreadsSortOrder;
  onChange: (threadsOrder: ForumThreadsSortOrder) => void;
};

const ForumThreadsOrderSelect: FCC<ForumThreadsOrderSelectProps> = ({
  value = ForumThreadsSortOrder.DATE_DESC,
  sx,
  style,
  onChange,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['forum']);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleToggleMenuVisible = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);
  const handleChange = (order: ForumThreadsSortOrder) => {
    onChange(order);
    handleClose();
  };

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        spacing={1}
        style={style}
        sx={sx}
        onClick={handleToggleMenuVisible}
      >
        <HIDLink
          /* @ts-ignore */
          label={t(`forum:forum_threads_order_${value}`)}
          sx={{ color: theme.palette.common.black }}
          underline="none"
          onClick={(event) => {
            event.preventDefault();
          }}
        />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </Stack>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isOpen}
        sx={{
          marginTop: theme.spacing(1),
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
      >
        {
          [ForumThreadsSortOrder.DATE_DESC, ForumThreadsSortOrder.DATE_ASC]
            .map((order) => order.toLowerCase())
            .map((order) => (
              <MenuItem
                key={order}
                onClick={() => handleChange(order as ForumThreadsSortOrder)}
              >
                {/* @ts-ignore */}
                {t(`forum:forum_threads_order_${order}`)}
              </MenuItem>
            ))
        }
      </Menu>
    </>
  );
};

export default ForumThreadsOrderSelect;
