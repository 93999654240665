import React, { FC } from 'react';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';

import HIDIconButton from '../../../../../../../components/buttons/HIDIconButton';
import Suggestion from '../../../../../../../components/icons/Suggestion';
import EntityType from '../../../../../../../constants/entityType';
import { useGetSuggestionsQuery } from '../api/suggestion.api';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { getSuggestionsPath } from '../navigation.suggestion';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';

type SuggestionsIconButtonProps = {
  entityType: EntityType;
};

const SuggestionsIconButton: FC<SuggestionsIconButtonProps> = ({
  entityType,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation(['home']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: { totalCount = 0 } = {} } = useGetSuggestionsQuery(propertyId ? { propertyId, entityType } : skipToken);

  if (!totalCount) {
    return <div style={{ width: theme.spacing(5), height: theme.spacing(5) }} />;
  }

  const handleClick = () => navigate(getPathWithPropertyIdOrInit(getSuggestionsPath, { propertyId, entityType }));

  return (
    <HIDIconButton
      Icon={Suggestion}
      badgeColor="error"
      badgeCount={totalCount}
      title={t('home:home_menu_suggestions_label')}
      onClick={handleClick}
    />
  );
};

export default SuggestionsIconButton;
