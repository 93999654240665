/* eslint-disable react/no-multi-comp */
import React, {
  FC,
  useEffect,
} from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { Stack } from '@mui/material';
import {
  Property,
  PropertyType,
} from '@house-id/houseid-types/dist/property';

import useScript from '../../../hooks/useScript';
import { useGetCurrentUserQuery } from '../../Auth/api/user.api';
import { HEADER_HEIGHT } from '../../../constants/layout';
import useGetCurrentProperty from '../../Property/hooks/useCurrentProperty';
import {
  useGetPropertyMainBuildingQuery,
  useGetPropertySpecificationQuery,
} from '../../Property/api/property.api';
import {
  FormOfOwnership,
  HousingType,
  PropertySpecification,
} from '../../Property/types/property.types';
import { useReportEventMutation } from '../../Analytics/api/analytics.api';
import useGetCurrentPropertyId from '../../Property/hooks/useGetCurrentPropertyId';
import { PartnersSource } from '../../Analytics/types.analytics';

const OUTBOUND_HOUSE_ID_MOVE_EVENT = 'OUTBOUND_HOUSE_ID_MOVE_EVENT';

type MoveData = {
  from?: object;
  to?: object;
  contacts?: object;
  date?: string;
};

export enum OutboundEventType {
  NAVIGATION = 'NAVIGATION',
  EXTERNAL_URL = 'EXTERNAL_URL',
  DATA_CHANGED = 'DATA_CHANGED',
  SERVICE_DATA_SUBMITTED = 'SERVICE_DATA_SUBMITTED',
}

type OutboundEvent = Event & {
  detail: {
    type: OutboundEventType;
    data: MoveData;
  },
};

const MOVE_DOMAIN = `${import.meta.env.VITE_APP_MOVE_DOMAIN}`;

const getPropertyAndBuildingType = (propertySpecification?: PropertySpecification, property?: Property) => {
  if (propertySpecification?.housingType) {
    if ([HousingType.VILLA_DETACHED, HousingType.AGRICULTURE].includes(propertySpecification.housingType)) {
      return {
        buildingType: 'HOUSE',
        propertyType: 'VILLA',
      };
    }
    if ([HousingType.CHAIN_HOUSE, HousingType.SEMI_DETACHED_HOUSE, HousingType.TOWNHOUSE].includes(propertySpecification.housingType)) {
      return {
        buildingType: 'HOUSE',
        propertyType: 'CHAIN_HOUSE',
      };
    }
    if ([HousingType.WINTER_HOUSE, HousingType.SUMMER_HOUSE, HousingType.TOWNHOUSE].includes(propertySpecification.housingType)) {
      return {
        buildingType: 'VACATION_HOUSE',
        propertyType: 'VACATION_HOUSE',
      };
    }
    if (propertySpecification.housingType === HousingType.APARTMENT
      && propertySpecification.formOfOwnership
      && ([FormOfOwnership.OWNERSHIP, FormOfOwnership.CONDOMINIUM].includes(propertySpecification.formOfOwnership))) {
      return {
        buildingType: 'APARTMENT',
        propertyType: 'CONDOMINIUM',
      };
    }
    if (propertySpecification.housingType === HousingType.APARTMENT && propertySpecification.formOfOwnership === FormOfOwnership.RENT) {
      return {
        buildingType: 'APARTMENT',
        propertyType: 'RENTAL_APARTMENT',
      };
    }
  }

  return {
    buildingType: property?.type === PropertyType.HOUSE
      ? 'HOUSE'
      : property?.type === PropertyType.CONDOMINIUM
        ? 'APARTMENT'
        : undefined,
    propertyType: undefined,
  };
};

type RenderMoveProps = { jws: string, initialData?: object };

const RenderMove: FC<RenderMoveProps> = ({ jws, initialData }) => {
  useScript(`${MOVE_DOMAIN}/latest/hid/houseid-move.js`);

  return (
    <div
      data-client-id="HOUSE_ID"
      data-initial-data={initialData ? JSON.stringify(initialData) : undefined}
      data-user-id={jws}
      id="house-id-move-root"
      style={{ width: '100%' }}
    />
  );
};

const Move: FC = () => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: property } = useGetCurrentProperty(undefined, { skip: !currentUser });
  const { data: propertyMainBuilding } = useGetPropertyMainBuildingQuery(property?.id ? { propertyId: property?.id } : skipToken);
  const { data: propertySpecification } = useGetPropertySpecificationQuery(property?.id ? { propertyId: property?.id } : skipToken);

  const [reportEventMutation] = useReportEventMutation();
  const { data: propertyId } = useGetCurrentPropertyId();

  const handleEvent = (event: Event) => {
    const { data, type } = (event as OutboundEvent).detail;

    if (type === OutboundEventType.DATA_CHANGED) {
      reportEventMutation({
        source: PartnersSource.HOUSE_ID,
        category: 'move',
        type: 'houseid-move-details-updated',
        propertyId,
        data,
      });
    }
  };

  useEffect(() => {
    window.addEventListener(OUTBOUND_HOUSE_ID_MOVE_EVENT, handleEvent);
    return () => {
      window.removeEventListener(OUTBOUND_HOUSE_ID_MOVE_EVENT, handleEvent);
    };
  }, []);

  const {
    buildingType,
    propertyType,
  } = getPropertyAndBuildingType(propertySpecification, property);

  const initialData = {
    contacts: {
      givenName: currentUser?.givenName,
      surname: currentUser?.surname,
      email: currentUser?.email,
      phone: currentUser?.phoneNumber,
    },
    from: {
      streetAddress: property?.address?.streetAddress,
      zipCode: property?.address?.postalCode,
      locality: property?.address?.addressLocality,
      squareMeters: propertySpecification?.livingArea || propertyMainBuilding?.area,
      numberOfRooms: ((propertyMainBuilding?.numberOfBathrooms || 0) + (propertyMainBuilding?.numberOfOtherRooms || 0)) || undefined,
      buildingType,
      propertyType,
      flatNumber: propertySpecification?.apartmentNumber,
    },
  };

  return currentUser?.jws && currentUser && property && propertySpecification && propertyMainBuilding
    ? (
      <RenderMove
        initialData={initialData}
        jws={currentUser.jws}
        key={property.id}
      />
    )
    : (
      <Stack alignItems="center" justifyContent="center" sx={{ width: '100%', height: `calc(100vh - ${HEADER_HEIGHT}px)` }}>
        <div className="move-loader" />
      </Stack>
    );
};

export default Move;
