import React, { useMemo } from 'react';
import {
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  DeleteOutline,
  EditOutlined,
  FileDownloadOutlined,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import HIDButton from '../../../../../../components/buttons/HIDButton';
import {
  FCC,
  Icon,
} from '../../../../../../types/common';
import EntityType from '../../../../../../constants/entityType';
import { getTypographyHeight } from '../../../../../../utils/style';
import ListEntitiesToolbarCountLabel from './ListEntitiesToolbarCountLabel';
import useGetPropertyPermissions from '../../../../hooks/useGetPropertyPermissions';
import useGetPropertyEntityPermissions from '../../../../hooks/useGetPropertyEntityPermissions';

export enum SelectionModeType {
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
  DOWNLOAD = 'DOWNLOAD',
  MOVE = 'MOVE',
  READ = 'READ',
  UNREAD = 'UNREAD',
}

type ModeAction = {
  id: string;
  mode: SelectionModeType;
  Icon?: Icon;
  label: string;
  onAction: () => void;
};

type ListEntitiesToolbarActionsProps = {
  entity?: EntityType;
  selectionModeType?: SelectionModeType;
  isFetching?: boolean;
  isLoading?: boolean;
  count?: number;
  countLabel?: string;
  selectedCount: number;
  SortingComponent?: React.ReactElement,
  onCancel: () => void;
  onDelete?: () => void;
  onUpdate?: () => void;
  onDownload?: () => void;
  onMove?: () => void;
  entityType?: EntityType;
  customActions?: Record<string, ModeAction[]>
};

const ListEntitiesToolbarActions: FCC<ListEntitiesToolbarActionsProps> = ({
  entity,
  selectionModeType,
  isFetching = false,
  isLoading = false,
  count = 0,
  countLabel,
  selectedCount = 0,
  SortingComponent,
  entityType,
  sx,
  onDelete,
  onUpdate,
  onDownload,
  onMove,
  onCancel,
  customActions,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  const { data: propertyPermissions } = useGetPropertyPermissions();
  const { getPropertyEntityPermissions: getPropertyEntityAccessRight } = useGetPropertyEntityPermissions();

  const permissions = entityType
    ? getPropertyEntityAccessRight(entityType)
    : propertyPermissions;

  const { canDelete, canUpdate } = permissions || {};

  const items: ModeAction[] = [
    onDelete && canDelete && {
      id: SelectionModeType.DELETE,
      mode: SelectionModeType.DELETE,
      Icon: DeleteOutline,
      label: t('common:delete_label'),
      onAction: onDelete,
    },
    onUpdate && canUpdate && {
      id: SelectionModeType.UPDATE,
      mode: SelectionModeType.UPDATE,
      Icon: EditOutlined,
      label: t('common:update_label'),
      onAction: onUpdate,
    },
    onDownload && {
      id: SelectionModeType.DOWNLOAD,
      mode: SelectionModeType.DOWNLOAD,
      Icon: FileDownloadOutlined,
      label: t('common:download_label'),
      onAction: onDownload,
    },
    onMove && canUpdate && {
      id: SelectionModeType.MOVE,
      mode: SelectionModeType.MOVE,
      Icon: FileDownloadOutlined,
      label: t('common:move_label'),
      onAction: onMove,
    },
  ].filter(Boolean);

  const currentModeActions: ModeAction[] = useMemo(() => {
    if (!selectionModeType) {
      return [];
    }
    if (customActions) {
      const currentModeCustomActions = customActions[selectionModeType];
      if (currentModeCustomActions?.length) {
        return currentModeCustomActions;
      }
    }

    return items.filter((item) => item.mode === selectionModeType);
  }, [customActions, items]);

  return (
    <Stack
      alignItems="center"
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
      spacing={{
        xxs: 1,
        xs: 2,
      }}
      sx={{ minHeight: 32, ...sx }}
    >
      {
        isLoading
          ? (
            <Skeleton
              height={getTypographyHeight(theme.typography.body1)}
              variant="rounded"
              width="35%"
            />
          )
          : currentModeActions.length
            ? (
              <>
                <Typography variant="body1">
                  {`${selectedCount} ${t('common:marked').toLowerCase()}`}
                </Typography>
                <Stack
                  direction="row"
                  spacing={{
                    xxs: 1,
                    xs: 2,
                  }}
                  sx={{ marginLeft: 'auto' }}
                >
                  {
                    (currentModeActions.map((button) => (
                      <HIDButton
                        Icon={button.Icon}
                        disabled={selectedCount === 0}
                        key={button.id}
                        size="small"
                        onClick={() => button.onAction()}
                      >
                        {button.label}
                      </HIDButton>
                    )))
                  }
                  <HIDButton
                    color="secondary"
                    size="small"
                    onClick={onCancel}
                  >
                    {t('common:cancel')}
                  </HIDButton>
                </Stack>
              </>
            )
            : (
              <>
                <ListEntitiesToolbarCountLabel
                  count={count}
                  entity={entity}
                  isUpdating={isFetching}
                  label={countLabel}
                />
                {SortingComponent}
              </>
            )
      }
    </Stack>
  );
};

export default ListEntitiesToolbarActions;
