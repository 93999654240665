import React, {
  FC,
  useEffect,
} from 'react';
import {
  Box,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QRCodeCanvas } from 'qrcode.react';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router';
import { HEADER_HEIGHT } from '../../../../constants/layout';
import useBreakPointsSizes from '../../../../hooks/useBreakpointsSizes';
import GooglePlayButton from '../../../../components/social/GooglePlayButton';
import AppStoreButton from '../../../../components/social/AppStoreButton';
import {
  useGetCustomTokenQuery,
  useGetTokenDataQuery,
} from '../../api/user.api';
import {
  getAppOnboardingLinkWithCustomToken,
  getAppStoreAppOnboardingLinkWithCustomToken,
  getGooglePlayAppOnboardingLinkWithCustomToken,
} from '../../../../utils/links';
import OnboardingImage from '../../../../assets/images/open_app.jpg';
import HouseIdLogo from '../../../../assets/images/logo.png';
import { openUrlNewTab } from '../../../../utils/file';
import { useLocationState } from '../../../../utils/routes';
import { getActiveAuth } from '../../../../external-services/firebase';
import RouteNames from '../../../../routes/RouteNames';
import { InitAppRouteState } from '../../types/auth.types';
import HIDLink from '../../../../components/HIDLink';
import useDialog from '../../../../hooks/useDialog';
import DialogNames from '../../../../hooks/useDialog/DialogNames';

const QR_CODE_BIG_SCREENS_SIZE = 200;
const QR_CODE_SIZE = 150;

const StoreLinks: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const locationState = useLocationState<InitAppRouteState>();

  const { t } = useTranslation(['auth']);

  const { isDownMd, isDownLg } = useBreakPointsSizes();

  const { data: token, isLoading } = useGetCustomTokenQuery();

  const handleGoogleButtonClick = () => {
    if (token) {
      openUrlNewTab(getGooglePlayAppOnboardingLinkWithCustomToken(token));
    }
  };

  const onboardingLinkWithCustomToken = token ? getAppOnboardingLinkWithCustomToken(token) : '';

  const handleAppleButtonClick = () => {
    if (token) {
      openUrlNewTab(getAppStoreAppOnboardingLinkWithCustomToken(token));
    }
  };

  const { data: tokenData } = useGetTokenDataQuery({});
  const [currentUser] = useAuthState(getActiveAuth());

  useEffect(() => {
    if (tokenData?.properties?.length || !currentUser) {
      navigate(RouteNames.INIT);
    }
  }, [tokenData, currentUser]);

  const [openEnterInviteCodeDialog] = useDialog(DialogNames.ENTER_INVITE_CODE_DIALOG);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      md={10}
      paddingX={{
        xxs: 4,
      }}
      paddingY={{
        md: 10,
        xxs: 4,
      }}
      sm={10}
      spacing={2}
      style={{
        height: `calc(100dvh-${HEADER_HEIGHT}`,
      }}
      wrap="wrap-reverse"
      xxs={12}
    >
      <Grid
        item
        lg={5}
        md={6}
      >
        <Stack spacing={2}>
          <Typography
            textAlign={
              isDownMd ? 'center' : undefined
            }
            variant={isDownMd ? 'h3' : 'h2'}
          >
            {locationState?.withInvitation
              ? t('auth:onboarding_onboarding_title')
              : t('auth:onboarding_onboarding_add_property_title')}
          </Typography>
          <Typography
            textAlign={
              isDownMd ? 'center' : undefined
            }
            variant="body1"
          >
            {t('auth:onboarding_onboarding_description')}
          </Typography>
          <Stack
            direction="row"
            justifyContent={isDownMd ? 'center' : 'flex-start'}
            spacing={2}
          >
            <GooglePlayButton onClick={handleGoogleButtonClick} />
            <AppStoreButton onClick={handleAppleButtonClick} />
          </Stack>
          <HIDLink
            label={t('auth:onboarding_do_you_have_an_invitation_code')}
            sx={{ alignSelf: 'flex-start' }}
            onClick={openEnterInviteCodeDialog}
          />
        </Stack>
      </Grid>
      <Grid
        item
        lg={5}
        md={6}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'inline-block',
          }}
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          >
            <Box
              sx={{
                padding: theme.spacing(2),
                border: 4,
                borderColor: theme.palette.primary.main,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 5,
              }}
            >
              {Boolean(!isLoading && onboardingLinkWithCustomToken) && (
                <QRCodeCanvas
                  imageSettings={{
                    height: 24,
                    width: 24,
                    excavate: true,
                    src: HouseIdLogo,
                  }}
                  size={isDownLg ? QR_CODE_SIZE : QR_CODE_BIG_SCREENS_SIZE}
                  value={onboardingLinkWithCustomToken}
                />
              )}
            </Box>
          </Box>
          <Box
            component="img"
            src={OnboardingImage}
            sx={{
              objectFit: 'scale-down',
              objectPosition: isDownMd ? 'top' : undefined,
              width: '100%',
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default StoreLinks;
