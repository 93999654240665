import React from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import {
  ArrowForward,
  DeleteOutline,
  OpenInBrowser,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { IconType } from 'react-file-icon';

import { FCC } from '../../../../../../types/common';
import HIDIconButton from '../../../../../../components/buttons/HIDIconButton';
import { openFileInNewTab } from '../../../../../../utils/file';
import FileIcon, { FileIconPropsMap } from './FileIcon';
import { ExternalMediaType } from '../../modules/Product/types.product';
import { FileMimeType } from '../../../../../../constants/mimeTypes';

export const ExternalMediaIconTypeMap: Record<ExternalMediaType, IconType> = {
  [ExternalMediaType.DOCUMENT]: 'document',
  [ExternalMediaType.IMAGE]: 'image',
  [ExternalMediaType.LINK]: 'font',
  [ExternalMediaType.VIDEO]: 'video',
};

type ExternalMediaListItemProps = {
  loading?: boolean;
  mime?: string;
  mediaType?: ExternalMediaType;
  url: string;
  isExternal?: boolean;
  condense?: boolean;
  onDelete?: () => void;
  onMove?: () => void;
};

const ExternalMediaListItem: FCC<ExternalMediaListItemProps> = ({
  loading,
  mime,
  mediaType,
  url,
  isExternal,
  sx,
  onDelete,
  onMove,
}) => {
  const { t } = useTranslation(['common']);
  const type = mime && (mime in FileMimeType)
    ? FileIconPropsMap[mime as keyof typeof FileIconPropsMap].type
    : mediaType
      ? ExternalMediaIconTypeMap[mediaType]
      : undefined;

  const handleOpen = () => openFileInNewTab(url, isExternal);

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      spacing={1}
      sx={sx}
    >
      <Stack
        alignItems="center"
        direction="row"
        spacing={1}
        sx={{ minWidth: 0 }}
      >
        <FileIcon type={type} />
        <Stack sx={{ minWidth: 0 }}>
          <Typography noWrap variant="body1">
            {url}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="center"
        spacing={1}
      >
        <HIDIconButton
          Icon={OpenInBrowser}
          disabled={loading}
          size="small"
          title={t('common:open_label')}
          onClick={handleOpen}
        />
        {onMove && (
          <HIDIconButton
            Icon={ArrowForward}
            disabled={loading}
            size="small"
            title={t('common:move_label')}
            onClick={onMove}
          />
        )}
        {onDelete && (
          <HIDIconButton
            Icon={DeleteOutline}
            disabled={loading}
            size="small"
            title={t('common:delete_label')}
            onClick={onDelete}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default ExternalMediaListItem;
