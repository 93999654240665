import React, {
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  Dialog,
  DialogContentText,
  DialogProps,
  Stack,
  TextFieldProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import useBreakPointsSizes from '../../hooks/useBreakpointsSizes';
import { HIDDialogProps } from '../../types/common';
import HIDButton from '../buttons/HIDButton';
import HIDLink from '../HIDLink';
import HIDDialogTitle, { HIDDialogTitleProps } from './HIDDialogTitle';
import HIDDialogContent from './HIDDialogContent';
import HIDDialogActions from './HIDDialogActions';
import HIDTextField from '../HIDTextField';

export type PromptDialogProps = HIDDialogProps & {
  title: string;
  iconType?: HIDDialogTitleProps['iconType'];
  maxWidth?: DialogProps['maxWidth'];
  description?: string;
  DescriptionComponent?: ReactNode;
  submitTitle?: string;
  cancelTitle?: string;
  label?: string;
  inputProps?: TextFieldProps['inputProps'];
  helperText?: string;
  disabled?: boolean;
  multiline?: boolean;
  loading?: boolean;
  closeOnAction?: boolean;
  onSubmit: (value: string) => void;
};

const PromptDialog: FC<PromptDialogProps> = ({
  open,
  maxWidth = 'xs',
  title,
  iconType,
  description,
  DescriptionComponent,
  submitTitle,
  cancelTitle,
  label = '',
  inputProps,
  helperText,
  disabled,
  multiline,
  loading,
  closeOnAction = true,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation(['common', 'forms_common']);
  const [value, setValue] = useState('');

  const { isDownSm } = useBreakPointsSizes();

  const handleSubmit = () => {
    onSubmit(value);
    if (closeOnAction) {
      onClose();
    }
  };

  useEffect(() => {
    if (open) {
      setValue('');
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      fullScreen={isDownSm}
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
    >
      <HIDDialogTitle
        iconType={iconType}
        title={title}
        onClose={onClose}
      />
      <HIDDialogContent style={isDownSm ? { alignItems: 'center', display: 'flex' } : undefined}>
        <Stack spacing={1}>
          {DescriptionComponent || (
            <DialogContentText>
              {description}
            </DialogContentText>
          )}
          <HIDTextField
            error={Boolean(helperText)}
            helperText={helperText}
            inputProps={inputProps}
            label={label}
            multiline={multiline}
            showHelperText={Boolean(helperText)}
            value={value}
            onChange={({ target }) => setValue(target.value)}
          />
        </Stack>
      </HIDDialogContent>
      <HIDDialogActions>
        <HIDLink
          disabled={loading}
          label={cancelTitle || t('common:cancel')}
          underline="none"
          onClick={onClose}
        />
        <HIDButton
          disabled={disabled || loading || Boolean(!value)}
          loading={loading}
          onClick={handleSubmit}
        >
          {submitTitle || t('common:yes')}
        </HIDButton>
      </HIDDialogActions>
    </Dialog>
  );
};

export default PromptDialog;
