import React from 'react';
import {
  Checkbox,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  TreeItem,
  TreeView,
} from '@mui/x-tree-view';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import * as R from 'ramda';
import { HIDBlob } from '@house-id/houseid-types/dist/common';

import {
  FCC,
} from '../../../../../types/common';
import FileListItem from '../../../../Property/modules/Content/components/lists/FileListItem';
import { downloadFile } from '../../../../../utils/download';
import { openFileInNewTab } from '../../../../../utils/file';
import { useLazyGetEmailAttachmentSignedUrlQuery } from '../api/emails.api';
import {
  HIDHierarchyBlob,
  HIDHierarchyFolder,
  HIDBlobHierarchyItem,
} from '../types.emails';

type SignedUrlFilesListProps = {
  selectedItemIds: Record<string, boolean>;
  blobs: Array<HIDBlob>;
  blobHierarchy: Array<HIDBlobHierarchyItem>;
  emailId: string,
  onChange: (values: Record<string, boolean>) => void;
};

export const isHIDHierarchyFolder = (item: HIDHierarchyBlob | HIDHierarchyFolder): item is HIDHierarchyFolder => 'children' in item;

export const getHIDHierarchyFolderIds = (item: HIDBlobHierarchyItem): Array<string> => {
  const isFolder = isHIDHierarchyFolder(item);
  return isFolder
    ? [item.id].concat(item.children.flatMap((child) => getHIDHierarchyFolderIds(child)))
    : [];
};

export const getFolderIdsFromHierarchy = (items: Array<HIDBlobHierarchyItem>): Array<string> =>
  R.pipe(
    R.map(getHIDHierarchyFolderIds),
    R.flatten,
  )(items);

export const mapSelectedBlobHierarchyItems = (item: HIDBlobHierarchyItem, value: boolean): Array<[string, boolean]> => {
  const isFolder = isHIDHierarchyFolder(item);
  return isFolder
    ? [[item.id, value] as [string, boolean]].concat(item.children.flatMap((child) => mapSelectedBlobHierarchyItems(child, value)))
    : [[item.id, value] as [string, boolean]];
};

const SignedUrlFilesList: FCC<SignedUrlFilesListProps> = ({
  selectedItemIds,
  emailId,
  blobHierarchy,
  sx,
  onChange,
}) => {
  const { t } = useTranslation(['entities', 'photos', 'common']);

  const [getAttachmentSignedUrl] = useLazyGetEmailAttachmentSignedUrlQuery();

  const handleOpen = (blobId: string) =>
    getAttachmentSignedUrl({ emailId, blobId })
      .unwrap()
      .then(({ url }) => openFileInNewTab(url));

  const handleDownload = (blobId: string, name: string) =>
    getAttachmentSignedUrl({ emailId, blobId })
      .unwrap()
      .then(({ url }) => downloadFile(url, name));

  const handleTreeNodeSelect = (item: HIDBlobHierarchyItem, checked: boolean) => {
    const isItemFolder = isHIDHierarchyFolder(item);
    if (isItemFolder) {
      const values = R.fromPairs(mapSelectedBlobHierarchyItems(item, checked));

      onChange({
        ...selectedItemIds,
        ...values,
      });
    } else {
      onChange({
        ...selectedItemIds,
        [item.id]: checked,
      });
    }
  };

  const renderTree = (item: HIDBlobHierarchyItem) => {
    const isItemFolder = isHIDHierarchyFolder(item);

    return (
      <TreeItem
        key={item.name}
        label={
          <Stack direction="row">
            <Checkbox
              checked={Boolean(selectedItemIds[item.id])}
              onChange={(_, checked) => handleTreeNodeSelect(item, checked)}
            />
            {isItemFolder
              ? (
                <FileListItem
                  iconSize={20}
                  mime="folder"
                  name={item.name}
                />
              ) : (
                <FileListItem
                  iconSize={20}
                  mime={item.mime}
                  name={item.name}
                  onDownload={() => handleDownload(item.id, item.name)}
                  onOpen={() => handleOpen(item.id)}
                />
              )}
          </Stack>
        }
        nodeId={item.id}
      >
        {
          isItemFolder
            ? item.children?.map((child) => renderTree(child))
            : null
        }
      </TreeItem>
    );
  };

  return (
    <Stack spacing={2} sx={sx}>
      {Boolean(blobHierarchy.length) && (
        <Typography paddingLeft={2} variant="h6">
          {t('common:attachments')}
        </Typography>
      )}
      <TreeView
        defaultCollapseIcon={<GridExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {blobHierarchy?.map((item) => renderTree(item))}
      </TreeView>
    </Stack>
  );
};

export default SignedUrlFilesList;
