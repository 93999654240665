import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PropertyFinancesFixedCostSectionData,
  PropertyProgressCategorySectionProps,
} from '../../../types.propertyProgress';
import PropertyProgressCategorySectionContainer from '../../../components/PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from '../../../components/PropertyProgressCategorySectionContent';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import RecurringExpensesOverview from '../../../../Finances/pages/financeOverview/components/RecurringExpensesOverview';

const PropertyFinancesFixedCostSection: FC<PropertyProgressCategorySectionProps<PropertyFinancesFixedCostSectionData>> = ({
  section,
}) => {
  const { t } = useTranslation(['forms_common', 'property']);

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const handleSeeMore = () => openMobileAppDialog();

  const handleAddOperatingCost = () => openMobileAppDialog();

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('property:property_progress_property_operating_cost_section_add_expenses')}
          viewAllButtonText={t('property:property_progress_property_operating_cost_section_view_expenses')}
          onAddClick={handleAddOperatingCost}
          onViewAllClick={handleSeeMore}
        >
          <RecurringExpensesOverview />
        </PropertyProgressCategorySectionContent>

      }
      key={section.id}
      section={section}
    />
  );
};

export default PropertyFinancesFixedCostSection;
