import React from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ChevronRight,
} from '@mui/icons-material';
import {
  PropertyProgressCategory,
} from '@house-id/houseid-types/dist/propertyProgress';

import { FCC } from '../../../../../../types/common';
import HIDLinearProgressWithLabel from '../../../../../../components/progress/HIDLinearProgressWithLabel';
import useBreakPointsSizes from '../../../../../../hooks/useBreakpointsSizes';
import useGetPropertyProgressCategoryMap from '../../hooks/useGetPropertyProgressCategoryMap';
import { getPropertyProgressPercent } from '../../utils/utils.propertyProgress';
import useGetPropertyProgressCategoryText from '../../hooks/useGetPropertyProgressCategoryText';
import HIDImage from '../../../../../../components/image/HIDImage';

type PropertyProgressListItemProps = {
  category: PropertyProgressCategory;
  onClick: () => void;
};

const PropertyProgressListItem: FCC<PropertyProgressListItemProps> = ({
  category: {
    id,
    name,
    progress,
    description,
    iconUrl,
  },
  onClick,
  sx,
}) => {
  const theme = useTheme();

  const { isDownLg } = useBreakPointsSizes();

  const propertyProgressPercent = getPropertyProgressPercent(progress);

  const getPropertyProgressPercentText = useGetPropertyProgressCategoryText();

  const text = description || getPropertyProgressPercentText({
    categoryId: id,
    percent: propertyProgressPercent,
  });

  const propertyProgressCategoryMap = useGetPropertyProgressCategoryMap();
  const CategoryIcon = propertyProgressCategoryMap[id]?.Icon;

  return (
    <Card
      sx={{
        padding: 2,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: '10px',
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.common.white,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.grey[100],
        },
        '&:active': {
          backgroundColor: theme.palette.grey[200],
        },
        ...sx,
      }}
      onClick={onClick}
    >
      <Stack spacing={1.5}>
        <Stack alignItems="center" direction="row" spacing={1}>
          {
            CategoryIcon
              ? <CategoryIcon size={32} />
              : iconUrl
                ? <HIDImage externalUrl={iconUrl} />
                : null
          }
          <Typography flex={1} variant="subtitle1">
            {name}
          </Typography>
          <ChevronRight />
        </Stack>
        <Stack spacing={1}>
          {Boolean(progress?.total) && (
            <HIDLinearProgressWithLabel
              sx={
                {
                  width: '100%',
                  height: 12,
                  borderRadius: '5px',
                }
              }
              value={propertyProgressPercent}
              variant="determinate"
            />
          )}
          {text && (
            <Typography
              variant={isDownLg ? 'body2' : 'body1'}
            >
              {text}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

export default PropertyProgressListItem;
