import React, {
  FC,
} from 'react';
import { useTranslation } from 'react-i18next';

import PromptDialog from '../../../../../components/dialogs/PromptDialog';
import { HIDDialogProps } from '../../../../../types/common';

export type ApplyPromoCodeDialogProps = HIDDialogProps & {
  onSubmit: (value: string) => void;
};

const ApplySharedTokenDialog: FC<ApplyPromoCodeDialogProps> = ({
  open,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation(['admin', 'common']);

  return (
    <PromptDialog
      multiline
      label={t('admin:impersonate_token')}
      maxWidth="md"
      open={open}
      submitTitle={t('common:apply')}
      title={t('admin:impersonate_apply_token')}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default ApplySharedTokenDialog;
