import { skipToken } from '@reduxjs/toolkit/query';

import { getActiveAuth } from '../../../../../external-services/firebase';
import {
  useSetUserSettingsMutation,
  useGetUserSettingsQuery,
} from '../../../../Auth/api/settings.api';
import { AreasVisibilityMap } from '../../../../Auth/types/settings.types';

type UseAreasVisibility = () => [
  (data: Partial<AreasVisibilityMap>) => void,
  Partial<AreasVisibilityMap>,
  boolean,
];

const useAreasVisibility: UseAreasVisibility = () => {
  const auth = getActiveAuth();

  const [setUserSettings] = useSetUserSettingsMutation();

  const {
    data: { areasVisibilityMap = {} } = {},
    isLoading: isLoadingUserSettings,
  } = useGetUserSettingsQuery(
    auth.currentUser?.uid
      ? { userUId: auth.currentUser.uid }
      : skipToken,
  );

  const updateVisibleAreasMap = (data: Partial<AreasVisibilityMap>) => {
    setUserSettings({ areasVisibilityMap: { ...areasVisibilityMap, ...data } });
  };

  return [updateVisibleAreasMap, areasVisibilityMap, isLoadingUserSettings];
};

export default useAreasVisibility;
