import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InsuranceWithParams } from '../types.insurance';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../utils/date';
import { formatMoney } from '../../../../../../../utils/string';
import HIDLink from '../../../../../../../components/HIDLink';
import { useGetInsuranceCompaniesQuery } from '../api/insurance.api';
import HIDImage from '../../../../../../../components/image/HIDImage';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import PropertyProgressContentItem from '../../../../PropertyProgress/components/PropertyProgressContentItem';

type InsuranceCardProps = {
  insurance: InsuranceWithParams;
  lastSyncTime?: string
};

const InsuranceCard: FC<InsuranceCardProps> = ({ insurance, lastSyncTime }) => {
  const theme = useTheme();
  const { t } = useTranslation(['insurances', 'forms_common']);

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const { data: companies = [] } = useGetInsuranceCompaniesQuery();

  const company = companies.find((companyData) => companyData.id === insurance?.insuranceCompany);

  const insuranceLastSync = lastSyncTime ? formatDate(new Date(lastSyncTime), DateTimeFormats.DATE_ONLY) : undefined;

  const activeUntil = insurance?.renewalDate
    ? t('insurances:insurance_active_till', { date: formatDate(new Date(insurance?.renewalDate), DateTimeFormats.DATE_ONLY) })
    : undefined;

  const monthlyCost = insurance?.premiumAmountYear
    ? t('forms_common:per_month', { value: formatMoney(Math.round(Number(insurance?.premiumAmountYear) / 12)) })
    : undefined;

  const yearlyCost = insurance?.premiumAmountYear
    ? t('forms_common:per_year', { value: formatMoney(Number(insurance?.premiumAmountYear)) })
    : undefined;

  const handleViewInsurance = () => openMobileAppDialog();

  return (
    <Card
      sx={{
        padding: 2,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: '10px',
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.common.white,
        width: '100%',
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6">
          {t('insurances:insurance_insurance_card_title', { name: insurance.insuranceHolderStreetAddress })}
        </Typography>
        {Boolean(lastSyncTime) && (
          <Typography
            color={theme.palette.grey[500]}
            variant="body1"
          >
            {t('forms_common:last_updated_date', { date: insuranceLastSync })}
          </Typography>
        )}
        <PropertyProgressContentItem
          Icon={company?.logoUrl ? () => <HIDImage sx={{ width: 48, aspectRatio: 1 }} url={company?.logoUrl} /> : undefined}
          description={company?.name}
          title={insurance.insuranceName}
          onNavigate={handleViewInsurance}
        />
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="subtitle1"
            >
              {monthlyCost}
            </Typography>
            <Typography
              variant="subtitle1"
            >
              {yearlyCost}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent={{ xs: 'space-between', xxs: 'flex-end' }}
            spacing={2}
          >
            {Boolean(activeUntil) && (
              <Typography
                color={theme.palette.primary.main}
                variant="body1"
              >
                {activeUntil}
              </Typography>
            )}
            <HIDLink
              label={t('insurances:insurance_read_more')}
              onClick={handleViewInsurance}
            />
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};
export default InsuranceCard;
