import { useNavigate } from 'react-router';

import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import useGetPropertyProgressCategoryMap from './useGetPropertyProgressCategoryMap';
import { PropertyProgressCategory } from '@house-id/houseid-types/dist/propertyProgress';

type UseGetHandlePropertyProgressCardPress = () => (category: PropertyProgressCategory) => void;

const useGetHandlePropertyProgressCardPress: UseGetHandlePropertyProgressCardPress = () => {
  const navigate = useNavigate();

  const { data: propertyId } = useGetCurrentPropertyId();

  const propertyProgressCategoryMap = useGetPropertyProgressCategoryMap();

  const handlePropertyProgressCardPress = (category: PropertyProgressCategory) => {
    const { getActionPath, onClick } = propertyProgressCategoryMap[category.id];
    const path = getActionPath && propertyId ? getActionPath({ propertyId }) : undefined;

    if (path) {
      navigate(path);
    } else if (propertyId && onClick) {
      onClick({ propertyId });
    }
  };

  return handlePropertyProgressCardPress;
};

export default useGetHandlePropertyProgressCardPress;
