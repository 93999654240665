import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import { getPropertyDeclarationDeepLink } from '../../../../../utils/links';
import { OpenMobileAppDialogProps } from '../../../components/dialogs/OpenMobileAppDialog';

const useGetOpenPropertyDeclarationMobileAppDialog = () => {
  const [openMobileAppDialog] = useDialog<OpenMobileAppDialogProps>(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const handleOpen = () => {
    openMobileAppDialog({ linkExtractor: getPropertyDeclarationDeepLink });
  };

  return handleOpen;
};

export default useGetOpenPropertyDeclarationMobileAppDialog;
