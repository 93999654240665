import React from 'react';
import { useNavigate } from 'react-router';
import {
  Stack,
} from '@mui/material';
import {
  MailOutline,
  Search,
} from '@mui/icons-material';
import { skipToken } from '@reduxjs/toolkit/query';

import HIDIconButton from '../../../../../components/buttons/HIDIconButton';
import PropertySelect from './PropertySelect';
import SearchAutocomplete from '../../../modules/Content/modules/Search/components/SearchAutocomplete';
import UserSettingsMenu from './UserSettingsMenu';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import {
  FCC,
  HeaderProps,
} from '../../../../../types/common';
import { getMessagesPath } from '../../../modules/Content/modules/Message/navigation.message';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { useGetMessagesQuery } from '../../../modules/Content/modules/Message/api/message.api';
import { getPathWithPropertyIdOrInit } from '../../../../Auth/navigation/navigation.auth';
import HIDHeader from '../../../../../components/layouts/HIDHeader';

// NOTE: Hardcode, can be improved
const SPACE_FOR_PROPERTY_SELECT = 152; // 8 + 40 + 8 + 40 + 40 + 16

const Header: FCC<HeaderProps> = ({
  isMenuOpened,
  isVisibleSearch,
  sx,
  onToggleMenuOpen,
  onToggleOpenSearch,
}) => {
  const navigate = useNavigate();
  const { isDownSm, isDownMd, isDownLg } = useBreakPointsSizes();
  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: { unreadCount = 0 } = {} } = useGetMessagesQuery(propertyId ? { propertyId, pageSize: 0 } : skipToken);

  const showMessages = () => navigate(getPathWithPropertyIdOrInit(getMessagesPath, { propertyId }));

  return (
    <HIDHeader
      LeftComponent={(
        (Boolean(propertyId) && ((!isDownSm && !isVisibleSearch) || !isDownMd)) && (
          <PropertySelect
            showAddNew
            buttonSx={{ minWidth: isDownLg && !isDownMd && isMenuOpened ? 200 : 250 }}
          />
        )
      )}
      MiddleComponent={(
        <>
          {
            isDownSm && !isVisibleSearch && Boolean(propertyId) && (
              <Stack
                alignItems="center"
                direction="row"
                sx={{
                  minWidth: 0,
                  maxWidth: `calc(100vw - ${SPACE_FOR_PROPERTY_SELECT}px)`,
                }}
              >
                <PropertySelect
                  showAddNew
                  buttonSx={{ minWidth: 0 }}
                  outline={false}
                  showImage={false}
                  sx={{ minWidth: 0 }}
                />
              </Stack>
            )
          }
          {isVisibleSearch && propertyId && (
            <Stack
              alignItems="center"
              direction="row"
              flex={1}
              justifyContent="center"
              marginRight={1}
            >
              <SearchAutocomplete propertyId={propertyId} />
            </Stack>
          )}
        </>
      )}
      RightComponent={(
        <>
          {!isVisibleSearch && Boolean(propertyId) && (
            <HIDIconButton
              Icon={Search}
              onClick={() => onToggleOpenSearch(true)}
            />
          )}
          {!isDownSm && (
            <HIDIconButton
              Icon={MailOutline}
              badgeCount={unreadCount}
              onClick={showMessages}
            />
          )}
          <UserSettingsMenu />
        </>
      )}
      isMenuOpened={isMenuOpened}
      sx={sx}
      onToggleMenuOpen={onToggleMenuOpen}
    />
  );
};

export default Header;
