import React, { FC } from 'react';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';

import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import FinanceOverviewWidget from './FinanceOverviewWidget';
import { TimeIntervalsGroupingType } from '../../../../Content/types/content.type';
import BudgetComparisonChart from '../../../modules/Budgets/components/BudgetComparisonChart';
import { ChartDateLength } from '../../../utils.finances';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import HIDButton from '../../../../../../../components/buttons/HIDButton';
import { getBudgetsOverviewPath } from '../../../modules/Budgets/navigation.budgets';
import PiggyBank from '../../../../../../../components/icons/PiggyBank';
import { useGetBudgetFiltrationQuery } from '../../../modules/Budgets/api/budgets.api';
import useGetCurrentBudget from '../../../modules/Budgets/hooks/useGetCurrentBudget';

const now = new Date();
const currentMonth = now.getMonth() + 1;

type BudgetsOverviewProps = {
  showActions?: boolean;
  showIcons?: boolean;
};

const BudgetsOverview: FC<BudgetsOverviewProps> = ({
  showActions = false,
  showIcons = false,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['forms_common', 'common', 'finances']);
  const theme = useTheme();

  const { isDownXl } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: currentBudget,
    isLoading: isLoadingCurrentBudget,
  } = useGetCurrentBudget();

  const {
    data: filtration,
    isLoading: isLoadingFiltration,
    isFetching: isFetchingFiltration,
  } = useGetBudgetFiltrationQuery(
    propertyId && currentBudget
      ? {
        propertyId,
        budgetId: currentBudget.id,
        fromMonth: currentMonth > 6 ? currentMonth - 5 : 1,
        toMonth: currentMonth,
        grouping: TimeIntervalsGroupingType.Monthly,
      }
      : skipToken,
  );

  const isLoading = isLoadingCurrentBudget || isLoadingFiltration || isFetchingFiltration;

  const handleNavigateToBudgetOverview = () => navigate(getPathWithPropertyIdOrInit(getBudgetsOverviewPath, { propertyId }));

  const actions = (
    <HIDButton
      color="primary"
      disabled={isLoading}
      sx={{ minWidth: 120 }}
      onClick={handleNavigateToBudgetOverview}
    >
      {t('common:view')}
    </HIDButton>
  );

  return filtration?.budget || isLoading
    ? (
      <BudgetComparisonChart
        data={filtration?.chart || []}
        dateLength={isDownXl ? ChartDateLength.TINY : ChartDateLength.LONG}
        footerInfo={showActions ? actions : undefined}
        groupingType={TimeIntervalsGroupingType.Monthly}
        isLoading={isLoading}
        minHeight={200}
        primaryLabel={t('finances:budgets')}
        showIcons={showIcons}
      />
    )
    : (
      <FinanceOverviewWidget
        Icon={PiggyBank}
        action={t('finances:add_budget')}
        description={t('finances:add_fixed_expenses_to_get_an_overview')}
        iconBackgroundColor={theme.palette.info.lighter}
        iconColor={theme.palette.info.dark}
        title={t('finances:create_budget_and_keep_track_of_your_expenses')}
        widgetBackground={theme.palette.info.lightest}
        onClick={showActions ? handleNavigateToBudgetOverview : undefined}
      />
    );
};

export default BudgetsOverview;
