import i18next from 'i18next';
import {
  enUS,
  sv,
} from 'date-fns/locale';

import Languages from '../constants/languages';

export const getCurrentLanguage = () => i18next.language as Languages === Languages.EN ? Languages.EN : Languages.SV;

export const getCurrentLocale = () => getCurrentLanguage() === Languages.EN ? enUS : sv;
