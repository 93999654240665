import { useGetEnumsQuery } from '../modules/Auth/api/enum.api';
import {
  EnumType,
  EnumDictionary,
  QueryHook,
} from '../types/common';

type UseGetEnum<TValue = string> = QueryHook<EnumDictionary<TValue> | undefined, EnumType>;

const useGetEnum: UseGetEnum = (enumType) => {
  const { data: enums, ...rest } = useGetEnumsQuery();

  return {
    data: enums && enumType ? enums[enumType] : undefined,
    ...rest,
  };
};

export default useGetEnum;
