import React, {
  useState,
} from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useUpdateEffect } from 'usehooks-ts';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';

import {
  useGetAllReceiptCategoriesQuery,
  useGetReceiptsSummaryQuery,
} from '../../../api/receipt.api';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import HIDInlineSelect, { SelectItem } from '../../../../../../../../../components/HIDInlineSelect';
import { ALL_RECEIPTS_CATEGORY } from '../../../constants.receipt';
import { getReceiptCategoryPath } from '../../../navigation.receipt';
import { useRouteParams } from '../../../../../../../../../utils/routes';
import { toggleSideDrawerOpen } from '../../../../../../../../../store/layoutReducer';
import ListStructureLoaderSkeleton from '../../../../../../../../../components/skeletonLoaders/ListStructureLoaderSkeleton';
import { getPathWithPropertyIdOrInit } from '../../../../../../../../Auth/navigation/navigation.auth';

const ReceiptsQuickNavigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['receipts']);

  const { category: categoryId } = useRouteParams<{ category: string }>();

  const { data: propertyId } = useGetCurrentPropertyId();
  const {
    data: categories = [],
    isLoading: isLoadingCategories,
  } = useGetAllReceiptCategoriesQuery(propertyId ? { propertyId } : skipToken);
  const { data: receiptsSummary, isLoading: isLoadingReceipts } = useGetReceiptsSummaryQuery(propertyId ? { propertyId } : skipToken);

  const countMap = Object.fromEntries(
    (receiptsSummary?.categories || []).map((category) => ([category.categoryId, category.count])),
  );

  const [selectedCategoryId, setSelectedCategoryId] = useState<string>(categoryId);

  const selectItems: Array<SelectItem> = [
    {
      id: ALL_RECEIPTS_CATEGORY,
      label: t('receipts:receipts_all_receipts'),
      count: R.sum(R.values(countMap)),
    },
  ]
    .concat(
      categories.map(
        (category) => ({
          id: category.id,
          label: category.name,
          count: countMap[category.id] || 0,
        }),
      ),
    );

  useUpdateEffect(() => {
    navigate(
      getPathWithPropertyIdOrInit(getReceiptCategoryPath, { propertyId, categoryId: selectedCategoryId }),
      { replace: true },
    );
    dispatch(toggleSideDrawerOpen(false));
  }, [selectedCategoryId]);

  return (
    <ListStructureLoaderSkeleton isLoading={isLoadingCategories || isLoadingReceipts}>
      <Stack>
        <Typography sx={{ marginBottom: 1 }} variant="h6">
          {t('receipts:receipts_receipts_title')}
        </Typography>
        <HIDInlineSelect
          items={selectItems}
          value={selectedCategoryId}
          onChange={setSelectedCategoryId}
        />
      </Stack>
    </ListStructureLoaderSkeleton>
  );
};

export default ReceiptsQuickNavigation;
