import React from 'react';
import {
  Stack,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FCC } from '../../../../../../../types/common';
import HIDTypography from '../../../../../../../components/HIDTypography';
import BonusOffersSectionCard from './BonusOffersSectionCard';

type BonusPointsCircularProgressProps = {
  title: string;
  isLoading?: boolean;
  points: number;
  progressColor: string;
};

const BonusPointsCircularProgress: FCC<BonusPointsCircularProgressProps> = ({
  title,
  points,
  isLoading = false,
  progressColor,
}) => {
  const { t } = useTranslation(['common']);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        style={{
          width: 104,
          height: 104,
          borderStyle: 'solid',
          borderRadius: '100%',
          borderWidth: 6,
          borderColor: progressColor,
        }}
      >
        <HIDTypography variant="h4">
          {t('common:points', { points })}
        </HIDTypography>
      </Stack>
      <HIDTypography
        fontWeight={500}
        isLoading={isLoading}
        skeletonWidth="40%"
        sx={{ textAlign: 'center', width: '100%' }}
        variant="body1"
      >
        {title}
      </HIDTypography>
    </Stack>
  );
};

type BonusPointsSectionProps = {
  sx?: SxProps<Theme>;
  averagePoints: number;
  propertyPoints: number;
};

// eslint-disable-next-line react/no-multi-comp
const BonusPointsSection: FCC<BonusPointsSectionProps> = ({
  sx,
  averagePoints,
  propertyPoints,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['bonus_offers']);

  return (
    <BonusOffersSectionCard
      alignItems="center"
      direction="row"
      justifyContent="center"
      spacing={5.5}
      sx={sx}
    >
      <BonusPointsCircularProgress
        points={propertyPoints}
        progressColor={theme.palette.primary.main}
        title={t('bonus_offers:bonus_offers_points_mine')}
      />
      <BonusPointsCircularProgress
        points={averagePoints}
        progressColor={theme.palette.warning.dark}
        title={t('bonus_offers:bonus_offers_points_average')}
      />
    </BonusOffersSectionCard>
  );
};

export default BonusPointsSection;
