import qs from 'query-string';
import {
  LoansSummary,
  ValuationSummary,
  FinanceInfo,
} from '@house-id/houseid-types/dist/finances/finances';

import { propertyApi } from '../../../api/property.api';
import { PropertyId } from '../../../types/property.types';
import { HIDApiTags } from '../../../../../api/HIDApiTags';
import { InsuranceWithParams } from '../../Content/modules/Insurance/types.insurance';

export const financeApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getLoansSummary: builder.query<LoansSummary, PropertyId>({
      query: ({ propertyId }) => `/loans?${qs.stringify({ propertyId })}`,
      providesTags: () => [HIDApiTags.LOAN],
    }),
    getMovestaValuation: builder.query<ValuationSummary, PropertyId>({
      query: ({ propertyId }) => `/partner/movesta/valuation/latest?${qs.stringify({ propertyId })}`,
      transformResponse: (response: { valuation: ValuationSummary }) => response.valuation,
      providesTags: () => [HIDApiTags.VALUATION],
    }),
    getFinanceInformation: builder.query<FinanceInfo, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/finance/information`,
      providesTags: () => [HIDApiTags.FINANCE_INFORMATION],
    }),
    getInsurances: builder.query<Array<InsuranceWithParams>, void>({
      query: () => 'v2/insurances',
      transformResponse: (response: { insurances: Array<InsuranceWithParams> }) => response.insurances,
      providesTags: () => [HIDApiTags.INSURANCES],
    }),
    patchFinanceInformation:
      builder.mutation<FinanceInfo, PropertyId & { financeInformation: Record<string, any>; }>({
        query: ({ propertyId, financeInformation }) => ({
          url: `/properties/${propertyId}/finance/information`,
          method: 'PATCH',
          body: financeInformation,
        }),
        invalidatesTags: [HIDApiTags.PROPERTY_FINANCE, HIDApiTags.PROPERTY_PROGRESS, HIDApiTags.FINANCE_INFORMATION],
      }),
  }),
});

export const {
  useGetLoansSummaryQuery,
  useGetMovestaValuationQuery,
  useGetFinanceInformationQuery,
  useGetInsurancesQuery,
  usePatchFinanceInformationMutation,
} = financeApi;
