import React, { FC } from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

import { Product } from '../../../modules/Content/modules/Product/types.product';
import ContentImage from '../../../modules/Content/components/ContentImage';
import { getFirstExternalImageUrl } from '../../../modules/Content/modules/Product/utils.products';
import { hidSpacing } from '../../../../../utils/number';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../utils/date';
import HIDClickable from '../../../../../components/HIDClickable';

type ProductWidgetItemProps = {
  product: Product;
  onClick?: () => void;
};

const ProductWidgetItem: FC<ProductWidgetItemProps> = ({
  product,
  onClick,
}) => {
  const theme = useTheme();

  const Container = onClick ? HIDClickable : Stack;

  return (
    <Container
      sx={{
        padding: 1,
        backgroundColor: theme.palette.common.white,
        height: hidSpacing(8),
      }}
      onClick={onClick}
    >
      <Stack
        alignItems="center"
        direction="row"
        spacing={1.5}
        sx={{ height: '100%' }}
      >
        <ContentImage
          blobs={product.blobs}
          externalImage={getFirstExternalImageUrl(product.externalMedia)}
          imageSx={{ maxHeight: theme.spacing(8) }}
          size={hidSpacing(6)}
          sx={{ marginRight: theme.spacing(1.5) }}
        />
        <Stack justifyContent="space-between" sx={{ minWidth: 0 }}>
          <Typography noWrap variant="subtitle1">
            {product.name}
          </Typography>
          {product.createdAt !== undefined && (
            <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
              {formatDate(new Date(product.createdAt), DateTimeFormats.DATE_ONLY)}
            </Typography>
          )}
        </Stack>
        {Boolean(onClick) && (
          <ChevronRight style={{ marginLeft: 'auto' }} />
        )}
      </Stack>
    </Container>
  );
};

export default ProductWidgetItem;
