import { useTranslation } from 'react-i18next';
import {
  Add,
  ChangeCircleOutlined,
  DeleteOutline,
  Edit,
  ExitToApp,
  VerifiedUserOutlined,
} from '@mui/icons-material';

import useDialog from '../../../hooks/useDialog';
import DialogNames from '../../../hooks/useDialog/DialogNames';
import { HIDListItemProps } from '../../../types/common';
import { useSetUserSettingsMutation } from '../../Auth/api/settings.api';
import { useGetPropertiesQuery } from '../api/property.api';
import useGetCurrentPropertyId from './useGetCurrentPropertyId';
import useGetIsCurrentUserPropertyOwner from './useGetIsCurrentUserPropertyOwner';
import useGetPropertyVerify from './useGetPropertyVerify';
import useTryUseFeatureDialog from '../../SubscriptionPlans/hooks/useTryUseFeatureDialog';
import { SubscriptionFeature } from '../../SubscriptionPlans/subscriptionPlans.types';
import AddUser from '../../../components/icons/AddUser';
import HomeIcon from '../../../components/icons/Home';
import useGetIsSfSellerSellingProperty from './useGetIsSfSellerSellingProperty';

type UseGetPropertyActions = (params: { propertyId?: string, excludeActions: Array<PropertyActionId> }) => Array<HIDListItemProps>;

export enum PropertyActionId {
  ADD_PROPERTY = 'add_property',
  VERIFY_PROPERTY = 'verify_property',
  SET_CURRENT_PROPERTY = 'set_current_property',
  SET_PROPERTY_STAGE = 'set_property_stage',
  INVITE_USER = 'invite_user',
  EDIT_DISPLAY_NAME = 'edit_display_name',
  REMOVE_PROPERTY = 'remove_property',
  LEAVE_PROPERTY = 'leave_property',
}

export const useGetPropertyActions: UseGetPropertyActions = ({ propertyId, excludeActions }) => {
  const { t } = useTranslation(['property']);

  const { data: properties } = useGetPropertiesQuery();
  const property = properties?.find((p) => p.id === propertyId);

  const { data: currentPropertyId } = useGetCurrentPropertyId();

  const isCurrentUserOwner = useGetIsCurrentUserPropertyOwner({ propertyId });
  const [setUserSettings] = useSetUserSettingsMutation();
  const { verify, canVerify } = useGetPropertyVerify({ propertyId });

  const { data: isSellingProperty } = useGetIsSfSellerSellingProperty();

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);
  const [openChangePropertyDisplayNameDialog] = useDialog(DialogNames.CHANGE_PROPERTY_DISPLAY_NAME);
  const [openDeletePropertyDialog] = useDialog(DialogNames.DELETE_PROPERTY_DIALOG);
  const [openLeavePropertyDialog] = useDialog(DialogNames.LEAVE_PROPERTY_DIALOg);
  const [openInviteUserDialog] = useDialog(DialogNames.INVITE_USER_DIALOG);
  const [openSetPropertyStageDialog] = useDialog(DialogNames.SET_PROPERTY_STAGE_DIALOG);

  const [
    proceedToFeatureOrOpenSubscriptionDialog,
    subscriptionFeaturesIsLoading,
  ] = useTryUseFeatureDialog({ subscriptionFeature: SubscriptionFeature.MULTIPLE_PROPERTIES });

  const handleAdd = () => proceedToFeatureOrOpenSubscriptionDialog({
    onAction: () => openMobileAppDialog(),
  });
  const handleEdit = () => openChangePropertyDisplayNameDialog({ propertyId });

  const handleRemove = () => openDeletePropertyDialog({ propertyId });

  const handleLeave = () => openLeavePropertyDialog({ propertyId });

  const handleSetCurrentProperty = () => setUserSettings({ currentPropertyId: propertyId });

  const handleInviteUser = () => openInviteUserDialog({ propertyId });
  const handleVerifyProperty = verify;

  const propertyActions: Array<HIDListItemProps> = [
    {
      id: PropertyActionId.ADD_PROPERTY,
      label: t('property:add_property'),
      Icon: Add,
      disabled: subscriptionFeaturesIsLoading,
      onClick: handleAdd,
    },
    isCurrentUserOwner && canVerify && {
      id: PropertyActionId.VERIFY_PROPERTY,
      label: t('property:verify_property'),
      Icon: VerifiedUserOutlined,
      onClick: handleVerifyProperty,
    },
    currentPropertyId === property?.id && !isSellingProperty && {
      id: PropertyActionId.SET_PROPERTY_STAGE,
      label: t('property:manage_property_users_set_property_stage_name'),
      Icon: HomeIcon,
      onClick: openSetPropertyStageDialog,
    },
    currentPropertyId !== property?.id && {
      id: PropertyActionId.SET_CURRENT_PROPERTY,
      label: t('property:switch_to_property'),
      Icon: ChangeCircleOutlined,
      onClick: handleSetCurrentProperty,
    },
    isCurrentUserOwner && {
      id: PropertyActionId.INVITE_USER,
      label: t('property:invite_user'),
      Icon: AddUser,
      onClick: handleInviteUser,
    },
    isCurrentUserOwner && {
      id: PropertyActionId.EDIT_DISPLAY_NAME,
      label: t('property:edit_to_property_name'),
      Icon: Edit,
      onClick: handleEdit,
    },
    isCurrentUserOwner && {
      id: PropertyActionId.REMOVE_PROPERTY,
      label: t('property:remove_property'),
      Icon: DeleteOutline,
      onClick: handleRemove,
    },
    !isCurrentUserOwner && {
      id: PropertyActionId.LEAVE_PROPERTY,
      label: t('property:leave_property'),
      Icon: ExitToApp,
      onClick: handleLeave,
    },
  ]
    .filter(Boolean)
    .filter((item) => !excludeActions.includes(item.id));

  return propertyActions;
};
