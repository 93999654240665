import { useMemo } from 'react';
import * as R from 'ramda';
import { skipToken } from '@reduxjs/toolkit/query';
import { Property } from '@house-id/houseid-types/dist/property';

import { useGetPropertiesQuery } from '../api/property.api';
import {
  QueryHook,
} from '../../../types/common';
import { getActiveAuth } from '../../../external-services/firebase';
import { useGetUserSettingsQuery } from '../../Auth/api/settings.api';

const useGetCurrentProperty: QueryHook<Property | undefined> = (args, options) => {
  const auth = getActiveAuth();

  const {
    data: properties = [],
    error: propertiesError,
    isLoading: isPropertiesLoading,
    isSuccess: isPropertiesSuccess,
    isError: isPropertiesError,
    isUninitialized: isPropertiesUninitialized,
    isFetching: isPropertiesFetching,
    refetch: refetchProperties,
  } = useGetPropertiesQuery(args, options);

  const {
    data: userSettings,
    isSuccess: isUserSettingsSuccess,
    isError: isUserSettingsError,
    isUninitialized: isUserSettingsUninitialized,
  } = useGetUserSettingsQuery(
    auth.currentUser?.uid && !options?.skip
      ? { userUId: auth.currentUser.uid }
      : skipToken,
  );

  const savedCurrentPropertyId = userSettings?.currentPropertyId;

  const currentProperty = useMemo(() => {
    if (isUserSettingsSuccess || isUserSettingsError) {
      if (savedCurrentPropertyId && properties) {
        return R.head(
          R.filter(
            (property: Property) => property.id === savedCurrentPropertyId,
            properties,
          ),
        ) || R.head(properties);
      }
      return R.head(properties);
    }
    return undefined;
  }, [savedCurrentPropertyId, properties, isUserSettingsSuccess, isUserSettingsError]);

  const handleRefetch = () => refetchProperties();

  return {
    data: currentProperty,
    error: propertiesError,
    isLoading: isPropertiesLoading,
    isSuccess: isPropertiesSuccess && isUserSettingsSuccess,
    isError: isPropertiesError || isUserSettingsError,
    isUninitialized: isPropertiesUninitialized || isUserSettingsUninitialized,
    isFetching: isPropertiesFetching,
    refetch: handleRefetch,
  };
};

export default useGetCurrentProperty;
