import React, {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Card,
  Dialog,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import {
  ArrowBack,
  ArrowForward,
  Check,
  DeleteOutline,
} from '@mui/icons-material';
import {
  FormikErrors,
  FormikProps,
  FormikTouched,
  useFormik,
} from 'formik';
import * as Yup from 'yup';
import { skipToken } from '@reduxjs/toolkit/query';
import { ModifyActionType } from '@house-id/houseid-types/dist/common';

import {
  HIDDialogProps,
} from '../../../../../../../../../types/common';
import useBreakPointsSizes from '../../../../../../../../../hooks/useBreakpointsSizes';
import HIDButton from '../../../../../../../../../components/buttons/HIDButton';
import HIDFormDatePicker from '../../../../../../../../../components/datePicker/HIDFormDatePicker';
import HIDYesNoGroup from '../../../../../../../../../components/HIDYesNoGroup';
import HIDIconButton from '../../../../../../../../../components/buttons/HIDIconButton';
import HIDTextField from '../../../../../../../../../components/HIDTextField';
import {
  GetStartedWizardData,
  Upkeep,
  UpkeepModify,
} from '../../../types.timeline';
import HIDAddLinkButton from '../../../../../../../../../components/HIDAddLinkButton';
import { removeByIndex } from '../../../../../../../../../utils/array';
import { getHandleSetField } from '../../../../../../../../../utils/form';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import {
  useGetGetStartedWizardDataQuery,
  useUpdateGetStartedWizardDataMutation,
} from '../../../api/timeline.api';
import { GET_STARTED_WIZARD_MAX_STEP } from '../../../constants.timeline';
import { DateTimeFormats } from '../../../../../../../../../utils/date';
import HIDDialogTitle from '../../../../../../../../../components/dialogs/HIDDialogTitle';
import HIDDialogContent from '../../../../../../../../../components/dialogs/HIDDialogContent';
import HIDDialogActions from '../../../../../../../../../components/dialogs/HIDDialogActions';

const isNotEmpty = R.compose(R.not, R.isEmpty);

const intersectionById = (a: Array<Upkeep>, b: Array<Partial<Upkeep>>) => {
  const bIds = b.filter((bItem) => bItem.id !== undefined).map((bItem) => bItem.id) as Array<string>;
  return a.filter((aItem) => bIds.includes(aItem.id));
};

const getUpkeepChanges = (prevUpkeep: Array<Upkeep>, upkeep: Array<Upkeep>): Array<UpkeepModify> => [
  ...R.difference(prevUpkeep, upkeep)
    .map((upkeep) => ({ ...upkeep, action: ModifyActionType.DELETE })),
  ...R.difference(upkeep, prevUpkeep)
    .map((upkeep) => ({ ...upkeep, action: ModifyActionType.CREATE })),
  ...intersectionById(upkeep, prevUpkeep)
    .map((upkeep) => ({ ...upkeep, action: ModifyActionType.UPDATE })),
];

type GetStartedWizardDataFormProps = GetStartedWizardData;

type RenovationFormProps = {
  index: number;
  formik: FormikProps<GetStartedWizardDataFormProps>,
  propName: 'renovations' | 'maintenance';
  title: string;
  touched?: boolean;
  annotationTile: string;
  canDelete: boolean;
  onDelete: () => void;
};

const UpkeepForm: FC<RenovationFormProps> = ({
  index,
  formik,
  propName,
  title,
  touched = false,
  annotationTile,
  canDelete,
  onDelete,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'forms_common', 'timeline']);

  const upkeepItem = formik.values[propName]?.[index];
  const formikErrors = formik.errors[propName] as unknown as FormikErrors<Array<Upkeep>>;
  const formikError = formikErrors?.[index];
  const formikTouchedItems = formik.touched[propName] as unknown as FormikTouched<Array<Upkeep>>;
  const formikTouched = formikTouchedItems?.[index];

  const handleSetField = getHandleSetField<GetStartedWizardDataFormProps>(formik);

  return (
    <Card
      sx={{
        padding: 2,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
      }}
    >
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="subtitle1">{`${title} ${index + 1}`}</Typography>
          {canDelete && (
            <HIDIconButton Icon={DeleteOutline} color="blank" onClick={onDelete} />
          )}
        </Stack>
        <Grid container spacing={2} sx={{ marginTop: 0 }}>
          <Grid item sm={7} xxs={12}>
            <HIDTextField
              required
              error={Boolean((formikTouched?.title || touched) && formikError?.title)}
              helperText={(formikTouched?.title || touched) ? formikError?.title : undefined}
              label={t('timeline:wizard_subject')}
              value={upkeepItem?.title}
              onChange={handleSetField(`${propName}.${index}.title`)}
            />
          </Grid>
          <Grid item sm={5} xxs={12}>
            <HIDFormDatePicker
              required
              error={Boolean((formikTouched?.date || touched) && formikError?.date)}
              helperText={(formikTouched?.date || touched) ? formikError?.date : undefined}
              label={t('forms_common:enter_date')}
              value={upkeepItem?.date ? new Date(upkeepItem.date) : undefined}
              onChange={(date) => formik.setFieldValue(`${propName}.${index}.date`, date?.toISOString())}
            />
          </Grid>
          <Grid item xxs={12}>
            <Typography sx={{ marginBottom: 2 }} variant="subtitle1">{annotationTile}</Typography>
            <HIDTextField
              multiline
              label={t('common:description')}
              value={upkeepItem?.annotation}
              variant="outlined"
              onChange={handleSetField(`${propName}.${index}.annotation`)}
            />
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
};

type GetStartedWizardProps = HIDDialogProps;

// eslint-disable-next-line react/no-multi-comp
const GetStartedWizard: FC<GetStartedWizardProps> = ({
  open,
  onClose,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'forms_common', 'timeline']);

  const { isDownSm } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: initialData } = useGetGetStartedWizardDataQuery(propertyId ? { propertyId } : skipToken);

  const [updateGetStartedWizardData, { isLoading: updating }] = useUpdateGetStartedWizardDataMutation();

  const [step, setStep] = useState(1);

  useEffect(() => {
    if (initialData?.activeStep !== undefined
      && initialData.activeStep !== step
      && initialData?.activeStep > 0
      && initialData?.activeStep <= GET_STARTED_WIZARD_MAX_STEP
    ) {
      setStep(initialData.activeStep);
    }
  }, [initialData?.activeStep]);

  const steps = R.range(1, GET_STARTED_WIZARD_MAX_STEP + 1);

  const [renovationStepTouched, setRenovationStepTouched] = useState(false);
  const [maintenanceStepTouched, setMaintenanceStepTouched] = useState(false);

  const upkeepSchema = Yup.object<Upkeep>({
    title: Yup.string().required(t('forms_common:field_mandatory')),
    date: Yup.string().required(t('forms_common:field_mandatory')),
    annotation: Yup.string().optional().nullable(),
  });

  const schema = Yup.object({
    constructionYear: Yup.number().optional().nullable(),
    completionDate: Yup.string().optional().nullable(),
    purchaseDate: Yup.string().optional().nullable(),
    accessDate: Yup.string().optional().nullable(),
    moveInDate: Yup.string().optional().nullable(),
    hasDoneRenovations: Yup.boolean().required(),
    renovations: Yup.array<Upkeep>().of(upkeepSchema).optional().nullable(),
    hasDoneMaintenance: Yup.boolean().required(),
    maintenance: Yup.array<Upkeep>().of(upkeepSchema).optional().nullable(),
    status: Yup.string().required(),
  });

  const formik = useFormik<GetStartedWizardDataFormProps>({
    initialValues: {
      constructionYear: initialData?.constructionYear,
      completionDate: initialData?.completionDate || '',
      purchaseDate: initialData?.purchaseDate || '',
      accessDate: initialData?.accessDate || '',
      moveInDate: initialData?.moveInDate || '',
      hasDoneRenovations: initialData?.hasDoneRenovations !== false,
      renovations: initialData?.renovations?.length
        ? initialData.renovations
        : (initialData?.hasDoneRenovations !== false ? [{} as UpkeepModify] : []),
      hasDoneMaintenance: initialData?.hasDoneMaintenance !== false,
      maintenance: initialData?.maintenance?.length
        ? initialData.maintenance
        : (initialData?.hasDoneMaintenance !== false ? [{} as UpkeepModify] : []),
      status: initialData?.status || 'not-started',
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: () => { },
  });

  const renovations = formik.values.renovations || [];
  const maintenance = formik.values.maintenance || [];

  const Step1 = (
    <>
      <Stack spacing={1}>
        <Typography variant="h5">
          {t('timeline:wizard_what_year_was_the_home_built')}
        </Typography>
        <HIDFormDatePicker
          dateTimeFormat={DateTimeFormats.YEAR_ONLY}
          key={step}
          label={t('forms_common:enter_year')}
          value={formik.values.constructionYear ? new Date(formik.values.constructionYear, 0, 1) : undefined}
          views={['year']}
          onBlur={formik.handleBlur('constructionYear')}
          onChange={(date) => formik.setFieldValue('constructionYear', date?.getFullYear())}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h5">
          {t('timeline:wizard_what_date_was_the_home_completed')}
        </Typography>
        <HIDFormDatePicker
          label={t('forms_common:enter_date')}
          value={formik.values.completionDate ? new Date(formik.values.completionDate) : undefined}
          onBlur={formik.handleBlur('completionDate')}
          onChange={(date) => formik.setFieldValue('completionDate', date?.toISOString())}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h5">
          {t('timeline:wizard_when_was_the_home_bought')}
        </Typography>
        <HIDFormDatePicker
          label={t('forms_common:enter_date')}
          value={formik.values.purchaseDate ? new Date(formik.values.purchaseDate) : undefined}
          onBlur={formik.handleBlur('purchaseDate')}
          onChange={(date) => formik.setFieldValue('purchaseDate', date?.toISOString())}
        />
      </Stack>
    </>
  );

  const Step2 = (
    <>
      <Stack spacing={1}>
        <Typography variant="h5">
          {t('timeline:wizard_when_was_your_accession_date')}
        </Typography>
        <HIDFormDatePicker
          label={t('forms_common:enter_date')}
          value={formik.values.accessDate ? new Date(formik.values.accessDate) : undefined}
          onBlur={formik.handleBlur('accessDate')}
          onChange={(date) => formik.setFieldValue('accessDate', date?.toISOString())}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h5">
          {t('timeline:wizard_when_did_you_move_in')}
        </Typography>
        <HIDFormDatePicker
          label={t('forms_common:enter_date')}
          value={formik.values.moveInDate ? new Date(formik.values.moveInDate) : undefined}
          onBlur={formik.handleBlur('moveInDate')}
          onChange={(date) => formik.setFieldValue('moveInDate', date?.toISOString())}
        />
      </Stack>
    </>
  );

  const Step3 = (
    <Stack spacing={2}>
      <Typography variant="h5">
        {t('timeline:wizard_have_you_done_any_renovations')}
      </Typography>
      <Typography variant="body1">
        {t('timeline:wizard_renovation_example')}
      </Typography>
      <HIDYesNoGroup
        noLabel={t('timeline:wizard_no_i_have_not_renovated_the_home')}
        value={formik.values.hasDoneRenovations}
        yesLabel={t('timeline:wizard_yes_i_have_renovated_the_home')}
        onChange={(newValue) => {
          formik.setFieldValue('hasDoneRenovations', newValue);
          formik.setFieldValue('renovations', newValue ? [{}] : []);
        }}
      />
      {formik.values.hasDoneRenovations && (
        <Typography variant="body1">
          {t('timeline:wizard_describe_them_briefly_and_you_can_gradually_add_more')}
        </Typography>
      )}
      {renovations.map((_renovation, index) => (
        <UpkeepForm
          annotationTile={t('timeline:wizard_describe_renovation')}
          canDelete={renovations.length > 1}
          formik={formik}
          index={index}
          key={index}
          propName="renovations"
          title={t('timeline:wizard_renovation')}
          touched={renovationStepTouched}
          onDelete={() => formik.setFieldValue('renovations', removeByIndex(index, renovations))}
        />
      ))}
      {formik.values.hasDoneRenovations && (
        <HIDAddLinkButton
          label={t('timeline:wizard_add_more_renovation')}
          sx={{ alignSelf: 'flex-end' }}
          onClick={() => formik.setFieldValue('renovations', [...renovations, {}])}
        />
      )}
    </Stack>
  );

  const Step4 = (
    <Stack spacing={2}>
      <Typography variant="h5">
        {t('timeline:wizard_have_you_done_any_maintenance')}
      </Typography>
      <Typography variant="body1">
        {t('timeline:wizard_maintenance_example')}
      </Typography>
      <HIDYesNoGroup
        noLabel={t('timeline:wizard_no_i_have_not_done_maintenance_on_the_home')}
        value={formik.values.hasDoneMaintenance}
        yesLabel={t('timeline:wizard_yes_i_have_done_maintenance_on_the_home')}
        onChange={(newValue) => {
          formik.setFieldValue('hasDoneMaintenance', newValue);
          formik.setFieldValue('maintenance', newValue ? [{}] : []);
        }}
      />
      {formik.values.hasDoneMaintenance && (
        <Typography variant="body1">
          {t('timeline:wizard_describe_them_briefly_and_you_can_gradually_add_more')}
        </Typography>
      )}
      {maintenance.map((_maintenanceItem, index) => (
        <UpkeepForm
          annotationTile={t('timeline:wizard_describe_maintenance')}
          canDelete={maintenance.length > 1}
          formik={formik}
          index={index}
          key={index}
          propName="maintenance"
          title={t('timeline:wizard_maintenance')}
          touched={maintenanceStepTouched}
          onDelete={() => formik.setFieldValue('maintenance', removeByIndex(index, maintenance))}
        />
      ))}
      {formik.values.hasDoneMaintenance && (
        <HIDAddLinkButton
          label={t('timeline:wizard_add_more_maintenance')}
          sx={{ alignSelf: 'flex-end' }}
          onClick={() => formik.setFieldValue('maintenance', [...maintenance, {}])}
        />
      )}
    </Stack>
  );

  const validateUpkeep = (upkeep: UpkeepModify) => {
    try {
      // NOTE: validateSync will throw Error if validation failing
      return upkeepSchema.validateSync(upkeep);
    } catch (error) {
      return false;
    }
  };

  const stepsMap: Record<number, { name: string, content: React.JSX.Element, onNext?: () => boolean }> = {
    1: {
      name: t('timeline:wizard_acquisition'),
      content: Step1,
    },
    2: {
      name: t('timeline:wizard_moving_in'),
      content: Step2,
    },
    3: {
      name: t('timeline:wizard_renovation'),
      content: Step3,
      onNext: () => {
        setRenovationStepTouched(true);
        return !formik.values.hasDoneRenovations
          || (renovations.length > 0 && renovations.every((upkeep) => validateUpkeep(upkeep)));
      },
    },
    4: {
      name: t('timeline:wizard_maintenance'),
      content: Step4,
      onNext: () => {
        setMaintenanceStepTouched(true);
        return !formik.values.hasDoneMaintenance
          || (maintenance.length > 0 && maintenance.every((upkeep) => validateUpkeep(upkeep)));
      },
    },
  };

  const handleUpdateData = (nextActiveStep: number) => {
    if (!propertyId) {
      return Promise.resolve();
    }

    const renovations = R.filter(isNotEmpty, formik.values.renovations || []);
    const maintenance = R.filter(isNotEmpty, formik.values.maintenance || []);

    return updateGetStartedWizardData({
      propertyId,
      purchaseDate: formik.values.purchaseDate,
      constructionYear: formik.values.constructionYear,
      completionDate: formik.values.completionDate,
      accessDate: formik.values.accessDate,
      moveInDate: formik.values.moveInDate,
      hasDoneRenovations: formik.values.hasDoneRenovations,
      renovations: getUpkeepChanges(initialData?.renovations || [], renovations),
      hasDoneMaintenance: formik.values.hasDoneMaintenance,
      maintenance: getUpkeepChanges(initialData?.maintenance || [], maintenance),
      status: nextActiveStep > GET_STARTED_WIZARD_MAX_STEP ? 'finished' : 'started',
      activeStep: Math.min(nextActiveStep, GET_STARTED_WIZARD_MAX_STEP),
    });
  };

  const handleClose = () => onClose();

  const directionRef = useRef<'forward' | 'backward'>('forward');

  const handleGoBack = (currentStep: number) => {
    directionRef.current = 'backward';
    handleUpdateData(currentStep - 1)
      .then(() => setStep((step) => step - 1));
  };

  const handleGoForward = (currentStep: number) => {
    directionRef.current = 'forward';

    formik.submitForm()
      .then(() => {
        const handleCompleteStep = stepsMap[currentStep].onNext;
        return !handleCompleteStep || handleCompleteStep();
      })
      .then(
        (canGoNext) => canGoNext
          ? handleUpdateData(currentStep + 1).then(() => canGoNext)
          : canGoNext,
      )
      .then((canGoNext) => {
        if (canGoNext) {
          if (currentStep === GET_STARTED_WIZARD_MAX_STEP) {
            formik.setFieldValue('status', 'finished');
          } else {
            formik.setFieldValue('status', 'started');
            setStep((step) => step + 1);
          }
        }
      });
  };

  const isFinished = formik.values.status === 'finished';

  return (
    <Dialog
      fullWidth
      fullScreen={isDownSm}
      maxWidth={false}
      open={open}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '600px',
          },
        },
      }}
      onClose={handleClose}
    >
      <HIDDialogTitle
        showBorder={!isFinished}
        onClose={handleClose}
      >
        {!isFinished && (
          <Stepper
            alternativeLabel
            activeStep={step - 1}
            sx={{ paddingBottom: 1 }}
          >
            {steps.map((stepNumber) => (
              <Step key={stepNumber}>
                <StepLabel>{stepsMap[stepNumber].name}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      </HIDDialogTitle>
      <HIDDialogContent style={{ paddingTop: theme.spacing(isFinished ? 3 : 2) }}>
        {
          isFinished
            ? (
              <Stack
                alignItems="center"
                justifyContent="center"
                spacing={3}
              >
                <Stack
                  alignSelf="center"
                  sx={{
                    padding: 3,
                    borderRadius: theme.spacing(5),
                    backgroundColor: theme.palette.primary.lighter,
                  }}
                >
                  <Check
                    sx={{
                      color: theme.palette.primary.main,
                      width: theme.spacing(4),
                      height: theme.spacing(4),
                    }}
                  />
                </Stack>
                <Typography variant="h5">
                  {t('common:good_job')}
                </Typography>
                <Typography sx={{ textAlign: 'center' }} variant="body1">
                  {t('timeline:wizard_get_started_finished_description')}
                </Typography>
              </Stack>
            )
            : (
              <Stack alignItems="center" justifyContent="center" spacing={2}>
                <Stack spacing={3} sx={{ width: '100%' }}>
                  {stepsMap[step].content}
                </Stack>
              </Stack>
            )
        }
      </HIDDialogContent>
      <HIDDialogActions>
        {isFinished
          ? (
            <HIDButton onClick={handleClose}>
              {t('timeline:wizard_show_timeline')}
            </HIDButton>
          )
          : (
            <>
              {step > 1 && (
                <HIDButton
                  Icon={ArrowBack}
                  color="secondary"
                  disabled={updating}
                  iconPosition="left"
                  loading={updating && directionRef.current === 'backward'}
                  onClick={() => handleGoBack(step)}
                >
                  {t('common:back')}
                </HIDButton>
              )}
              <HIDButton
                Icon={step < GET_STARTED_WIZARD_MAX_STEP ? ArrowForward : undefined}
                color="primary"
                disabled={updating}
                loading={updating && directionRef.current === 'forward'}
                sx={{ minWidth: 125 }}
                onClick={() => handleGoForward(step)}
              >
                {step < GET_STARTED_WIZARD_MAX_STEP ? t('common:next') : t('common:done')}
              </HIDButton>
            </>
          )}
      </HIDDialogActions>
    </Dialog>
  );
};

export default GetStartedWizard;
