import React, { FC } from 'react';

import { usePatchPropertyProgressInsuranceMutation } from '../../../api/propertyProgress.api';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import PropertyProgressDynamicContentSection from '../../../components/PropertyProgressDynamicContentSection';
import {
  PropertyProgressCategorySectionProps,
  PropertySecuritySectionData,
} from '../../../types.propertyProgress';

const PropertySecuritySection: FC<PropertyProgressCategorySectionProps<PropertySecuritySectionData>> = ({
  section,
}) => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const [patchPropertyProgressInsurance, { isLoading: isPropertyProgressInsurancePatching }] = usePatchPropertyProgressInsuranceMutation();

  const handleUpdate = (difference: Record<string, unknown>) => {
    if (propertyId) {
      patchPropertyProgressInsurance({ propertyId, data: difference });
    }
  };

  return (
    <PropertyProgressDynamicContentSection
      key={section.id}
      loading={isPropertyProgressInsurancePatching}
      section={section}
      onUpdate={handleUpdate}
    />
  );
};

export default PropertySecuritySection;
