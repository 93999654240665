import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Typography,
  useTheme,
  Stack,
} from '@mui/material';
import {
  useNavigate,
} from 'react-router-dom';
import {
  AuthError,
  AuthErrorCodes,
  MultiFactorError,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  getMultiFactorResolver,
} from 'firebase/auth';
import * as R from 'ramda';

import {
  useAuthState,
  useSignOut,
} from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';

import { getActiveAuth } from '../../../external-services/firebase';
import LoginLayout from './LoginLayout';
import HIDButton from '../../../components/buttons/HIDButton';
import loginImageUrl from '../../../assets/images/login.jpg';
import ErrorBox from '../components/ErrorBox';
import useGetAuthErrorMessage from '../hooks/useGetAuthErrorMessage';
import { hasTokenUserIdClaim } from '../utils/token';
import { useLocationState } from '../../../utils/routes';
import { InitAppRouteState } from '../types/auth.types';
import {
  getInitAppPath,
  getLoginPath,
} from '../navigation/navigation.auth';
import { useIntroductoryParams } from '../hooks/useIntroductoryRouteParams';
import ConfirmCodeInput from '../components/ConfirmCodeInput';
import ResendCodeLink, { ResendCodeLinkRef } from '../components/ResendCodeLink';
import { VERIFICATION_CODE_LENGTH } from '../constants.auth';

const RECAPTCHA_CONTAINER_ID = 'recaptcha-container-id';

const LoginSecondFactor = () => {
  const navigate = useNavigate();
  const locationState = useLocationState<InitAppRouteState>();
  useIntroductoryParams();

  const theme = useTheme();
  const { t } = useTranslation(['auth', 'forms_common']);

  const { skipOrigin } = useLocationState<{ skipOrigin?: boolean }>();

  const { error: jsonError } = useLocationState<{ error: string }>();
  const error = jsonError ? JSON.parse(jsonError) as MultiFactorError : undefined;

  useEffect(() => {
    if (!error) {
      navigate(getLoginPath());
    }
  }, [error]);

  const [isSendingSMS, setIsSendingSMS] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);

  const auth = getActiveAuth();
  const [currentUser] = useAuthState(auth);
  const [signOut] = useSignOut(auth);

  const [authError, setAuthError] = useState<AuthError | undefined>();

  const [userNotExistError, setUserNotExistError] = useState<AuthError | undefined>();
  const [verificationId, setVerificationId] = useState<string | undefined>();
  const [verificationCode, setVerificationCode] = useState<string | undefined>();

  const resendCodeLinkRef = useRef<ResendCodeLinkRef>();

  const handleVerificationCodeChange = (newVerificationCode?: string) => {
    setVerificationCode(newVerificationCode);
    setAuthError(undefined);
  };

  const handleRequestVerificationCode = () => {
    setAuthError(undefined);
    setVerificationCode(undefined);
    setIsSendingSMS(true);

    if (!error) {
      return;
    }

    // Just in case recaptcha was rendered already
    const recaptchaContainer = document.querySelector(`#${RECAPTCHA_CONTAINER_ID}`);
    if (recaptchaContainer && recaptchaContainer.innerHTML) {
      recaptchaContainer.innerHTML = '';
    }

    const recaptchaVerifier = new RecaptchaVerifier(auth, RECAPTCHA_CONTAINER_ID);

    const resolver = getMultiFactorResolver(auth, error);

    const phoneHint = resolver.hints.find((hint) => hint.factorId === PhoneMultiFactorGenerator.FACTOR_ID);

    const phoneInfoOptions = {
      multiFactorHint: phoneHint,
      session: resolver.session,
    };

    const phoneAuthProvider = new PhoneAuthProvider(auth);

    // Send SMS verification code
    return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
      .then((newVerificationId) => {
        setVerificationId(newVerificationId);
        resendCodeLinkRef.current?.reset();
        recaptchaVerifier.clear();
      })
      .catch((error) => {
        setAuthError(error as AuthError);
      })
      .finally(() => setIsSendingSMS(false));
  };

  useEffect(() => {
    try {
      handleRequestVerificationCode();
    } catch (error) {
      navigate(getLoginPath());
    }
  }, []);

  const handleLoginWithSecondFactor = (verificationId?: string, verificationCode?: string) => {
    if (!verificationId || !verificationCode || !error) {
      return;
    }

    setAuthError(undefined);
    setIsSigningIn(true);

    const resolver = getMultiFactorResolver(auth, error);

    const credentials = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credentials);

    // Complete sign-in.
    resolver.resolveSignIn(multiFactorAssertion)
      .catch((error) => {
        setAuthError(error as AuthError);
      })
      .finally(() => {
        setIsSigningIn(false);
      });
  };

  const authErrorMessage = useGetAuthErrorMessage(authError || userNotExistError);

  useEffect(() => {
    if (R.isNotNil(currentUser)) {
      currentUser.getIdToken()
        .then((token) => {
          if (hasTokenUserIdClaim(token)) {
            navigate(getInitAppPath(), {
              replace: true,
              state: {
                propertyId: locationState?.propertyId,
                from: locationState?.from && !skipOrigin
                  ? { pathname: locationState?.from?.pathname, search: locationState?.from?.search }
                  : undefined,
              },
            });
          } else {
            setUserNotExistError({ code: AuthErrorCodes.INVALID_EMAIL } as AuthError);
            signOut();
          }
        })
        .catch(() => {
          // TODO: Add error reporting
        });
    }
  }, [currentUser]);

  useEffect(() => {
    if (verificationCode && verificationCode.length === VERIFICATION_CODE_LENGTH) {
      // auto trigger sign in after 6 digit is entered
      handleLoginWithSecondFactor(verificationId, verificationCode);
    }
  }, [verificationCode]);

  return (
    <LoginLayout
      canGoBack
      RightComponent={
        <Stack spacing={2}>
          <Typography
            gutterBottom
            fontWeight="bold"
            style={{ marginBottom: theme.spacing(2) }}
            variant="h3"
          >
            {t('auth:login_second_factor_title')}
          </Typography>
          <Typography
            style={{ whiteSpace: 'pre-wrap' }}
            variant="body1"
          >
            {t('auth:login_second_factor_description')}
          </Typography>
          {authErrorMessage && (
            <ErrorBox
              message={authErrorMessage}
            />
          )}
          <Stack alignItems="center" id={RECAPTCHA_CONTAINER_ID} />
          {verificationId && (
            <ConfirmCodeInput
              isDisabled={isSigningIn}
              length={VERIFICATION_CODE_LENGTH}
              value={verificationCode}
              onChange={handleVerificationCodeChange}
            />
          )}
          <ResendCodeLink
            isDisabled={isSigningIn || isSendingSMS}
            isVisible={Boolean(verificationId)}
            ref={resendCodeLinkRef}
            onClick={() => handleRequestVerificationCode()}
          />
          <HIDButton
            fullWidth
            disabled={isSigningIn}
            size="large"
            onClick={() => handleLoginWithSecondFactor(verificationId, verificationCode)}
          >
            {t('auth:login_login_button')}
          </HIDButton>
        </Stack>
      }
      backgroundImageUrl={loginImageUrl}
      message={t('auth:login_message')}
      onArrowBackClick={() => navigate(getLoginPath())}
    />
  );
};

export default LoginSecondFactor;
