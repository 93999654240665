import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RecurringExpense } from '@house-id/houseid-types/dist/finances/recurringExpenses';

import HIDTypography from '../../../../../../../components/HIDTypography';
import { EMPTY_VALUE } from '../../../../../../../utils/string';
import BasicInfoSection, { BasicInfoItem } from '../../../../Content/components/sections/BasicInfoSection';
import useGetEnum from '../../../../../../../hooks/useGetEnum';
import { EnumType } from '../../../../../../../types/common';

type ExpenseDetailedInformationSectionProps = {
  expense: RecurringExpense;
};

const ExpenseDetailedInformationSection: FC<ExpenseDetailedInformationSectionProps> = ({
  expense,
}) => {
  const { t } = useTranslation(['common', 'finances']);

  const { data: expensePaymentCategory } = useGetEnum(EnumType.ExpensePaymentCategory);
  const { data: expensePaymentInterval } = useGetEnum(EnumType.ExpensePaymentInterval);

  const categoryName = expensePaymentCategory?.[expense.category];
  const intervalName = expensePaymentInterval?.[expense.interval];

  const fields = [
    { label: t('finances:name_of_cost'), value: expense.label || EMPTY_VALUE },
    { label: t('finances:cost_range'), value: intervalName },
    { label: t('common:category'), value: categoryName },
  ].filter(Boolean) as Array<BasicInfoItem>;

  return (
    <Stack>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <HIDTypography variant="h6">
          {t('finances:detailed_information')}
        </HIDTypography>
      </Stack>
      <BasicInfoSection items={fields} />
    </Stack>
  );
};

export default ExpenseDetailedInformationSection;
