import {
  Card,
  useTheme,
  Skeleton,
  Stack,
} from '@mui/material';
import React from 'react';
import { getTypographyHeight } from '../../utils/style';
import { FCC } from '../../types/common';

const PieChartIcon = () => (
  <svg
    fill="currentColor"
    style={{
      width: '100%',
      visibility: 'visible',
    }}
    viewBox="0 0 100 100"
  >
    <path d="M85.829 15.229 66.521 31.98a24.335 24.335 0 0 0-7.437-4.724L67.643 3.24a49.571 49.571 0 0 1 18.186 11.989ZM33.884 31.457c-9.526 8.264-11.123 22.271-4.129 32.421L10.448 80.63C-5.687 59.813-2.907 29.651 17.216 12.192 30.201.927 47.384-2.43 62.853 1.699l-8.61 24.161c-7.028-1.263-14.542.549-20.359 5.597Zm-.298 65.787c-7.877-2.73-14.015-6.63-19.582-12.491l19.315-16.759c9.035 8.37 23.106 8.782 32.63.519a24.404 24.404 0 0 0 6.065-7.988l24.144 8.37c-9.937 24.401-37.412 37.072-62.572 28.349ZM70.082 36.1l19.314-16.757c10.034 12.834 13.097 28.755 8.547 44.404l-24.168-8.375c1.457-6.498.279-13.506-3.693-19.272Z" />
  </svg>
);

type PieChartLoaderSkeletonProps = {
  iconSize?: number;
  isLoading: boolean;
};

// eslint-disable-next-line react/no-multi-comp
const PieChartLoaderSkeleton: FCC<PieChartLoaderSkeletonProps> = ({
  iconSize = 100,
  isLoading,
  children,
}) => {
  const theme = useTheme();

  if (isLoading) {
    return (
      <Card
        sx={{
          padding: 2,
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: '10px',
          borderColor: theme.palette.grey[300],
          backgroundColor: theme.palette.common.white,
          width: '100%',
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={2}
        >
          <Skeleton
            height={iconSize}
            sx={{ color: theme.palette.shadow[100], backgroundColor: 'unset' }}
            variant="circular"
            width={iconSize}
          >
            <PieChartIcon />
          </Skeleton>
          <Stack
            justifyContent="center"
            spacing={1}
            sx={{ maxWidth: '300px' }}
            width="100%"
          >
            <Skeleton
              height={getTypographyHeight(theme.typography.body2)}
              variant="rounded"
              width="55%"
            />
            <Skeleton
              height={getTypographyHeight(theme.typography.h3)}
              variant="rounded"
              width="100%"
            />
          </Stack>
        </Stack>
      </Card>
    );
  }

  return children;
};

export default PieChartLoaderSkeleton;
