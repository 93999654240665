import React from 'react';
import {
  Card,
  Typography,
  useTheme,
} from '@mui/material';
import {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { LIST_CHEVRON_COLUMN_CONFIG } from '../../../../../constants/columns';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import {
  useNavigateBackOr,
} from '../../../../../utils/routes';
import HomeListLayout from '../../../../Property/pages/Home/components/HomeListLayout';
import useGetCurrentPropertyId from '../../../../Property/hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../../../Property/navigation/navigation.property';
import {
  useGetEmailsQuery,
} from '../api/emails.api';
import {
  EmailShortModel,
} from '../types.emails';
import ListEntitiesActions from '../../../../Property/modules/Content/components/actions/ListEntitiesActions';
import {
  formatDate,
  DateTimeFormats,
} from '../../../../../utils/date';
import { EMPTY_VALUE } from '../../../../../utils/string';
import { getUploadEmailPath } from '../navigation.emails';
import { getPathWithPropertyIdOrInit } from '../../../../Auth/navigation/navigation.auth';

const Emails = () => {
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();
  const theme = useTheme();
  const { t } = useTranslation(['admin', 'common']);
  const { isDownMd, isDownSm } = useBreakPointsSizes();
  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: emails = [],
    isLoading,
  } = useGetEmailsQuery();

  const columns: Array<GridColDef> = [
    {
      field: 'sender',
      headerName: t('common:sender'),
      flex: 0.3,
      type: 'string',
      renderCell: (params: GridRenderCellParams) => {
        const { sender } = params?.row as EmailShortModel;
        return (
          <Typography noWrap variant="subtitle1">
            {sender}
          </Typography>
        );
      },
    },
    !isDownSm && {
      field: 'snippet',
      headerName: t('common:message'),
      flex: 0.4,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { snippet } = params?.row as EmailShortModel;
        return (
          <Typography variant="body2">
            {snippet}
          </Typography>
        );
      },
    } as GridColDef,
    {
      field: 'numberOfBlobs',
      headerName: t('common:files'),
      flex: 0.1,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { numberOfBlobs } = params?.row as EmailShortModel;
        return (
          <Typography variant="body2">
            {numberOfBlobs}
          </Typography>
        );
      },
    } as GridColDef,
    !isDownMd && {
      field: 'updatedAt',
      headerName: t('common:updated_at'),
      flex: 0.1,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { updatedAt } = params?.row as EmailShortModel;
        return (
          <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
            {updatedAt ? formatDate(new Date(updatedAt), DateTimeFormats.DATE_ONLY) || EMPTY_VALUE : EMPTY_VALUE}
          </Typography>
        );
      },
    },
    LIST_CHEVRON_COLUMN_CONFIG,
  ].filter(Boolean);

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    const email = params?.row as EmailShortModel;
    navigate(getUploadEmailPath(email.id));
  };

  const handleDelete = () => { };

  return (
    <HomeListLayout
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <ListEntitiesActions
            onDelete={handleDelete}
          />
        </Card>
      }
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'name',
              sort: 'asc',
            },
          ],
        },
      }}
      isLoading={isLoading}
      rows={emails}
      sideDrawerElements={[
        <ListEntitiesActions
          key={ListEntitiesActions.name}
          onDelete={handleDelete}
        />,
      ].filter(Boolean)}
      title={t('admin:emails_title')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
      onRowClick={handleRowClick}
    />
  );
};

export default Emails;
