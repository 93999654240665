import React, {
  FC,
  useMemo,
} from 'react';
import {
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import * as R from 'ramda';
import { RecurringExpense } from '@house-id/houseid-types/dist/finances/recurringExpenses';

import useGetEnum from '../../../../../../../hooks/useGetEnum';
import { EnumType } from '../../../../../../../types/common';
import HIDTypography from '../../../../../../../components/HIDTypography';
import HIDSelectItem from '../../../../../../../components/HIDSelectItem';
import { getRecurringExpenseCategoryPath } from '../navigation.recurringExpenses';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import { ALL_RECURRING_EXPENSES_CATEGORY } from '../../../constants.finance';
import { useGetRecurringExpensesFiltrationQuery } from '../api/recurringExpenses.api';
import useScrollWithShadow from '../../../../../../../hooks/useScrollWithShadow';
import HIDFiltersContainer from '../../../../../../../components/HIDFiltersContainer';
import ListStructureLoaderSkeleton from '../../../../../../../components/skeletonLoaders/ListStructureLoaderSkeleton';

const getCategoriesCountMap = (recurringExpenses: Array<RecurringExpense>) => {
  const categoriesList = R.pipe(
    // @ts-ignore
    R.groupBy((expense: RecurringExpense) => expense.category),
    R.map((grouped) => grouped?.length),
  )(recurringExpenses);

  return (categoriesList as Record<string, number>);
};

type ExpensesPaymentCategoriesSectionProps = {
  activeCategoryId: string;
  showIncompleteCosts: boolean;
};

const ExpensesPaymentCategoriesSection: FC<ExpensesPaymentCategoriesSectionProps> = ({
  activeCategoryId,
  showIncompleteCosts,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'finances']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: { expenses } = {},
    isLoading: isLoadingRecurringExpenses,
  } = useGetRecurringExpensesFiltrationQuery({
    includeIncompleteMonths: showIncompleteCosts,
  });

  const categoriesCountMap = useMemo(
    () => {
      const countMap = getCategoriesCountMap(expenses || []);
      countMap[ALL_RECURRING_EXPENSES_CATEGORY] = R.sum(Object.values(countMap));
      return countMap;
    },
    [expenses],
  );

  const {
    data: ExpensePaymentCategoryEnum,
    isLoading: isLoadingExpensePaymentCategoryEnum,
  } = useGetEnum(EnumType.ExpensePaymentCategory);

  const costsLabel: string = t('finances:costs');
  const allCategoriesLabel = `${t('common:all_label')} ${costsLabel.toLowerCase()}`;

  const navigateToCategory = (categoryId: string) =>
    navigate(getPathWithPropertyIdOrInit(getRecurringExpenseCategoryPath, { propertyId, categoryId }));

  const categories = [
    [ALL_RECURRING_EXPENSES_CATEGORY, allCategoriesLabel],
    ...Object.entries(ExpensePaymentCategoryEnum || {}),
  ] as Array<[string, string]>;

  const { boxShadow, scrollContainerRef, onScrollHandler } = useScrollWithShadow();

  const categoriesWithItems = categories
    .filter(([categoryId]) => categoriesCountMap[categoryId]);

  if (!categoriesWithItems.length) {
    return null;
  }

  return (
    <ListStructureLoaderSkeleton
      isLoading={isLoadingRecurringExpenses || isLoadingExpensePaymentCategoryEnum}
      itemsCount={20}
    >
      <Stack>
        <HIDTypography variant="h6">
          {t('common:categories')}
        </HIDTypography>
        <HIDFiltersContainer
          ref={scrollContainerRef}
          sx={{ boxShadow, mt: 1, pr: 1 }}
          onScroll={onScrollHandler}
        >
          {categoriesWithItems.map(([categoryId, category]) => (
            <HIDSelectItem
              count={expenses ? categoriesCountMap[categoryId] || 0 : undefined}
              isSelected={activeCategoryId === categoryId}
              key={categoryId}
              label={category}
              onClick={() => navigateToCategory(categoryId)}
            />
          ))}
        </HIDFiltersContainer>
      </Stack>
    </ListStructureLoaderSkeleton>
  );
};

export default ExpensesPaymentCategoriesSection;
