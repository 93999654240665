import React from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { FCC } from '../../../types/common';
import {
  DateTimeFormats,
  formatDate,
} from '../../../utils/date';
import HIDClickable from '../../../components/HIDClickable';
import {
  ForumThread,
  ForumAuthor,
  ForumThreadTarget,
} from '../forum.types';
import { getNoWrapStyle } from '../../../utils/style';
import ThreadViewsAndRepliesCount from '../pages/forumThread/components/ThreadViewsAndRepliesCount';
import useBreakPointsSizes from '../../../hooks/useBreakpointsSizes';
import ForumAuthorAvatar from './ForumAuthorAvatar';
import HIDTag from '../../../components/HIDTag';
import { getRandomBetween } from '../../../utils/random';
import HIDTypography from '../../../components/HIDTypography';
import HIDMuiIcon from '../../../components/icons/HIDMuiIcon';

type ThreadListItemProps = {
  author?: ForumAuthor;
  thread: ForumThread;
  threadTopicName?: string;
  isLoading?: boolean;
  onClick: () => void;
};

const ThreadListItem: FCC<ThreadListItemProps> = ({
  author,
  thread,
  threadTopicName,
  isLoading,
  sx,
  style,
  onClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['forum']);
  const { isDownSm } = useBreakPointsSizes();

  return (
    <HIDClickable
      direction="row"
      isDisabled={isLoading}
      spacing={2}
      style={style}
      sx={sx}
      onClick={onClick}
    >
      {(author || isLoading) && (
        <ForumAuthorAvatar author={author} isLoading={isLoading} />
      )}
      <Stack justifyContent="space-between" sx={{ flexGrow: 1 }}>
        <Stack
          alignItems="center"
          direction="row"
          spacing={1}
        >
          <HIDTypography
            isLoading={isLoading}
            skeletonWidth={getRandomBetween(100, 200)}
            sx={getNoWrapStyle(1)}
            variant="subtitle1"
          >
            {thread.subject}
          </HIDTypography>
          {thread?.target === ForumThreadTarget.EXPERT && (
            <HIDTag color={theme.palette.warning.light} label={t('forum:forum_expert')} />
          )}
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={1}
        >
          <Stack direction={isDownSm ? 'column' : 'row'}>
            {Boolean(threadTopicName) && (
              <Typography sx={getNoWrapStyle(1)}>
                {`${threadTopicName},`}&nbsp;
              </Typography>
            )}
            <HIDTypography
              isLoading={isLoading}
              skeletonWidth={isDownSm ? getRandomBetween(50, 75) : getRandomBetween(100, 200)}
              sx={getNoWrapStyle(1)}
            >
              {formatDate(new Date(thread.createdAt), isDownSm ? DateTimeFormats.DATE_ONLY_TEXT_SHORT : DateTimeFormats.DATE_ONLY_TEXT)}
            </HIDTypography>
          </Stack>
          {isDownSm && (
            <ThreadViewsAndRepliesCount
              isLoading={isLoading}
              repliesCount={thread.replyCount}
              sx={{ alignSelf: 'flex-end' }}
              viewsCount={thread.usersReadCount}
            />
          )}
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        spacing={1}
        style={{ marginLeft: theme.spacing(1) }}
      >
        {!isDownSm && (
          <ThreadViewsAndRepliesCount
            isLoading={isLoading}
            repliesCount={thread.replyCount}
            viewsCount={thread.usersReadCount}
          />
        )}
        <HIDMuiIcon Icon={ChevronRight} isDisabled={isLoading} />
      </Stack>
    </HIDClickable>
  );
};

export default ThreadListItem;
