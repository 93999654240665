import React, { FC } from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';

import HIDLink from '../../../../../components/HIDLink';
import {
  getCreateProductPath,
  getProductPath,
  getProductsPath,
} from '../../../modules/Content/modules/Product/navigation.product';
import ProductsEmptyImage from '../../../../../assets/images/products_empty.png';
import HIDButton from '../../../../../components/buttons/HIDButton';
import ProductWidgetItem from './ProductWidgetItem';
import {
  HomeWidgetType,
  useGetWidgetHeight,
} from '../utils/useGetWidgetHeight';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { useGetAllProductsQuery } from '../../../modules/Content/modules/Product/api/product.api';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import { getPathWithPropertyIdOrInit } from '../../../../Auth/navigation/navigation.auth';

const PRODUCTS_COUNT = 4;

const RecentlyAddedProductsEmpty = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation(['home', 'common']);

  const { data: propertyId } = useGetCurrentPropertyId();
  const handleNavigateToCreateProduct = () => navigate(getPathWithPropertyIdOrInit(getCreateProductPath, { propertyId }));

  const { isDownMd } = useBreakPointsSizes();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{
        padding: theme.spacing(1, 2, 0, 2),
        height: '100%',
      }}
    >
      <img
        src={ProductsEmptyImage}
        style={{ width: 108, height: 99 }}
      />
      <Typography
        style={{ marginTop: theme.spacing(1) }}
        variant="h6"
      >
        {t('home:home_add_products')}
      </Typography>
      <Typography style={{ marginTop: theme.spacing(1) }} variant="body1">
        {t('home:home_collect_your_products_in_one_place')}
      </Typography>
      <HIDButton color="secondary" style={{ marginTop: isDownMd ? theme.spacing(2) : 'auto' }} onClick={handleNavigateToCreateProduct}>
        {t('common:add_label')}
      </HIDButton>
    </Stack>
  );
};

// eslint-disable-next-line react/no-multi-comp
const RecentlyAddedProductsWidget: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation(['home', 'forms_common']);

  const { isDownMd } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: { products = [] } = {}, isLoading } = useGetAllProductsQuery(
    propertyId ? { propertyId, pageSize: PRODUCTS_COUNT } : skipToken,
  );

  const firstProducts = products.slice(0, PRODUCTS_COUNT);

  const handleGoToProducts = () => navigate(getPathWithPropertyIdOrInit(getProductsPath, { propertyId }));
  const handleGoToProduct = (id: string) => navigate(getPathWithPropertyIdOrInit(getProductPath, { propertyId, id }));

  const widgetHeight = useGetWidgetHeight(HomeWidgetType.RECENTLY_ADDED_PRODUCTS);

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2.5),
        height: isDownMd ? 'unset' : widgetHeight,
      }}
    >
      <Stack spacing={1} sx={{ height: '100%' }}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ minWidth: 0 }}
        >
          <Typography
            noWrap
            variant="h5"
          >
            {t('home:home_recently_added_products')}
          </Typography>
          {!isLoading && Boolean(products.length) && (
            <HIDLink
              label={t('forms_common:view_all')}
              onClick={handleGoToProducts}
            />
          )}
        </Stack>
        {
          !isLoading && !products.length
            ? <RecentlyAddedProductsEmpty />
            : (
              <Stack>
                {
                  firstProducts.map((product) => (
                    <ProductWidgetItem
                      key={product.id}
                      product={product}
                      onClick={() => handleGoToProduct(product.id)}
                    />
                  ))
                }
              </Stack>
            )
        }
      </Stack>
    </Card>
  );
};

export default RecentlyAddedProductsWidget;
