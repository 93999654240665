import {
  useEffect,
  useRef,
} from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { skipToken } from '@reduxjs/toolkit/query';

import useGetSubscriptionPlanTrialPeriodInfo from '../../../../SubscriptionPlans/hooks/useGetSubscriptionTrialPeriodInfo';
import {
  useGetUserSettingsQuery,
  useSetUserSettingsMutation,
} from '../../../../Auth/api/settings.api';
import { getActiveAuth } from '../../../../../external-services/firebase';
import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import { StartTrialPeriodDialogProps } from '../components/StartTrialPeriodDialog';

const useShowStartTrialPeriodDialog = () => {
  const [currentUser] = useAuthState(getActiveAuth());
  // TODO: remove this hack after adding static IDs for useDialog hook (improvement needed)
  const shouldOpen = useRef(true);

  const {
    data: { isEnabled, isExpired, durationDays } = {},
  } = useGetSubscriptionPlanTrialPeriodInfo(undefined, { skip: !currentUser?.uid });

  const [setUserSettings] = useSetUserSettingsMutation();

  const {
    data: { excludedDialogsToShow = [] } = {},
    isLoading: isLoadingUserSettings,
  } = useGetUserSettingsQuery(
    currentUser && currentUser.uid
      ? { userUId: currentUser.uid }
      : skipToken,
  );

  const [openStartTrialPeriodDialog] = useDialog<StartTrialPeriodDialogProps>(DialogNames.START_TRIAL_PERIOD_DIALOG);

  useEffect(() => {
    const showStartTrialPeriodDialog = !excludedDialogsToShow.includes(DialogNames.START_TRIAL_PERIOD_DIALOG);

    if (
      isEnabled
      && shouldOpen.current
      && durationDays
      && showStartTrialPeriodDialog
      && !isExpired
      && !isLoadingUserSettings
    ) {
      openStartTrialPeriodDialog({
        trialPeriodDays: durationDays,
        onAgree: () => {
          setUserSettings({
            excludedDialogsToShow: [...excludedDialogsToShow, DialogNames.START_TRIAL_PERIOD_DIALOG],
          });
        },
      });

      shouldOpen.current = false;
    }
  }, [excludedDialogsToShow, isEnabled, isLoadingUserSettings, durationDays, isExpired]);
};

export default useShowStartTrialPeriodDialog;
