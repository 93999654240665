import {
  useEffect,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import useDialog from '../../../hooks/useDialog';
import DialogNames from '../../../hooks/useDialog/DialogNames';
import { useRouteQueryParams } from '../../../utils/routes';
import { WaitBankIdCompletedDialogProps } from '../components/OpenBankIdDialog/WaitBankIdCompletedDialog';
import { OpenBankIdDialogProps } from '../components/OpenBankIdDialog/OpenBankIdDialog';

export type UseBankIdQueryParams = {
  sessionKey?: string
  bankIdSessionRef?: string
};

type UseBankIdProps = {
  sessionKey?: OpenBankIdDialogProps['sessionKey'];
  onSuccess: OpenBankIdDialogProps['onSuccess'];
};

type UseBankId = (props: UseBankIdProps) => () => void;

const useBankId: UseBankId = (props) => {
  const [_, setSearchParams] = useSearchParams();
  const { bankIdSessionRef, sessionKey, ...rest } = useRouteQueryParams<UseBankIdQueryParams>();

  const [openBankIdModal] = useDialog<OpenBankIdDialogProps>(DialogNames.OPEN_BANK_ID_DIALOG);
  const [openWaitBankIdModal] = useDialog<WaitBankIdCompletedDialogProps>(DialogNames.WAIT_BANK_ID_COMPLETED_DIALOG);

  useEffect(() => {
    if (bankIdSessionRef && (!props.sessionKey || (props.sessionKey && props.sessionKey === sessionKey))) {
      openWaitBankIdModal({
        sessionKey: props.sessionKey,
        bankIdSessionRef,
        onSuccess: props.onSuccess,
      });
      setSearchParams(rest);
    }
  }, [bankIdSessionRef]);

  return () => openBankIdModal({
    sessionKey: props.sessionKey,
    onSuccess: props.onSuccess,
  });
};

export default useBankId;
