import React, { FC } from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

import { useGetAllDocumentCategoriesQuery } from '../api/document.api';
import HomeListLayout from '../../../../../pages/Home/components/HomeListLayout';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import { ALL_DOCUMENTS_CATEGORY } from '../constants.document';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { DocumentCategory } from '../types.document';
import { LIST_CHEVRON_COLUMN_CONFIG } from '../../../../../../../constants/columns';
import ListCategoriesActions from '../../../components/actions/ListCategoriesActions';
import {
  getCreateDocumentPath,
  getDocumentCategoryPath,
} from '../navigation.document';
import { getHomePath } from '../../../../../navigation/navigation.property';
import { useNavigateBackOr } from '../../../../../../../utils/routes';
import EntityType from '../../../../../../../constants/entityType';
import SuggestionsIconButton from '../../Suggestion/components/SuggestionsIconButton';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import useSearch from '../../../../../../../hooks/useSearch';

const DocumentCategories: FC = () => {
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();
  const theme = useTheme();
  const { t } = useTranslation(['documents', 'common']);
  const { isDownSm } = useBreakPointsSizes();
  const { data: propertyId } = useGetCurrentPropertyId();

  const { displaySearch } = useSearch(EntityType.DOCUMENT);

  const {
    data: documentsCategories = [],
    isLoading,
  } = useGetAllDocumentCategoriesQuery(propertyId ? { propertyId } : skipToken);

  const categories = documentsCategories.length > 0
    ? [
      ...documentsCategories,
      {
        id: ALL_DOCUMENTS_CATEGORY,
        name: t('documents_documents_categories_all_documents'),
        count: R.sum(R.map((category) => category.count, documentsCategories)),
      },
    ]
    : [];

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: t('documents:documents_documents_categories_label_column'),
      flex: isDownSm ? 0.8 : 1,
      type: 'string',
      sortable: !isDownSm,
      renderCell: (params: GridRenderCellParams) => {
        const { name } = params?.row as DocumentCategory;
        return (
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{ minWidth: 0 }}
          >
            <FolderOutlinedIcon />
            <Typography noWrap variant="subtitle1">
              {name}
            </Typography>
          </Stack>
        );
      },
    },
    !isDownSm && {
      field: 'count',
      headerName: t('documents:documents_documents_categories_amount_label'),
      flex: 0.2,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { count } = params?.row as DocumentCategory;
        return (
          <Typography color={theme.palette.grey[500]} variant="subtitle1">
            {t('common:items_count', { count })}
          </Typography>
        );
      },
    } as GridColDef,
    LIST_CHEVRON_COLUMN_CONFIG,
  ].filter(Boolean);

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    const documentCategory = params?.row as DocumentCategory;
    navigate(getPathWithPropertyIdOrInit(getDocumentCategoryPath, { propertyId, categoryId: encodeURIComponent(documentCategory.id) }));
  };

  const handleAdd = () => navigate(getPathWithPropertyIdOrInit(getCreateDocumentPath, { propertyId }));

  return (
    <HomeListLayout
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <ListCategoriesActions
            onAdd={handleAdd}
            onSearch={displaySearch}
          />
        </Card>
      }
      TitleRightComponent={<SuggestionsIconButton entityType={EntityType.DOCUMENT} />}
      columns={columns}
      getRowId={(row: DocumentCategory) => row.id}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'name',
              sort: 'asc',
            },
          ],
        },
      }}
      isLoading={isLoading}
      rows={categories}
      sideDrawerElements={[
        <ListCategoriesActions
          key={ListCategoriesActions.name}
          onAdd={handleAdd}
          onSearch={displaySearch}
        />,
      ]}
      title={t('documents:documents_documents_categories_title')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
      onRowClick={handleRowClick}
    />
  );
};

export default DocumentCategories;
