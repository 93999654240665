import React, {
  FC,
  useEffect,
  useMemo,
} from 'react';
import {
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Add,
} from '@mui/icons-material';
import {
  FieldArray,
  useFormikContext,
} from 'formik';
import {
  SubcategoryIdType,
  BudgetTemplateCategory,
  RemappedBudgetCategory,
  RemappedBudgetSubcategory,
  CreateUpdateBudgetValues,
} from '@house-id/houseid-types/dist/finances/budgets';

import BudgetCategoryCollapsibleSection from './BudgetCategoryCollapsibleSection';
import HIDButton from '../../../../../../../../../components/buttons/HIDButton';
import ContextMenu from '../../../../../../../../../components/contextMenu/ContextMenu';
import { formatMoney } from '../../../../../../../../../utils/string';
import useBudgetCategoriesDataMapper from '../hooks/useBudgetCategoriesDataMapper';
import getCategoryAnnualAmountSum from '../utils/getCategoryAnnualAmountSum';
import BudgetSubcategorySection from './BudgetSubcategorySection';
import {
  getCategoryDataPath,
  getSubcategoriesDataPath,
} from '../utils/formikFieldDataPath.utils';

type BudgetPaymentCategorySectionProps = {
  data: RemappedBudgetCategory;
  template?: BudgetTemplateCategory;
};

const BudgetCategorySection: FC<BudgetPaymentCategorySectionProps> = ({
  data,
  template,
}) => {
  const {
    hasMultiSubcategories,
    categoryId,
    subcategories,
    subcategoriesOptionsList,
    annualAmount,
    name: categoryName,
  } = data;

  const categoryPath = getCategoryDataPath(categoryId);
  const subcategoriesPath = getSubcategoriesDataPath(categoryId);

  const { setFieldValue } = useFormikContext<CreateUpdateBudgetValues>();

  const { t } = useTranslation(['forms_common', 'common']);

  const { getEmptySubcategoryEntity } = useBudgetCategoriesDataMapper();

  useEffect(() => {
    const annualAmount = getCategoryAnnualAmountSum(subcategories || []);
    setFieldValue(`${categoryPath}.annualAmount`, annualAmount);
  }, [subcategories, categoryId]);

  const handleAddSubcategory = (push: (subcategory: RemappedBudgetSubcategory) => void, subcategoryId?: SubcategoryIdType) => {
    if (template) {
      const subcategoryTemplate = subcategoryId
        ? template.subcategories.find((item) => subcategoryId === item.id)
        : template.subcategories[0];

      if (subcategoryTemplate) {
        push(getEmptySubcategoryEntity(subcategoryTemplate));
      }
    }
  };

  const hasManySubcategoryVariants = subcategoriesOptionsList && subcategoriesOptionsList.length > 1;

  const subcategoryNameIndexMap: Record<string, Array<number>> = useMemo(
    () => (subcategories || [])
      .reduce((acc, item, index) => {
        if (item.name) {
          if (item.name in acc) {
            acc[item.name].push(index);
          } else {
            acc[item.name] = [index];
          }
        }

        return acc;
      }, {} as Record<string, Array<number>>),
    [subcategories?.length],
  );

  const getSubcategoryName = (subcategoryIndex: number, name?: string) => {
    const nameIndexes = name ? subcategoryNameIndexMap[name] : [];
    const nameIndex = nameIndexes.indexOf(subcategoryIndex);

    return nameIndexes.length > 1 || !hasManySubcategoryVariants
      ? `${name} (${nameIndex + 1})`
      : name || '';
  };

  return (
    <BudgetCategoryCollapsibleSection
      categoryId={categoryId}
      sum={t('forms_common:per_year', { value: formatMoney(annualAmount) })}
      title={categoryName}
    >
      <FieldArray
        name={subcategoriesPath}
        render={({ push, remove }) => (
          <Stack
            spacing={1.5}
            sx={{ marginTop: 2 }}
          >
            {subcategories?.map((subcategory, index) => (
              <BudgetSubcategorySection
                categoryId={categoryId}
                data={subcategory}
                hasMultiSubcategories={hasMultiSubcategories}
                key={`${subcategory.id || subcategory.subcategoryId}-${index}`}
                subcategoryIndex={index}
                subcategoryName={getSubcategoryName(index, subcategory.name)}
                onDeleteSubcategory={remove}
              />
            ))}
            {hasMultiSubcategories && (
              <Stack
                direction={{ sm: 'column', md: 'row' }}
                justifyContent="flex-end"
              >
                {hasManySubcategoryVariants
                  ? (
                    <ContextMenu
                      Button={({ onClick }) => (
                        <HIDButton
                          Icon={Add}
                          onClick={onClick}
                        >
                          {t('common:add_label')}
                        </HIDButton>
                      )}
                      listItems={
                        subcategoriesOptionsList.map(({ id, label }) => ({
                          id,
                          label,
                          onClick: () => handleAddSubcategory(push, id),
                        }))
                      }
                    />
                  )
                  : (
                    <HIDButton
                      Icon={Add}
                      onClick={() => handleAddSubcategory(push)}
                    >
                      {t('common:add_label')}
                    </HIDButton>
                  )}
              </Stack>
            )}
          </Stack>
        )}
      />
    </BudgetCategoryCollapsibleSection>
  );
};

export default BudgetCategorySection;
