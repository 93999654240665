import { Property } from '@house-id/houseid-types/dist/property';

import { propertyApi } from './property.api';
import {
  InviteCode,
  PropertyId,
} from '../types/property.types';

export const inviteCode = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getInviteCode: builder.query<InviteCode, { code: string }>({
      query: ({ code }) => `/propertyInvites/${encodeURIComponent(code)}`,
    }),
    acceptInviteCode: builder.mutation<PropertyId, { code: string }>({
      query: ({ code }) => ({
        url: `/users/me/propertyInvites/${encodeURIComponent(code)}`,
        method: 'PUT',
      }),
    }),
    declineInviteCode: builder.mutation<Property, { code: string }>({
      query: ({ code }) => ({
        url: `/users/me/propertyInvites/${encodeURIComponent(code)}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetInviteCodeQuery,
  useAcceptInviteCodeMutation,
  useDeclineInviteCodeMutation,
} = inviteCode;
