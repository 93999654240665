import { generateRoutePath } from '../../../../utils/routes';
import AdminRouteNames from '../../navigation/AdminRouteNames';

export const getEmailsPath = () => generateRoutePath(
  AdminRouteNames.EMAILS,
);

export const getUploadEmailPath = (id: string) => generateRoutePath(
  AdminRouteNames.UPLOAD_EMAIL,
  { id },
);
