import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';

import {
  EMPTY_VALUE,
  formatMoney,
} from '../../../../../../../../../utils/string';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../../../utils/date';
import BasicInfoSection, { BasicInfoItem } from '../../../../../components/sections/BasicInfoSection';
import { Product } from '../../../types.product';
import { useGetProductCategoriesQuery } from '../../../api/product.api';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import { useGetPeriodLabelFromMonth } from '../../../../../../../../../utils/translation';
import { getIdentifiers } from '../../../utils.products';

type ProductBasicInfoSectionProps = {
  product: Product;
};

const ProductBasicInfoSection: FC<ProductBasicInfoSectionProps> = ({
  product,
}) => {
  const { t } = useTranslation(['common', 'products']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: productCategories = [],
  } = useGetProductCategoriesQuery(propertyId ? { propertyId } : skipToken);

  const purchaseDateFormatted = product.purchaseDate
    ? formatDate(new Date(product.purchaseDate), DateTimeFormats.DATE_ONLY)
    : undefined;

  const type = productCategories
    .flatMap(
      (category) => [
        { id: category.id, name: category.name },
        ...(category.children as Array<{ id: string, name: string }>),
      ],
    )
    .find(({ id }) => id === product.type);

  const getProductPeriodLabelFromMonth = useGetPeriodLabelFromMonth();

  const fields: Array<BasicInfoItem> = [
    { label: t('products:product_name'), value: product.name },
    {
      label: t('products:private_product'),
      value: product.movable ? t('common:yes') : t('common:no'),
    },
    { label: t('products:brand'), value: product.brand || EMPTY_VALUE },
    { label: t('products:product_type'), value: type?.name || EMPTY_VALUE },
    product?.serialNumber && { label: t('products:serial_number'), value: product?.serialNumber || EMPTY_VALUE },
    ...getIdentifiers(product),
    product?.productLink && { label: t('products:product_link'), value: product?.productLink || EMPTY_VALUE, isLink: true },
    product?.place && { label: t('products:purchase_place'), value: product?.place || EMPTY_VALUE },
    product?.purchaseDate && { label: t('products:purchase_date'), value: purchaseDateFormatted || EMPTY_VALUE },
    product?.price && { label: t('products:purchase_price'), value: formatMoney(product?.price) || EMPTY_VALUE },
    product?.lifetimeInMonth && {
      label: t('products:estimated_lifetime'),
      value: getProductPeriodLabelFromMonth(product.lifetimeInMonth) || EMPTY_VALUE,
    },
    product?.warrantyInMonth && {
      label: t('products:warranty_period'),
      value: getProductPeriodLabelFromMonth(product.warrantyInMonth) || EMPTY_VALUE,
    },
  ].filter(Boolean) as Array<BasicInfoItem>;

  return (
    <BasicInfoSection items={fields} />
  );
};

export default ProductBasicInfoSection;
