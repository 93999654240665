import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

import useTryUseFeatureDialog from '../../SubscriptionPlans/hooks/useTryUseFeatureDialog';
import { SubscriptionFeature } from '../../SubscriptionPlans/subscriptionPlans.types';
import {
  getForumThreadPath as getNotPartnersForumThreadPath,
  getPartnersForumThreadPath,
} from '../navigation/navigation.forum';
import {
  ForumThreadTarget,
} from '../forum.types';
import { toggleSideDrawerOpen } from '../../../store/layoutReducer';
import { useGetActiveAuthUser } from '../../../external-services/firebase';

type ForumThreadNavigation = {
  id: string;
  topicId: string;
  target: ForumThreadTarget;
};

const useTryNavigateToThread = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isPartnerAuth } = useGetActiveAuthUser();

  const getForumThreadPath = isPartnerAuth ? getPartnersForumThreadPath : getNotPartnersForumThreadPath;

  const [
    proceedToFeatureOrOpenSubscriptionDialog,
  ] = useTryUseFeatureDialog({ subscriptionFeature: SubscriptionFeature.EXPERTS_PANEL_AND_FORUM });

  const handleNavigateToThread = (topicId: string, threadId: string) => navigate(getForumThreadPath({ topicId, threadId }));

  const handleTryNavigateToThread = (thread: ForumThreadNavigation) => {
    dispatch(toggleSideDrawerOpen(false));

    const { id: threadId, topicId, target } = thread;

    if (target === ForumThreadTarget.EXPERT) {
      proceedToFeatureOrOpenSubscriptionDialog({
        onAction: () => handleNavigateToThread(topicId, threadId),
      });
    } else {
      handleNavigateToThread(topicId, threadId);
    }
  };

  return handleTryNavigateToThread;
};

export default useTryNavigateToThread;
