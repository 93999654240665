import {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import DialogNames from './DialogNames';
import { HIDDialogProps } from '../../types/common';
import { createGuid } from '../../utils/uuid';
import {
  DialogStateType,
  getIsDialogOpen,
  hideDialog,
  showDialog,
} from '../../store/dialogsReducer';

const useDialog = <T extends HIDDialogProps = HIDDialogProps>(name: DialogNames, onClose?: (params?: T) => void): [
  (props?: Omit<T, keyof HIDDialogProps>) => void,
  () => void,
  boolean,
] => {
  const idRef = useRef(createGuid());
  const id = idRef.current;

  const dispatch = useDispatch();
  const isOpen: boolean = useSelector((state: { dialogs: DialogStateType }) => getIsDialogOpen(id, state));

  const [wasOpen, setWasOpen] = useState(false);

  useEffect(() => {
    if (onClose && wasOpen && !isOpen) {
      onClose();
    }

    setWasOpen(isOpen);
  }, [isOpen]);

  return [
    (props?: Omit<T, keyof HIDDialogProps>) => {
      dispatch(showDialog({ name, id, props }));
    },
    () => dispatch(hideDialog({ id })),
    isOpen,
  ];
};

export default useDialog;
