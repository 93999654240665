import React, { FC } from 'react';
import {
  Card,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  PropertyProgressCategoryId,
  PropertyProgressSection,
} from '@house-id/houseid-types/dist/propertyProgress';

import PropertyPlanningConnectedToRoomSection from './components/PropertyPlanningConnectedToRoomSection';
import PropertyPlanningNotConnectedToRoomTasksSection from './components/PropertyPlanningNotConnectedToRoomTasksSection';
import PropertyPlanningTimeline from './components/PropertyPlanningTimeline';
import { useNavigateBackOr } from '../../../../../../utils/routes';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import HomeLayout from '../../../../pages/Home/components/HomeLayout';
import { useGetPropertyProgressPlanningQuery } from '../../api/propertyProgress.api';
import PropertyProgressPageContent from '../../components/PropertyProgressPageContent';
import PropertyProgressQuickNavigation from '../../components/PropertyProgressQuickNavigation';
import { getPropertyProgressPath } from '../../navigation.propertyProgress';
import {
  PropertyProgressCategorySectionProps,
  PropertyPlanningSectionData,
  PropertyPlaningSectionId,
} from '../../types.propertyProgress';
import PropertyPlanningRoomsSection from './components/PropertyPlanningRoomsSection';
import useGetPropertyProgressCategoryContentText from '../../hooks/useGetPropertyProgressCategoryContentText';
import { getPropertyProgressPercent } from '../../utils/utils.propertyProgress';
import { getPathWithPropertyIdOrInit } from '../../../../../Auth/navigation/navigation.auth';

const PropertyProductsSectionsContent: Record<string, FC<PropertyProgressCategorySectionProps>> = {
  [PropertyPlaningSectionId.PROPERTY_PLANNING_ROOMS]: PropertyPlanningRoomsSection,
  [PropertyPlaningSectionId.PROPERTY_PLANNING_TASKS_CONNECTED_TO_ROOM]: PropertyPlanningConnectedToRoomSection,
  [PropertyPlaningSectionId.PROPERTY_PLANNING_TASKS_NOT_CONNECTED_TO_ROOM]: PropertyPlanningNotConnectedToRoomTasksSection,
  [PropertyPlaningSectionId.PROPERTY_PLANNING_TIMELINE_ENTRIES]: PropertyPlanningTimeline,
};

const PropertyPlanning: FC = () => {
  const { t } = useTranslation(['property']);
  const navigateBackOr = useNavigateBackOr();

  const { data: propertyId } = useGetCurrentPropertyId();
  const {
    data: propertyPlanning,
    isLoading: isPropertySpecificationLoading,
  } = useGetPropertyProgressPlanningQuery(propertyId ? { propertyId } : skipToken);

  const sections = propertyPlanning?.sections || [];

  const handleRenderSectionContent = (section: PropertyProgressSection<PropertyPlanningSectionData>) => {
    const Content = PropertyProductsSectionsContent[section.id];

    return Content ? <Content section={section} /> : null;
  };

  const getPropertyProgressCategoryContentDescription = useGetPropertyProgressCategoryContentText();
  const description = getPropertyProgressCategoryContentDescription({
    percent: getPropertyProgressPercent(propertyPlanning?.progress),
    categoryId: PropertyProgressCategoryId.PLANNING,
  });

  return (
    <HomeLayout
      SideColumn={
        <Stack spacing={2}>
          <Card sx={{ padding: 2 }}>
            <PropertyProgressQuickNavigation
              currentPropertyProgressCategoryId={PropertyProgressCategoryId.PLANNING}
              key={PropertyProgressQuickNavigation.name}
            />
          </Card>
        </Stack>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getPropertyProgressPath, { propertyId }),
          name: t('property:property_progress_title'),
        },
      ]}
      sideDrawerElements={[
        <PropertyProgressQuickNavigation
          currentPropertyProgressCategoryId={PropertyProgressCategoryId.PLANNING}
          key={PropertyProgressQuickNavigation.name}
        />,
      ]}
      title={t('property:property_progress_property_planning_title')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getPropertyProgressPath, { propertyId }))}
    >
      <PropertyProgressPageContent
        description={description}
        loading={isPropertySpecificationLoading}
        progress={propertyPlanning?.progress}
        renderSectionContent={handleRenderSectionContent}
        sections={sections}

      />
    </HomeLayout>
  );
};

export default PropertyPlanning;
