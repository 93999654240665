import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import {
  Card,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';
import * as R from 'ramda';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  BookmarkAddOutlined,
  BookmarkRemoveOutlined,
} from '@mui/icons-material';
import {
  HIDBlobModify,
  ModifyActionType,
} from '@house-id/houseid-types/dist/common';

import {
  useGetForumRepliesQuery,
  useGetForumThreadQuery,
  useGetForumTopicsQuery,
  useCreateReplyMutation,
  useDeleteThreadMutation,
  useUpdateThreadMutation,
  useCreateBlobUploadUrlMutation,
  useUploadFileBlobMutation,
  useAddToBookmarksMutation,
  useRemoveFromBookmarksMutation,
} from '../../api/forum.api';
import {
  useNavigateBackOr,
  useRouteParams,
} from '../../../../utils/routes';
import HomeLayout from '../../../Property/pages/Home/components/HomeLayout';
import useBreakPointsSizes from '../../../../hooks/useBreakpointsSizes';
import {
  getForumPath as getNotPartnersForumPath,
  getPartnersForumPath,
  getForumTopicPath as getNotPartnerForumTopicPath,
  getPartnersForumTopicPath,
  getExpertPath as getNotPartnersExpertsPath,
  getPartnersExpertsPath,
} from '../../navigation/navigation.forum';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../utils/date';
import HIDButton from '../../../../components/buttons/HIDButton';
import ForumThreadReply from './components/ForumThreadReply';
import ForumRepliesOrderSelect from './components/ForumRepliesOrderSelect';
import {
  ForumAuthorType,
  ForumRepliesOrder,
  ForumThreadTarget,
} from '../../forum.types';
import { capitalize } from '../../../../utils/url';
import ListEntitiesActions from '../../../Property/modules/Content/components/actions/ListEntitiesActions';
import ReadMoreAboutExperts from '../../components/ReadMoreAboutExperts';
import ThreadViewsAndRepliesCount from './components/ThreadViewsAndRepliesCount';
import { getMutationFixedCacheKey } from '../../../Property/modules/Content/utils/cacheKeys';
import ForumAuthorAvatar from '../../components/ForumAuthorAvatar';
import HIDTag from '../../../../components/HIDTag';
import { createFakeReply } from '../../utils/fakeForumEntities';
import HIDTypography from '../../../../components/HIDTypography';
import {
  getActiveAuth,
  useGetActiveAuthUser,
} from '../../../../external-services/firebase';
import ForumTextEditor from './components/ForumTextEditor';
import ForumImageAttachments from '../../components/ForumImageAttachments';
import HIDFilePickerButton from '../../../../components/filePicker/HIDFilePickerButton';
import { ImageMimeTypes } from '../../../../constants/mimeTypes';
import { getIsTypeOf } from '../../../../utils/object';
import useTryUseFeatureDialog from '../../../SubscriptionPlans/hooks/useTryUseFeatureDialog';
import { SubscriptionFeature } from '../../../SubscriptionPlans/subscriptionPlans.types';
import HIDLink from '../../../../components/HIDLink';
import { getSubscriptionPlansPath } from '../../../SubscriptionPlans/navigation/navigation.subscriptionPlans';
import { useGetTokenDataQuery } from '../../../Auth/api/user.api';

const getIsFile = getIsTypeOf<File>('arrayBuffer');

const ForumThread: FC = () => {
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();
  const theme = useTheme();
  const { t } = useTranslation(['forum', 'common', 'subscriptions']);

  const { isPartnerAuth } = useGetActiveAuthUser();

  const [currentUser] = useAuthState(getActiveAuth());
  const { data: tokenData } = useGetTokenDataQuery({}, { skip: !currentUser });

  const { topicId, threadId } = useRouteParams<{ topicId: string, threadId: string }>();

  const { isDownLg, isDownMd, isDownSm } = useBreakPointsSizes();

  const [createReply, { isLoading: replyIsCreating }] = useCreateReplyMutation();
  const [updateThread] = useUpdateThreadMutation();
  const [threadIsUpdating, setThreadIsUpdating] = useState(false);
  const [deleteThread, { isLoading: threadIsDeleting, isSuccess: threadIsDeleted }] = useDeleteThreadMutation({
    fixedCacheKey: getMutationFixedCacheKey(threadId),
  });

  const [createUploadFileContainer] = useCreateBlobUploadUrlMutation();
  const [uploadBlob] = useUploadFileBlobMutation();

  const [addToBookmarks, { isLoading: addingToBookmarks }] = useAddToBookmarksMutation();
  const [removeFromBookmarks, { isLoading: removingFromBookmarks }] = useRemoveFromBookmarksMutation();

  const getForumPath = isPartnerAuth ? getPartnersForumPath : getNotPartnersForumPath;
  const getForumTopicPath = isPartnerAuth ? getPartnersForumTopicPath : getNotPartnerForumTopicPath;
  const getExpertPath = isPartnerAuth ? getPartnersExpertsPath : getNotPartnersExpertsPath;

  const { data: topics } = useGetForumTopicsQuery();
  const {
    data: thread,
    isLoading: threadIsLoading,
  } = useGetForumThreadQuery(threadIsDeleting || threadIsDeleted ? skipToken : { threadId });

  const topic = topics?.find((topic) => topic.id === topicId);

  const [repliesSortOrder, setSortRepliesOrder] = useState<ForumRepliesOrder | undefined>();

  const [isEditMode, setIsEditMode] = useState(false);
  const replyDefaultState = { reply: '', anonymous: false, attachments: [] };

  const [threadBody, setThreadBody] = useState(thread?.body || '');
  const [threadAttachments, setThreadAttachments] = useState<Array<HIDBlobModify | File>>(thread?.blobs || []);
  const [replyState, setReplyState] = useState<{ reply: string, anonymous: boolean, attachments: Array<File> }>(replyDefaultState);

  const [canReadThread, setCanReadThread] = useState<boolean>(Boolean(isPartnerAuth));

  const [
    proceedToFeatureOrOpenSubscriptionDialog,
    subscriptionFeaturesIsLoading,
  ] = useTryUseFeatureDialog({ subscriptionFeature: SubscriptionFeature.EXPERTS_PANEL_AND_FORUM });

  const threadOrPermissionsIsLoading = threadIsLoading || subscriptionFeaturesIsLoading;

  useEffect(() => {
    if (thread) {
      setThreadBody(thread.body);
      setThreadAttachments(thread.blobs || []);
      if (thread.target === ForumThreadTarget.EXPERT) {
        proceedToFeatureOrOpenSubscriptionDialog({
          onAction: () => setCanReadThread(true),
        });
      } else {
        setCanReadThread(true);
      }
    }
  }, [thread]);

  const resetReplyState = () => setReplyState(replyDefaultState);

  const handleUpdateThread = () => {
    setThreadBody(thread?.body || '');
    setThreadAttachments(thread?.blobs || []);
    setIsEditMode(true);
  };

  const handleUploadFiles = async (files: Array<File>) => {
    const containers = await createUploadFileContainer({ files }).unwrap();

    return Promise.all(
      containers.map(
        async (container, index) => {
          await uploadBlob({
            signedUploadUrl: container.signedUploadUrl,
            file: files[index],
            type: files[index].type,
          });

          return container.id;
        },
      ),
    );
  };

  const handleSaveThread = async () => {
    if (thread) {
      setThreadIsUpdating(true);

      const [fileAttachment, blobAttachment] = R.partition(getIsFile, threadAttachments || []);

      const leftThreadBlobs = blobAttachment;
      const threadFiles = fileAttachment;
      const newThreadBlobIds = await handleUploadFiles(threadFiles);

      updateThread({
        id: thread.id,
        body: threadBody,
        blobs: [
          ...R.difference(thread.blobs || [], leftThreadBlobs || [])
            .map((blob) => ({ ...blob, action: ModifyActionType.DELETE })),
          ...newThreadBlobIds
            .map((blobId) => ({ id: blobId, action: ModifyActionType.CREATE } as HIDBlobModify)),
        ],
      })
        .unwrap()
        .then((updatedThread) => {
          setIsEditMode(false);
          setThreadBody(updatedThread.body);
          setThreadAttachments(updatedThread.blobs || []);
        })
        .finally(() => setThreadIsUpdating(false));
    }
  };

  const {
    data: {
      replies = [],
      sort: initialSortOrder,
    } = {},
    isLoading: repliesIsLoading,
  } = useGetForumRepliesQuery(threadIsDeleting || threadIsDeleted ? skipToken : { threadId, sort: repliesSortOrder });

  const hasExpertReply = replies.some((reply) => reply.author.type === ForumAuthorType.EXPERT);

  const repliesList = repliesIsLoading
    ? R.times((n) => createFakeReply(n.toString()), 3)
    : replies;

  useEffect(() => {
    if (!repliesSortOrder && initialSortOrder) {
      setSortRepliesOrder(initialSortOrder);
    }
  }, [initialSortOrder]);

  const ViewsAndReplies = (
    <ThreadViewsAndRepliesCount
      isLoading={threadOrPermissionsIsLoading}
      repliesCount={thread?.replyCount}
      viewsCount={thread?.usersReadCount}
    />
  );

  const Divider = (
    <hr
      style={{
        color: theme.palette.grey[300],
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderTopWidth: 1,
      }}
    />
  );

  const handleSendReply = async () => {
    if (thread?.author) {
      const newThreadBlobIds = await handleUploadFiles(replyState.attachments);

      createReply({
        threadId,
        author: thread?.author,
        body: replyState.reply,
        anonymous: replyState.anonymous,
        blobs: newThreadBlobIds
          .map((blobId) => ({ id: blobId, action: ModifyActionType.CREATE } as HIDBlobModify)),
      })
        .then(() => resetReplyState());
    }
  };

  const handleDelete = () => {
    if (thread) {
      deleteThread({ id: thread.id })
        .then(() => navigateBackOr(getForumTopicPath({ topicId: thread.topicId })));
    }
  };

  const handleNavigateToExperts = () => navigate(getExpertPath());

  const handleAboutThreadChanges = () => {
    setThreadAttachments((threadAttachments.filter((attachment) => !getIsFile(attachment))));
    setIsEditMode(false);
  };

  const handleRemoveThreadAttachment = (indexToRemove: number) =>
    setThreadAttachments(threadAttachments?.filter((_item, index) => index !== indexToRemove));

  const handleRemoveReplyAttachment = (indexToRemove: number) => {
    setReplyState((before) => ({
      ...before,
      attachments: replyState.attachments.filter((_attachment, index) => index !== indexToRemove),
    }));
  };

  const handleAddToBookmarks = () => addToBookmarks({ threadId });

  const handleRemoveFromBookmarks = () => removeFromBookmarks({ threadId });

  const customActions = [
    !thread?.me?.followed && {
      id: 'add_to_bookmarks',
      disabled: addingToBookmarks || removingFromBookmarks,
      Icon: BookmarkAddOutlined,
      label: t('forum:forum_add_to_bookmarks'),
      onClick: handleAddToBookmarks,
    },
    thread?.me?.followed && {
      id: 'remove_from_bookmarks',
      disabled: addingToBookmarks || removingFromBookmarks,
      Icon: BookmarkRemoveOutlined,
      label: t('forum:forum_remove_from_bookmarks'),
      onClick: handleRemoveFromBookmarks,
    },
  ].filter(Boolean);

  const canDelete = thread?.author?.me || tokenData?.isAdmin;

  return (
    <HomeLayout
      Header={({ ManageButton }) => (
        <>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={2}
            sx={{
              padding: {
                xl: theme.spacing(4),
                md: theme.spacing(3),
                sm: 0,
              },
            }}
          >
            <Stack
              alignItems="center"
              direction="row"
              flexGrow={1}
              justifyContent={isDownMd ? 'flex-start' : 'space-between'}
              spacing={2}
            >
              <Stack
                alignItems="center"
                direction={isDownSm ? 'column' : 'row'}
                justifyContent="center"
                spacing={1}
                sx={{ width: '100%' }}
              >
                <HIDTypography
                  component="h1"
                  isLoading={threadOrPermissionsIsLoading}
                  skeletonWidth="60%"
                  sx={{ width: '100%' }}
                  variant={isDownLg ? 'h4' : 'h3'}
                >
                  {thread?.subject}
                </HIDTypography>
                {thread?.target === ForumThreadTarget.EXPERT && (
                  <HIDTag
                    color={theme.palette.warning.light}
                    label={t('forum:forum_expert')}
                    sx={{ alignSelf: isDownSm ? 'flex-start' : 'center' }}
                  />
                )}
              </Stack>
              {!isDownSm && ViewsAndReplies}
            </Stack>
            {isDownMd ? ManageButton : null}
          </Stack>
          {isDownSm && (
            <Stack direction="row" justifyContent="flex-end" sx={{ marginTop: 2 }}>
              {ViewsAndReplies}
            </Stack>
          )}
          {(thread?.target === ForumThreadTarget.EXPERT && thread.author.me && !hasExpertReply) && (
            <Card
              sx={{
                margin: {
                  xl: theme.spacing(0, 4),
                  md: theme.spacing(0, 3),
                  xxs: theme.spacing(2, 0),
                },
                padding: {
                  md: theme.spacing(3),
                  xxs: theme.spacing(1, 1.5),
                },
              }}
            >
              <Typography color={theme.palette.primary.dark} variant="subtitle1">
                {t('forum:forum_expert_will_answer_soon', { topic: topic?.name || '' })}
              </Typography>
            </Card>
          )}
        </>
      )}
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ListEntitiesActions
              customActions={customActions}
              onDelete={canDelete ? handleDelete : undefined}
            />
          </Card>
          <Card sx={{ padding: 2 }}>
            <ReadMoreAboutExperts onClick={handleNavigateToExperts} />
          </Card>
        </>
      }
      breadcrumbsLinks={[
        {
          link: getForumPath(),
          name: t('forum:forum_title'),
        },
        topic?.name && {
          link: getForumTopicPath({ topicId }),
          name: topic.name,
        },
      ].filter(Boolean)}
      sideDrawerElements={[
        <ListEntitiesActions
          customActions={customActions}
          key={ListEntitiesActions.name}
          onDelete={canDelete ? handleDelete : undefined}
        />,
        <ReadMoreAboutExperts key={ReadMoreAboutExperts.name} style={{ marginTop: theme.spacing(1) }} onClick={handleNavigateToExperts} />,
      ].filter(Boolean)}
      title={thread?.subject}
    >
      <Stack>
        {Divider}
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ padding: theme.spacing(1, 0) }}
        >
          <ForumAuthorAvatar showLabel author={thread?.author} isLoading={threadOrPermissionsIsLoading} />
          <HIDTypography isLoading={threadOrPermissionsIsLoading} skeletonWidth={60} sx={{ color: theme.palette.grey[500] }}>
            {thread ? formatDate(new Date(thread.updatedAt || thread.createdAt), DateTimeFormats.DATE_AND_TIME) : ''}
          </HIDTypography>
        </Stack>
        {
          isEditMode
            ? <ForumTextEditor sx={{ marginTop: 2 }} value={threadBody} onChange={setThreadBody} />
            // eslint-disable-next-line react/no-danger
            : <span dangerouslySetInnerHTML={{ __html: thread?.body || '' }} style={{ marginTop: theme.spacing(2) }} />
        }
        {threadAttachments.length > 0 && (
          <ForumImageAttachments
            attachments={threadAttachments}
            isEditable={isEditMode}
            sx={{ marginTop: 2 }}
            onRemove={(_attachment, index) => handleRemoveThreadAttachment(index)}
          />
        )}
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="flex-end"
          spacing={1.5}
          sx={{ margin: theme.spacing(2, 0, 1.5) }}
        >
          {
            thread?.author?.me && (
              isEditMode
                ? (
                  <>
                    <HIDFilePickerButton
                      checkForStorageLimit={false}
                      color="secondary"
                      disabled={threadIsUpdating}
                      mimeTypes={ImageMimeTypes}
                      size="small"
                      sx={{ alignSelf: 'flex-end' }}
                      onFilesSelected={(newAttachments) => setThreadAttachments(threadAttachments.concat(newAttachments))}
                    />
                    <HIDButton
                      color="secondary"
                      disabled={threadIsUpdating}
                      size="small"
                      onClick={handleAboutThreadChanges}
                    >
                      {t('common:cancel')}
                    </HIDButton>
                    <HIDButton
                      color="primary"
                      disabled={threadOrPermissionsIsLoading || !threadBody.length}
                      loading={threadIsUpdating}
                      size="small"
                      onClick={handleSaveThread}
                    >
                      {t('common:save')}
                    </HIDButton>
                  </>
                )
                : (
                  <HIDButton
                    color="primary"
                    loading={threadIsUpdating}
                    size="small"
                    onClick={handleUpdateThread}
                  >
                    {t('common:update_label')}
                  </HIDButton>
                )
            )
          }
        </Stack>
        {Divider}
        {(canReadThread || threadOrPermissionsIsLoading) && (
          <>
            <Typography sx={{ marginBottom: 1 }} variant="subtitle1">
              {t('forum:forum_write_answer')}
            </Typography>
            <ForumTextEditor
              sx={{ margin: theme.spacing(2, 0) }}
              value={replyState.reply}
              onChange={(value) => setReplyState((before) => ({ ...before, reply: value }))}
            />
            {replyState.attachments.length > 0 && (
              <ForumImageAttachments
                isEditable
                attachments={replyState.attachments}
                sx={{ marginTop: 1, marginBottom: 2 }}
                onRemove={(_attachment, index) => handleRemoveReplyAttachment(index)}
              />
            )}
          </>
        )}
        {(canReadThread || threadOrPermissionsIsLoading) && (
          <Stack
            alignItems="center"
            direction="row"
            justifyContent={isPartnerAuth ? 'flex-end' : 'space-between'}
          >
            {!isPartnerAuth && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={replyState.anonymous}
                    onChange={() => setReplyState((before) => ({ ...before, anonymous: !replyState.anonymous }))}
                  />
                }
                label={isDownMd ? t('forum:forum_anonymous') : t('forum:forum_be_anonymous')}
              />
            )}
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
              spacing={1.5}
            >
              <HIDFilePickerButton
                checkForStorageLimit={false}
                color="secondary"
                mimeTypes={ImageMimeTypes}
                size="small"
                sx={{ alignSelf: 'flex-end' }}
                onFilesSelected={
                  (newAttachments) => setReplyState((before) => ({ ...before, attachments: before.attachments.concat(newAttachments) }))
                }
              />
              <HIDButton
                disabled={!replyState.reply || threadIsDeleting}
                loading={replyIsCreating}
                size="small"
                onClick={handleSendReply}
              >
                {t('common:send')}
              </HIDButton>
            </Stack>
          </Stack>
        )}
        {thread && repliesList.length > 0 && (canReadThread || threadOrPermissionsIsLoading) && (
          <>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              sx={{ margin: theme.spacing(3, 0) }}
            >
              <HIDTypography isLoading={repliesIsLoading} skeletonWidth={50} variant="subtitle1">
                {
                  replies.length === 1
                    ? `${replies.length} ${capitalize(t('forum:forum_reply'))}`
                    : `${replies.length} ${capitalize(t('forum:forum_replies'))}`
                }
              </HIDTypography>
              <ForumRepliesOrderSelect
                sx={{ margin: theme.spacing(2, 0) }}
                value={repliesSortOrder}
                onChange={setSortRepliesOrder}
              />
            </Stack>
            <Stack spacing={3}>
              {
                repliesList
                  ?.map((reply) => (
                    <ForumThreadReply
                      isAccepted={thread?.acceptedAnswerId === reply.id}
                      isDisabled={threadIsDeleting || threadIsDeleted || repliesIsLoading}
                      isLoading={repliesIsLoading}
                      key={reply.id}
                      reply={reply}
                      thread={thread}
                    />
                  ))
              }
            </Stack>
          </>
        )}
        {!canReadThread && !threadOrPermissionsIsLoading && (
          <Stack
            alignItems="center"
            direction="row"
            sx={{ marginTop: 2 }}
          >
            <HIDTypography>
              {t('subscriptions:subscription_expert_forum_blocked')}&nbsp;&nbsp;
              { }
              <HIDLink
                label={t('subscriptions:subscription_upgrade_now')}
                sx={{ paddingBottom: 0.5 }}
                onClick={() => navigate(getSubscriptionPlansPath())}
              />
            </HIDTypography>
          </Stack>
        )}
      </Stack>
    </HomeLayout>
  );
};

export default ForumThread;
