import React, {
  FC,
  useMemo,
} from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Progress,
  PropertyProgressCategoryId,
} from '@house-id/houseid-types/dist/propertyProgress';

import HIDInlineSelect from '../../../../../components/HIDInlineSelect';
import HIDCircularProgress from '../../../../../components/progress/HIDCircularProgress';
import { toggleSideDrawerOpen } from '../../../../../store/layoutReducer';
import { FCC } from '../../../../../types/common';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { useGetPropertyProgressQuery } from '../api/propertyProgress.api';
import useGetPropertyProgressCategoryMap from '../hooks/useGetPropertyProgressCategoryMap';
import { getPropertyProgressPercent } from '../utils/utils.propertyProgress';
import { getPathWithPropertyIdOrInit } from '../../../../Auth/navigation/navigation.auth';

type PropertyProgressQuickNavigationProps = {
  currentPropertyProgressCategoryId: PropertyProgressCategoryId;
};

type PropertyProgressCircularProgressProps = {
  progress: Progress;
};

const PropertyProgressCircularProgress: FC<PropertyProgressCircularProgressProps> = ({
  progress,
}) => {
  const theme = useTheme();

  return (
    <HIDCircularProgress
      progressColorBackground={theme.palette.primary.lighter}
      size="small"
      sx={{
        backgroundColor: theme.palette.common.white,
        borderRadius: '100%',
      }}
      value={getPropertyProgressPercent(progress)}
    />
  );
};

// eslint-disable-next-line react/no-multi-comp
const PropertyProgressQuickNavigation: FCC<PropertyProgressQuickNavigationProps> = ({
  currentPropertyProgressCategoryId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['property']);

  const { data: propertyId } = useGetCurrentPropertyId();
  const { currentData: propertyProgress } = useGetPropertyProgressQuery(propertyId ? { propertyId } : skipToken);

  const propertyProgressCategoryMap = useGetPropertyProgressCategoryMap();

  const handleCategoryChange = (categoryId: string) => {
    const currentCategory = propertyProgressCategoryMap[categoryId];
    if (currentCategory) {
      if (currentCategory?.getActionPath) {
        navigate(
          getPathWithPropertyIdOrInit(currentCategory.getActionPath, { propertyId }),
          { replace: true },
        );
      } else if (propertyId && currentCategory?.onClick) {
        currentCategory.onClick({ propertyId });
      }

      dispatch(toggleSideDrawerOpen(false));
    }
  };

  const mappedCategories = useMemo(() => {
    const values = propertyProgress?.categories || [];
    return values.map((category) => ({
      id: category.id,
      label: category.name,
      Icon: propertyProgressCategoryMap[category.id].Icon,
      CountComponent:
        category?.progress?.total
          ? (
            <PropertyProgressCircularProgress progress={category.progress} />
          )
          : undefined,
    }));
  }, [propertyProgress]);

  return (
    <Stack>
      <Typography sx={{ marginBottom: 1 }} variant="h6">
        {t('property:property_progress_title')}
      </Typography>
      <HIDInlineSelect
        items={mappedCategories}
        value={currentPropertyProgressCategoryId}
        onChange={handleCategoryChange}
      />
    </Stack>
  );
};

export default PropertyProgressQuickNavigation;
