import {
  Archive,
  Blueprints,
  CheckMarkRect,
  DocMoney,
  Document,
  HandOver,
  Home,
  HomeSettings,
  HomeSuccess,
  Offer,
  PieChart,
  Product,
  Prospect,
  Shield,
  Task,
  Truck,
} from '../../../../../components/icons/Icons';
import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import {
  Icon,
} from '../../../../../types/common';
import {
  getPropertySettingsPath,
  useNavigateToMove,
} from '../../../navigation/navigation.property';
import useGetOpenPropertyDeclarationMobileAppDialog from '../../SellingProperty/hooks/useGetOpenPropertyDeclarationMobileAppDialog';
import {
  getSellingPropertyArchivePath,
  getSellingPropertyHandoverPath,
  getSellingPropertyInformationPath,
  getSellingPropertyChecklistPath,
} from '../../SellingProperty/navigation.sellingProperty';
import {
  getPropertyPurchasePath,
  getPropertySpecificationPath,
  getPropertyBlueprintsPath,
  getPropertyDocumentationPath,
  getPropertyPlanningPath,
  getPropertyFinancePath,
  getPropertyInsuranceAndSafetyPath,
  getPropertyProductsPath,
} from '../navigation.propertyProgress';

import { PropertyProgressCategoryId } from '@house-id/houseid-types/dist/propertyProgress';

type PropertyProgressCategoryMapDetails = {
  getActionPath?: ({ propertyId }: { propertyId: string }) => string;
  onClick?: ({ propertyId }: { propertyId: string }) => void;
  Icon: Icon;
};

type UseGetPropertyProgressCategoryMap = () => Record<string, PropertyProgressCategoryMapDetails>;

const useGetPropertyProgressCategoryMap: UseGetPropertyProgressCategoryMap = () => {
  const navigateToMove = useNavigateToMove();

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const openPropertyDeclarationMobileAppDialog = useGetOpenPropertyDeclarationMobileAppDialog();

  return {
    [PropertyProgressCategoryId.PURCHASE]: {
      getActionPath: getPropertyPurchasePath,
      Icon: Prospect,
    },
    [PropertyProgressCategoryId.SPECIFICATIONS]: {
      getActionPath: getPropertySpecificationPath,
      Icon: Home,
    },
    [PropertyProgressCategoryId.BLUEPRINTS]: {
      getActionPath: getPropertyBlueprintsPath,
      Icon: Blueprints,
    },
    [PropertyProgressCategoryId.DOCUMENTS]: {
      getActionPath: getPropertyDocumentationPath,
      Icon: Document,
    },
    [PropertyProgressCategoryId.SETTINGS]: {
      getActionPath: getPropertySettingsPath,
      Icon: HomeSettings,
    },
    [PropertyProgressCategoryId.PLANNING]: {
      getActionPath: getPropertyPlanningPath,
      Icon: Task,
    },
    [PropertyProgressCategoryId.FINANCE]: {
      getActionPath: getPropertyFinancePath,
      Icon: PieChart,
    },
    [PropertyProgressCategoryId.INSURANCE_AND_SAFETY]: {
      getActionPath: getPropertyInsuranceAndSafetyPath,
      Icon: Shield,
    },
    [PropertyProgressCategoryId.OFFERS]: {
      onClick: openMobileAppDialog,
      getActionPath: undefined,
      Icon: Offer,
    },
    [PropertyProgressCategoryId.PRODUCTS]: {
      getActionPath: getPropertyProductsPath,
      Icon: Product,
    },
    [PropertyProgressCategoryId.SALE_CHECKLIST]: {
      getActionPath: getSellingPropertyChecklistPath,
      Icon: CheckMarkRect,
    },
    [PropertyProgressCategoryId.DECLARATION]: {
      onClick: openPropertyDeclarationMobileAppDialog,
      getActionPath: undefined,
      Icon: DocMoney,
    },
    [PropertyProgressCategoryId.REALTOR_PROPERTY_INFORMATION]: {
      getActionPath: getSellingPropertyInformationPath,
      Icon: HomeSuccess,
    },
    [PropertyProgressCategoryId.MOVING_SERVICES]: {
      onClick: navigateToMove,
      Icon: Truck,
    },
    [PropertyProgressCategoryId.HANDOVER]: {
      getActionPath: getSellingPropertyHandoverPath,
      Icon: HandOver,
    },
    [PropertyProgressCategoryId.ARCHIVE]: {
      getActionPath: getSellingPropertyArchivePath,
      Icon: Archive,
    },
  };
};

export default useGetPropertyProgressCategoryMap;
