import React, {
  FC,
  useMemo,
} from 'react';
import {
  Card,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { skipToken } from '@reduxjs/toolkit/query';

import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import { useGetEventsQuery } from '../../../modules/Content/modules/Timeline/api/timeline.api';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import useScrollWithShadow from '../../../../../hooks/useScrollWithShadow';
import {
  hidSpacing,
} from '../../../../../utils/number';
import { TimelineEvent } from '../../../modules/Content/modules/Timeline/types.timeline';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../utils/date';
import RemindersImage from '../../../../../assets/images/reminders_empty.png';
import { getTypographyHeight } from '../../../../../utils/style';
import {
  HomeWidgetType,
  useGetWidgetHeight,
} from '../utils/useGetWidgetHeight';

const SHOW_REMINDERS_COUNT = 5;

const RemindersLoadingState = () => {
  const theme = useTheme();

  const lines = 2;
  const itemSpacing = 0.25;
  const sizeReduce = hidSpacing(((lines - 1) * itemSpacing) / lines);

  return (
    <Stack>
      {R.range(0, SHOW_REMINDERS_COUNT).map((_, index) => (
        <Stack key={index} spacing={0.25} sx={{ padding: theme.spacing(0.5, 1) }}>
          <Skeleton height={getTypographyHeight(theme.typography.subtitle1) - sizeReduce} variant="rounded" width="100%" />
          <Skeleton height={getTypographyHeight(theme.typography.body2) - sizeReduce} variant="rounded" width="100%" />
        </Stack>
      ))}
    </Stack>
  );
};

// eslint-disable-next-line react/no-multi-comp
const RemindersEmptyState = () => {
  const { t } = useTranslation(['home']);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={2.5}
      sx={{
        padding: 3,
        height: '100%',
      }}
    >
      <img src={RemindersImage} style={{ width: 100, height: 96 }} />
      <Typography sx={{ textAlign: 'center' }} variant="subtitle1">
        {t('home:home_no_reminders')}
      </Typography>
      <Typography sx={{ textAlign: 'center' }} variant="body1">
        {t('home:home_no_reminders_description')}
      </Typography>
      <Typography variant="body1">
        &nbsp;
      </Typography>
    </Stack>
  );
};

// eslint-disable-next-line react/no-multi-comp
const EventItem: FC<{ event: TimelineEvent }> = ({
  event,
}) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{ padding: theme.spacing(0.5) }}
    >
      <Typography variant="subtitle1">
        {event.title}
      </Typography>
      <Typography sx={{ color: theme.palette.grey[500] }} variant="body2">
        {event.dateHeader || formatDate(new Date(event.start), DateTimeFormats.DATE_ONLY_TEXT)}
      </Typography>
    </Stack>
  );
};

// eslint-disable-next-line react/no-multi-comp
const RemindersWidget: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['home']);

  const { isDownSm } = useBreakPointsSizes();

  const widgetHeight = useGetWidgetHeight(HomeWidgetType.REMINDERS);

  const titleHeight = hidSpacing(3);

  const dateNow = useMemo(() => new Date(), []);

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: { events = [] } = {}, isLoading } = useGetEventsQuery(
    propertyId
      ? {
        propertyId,
        from: dateNow.toISOString(),
        orderBy: 'start_asc',
        pageSize: SHOW_REMINDERS_COUNT,
      }
      : skipToken,
  );

  const { boxShadow, scrollContainerRef, onScrollHandler } = useScrollWithShadow({ showBottom: false });

  const hasNoItems = !isLoading && !events.length;

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.common.white,
        padding: isDownSm ? theme.spacing(2) : theme.spacing(2, 1, 0, 2),
        height: widgetHeight || '100%',
      }}
    >
      <Stack spacing={1} sx={{ height: '100%' }}>
        <Typography
          sx={{ height: titleHeight }}
          variant="h5"
        >
          {t('home:home_reminders')}
        </Typography>
        {
          isLoading
            ? <RemindersLoadingState />
            : hasNoItems
              ? <RemindersEmptyState />
              : (
                <Stack
                  ref={scrollContainerRef}
                  sx={{
                    height: widgetHeight - titleHeight - hidSpacing(1 + 2),
                    overflowY: 'auto',
                    boxShadow,
                  }}
                  onScroll={onScrollHandler}
                >
                  {events.map((event, index) => (
                    <EventItem event={event} key={index} />
                  ))}
                </Stack>
              )
        }
      </Stack>
    </Card>
  );
};

export default RemindersWidget;
