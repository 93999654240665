import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';

import layoutReducer, { resetLayoutState } from './layoutReducer';
import { propertyApi } from '../modules/Property/api/property.api';
import { receiptApi } from '../modules/Property/modules/Content/modules/Receipt/api/receipt.api';
import rtkErrorLoggerMiddleware from './middlewares/rtkErrorLoggerMiddleware';
import { partnersApi } from '../modules/Partners/api/partners.api';
import { documentApi } from '../modules/Property/modules/Content/modules/Document/api/document.api';
import { contentApi } from '../modules/Property/modules/Content/api/content.api';
import { photoApi } from '../modules/Property/modules/Content/modules/Photo/api/photo.api';
import { noteApi } from '../modules/Property/modules/Content/modules/Note/api/note.api';
import { contentFileApi } from '../modules/Property/modules/Content/modules/ContentFile/api/contentFile.api';
import { productApi } from '../modules/Property/modules/Content/modules/Product/api/product.api';
import {
  dialogsReducer,
  resetDialogState,
} from './dialogsReducer';
import { adminApi } from '../modules/Admin/api/admin.api';
import { buildingApi } from '../modules/Property/modules/Content/modules/Building/api/building.api';
import { taskApi } from '../modules/Property/modules/Content/modules/GoodToDoTask/api/task.api';
import { settingsApi } from '../modules/Auth/api/settings.api';
import { financeApi } from '../modules/Property/modules/Finances/api/finance.api';
import { articleApi } from '../modules/Property/modules/Content/modules/Article/api/article.api';
import { supportApi } from '../modules/Property/api/support.api';
import { forumApi } from '../modules/Forum/api/forum.api';
import { userCommonApi } from '../modules/Auth/api/user.common.api';
import { authApi } from '../modules/Auth/api/auth.api';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [propertyApi.reducerPath]: propertyApi.reducer,
    [userCommonApi.reducerPath]: userCommonApi.reducer,
    [partnersApi.reducerPath]: partnersApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    layout: layoutReducer,
    dialogs: dialogsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredPaths: ['dialogs.list'],
      ignoredActions: ['dialogs/showDialog'],
    },
  }).prepend(
    authApi.middleware,
    propertyApi.middleware,
    userCommonApi.middleware,
    partnersApi.middleware,
    adminApi.middleware,
  )
    .concat(
      rtkErrorLoggerMiddleware,
    ),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useGetClearCache = () => {
  const dispatch = useDispatch();

  const resetCache = () => {
    dispatch(resetDialogState());
    dispatch(resetLayoutState());
    dispatch(propertyApi.util.resetApiState());
    dispatch(userCommonApi.util.resetApiState());
    dispatch(receiptApi.util.resetApiState());
    dispatch(documentApi.util.resetApiState());
    dispatch(partnersApi.util.resetApiState());
    dispatch(contentApi.util.resetApiState());
    dispatch(photoApi.util.resetApiState());
    dispatch(noteApi.util.resetApiState());
    dispatch(contentFileApi.util.resetApiState());
    dispatch(productApi.util.resetApiState());
    dispatch(adminApi.util.resetApiState());
    dispatch(buildingApi.util.resetApiState());
    dispatch(taskApi.util.resetApiState());
    dispatch(settingsApi.util.resetApiState());
    dispatch(financeApi.util.resetApiState());
    dispatch(articleApi.util.resetApiState());
    dispatch(supportApi.util.resetApiState());
    dispatch(forumApi.util.resetApiState());
  };

  return resetCache;
};
