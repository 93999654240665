import React, {
  FC,
} from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BudgetTemplateCategoryId } from '@house-id/houseid-types/dist/finances/budgets';

import {
  CurrencyAdornment,
  CustomAdornment,
} from '../../../../../../../../../components/HIDTextField';
import useBreakPointsSizes from '../../../../../../../../../hooks/useBreakpointsSizes';
import DebouncedTextField from './DebouncedTextField';
import BudgetSubcategoryHeader from './BudgetSubcategoryHeader';
import { getMortgagesDataPath } from '../utils/formikFieldDataPath.utils';

type MortgagePaymentCardProps = {
  name: string;
  index: number;
  subcategoryIndex: number;
  categoryId: BudgetTemplateCategoryId;
  isCopied?: boolean;
  onDelete: () => void;
};

const mortgagePaymentCardInputProps = {
  min: 0,
  step: 0.1,
};

const BudgetMortgageCard: FC<MortgagePaymentCardProps> = ({
  name,
  index,
  categoryId,
  subcategoryIndex,
  isCopied,
  onDelete,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['finances']);
  const { isDownSm } = useBreakPointsSizes();

  const mortgagesPath = getMortgagesDataPath(categoryId, subcategoryIndex);
  const mortgageFieldPath = `${mortgagesPath}.${index}`;

  return (
    <Stack
      spacing={{ sm: 1, md: 2 }}
      sx={{
        padding: 1.5,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: '5px',
        borderColor: isCopied ? theme.palette.primary.dark : theme.palette.grey[300],
        backgroundColor: theme.palette.common.white,
        width: '100%',
      }}
    >
      <BudgetSubcategoryHeader
        name={name}
        onDelete={onDelete}
      />
      <Stack
        direction={isDownSm ? 'column' : 'row'}
        justifyContent="space-between"
        spacing={{ sm: 1, md: 2 }}
      >
        <DebouncedTextField
          isRequired
          endAdornment={<CurrencyAdornment />}
          fieldPath={`${mortgageFieldPath}.debt`}
          inputProps={mortgagePaymentCardInputProps}
          label={t('finances:budgets_payment_mortgage_debt')}
          type="number"
        />
        <DebouncedTextField
          isRequired
          endAdornment={<CustomAdornment label="%" />}
          fieldPath={`${mortgageFieldPath}.averageInterestRate`}
          inputProps={mortgagePaymentCardInputProps}
          label={t('finances:budgets_payment_interest_rate_label')}
          type="number"
        />
        <DebouncedTextField
          isRequired
          endAdornment={<CurrencyAdornment />}
          fieldPath={`${mortgageFieldPath}.amortization`}
          inputProps={mortgagePaymentCardInputProps}
          label={t('finances:budgets_payment_amortization_label')}
          type="number"
        />
      </Stack>
    </Stack>
  );
};

export default BudgetMortgageCard;
