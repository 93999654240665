import RouteNames from '../../../../../../routes/RouteNames';
import { generateRoutePath } from '../../../../../../utils/routes';
import { PropertyIdNavigation } from '../../../../types/property.types';

type BudgetsPathParams = PropertyIdNavigation & { id?: string; };

export const getBudgetsOverviewPath = ({ propertyId, id }: BudgetsPathParams) => generateRoutePath(
  RouteNames.BUDGETS_OVERVIEW,
  { propertyId },
  { budgetId: id },
);

type BudgetCategoryPathParams = PropertyIdNavigation & { id: string; categoryId?: string; };

export const getBudgetCategoryPath = ({ propertyId, id, categoryId }: BudgetCategoryPathParams) => generateRoutePath(
  RouteNames.BUDGET_CATEGORY,
  { propertyId, id },
  { categoryId },
);

export const getCreateBudgetPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.BUDGETS_CREATE,
  { propertyId },
);

type UpdateBudgetPathParams = PropertyIdNavigation & { id: string; categoryId?: string };

export const getUpdateBudgetPath = ({ propertyId, id, categoryId }: UpdateBudgetPathParams) => generateRoutePath(
  RouteNames.BUDGETS_UPDATE,
  { propertyId, id },
  { categoryId },
);
