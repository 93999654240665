import { useMemo } from 'react';
import * as R from 'ramda';
import {
  QueryHook,
} from '../../../types/common';
import {
  SubscriptionPlanOfferByPeriod,
  SubscriptionPlanOptionMddBuySettingType,
} from '../subscriptionPlans.types';
import { useGetSubscriptionPlanOffersQuery } from '../api/subscriptionPlans.api';
import { useGetActiveAuthUser } from '../../../external-services/firebase';
import { getSubscriptionPlanPeriod } from '../subscriptionPlans.utils';

type UseGetSubscriptionPlanOffers =
  QueryHook<Record<string, SubscriptionPlanOfferByPeriod> | undefined>;

const useGetSubscriptionPlanOffers: UseGetSubscriptionPlanOffers = (
  _args,
  options,
) => {
  const { user: currentUser } = useGetActiveAuthUser();
  const { data: subscriptionPlanOffers = [], ...rest } = useGetSubscriptionPlanOffersQuery(undefined, {
    skip: Boolean(!currentUser || options?.skip),
  });

  const periodData = useMemo(() => {
    if (subscriptionPlanOffers.length) {
      return R.fromPairs(
        subscriptionPlanOffers
          .map((subscriptionPlan) => {
            const periods = R.fromPairs(
              subscriptionPlan.periods
                .filter(
                  (periodData) => periodData.providerData.buySettings.type !== SubscriptionPlanOptionMddBuySettingType.NOT_AVAILABLE,
                )
                .map((period) => [getSubscriptionPlanPeriod(period.duration), period]),
            );
            return {
              planId: subscriptionPlan.id,
              periods,
            };
          })
          .map((subscriptionPlan) => [subscriptionPlan.planId, subscriptionPlan]),
      ) as unknown as Record<string, SubscriptionPlanOfferByPeriod>;
    }
    return undefined;
  }, [subscriptionPlanOffers]);

  return {
    data: periodData,
    ...rest,
  };
};

export default useGetSubscriptionPlanOffers;
