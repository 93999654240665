/* eslint-disable no-param-reassign */
import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import DialogNames from '../hooks/useDialog/DialogNames';

export type DialogStateListEntry = {
  id: string
  name: DialogNames
  props?: Partial<{ open: boolean, onDisable?: () => void; onAfterClose?: () => void; }>,
};

export type DialogStateType = {
  list: DialogStateListEntry[];
  showedDialogIds: Array<string>;
};

const INITIAL_STATE: DialogStateType = {
  list: [],
  showedDialogIds: [],
};

const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState: INITIAL_STATE,
  reducers: {
    showDialog(state, action: PayloadAction<DialogStateListEntry>) {
      const dialog = state.list.find((dialog) => dialog.id === action.payload.id);
      if (!dialog) {
        state.list.push({ ...action.payload, props: { ...action.payload.props, open: true } });
      } else if (!dialog.props?.open) {
        dialog.props = {
          ...action.payload.props,
          open: true,
        };
      }
    },
    hideDialog(state, action: PayloadAction<{ id: string, props?: DialogStateListEntry['props'] }>) {
      const dialog = state.list.find((dialog) => dialog.id === action.payload.id);
      if (dialog) {
        dialog.props = {
          ...dialog.props,
          open: false,
        };
        state.showedDialogIds.push(dialog.id);
      }
    },
    removeDialog(state, action: PayloadAction<string>) {
      state.list = state.list.filter((dialog) => dialog.id !== action.payload);
    },
    resetDialogState(state) {
      state.list = INITIAL_STATE.list;
    },
  },
});

export const {
  showDialog,
  hideDialog,
  removeDialog,
  resetDialogState,
} = dialogsSlice.actions;

export const dialogsReducer = dialogsSlice.reducer;

export const getDialogs = ({ dialogs }: { dialogs: DialogStateType }) => dialogs.list;

export const getIsDialogOpen = (id: string, { dialogs }: { dialogs: DialogStateType }) => Boolean(
  dialogs.list.find((dialog) => dialog.id === id)?.props?.open,
);
