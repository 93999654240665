import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import { PropertyProgressCategoryId } from '@house-id/houseid-types/dist/propertyProgress';

import {
  PropertyBlueprintsSectionId,
  PropertyDocumentationSectionId,
  PropertyFinanceSectionId,
  PropertyPlaningSectionId,
  PropertyProductsSectionId,

  PropertyPurchaseSectionId,
  PropertySpecificationSectionId,
} from '../types.propertyProgress';

type UseGetPropertyProgressCategoryContentTextParams = {
  categoryId: PropertyProgressCategoryId;
  sectionId?: string;
  percent: number;
};

type GetTextByPercentOrDefaultParams = {
  percent: number;
  moreThanZeroText: string;
  defaultText: string;
};
const getTextByPercentOrDefault = ({
  percent,
  moreThanZeroText,
  defaultText,
}: GetTextByPercentOrDefaultParams) => match(percent)
  .when((percent) => percent > 0, () => moreThanZeroText)
  .otherwise(() => defaultText);

type UseGetPropertyProgressCategoryContentText = () => (params: UseGetPropertyProgressCategoryContentTextParams) => string | undefined;

type PropertyProgressCategoryContent = {
  description: (params: UseGetPropertyProgressCategoryContentTextParams) => string;
  sections: Record<string, (params: UseGetPropertyProgressCategoryContentTextParams) => string>
};

const useGetPropertyProgressCategoryContentText: UseGetPropertyProgressCategoryContentText = () => {
  const { t } = useTranslation(['property_progress']);

  const texts: Record<string, PropertyProgressCategoryContent> = {
    [PropertyProgressCategoryId.BLUEPRINTS]: {
      description:
        ({ percent }) => getTextByPercentOrDefault({
          percent,
          moreThanZeroText: '',
          defaultText: t('property_progress:photos_and_blueprints_category_content_description_default'),
        }),
      sections: {
        [PropertyBlueprintsSectionId.PROPERTY_COVER_PHOTOS]: () =>
          t('property_progress:property_progress_property_blueprints_cover_images'),
        [PropertyBlueprintsSectionId.PROPERTY_PHOTOS]: ({ percent }) => getTextByPercentOrDefault({
          percent,
          moreThanZeroText: t('property_progress:property_cover_photos_section_description_more_than_zero'),
          defaultText: t('property_progress:property_cover_photos_section_description_default'),
        }),
        [PropertyBlueprintsSectionId.BLUEPRINT_DOCUMENT_TYPES]: ({ percent }) => getTextByPercentOrDefault({
          percent,
          moreThanZeroText: t('property_progress:blueprint_document_types_section_description_more_than_zero'),
          defaultText: t('property_progress:blueprint_document_types_section_description_default'),
        }),
      },
    },
    [PropertyProgressCategoryId.PLANNING]: {
      description:
        ({ percent }) => getTextByPercentOrDefault({
          percent,
          moreThanZeroText: '',
          defaultText: t('property_progress:property_progress_property_planning_description'),
        }),
      sections: {
        [PropertyPlaningSectionId.PROPERTY_PLANNING_TIMELINE_ENTRIES]: ({ percent }) =>
          getTextByPercentOrDefault({
            percent,
            moreThanZeroText: t('property_progress:property_planning_timeline_entries_description_more_than_zero'),
            defaultText: '',
          }),
        [PropertyPlaningSectionId.PROPERTY_PLANNING_ROOMS]: ({ percent }) =>
          getTextByPercentOrDefault({
            percent,
            moreThanZeroText: t('property_progress:property_planning_rooms_description_more_than_zero'),
            defaultText: t('property_progress:property_planning_rooms_description_default'),
          }),
        [PropertyPlaningSectionId.PROPERTY_PLANNING_TASKS_CONNECTED_TO_ROOM]: () =>
          t('property_progress:property_planning_tasks_connected_to_room_description'),
        [PropertyPlaningSectionId.PROPERTY_PLANNING_TASKS_NOT_CONNECTED_TO_ROOM]: () =>
          t('property_progress:property_planning_tasks_not_connected_to_room_description'),

      },
    },
    [PropertyProgressCategoryId.INSURANCE_AND_SAFETY]: {
      description:
        ({ percent }) => getTextByPercentOrDefault({
          percent,
          moreThanZeroText: '',
          defaultText: t('property_progress:property_progress_property_insurance_and_safety_description'),
        }),
      sections: {},
    },
    [PropertyProgressCategoryId.FINANCE]: {
      description:
        ({ percent }) => getTextByPercentOrDefault({
          percent,
          moreThanZeroText: '',
          defaultText: t('property_progress:finance_content_description_default'),
        }),
      sections: {
        [PropertyFinanceSectionId.FINANCE_LOANS]: ({ percent }) =>
          getTextByPercentOrDefault({
            percent,
            moreThanZeroText: '',
            defaultText: t('property_progress:finance_loans_description_default'),
          }),
        [PropertyFinanceSectionId.FINANCE_LOANS_INTEREST_RATE]: ({ percent }) =>
          getTextByPercentOrDefault({
            percent,
            moreThanZeroText: t('property_progress:finance_loans_interest_rate_description_more_than_zero'),
            defaultText: t('property_progress:finance_loans_interest_rate_description_default'),
          }),
      },
    },
    [PropertyProgressCategoryId.SPECIFICATIONS]: {
      description:
        ({ percent }) => getTextByPercentOrDefault({
          percent,
          moreThanZeroText: t('property_progress:property_specification_description_more_than_zero'),
          defaultText: t('property_progress:property_specification_description_default'),
        }),
      sections: {
        [PropertySpecificationSectionId.BUILDINGS]: ({ percent }) =>
          getTextByPercentOrDefault({
            percent,
            moreThanZeroText: t('property_progress:property_specification_buildings_description_more_than_zero'),
            defaultText: t('property_progress:property_specification_buildings_description_default'),
          }),
        [PropertySpecificationSectionId.OUTDOOR]: ({ percent }) =>
          getTextByPercentOrDefault({
            percent,
            moreThanZeroText: t('property_progress:property_specification_outdoors_description_more_than_zero'),
            defaultText: t('property_progress:property_specification_outdoors_description_default'),
          }),
      },
    },
    [PropertyProgressCategoryId.DOCUMENTS]: {
      description: () => '',
      sections: {
        [PropertyDocumentationSectionId.PROPERTY_DOCUMENTATION_DOCUMENT_TYPES]: ({ percent }) =>
          getTextByPercentOrDefault({
            percent,
            moreThanZeroText: t('property_progress:property_documentation_document_types_description_more_than_zero'),
            defaultText: t('property_progress:property_documentation_document_types_description_default'),
          }),
        [PropertyDocumentationSectionId.PROPERTY_DOCUMENTATION_RECEIPT_SUMMARY]: ({ percent }) =>
          getTextByPercentOrDefault({
            percent,
            moreThanZeroText: t('property_progress:property_documentation_receipt_summary_description_more_than_zero'),
            defaultText: t('property_progress:property_documentation_receipt_summary_description_default'),
          }),
      },
    },
    [PropertyProgressCategoryId.PURCHASE]: {
      description: () => '',
      sections: {
        [PropertyPurchaseSectionId.PROPERTY_PURCHASE_DOCUMENT_TYPES]: ({ percent }) =>
          getTextByPercentOrDefault({
            percent,
            moreThanZeroText: t('property_progress:property_purchase_document_types_description_more_than_zero'),
            defaultText: t('property_progress:property_purchase_document_types_description_default'),
          }),
      },
    },
    [PropertyProgressCategoryId.PRODUCTS]: {
      description:
        ({ percent }) => getTextByPercentOrDefault({
          percent,
          moreThanZeroText: '',
          defaultText: t('property_progress:property_products_description_default'),
        }),
      sections: {
        [PropertyProductsSectionId.PROPERTY_PRODUCTS]: ({ percent }) =>
          getTextByPercentOrDefault({
            percent,
            moreThanZeroText: t('property_progress:property_products_section_description_more_than_zero'),
            defaultText: t('property_progress:property_products_section_description_default'),
          }),
      },
    },
  };

  return (params: UseGetPropertyProgressCategoryContentTextParams) => {
    const category = texts[params.categoryId];
    const section = category && params.sectionId
      ? category.sections[params.sectionId]
      : undefined;

    return section
      ? section(params)
      : category && !params.sectionId
        ? category.description(params)
        : undefined;
  };
};

export default useGetPropertyProgressCategoryContentText;
