import {
  Card,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { Loan } from '@house-id/houseid-types/dist/finances/finances';

import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import { FCC } from '../../../../../types/common';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../utils/date';
import { formatMoney } from '../../../../../utils/string';
import { getNoWrapStyle } from '../../../../../utils/style';
import PropertyProgressContentItem from '../../PropertyProgress/components/PropertyProgressContentItem';
import { BudgetCostColors } from '../constants.finance';
import { LoansPieChart } from './LoansPieChart';
import Home from '../../../../../components/icons/Home';

type LoanItemProps = {
  loan: Loan;
  total: number;
  color: string;
};

const LoanItem: FC<LoanItemProps> = ({
  loan,
  total,
  color,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['finances']);

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const loanPercentage = Math.abs((loan.balance / total) * 100);
  const interest = loan.interest * 100;

  const title = t('finances:loan_with_percent', { percent: loanPercentage.toFixed(2) });
  const subtitle = t('finances:interest_rate_with_percent', { percent: interest.toFixed(2) });
  const value = Math.abs(loan.balance);

  const handleLoanView = () => openMobileAppDialog();

  return (
    <PropertyProgressContentItem
      DescriptionComponent={
        <Stack>
          <Typography variant="subtitle2">{formatMoney(value)}</Typography>
          <Typography variant="body2">{subtitle}</Typography>
        </Stack>
      }
      Icon={() => (
        <Card sx={{ padding: 2, backgroundColor: theme.palette.grey[50] }}>
          <Stack alignItems="center" justifyContent="center">
            <Home iconColor={color} />
          </Stack>
        </Card>
      )}
      title={title}
      onNavigate={handleLoanView}
    />
  );
};

type BudgetWidgetProps = {
  loans: Array<Loan>;
  lastSyncDate?: string;
};

// eslint-disable-next-line react/no-multi-comp
const LoansWidget: FCC<BudgetWidgetProps> = ({
  loans,
  lastSyncDate: lastSyncDateProp,
}) => {
  const { t } = useTranslation(['forms_common', 'finances']);
  const theme = useTheme();

  const loansValues = loans.map((item) => ({ name: item.loanNumber, value: Math.abs(item.balance) }));

  const loansSum = Math.abs(R.sum(loans.map((i) => i.balance)));

  const lastSyncDate = lastSyncDateProp
    ? formatDate(new Date(lastSyncDateProp), DateTimeFormats.DATE_ONLY)
    : undefined;

  return loansValues.length
    ? (
      <Card
        sx={{
          paddingY: 2,
          paddingX: 1,
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: '10px',
          borderColor: theme.palette.grey[300],
          backgroundColor: theme.palette.common.white,
          width: '100%',
        }}
      >
        <Stack
          justifyContent="space-between"
          spacing={1}
        >
          <Stack
            alignItems="center"
            direction="row"
            flexWrap="wrap"
            spacing={1}
          >
            {Boolean(loansValues.length) && <LoansPieChart data={loansValues} size={120} />}
            <Stack justifyContent="center">
              <Typography
                sx={getNoWrapStyle(1)}
                variant="subtitle1"
              >
                {t('forms_common:total')}
              </Typography>
              <Typography variant="h3">
                {formatMoney(loansSum, false)}
              </Typography>
            </Stack>
          </Stack>
          <Typography color={theme.palette.grey[400]} paddingLeft={1}>
            {t('forms_common:last_updated_date', { date: lastSyncDate })}
          </Typography>
          <Grid container spacing={1} xxs={12}>
            {loans.map((loan, index) => (
              <Grid
                item
                key={loan.id}
                sm={6}
                xxs={12}
              >
                <LoanItem color={BudgetCostColors[index]} loan={loan} total={loansSum} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Card>
    )
    : null;
};

export default LoansWidget;
