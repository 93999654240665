import {
  doc,
  getDoc,
  setDoc,
} from 'firebase/firestore';

import { propertyApi } from '../../Property/api/property.api';
import { HIDApiTags } from '../../../api/HIDApiTags';
import {
  UserSettings,
  PropertySettings,
} from '../types/settings.types';
import {
  getActiveAuth,
  firestore,
} from '../../../external-services/firebase';
import { PropertyId } from '../../Property/types/property.types';

export const settingsApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserSettings: builder.query<UserSettings | undefined, { userUId?: string }>({
      queryFn: async ({ userUId: currentUserUid }) => {
        if (currentUserUid) {
          const userDocument = await getDoc(doc(firestore, 'users', currentUserUid));
          const userDocumentData: Record<string, string> | undefined = userDocument.data();
          return { data: userDocumentData };
        }
        return { data: undefined };
      },
      providesTags: [HIDApiTags.USER_SETTINGS],
    }),
    setUserSettings: builder.mutation<void, UserSettings | undefined>({
      queryFn: async (settings) => {
        const currentUserUid = getActiveAuth().currentUser?.uid;
        if (currentUserUid) {
          await setDoc(doc(firestore, 'users', currentUserUid), settings, {
            merge: true,
          });
        }
        return { data: undefined };
      },
      invalidatesTags: [HIDApiTags.USER_SETTINGS],
    }),
    getPropertySettings: builder.query<PropertySettings | undefined, { userUId: string | undefined } & PropertyId>({
      queryFn: async ({ userUId: currentUserUid, propertyId }) => {
        if (currentUserUid) {
          const propertyData = await getDoc(doc(firestore, 'users', currentUserUid, 'properties', propertyId));

          const propertyDocumentData: Record<string, string> | undefined = propertyData.data();
          return { data: propertyDocumentData };
        }
        return { data: undefined };
      },
      providesTags: [HIDApiTags.USER_SETTINGS],
    }),
    setPropertySettings: builder.mutation<void, PropertyId & { settings: PropertySettings | undefined }>({
      queryFn: async ({ propertyId, settings }) => {
        const currentUserUid = getActiveAuth().currentUser?.uid;
        if (currentUserUid) {
          await setDoc(doc(firestore, 'users', currentUserUid, 'properties', propertyId), settings, {
            merge: true,
          });
        }
        return { data: undefined };
      },
      invalidatesTags: [HIDApiTags.USER_SETTINGS],
    }),
  }),
});

export const {
  useGetUserSettingsQuery,
  useGetPropertySettingsQuery,
  useLazyGetPropertySettingsQuery,
  useSetUserSettingsMutation,
  useSetPropertySettingsMutation,
} = settingsApi;
