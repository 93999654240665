import qs from 'query-string';
import {
  HIDEntityId,
  ModifyActionType,
} from '@house-id/houseid-types/dist/common';
import {
  ExpenseBankAccount,
  ExpenseBankAccountUserConsents,
  ExpensesBankAccountsConsentsAction,
  FinsharkAuthorization,
  FinsharkSessionIdentifier,
  FixedExpensesCandidatesSyncProgress,
  RecurringExpense,
  RecurringExpenseSummary,
  RecurringExpensesSummary,
  SyncBankAccountsData,
} from '@house-id/houseid-types/dist/finances/recurringExpenses';

import { HIDApiTags } from '../../../../../../../api/HIDApiTags';
import { propertyApi } from '../../../../../api/property.api';
import { TimeIntervalsGroupingType } from '../../../../Content/types/content.type';

export type GetRecurringExpensesFiltrationProps = {
  accounts?: Array<string>;
  from?: string;
  to?: string;
  grouping?: TimeIntervalsGroupingType;
  categories?: string;
  expenses?: string;
  includeIncompleteMonths?: boolean;
};

export type GetRecurringExpensesComparisonProps = {
  accounts?: Array<string>;
  year?: number,
  compareToYear?: number;
  budget?: string;
  fromMonth?: number;
  toMonth?: number;
  grouping?: TimeIntervalsGroupingType;
  categories?: string;
  expenses?: string;
  includeIncompleteMonths?: boolean;
};

type RecurringExpenseOriginalResponse = {
  suggested: Array<RecurringExpense>,
  recurring: Array<RecurringExpense>,
  other: Array<RecurringExpense>,
};

export type GetRecurringExpensesCandidatesResponse = {
  candidates: Array<RecurringExpense>,
  recurringExpense: Array<RecurringExpense>,
  notMappedExpenses: Array<RecurringExpense>,
};

export type PatchRecurringExpensesBankAccountsProps = {
  actions: Array<{
    id: string;
    action: ModifyActionType;
    offlineSyncAllowed?: boolean;
  }>
};

export type RecurringExpenseUpdateModel = Omit<RecurringExpense, 'transactions' | 'lastTransaction' | 'transactionsCount'>
& { action: ModifyActionType.UPDATE } & HIDEntityId;

export type RecurringExpenseCreateModel = Omit<RecurringExpense, 'transactions' | 'lastTransaction' | 'transactionsCount'>
& { action: ModifyActionType.CREATE };

export type RecurringExpenseDeleteModel = HIDEntityId & { action: ModifyActionType.DELETE };

export type RecurringExpenseModifyActionModel = RecurringExpenseUpdateModel | RecurringExpenseCreateModel;

export const recurringExpensesApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecurringExpensesFiltration: builder.query<RecurringExpensesSummary, GetRecurringExpensesFiltrationProps>({
      query: (params) => `/banking/recurring-expenses/summary?${qs.stringify(params)}`,
      providesTags: () => [HIDApiTags.RECURRING_EXPENSE_FILTRATION],
    }),
    getRecurringExpensesComparison: builder.query<RecurringExpensesSummary, GetRecurringExpensesComparisonProps>({
      query: (params) => `/banking/recurring-expenses/summary?${qs.stringify(params)}`,
      providesTags: () => [HIDApiTags.RECURRING_EXPENSE_COMPARISON],
    }),
    getRecurringExpensesCandidates: builder.query<GetRecurringExpensesCandidatesResponse, void>({
      query: () => '/banking/recurring-expenses/suggestions',
      providesTags: () => [HIDApiTags.RECURRING_EXPENSE_CANDIDATE],
      transformResponse: (response: RecurringExpenseOriginalResponse) => ({
        candidates: response.suggested,
        recurringExpense: response.recurring,
        notMappedExpenses: response.other,
      }),
    }),
    getRecurringExpensesSummary: builder.query<RecurringExpenseSummary, void>({
      query: () => '/banking/summary',
      providesTags: () => [HIDApiTags.RECURRING_EXPENSE_SUMMARY],
    }),
    getRecurringExpensesBankAccounts: builder.query<Array<ExpenseBankAccount>, void>({
      query: () => '/banking/accounts',
      providesTags: () => [HIDApiTags.RECURRING_EXPENSE_BANK_ACCOUNT],
      transformResponse: (response: { accounts: Array<ExpenseBankAccount> }) => response.accounts,
    }),
    patchRecurringExpensesBankAccounts: builder.mutation<void, PatchRecurringExpensesBankAccountsProps>({
      query: ({ actions }) => ({
        url: '/banking/accounts',
        method: 'PATCH',
        body: actions,
      }),
      invalidatesTags: () => [
        HIDApiTags.RECURRING_EXPENSE_BANK_ACCOUNT,
        HIDApiTags.RECURRING_EXPENSE,
        HIDApiTags.RECURRING_EXPENSE_CANDIDATE,
        HIDApiTags.RECURRING_EXPENSE_FILTRATION,
        HIDApiTags.RECURRING_EXPENSE_COMPARISON,
        HIDApiTags.RECURRING_EXPENSE_SUMMARY,
      ],
    }),
    updateRecurringExpense: builder.mutation<void, Partial<RecurringExpenseModifyActionModel>>({
      query: (expense) => ({
        url: '/banking/recurring-expenses/mappings?skipResponse=true',
        method: 'PATCH',
        body: [expense],
      }),
      invalidatesTags: () => [
        HIDApiTags.RECURRING_EXPENSE_BANK_ACCOUNT,
        HIDApiTags.RECURRING_EXPENSE,
        HIDApiTags.RECURRING_EXPENSE_FILTRATION,
        HIDApiTags.RECURRING_EXPENSE_COMPARISON,
        HIDApiTags.RECURRING_EXPENSE_SUMMARY,
      ],
    }),
    updateRecurringExpenses: builder.mutation<void, Array<Partial<RecurringExpenseModifyActionModel>>>({
      query: (expenses) => ({
        url: '/banking/recurring-expenses/mappings',
        method: 'PATCH',
        body: expenses,
      }),
      invalidatesTags: () => [
        HIDApiTags.RECURRING_EXPENSE_BANK_ACCOUNT,
        HIDApiTags.RECURRING_EXPENSE,
        HIDApiTags.RECURRING_EXPENSE_FILTRATION,
        HIDApiTags.RECURRING_EXPENSE_COMPARISON,
        HIDApiTags.RECURRING_EXPENSE_SUMMARY,
      ],
    }),
    deleteRecurringExpense: builder.mutation<void, RecurringExpenseDeleteModel>({
      query: (expense) => ({
        url: '/banking/recurring-expenses/mappings?skipResponse=true',
        method: 'PATCH',
        body: [expense],
      }),
      invalidatesTags: () => [
        HIDApiTags.RECURRING_EXPENSE_BANK_ACCOUNT,
        HIDApiTags.RECURRING_EXPENSE,
        HIDApiTags.RECURRING_EXPENSE_FILTRATION,
        HIDApiTags.RECURRING_EXPENSE_COMPARISON,
        HIDApiTags.RECURRING_EXPENSE_SUMMARY,
      ],
    }),
    getFinsharkUrl: builder.query<{ url: string }, { sandbox?: boolean; redirectUri: string; skipRedirect: boolean; refresh?: boolean }>({
      query: (params) => ({
        url: `finshark/initiate?${qs.stringify(params)}`,
        method: 'GET',
      }),
    }),
    getFinsharkAuthorization: builder.query<FinsharkAuthorization, FinsharkSessionIdentifier>({
      query: (params) => ({
        url: `finshark/authorization?${qs.stringify(params)}`,
        method: 'GET',
      }),
    }),
    syncFinsharkBankAccounts: builder.mutation<{ id: string }, SyncBankAccountsData>({
      query: (syncBankAccountsData) => ({
        url: '/finshark/sync',
        method: 'POST',
        body: syncBankAccountsData,
      }),
      invalidatesTags: () => [
        HIDApiTags.RECURRING_EXPENSE_BANK_ACCOUNT,
        HIDApiTags.RECURRING_EXPENSE,
        HIDApiTags.RECURRING_EXPENSE_CANDIDATE,
        HIDApiTags.RECURRING_EXPENSE_COMPARISON,
        HIDApiTags.RECURRING_EXPENSE_SUMMARY,
      ],
    }),
    getSyncFinsharkBankAccountsProgress: builder.query<FixedExpensesCandidatesSyncProgress, { syncJobId: string }>({
      query: ({ syncJobId }) => ({
        url: `/finshark/sync/${syncJobId}`,
      }),
    }),
    getExpensesBankAccountsConsents: builder.query<Array<ExpenseBankAccountUserConsents>, void>({
      query: () => '/banking/consents',
      providesTags: () => [HIDApiTags.RECURRING_EXPENSE_CONSENTS],
    }),
    getExpensesSharedBankAccountsConsents: builder.query<Array<ExpenseBankAccountUserConsents>, void>({
      query: () => '/banking/consents/shared',
      providesTags: () => [HIDApiTags.RECURRING_EXPENSE_SHARED_CONSENTS],
    }),
    updateExpensesBankAccountsConsents: builder.mutation<void, Array<ExpensesBankAccountsConsentsAction>>({
      query: (actions) => ({
        url: '/banking/consents',
        method: 'PATCH',
        body: { actions },
      }),
      invalidatesTags: () => [
        HIDApiTags.RECURRING_EXPENSE_CONSENTS,
      ],
    }),
    updateExpensesSharedBankAccountsConsents: builder.mutation<void, Array<ExpensesBankAccountsConsentsAction>>({
      query: (actions) => ({
        url: '/banking/consents/shared',
        method: 'PATCH',
        body: { actions },
      }),
      invalidatesTags: () => [
        HIDApiTags.RECURRING_EXPENSE_SHARED_CONSENTS,
        HIDApiTags.RECURRING_EXPENSE,
        HIDApiTags.RECURRING_EXPENSE_CANDIDATE,
        HIDApiTags.RECURRING_EXPENSE_COMPARISON,
        HIDApiTags.RECURRING_EXPENSE_SUMMARY,
      ],
    }),
  }),
});

export const {
  useGetRecurringExpensesFiltrationQuery,
  useGetRecurringExpensesComparisonQuery,
  useGetRecurringExpensesCandidatesQuery,
  useGetRecurringExpensesSummaryQuery,
  useGetRecurringExpensesBankAccountsQuery,
  usePatchRecurringExpensesBankAccountsMutation,
  useUpdateRecurringExpenseMutation,
  useUpdateRecurringExpensesMutation,
  useLazyGetFinsharkUrlQuery,
  useGetFinsharkAuthorizationQuery,
  useSyncFinsharkBankAccountsMutation,
  useGetSyncFinsharkBankAccountsProgressQuery,
  useDeleteRecurringExpenseMutation,
  useGetExpensesBankAccountsConsentsQuery,
  useGetExpensesSharedBankAccountsConsentsQuery,
  useUpdateExpensesBankAccountsConsentsMutation,
  useUpdateExpensesSharedBankAccountsConsentsMutation,
} = recurringExpensesApi;
