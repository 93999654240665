import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import {
  Card,
  Grid,
  Stack,
  useTheme,
  Skeleton,
  Typography,
} from '@mui/material';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { ModifyActionType } from '@house-id/houseid-types/dist/common';
import {
  ExpenseBankAccount,
  ExpensesBankProvider,
} from '@house-id/houseid-types/dist/finances/recurringExpenses';

import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import {
  FCC,
} from '../../../../../../../../types/common';
import {
  useNavigateBackOr,
} from '../../../../../../../../utils/routes';
import { getTypographyHeight } from '../../../../../../../../utils/style';
import { useGetCurrentUserQuery } from '../../../../../../../Auth/api/user.api';
import { getHomePath } from '../../../../../../navigation/navigation.property';
import HomeLayout from '../../../../../../pages/Home/components/HomeLayout';
import {
  useGetRecurringExpensesBankAccountsQuery,
  usePatchRecurringExpensesBankAccountsMutation,
} from '../../api/recurringExpenses.api';
import BankAccountListItem from './components/BankAccountListItem';
import RecurringExpensesActions from '../../components/RecurringExpensesActions';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import BankListItem from './components/BankListItem';
import HIDButton from '../../../../../../../../components/buttons/HIDButton';
import useDialog from '../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../hooks/useDialog/DialogNames';
import { DeleteRecurringExpensesBankAccountDialogProps } from '../../components/dialogs/DeleteRecurringExpensesBankAccountDialog';
import { DeleteRecurringExpensesBankDialogProps } from '../../components/dialogs/DeleteRecurringExpensesBankDialog';
import NoContent from '../../../../../Content/components/NoContent';

type ExternalServicesLoaderSkeletonProps = {
  itemsCount?: number;
  isLoading: boolean;
};

// TODO: remove and move loading logic into render
const ManageRecurringExpensesBankAccountsSkeleton: FCC<ExternalServicesLoaderSkeletonProps> = ({
  itemsCount = 4,
  isLoading,
  children,
}) => {
  const theme = useTheme();

  const { isDownMd } = useBreakPointsSizes();

  if (isLoading) {
    const height = getTypographyHeight(theme.typography.body2);

    return (
      <Grid
        container
        rowSpacing={isDownMd ? 2 : 0}
        spacing={isDownMd ? 0 : 2}
      >
        {R.range(0, itemsCount).map((i) => (
          <Grid
            item
            key={i}
            xxs={12}
          >
            <Stack
              height={150}
              sx={{
                borderRadius: theme.spacing(1.25),
                backgroundColor: theme.palette.common.white,
                padding: theme.spacing(1.5),
                border: `1px solid ${theme.palette.grey[300]}`,
              }}
            >
              <Skeleton
                height={height}
                sx={{ margin: theme.spacing(2) }}
                variant="rounded"
                width="30%"
              />
              <Skeleton
                height={height}
                sx={{ margin: theme.spacing(2) }}
                variant="rounded"
                width="70%"
              />
              <Stack direction="row-reverse">
                <Skeleton
                  height={height + 10}
                  sx={{ borderRadius: '20px', marginRight: theme.spacing(2) }}
                  variant="rounded"
                  width="100px"
                />
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
    );
  }

  return children;
};

// eslint-disable-next-line react/no-multi-comp
const ManageRecurringExpensesBankAccounts: FC = () => {
  const { t } = useTranslation(['finances', 'common']);
  const navigateBackOr = useNavigateBackOr();

  const { data: currentUser } = useGetCurrentUserQuery();
  const { isDownMd } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();

  const [bankAccountsSyncAllowed, setBankAccountsSyncAllowed] = useState<Record<string, boolean>>({});

  const [openDeleteRecurringExpensesBankDialog] = useDialog<DeleteRecurringExpensesBankDialogProps>(
    DialogNames.DELETE_RECURRING_EXPENSES_BANK_DIALOG,
  );
  const [openDeleteRecurringExpensesBankAccountDialog] = useDialog<DeleteRecurringExpensesBankAccountDialogProps>(
    DialogNames.DELETE_RECURRING_EXPENSES_BANK_ACCOUNT_DIALOG,
  );

  const handleDeleteBankAccount = (bankAccountId: string) => openDeleteRecurringExpensesBankAccountDialog({ bankAccountId });
  const handleDeleteBank = (bankId: string) => openDeleteRecurringExpensesBankDialog({ bankId });
  const [patchExpensesBankAccounts, { isLoading: isBankAccountsPatching }] = usePatchRecurringExpensesBankAccountsMutation();

  const {
    data: bankAccounts = [],
    isLoading: isBankAccountsLoading,
  } = useGetRecurringExpensesBankAccountsQuery();

  const userBankAccounts = R.filter((account) => account?.user?.id === currentUser?.id, bankAccounts);
  const banks = R.pipe(
    R.map((bankAccount: ExpenseBankAccount) => bankAccount.provider),
    R.uniqBy((bankAccountProvider: ExpensesBankProvider) => bankAccountProvider.id),
  )(userBankAccounts);

  useEffect(() => {
    setBankAccountsSyncAllowed(
      R.pipe(
        R.map((account: ExpenseBankAccount) => [account.id, account.offlineSyncAllowed] as [PropertyKey, string | boolean]),
        R.fromPairs,
      )(userBankAccounts) as Record<string, boolean>,
    );
  }, [bankAccounts]);

  const handleGoBack = () => navigateBackOr(propertyId ? getHomePath({ propertyId }) : undefined);

  const handleSave = () => {
    const accountsToUpdate = R.pipe(
      R.filter((account: ExpenseBankAccount) => bankAccountsSyncAllowed[account.id] !== account.offlineSyncAllowed),
      R.map((account: ExpenseBankAccount) => ({
        action: ModifyActionType.UPDATE,
        id: account.id,
        offlineSyncAllowed: Boolean(bankAccountsSyncAllowed[account.id]),
      })),
    )(userBankAccounts);

    patchExpensesBankAccounts({ actions: accountsToUpdate })
      .then(() => handleGoBack());
  };

  return (
    <HomeLayout
      BodyLoaderSkeleton={ManageRecurringExpensesBankAccountsSkeleton}
      SideColumn={
        <Stack spacing={2}>
          <Card sx={{ padding: 2 }}>
            <RecurringExpensesActions />
          </Card>
        </Stack>
      }
      isLoading={isBankAccountsLoading}
      sideDrawerElements={[
        <RecurringExpensesActions
          isSideDrawer
          key={RecurringExpensesActions.name}
        />,
      ]}
      title={t('finances:expenses_manage_expenses_bank_accounts_title')}
      onBack={handleGoBack}
    >
      {userBankAccounts?.length
        ? (
          <Stack spacing={2} sx={{ marginTop: isDownMd ? 2 : 0 }}>
            <Typography>{t('finances:expenses_manage_expenses_bank_accounts_subtitle')}</Typography>
            <Typography fontWeight="bold">{t('finances:expenses_manage_expenses_bank_accounts_manage_accounts')}</Typography>
            <Grid
              container
              rowSpacing={isDownMd ? 2 : 0}
              spacing={isDownMd ? 0 : 2}
            >
              {
                userBankAccounts
                  .map((bankAccount) => (
                    <Grid
                      item
                      key={bankAccount.id}
                      lg={6}
                      xxs={12}
                    >
                      <BankAccountListItem
                        bankAccount={bankAccount}
                        sx={{ height: '100%' }}
                        updateAutomatically={Boolean(bankAccountsSyncAllowed?.[bankAccount.id])}
                        onDelete={() => handleDeleteBankAccount(bankAccount.id)}
                        onUpdateAutomaticallyChange={(value) =>
                          setBankAccountsSyncAllowed((prev) => ({ ...prev, [bankAccount.id]: value }))}
                      />
                    </Grid>
                  ))
              }
            </Grid>
            <Typography fontWeight="bold">{t('finances:expenses_manage_expenses_bank_accounts_manage_banks')}</Typography>
            <Grid
              container
              rowSpacing={isDownMd ? 2 : 0}
              spacing={isDownMd ? 0 : 2}
            >
              {
                banks
                  .map((bank) => (
                    <Grid
                      item
                      key={bank.id}
                      lg={6}
                      xxs={12}
                    >
                      <BankListItem
                        bank={bank}
                        onDelete={() => handleDeleteBank(bank.id)}
                      />
                    </Grid>
                  ))
              }
            </Grid>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
              spacing={2}
            >
              <HIDButton color="secondary" size="medium" onClick={handleGoBack}>
                {t('common:cancel')}
              </HIDButton>
              <HIDButton
                color="primary"
                loading={isBankAccountsPatching}
                size="medium"
                onClick={handleSave}
              >
                {t('common:save')}
              </HIDButton>
            </Stack>
          </Stack>
        )
        : (
          <Stack
            sx={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <NoContent
              description={t('finances:no_recurring_expenses_suggestions_description')}
              title={t('finances:no_recurring_expenses_suggestions_title')}
            />
          </Stack>
        )}
    </HomeLayout>
  );
};

export default ManageRecurringExpensesBankAccounts;
