import React, {
  FC,
  useCallback,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetActiveAuthUser } from '../../../external-services/firebase';
import {
  useGetTokenDataQuery,
  useLazyGetCurrentUserQuery,
} from '../api/user.api';
import HIDFullScreenLoader from '../../../components/HIDFullScreenLoader';
import useGetCurrentProperty from '../../Property/hooks/useCurrentProperty';
import { getHomePath } from '../../Property/navigation/navigation.property';
import {
  useLocationState,
} from '../../../utils/routes';
import {
  InitAppRouteState,
  UserTokenData,
} from '../types/auth.types';
import { useSetUserSettingsMutation } from '../api/settings.api';
import { setExternalServicesUserData } from '../../../external-services';
import {
  getOnboardingPath,
} from '../navigation/navigation.auth';
import RouteNames from '../../../routes/RouteNames';
import { getPartnersHomePath } from '../../Partners/navigation/navigation.partners';

type InitAppProps = {
  skipOrigin?: boolean;
};

const InitApp: FC<InitAppProps> = ({ skipOrigin }) => {
  const navigate = useNavigate();
  const locationState = useLocationState<InitAppRouteState>();

  const { user: currentUser, isLoading: currentUserIsLoading, isPartnerAuth } = useGetActiveAuthUser();
  const { data: tokenData } = useGetTokenDataQuery({}, { skip: currentUserIsLoading || !currentUser });
  const { data: property } = useGetCurrentProperty(undefined, { skip: currentUserIsLoading || !currentUser || isPartnerAuth });
  const [getCurrentUser] = useLazyGetCurrentUserQuery();

  const [setUserSettings] = useSetUserSettingsMutation();

  const handleInitApp = useCallback((tokenData: UserTokenData) => {
    getCurrentUser()
      .unwrap()
      .then((user) => setExternalServicesUserData(tokenData, user));

    const properties = tokenData?.properties || [];
    const hasProperties = properties.length > 0;

    if (isPartnerAuth) {
      navigate(getPartnersHomePath());
    } else if (!hasProperties) {
      const origin = !skipOrigin && locationState?.from?.pathname && locationState?.from?.pathname !== RouteNames.INIT
        ? `${locationState.from?.pathname}${locationState.from?.search || ''}`
        : getOnboardingPath();

      navigate(origin, { replace: true, state: skipOrigin ? undefined : locationState });
    } else {
      const routePropertyId = locationState?.propertyId;

      const currentPropertyId = routePropertyId && properties.includes(routePropertyId)
        ? routePropertyId
        : property?.id;

      if (currentPropertyId && property?.id !== currentPropertyId) {
        setUserSettings({ currentPropertyId });
      }

      if (currentPropertyId) {
        const origin = !skipOrigin && locationState?.from
          ? `${locationState.from?.pathname}${locationState.from?.search || ''}`
          : getHomePath({ propertyId: currentPropertyId });

        navigate(origin, { replace: true });
      }
    }
  }, [locationState, property, currentUser, skipOrigin]);

  useEffect(() => {
    if (!currentUserIsLoading && currentUser && tokenData) {
      handleInitApp(tokenData);
    }
  }, [currentUser, locationState, property, tokenData, skipOrigin]);

  return (
    <HIDFullScreenLoader />
  );
};

export default InitApp;
