import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { useDropzone } from 'react-dropzone';
import { ImageOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';

import HIDButton, { HIDButtonProps } from '../buttons/HIDButton';
import { FCC } from '../../types/common';
import {
  AllSupportedFileMimeTypes,
  FileMimeType,
  ImageMimeTypes,
} from '../../constants/mimeTypes';
import { getAcceptMimeTypeMap } from './utils';
import useBreakPointsSizes from '../../hooks/useBreakpointsSizes';
import HIDIconButton from '../buttons/HIDIconButton';
import useTryUseFeatureDialog from '../../modules/SubscriptionPlans/hooks/useTryUseFeatureDialog';
import { SubscriptionFeature } from '../../modules/SubscriptionPlans/subscriptionPlans.types';

type HIDFilePickerButtonProps = {
  color: HIDButtonProps['color'];
  mimeTypes?: Array<FileMimeType>;
  isMultiple?: boolean;
  disabled?: boolean;
  checkForStorageLimit?: boolean;
  size?: HIDButtonProps['size'];
  onFilesSelected: (files: Array<File>) => void;
};

const HIDFilePickerButton: FCC<HIDFilePickerButtonProps> = ({
  color,
  mimeTypes = AllSupportedFileMimeTypes,
  disabled,
  isMultiple = true,
  sx,
  style,
  checkForStorageLimit = true,
  size,
  onFilesSelected,
}) => {
  const { t } = useTranslation(['photos']);

  const onlyImages = R.equals(mimeTypes, ImageMimeTypes);

  const acceptMap = getAcceptMimeTypeMap(mimeTypes);

  const { isDownLg, isDownSm } = useBreakPointsSizes();

  const [
    proceedToFeatureOrOpenSubscriptionDialog,
  ] = useTryUseFeatureDialog({ subscriptionFeature: SubscriptionFeature.STORAGE_SPACE });

  const onDrop = useCallback(
    (files: Array<File>) => {
      if (checkForStorageLimit) {
        proceedToFeatureOrOpenSubscriptionDialog({ onAction: () => onFilesSelected(files) });
      } else {
        onFilesSelected(files);
      }
    },
    [onFilesSelected, proceedToFeatureOrOpenSubscriptionDialog, checkForStorageLimit],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    accept: acceptMap,
    multiple: isMultiple,
    onDrop,
  });

  const buttonLabel = onlyImages
    ? isMultiple ? t('photos:select_images') : t('photos:select_image')
    : t('photos:select_attachments');

  return (
    <Stack {...getRootProps()} style={style} sx={sx}>
      <input {...getInputProps()} />
      {
        isDownSm
          ? (
            <HIDIconButton
              Icon={ImageOutlined}
              color="white"
              disabled={disabled}
              title={buttonLabel}
              onClick={open}
            />
          )
          : (
            <HIDButton
              Icon={isDownLg ? undefined : ImageOutlined}
              color={color}
              disabled={disabled}
              iconPosition="left"
              size={size}
              onClick={open}
            >
              {buttonLabel}
            </HIDButton>
          )
      }

    </Stack>
  );
};

export default HIDFilePickerButton;
