import React, {
  useEffect,
  useState,
} from 'react';
import { skipToken } from '@reduxjs/toolkit/query';

import { useDispatch } from 'react-redux';
import EntityType from '../../../../../../../constants/entityType';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import {
  suggestionApi,
  useGetEntitySuggestionsQuery,
  useGetSuggestionsSettingsQuery,
} from '../api/suggestion.api';
import EntitySuggestionSection from '../components/EntitySuggestionSection';
import { HIDApiTags } from '../../../../../../../api/HIDApiTags';

type UseEntitySuggestionSection = (params:
{
  entityId?: string;
  entityType: EntityType;
  fieldRefs?: Record<string, React.MutableRefObject<HTMLInputElement | undefined>>;
  customFieldSuggestionAction?: Record<string, () => void>
  initialSuggestion?: string;
}) => {
  refetchSuggestions: () => void,
  SuggestionSection: React.ReactElement | null,
};

const getFieldNameFromSuggestion = (suggestion: string) => suggestion.split('missing_')?.[1] || suggestion;

const useEntitySuggestionSection: UseEntitySuggestionSection = ({
  entityId,
  entityType,
  fieldRefs,
  customFieldSuggestionAction,
  initialSuggestion,
}) => {
  const dispatch = useDispatch();
  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: suggestionSettings } = useGetSuggestionsSettingsQuery(propertyId ? { propertyId } : skipToken);
  const suggestionEnabled = suggestionSettings?.suggestionsEnabled;

  const {
    data: suggestion,
    isLoading: suggestionsIsLoading,
    refetch: refetchEntitySuggestions,
  } = useGetEntitySuggestionsQuery(propertyId && entityId ? { propertyId, entityType, entityId } : skipToken);

  const refetchSuggestions = () => {
    refetchEntitySuggestions();
    dispatch(suggestionApi.util.invalidateTags([HIDApiTags.SUGGESTIONS_SUMMARY]));
  };

  const [scrolledFromRoute, setScrolledFromRoute] = useState(false);

  const fixSuggestion = (suggestionKey: string) => {
    const fieldId = getFieldNameFromSuggestion(suggestionKey);

    if (customFieldSuggestionAction && customFieldSuggestionAction[fieldId]) {
      customFieldSuggestionAction[fieldId]();

      return true;
    }

    if (fieldRefs && fieldRefs[fieldId] && fieldRefs[fieldId].current) {
      fieldRefs[fieldId].current?.scrollIntoView(false);
      fieldRefs[fieldId].current?.focus();

      return true;
    }

    return false;
  };

  useEffect(() => {
    if (entityId && initialSuggestion && !scrolledFromRoute) {
      const hasScrolled = fixSuggestion(initialSuggestion);
      if (hasScrolled) {
        setScrolledFromRoute(true);
      }
    }
  }, [initialSuggestion]);

  return {
    fieldRefs,
    refetchSuggestions,
    SuggestionSection: suggestionEnabled && suggestion && suggestion.suggestions.length
      ? (
        <EntitySuggestionSection
          loading={suggestionsIsLoading}
          suggestion={suggestion}
          onFixSuggestion={fixSuggestion}
        />
      )
      : null,
  };
};

export default useEntitySuggestionSection;
