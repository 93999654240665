import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';

import { useGetForumThreadsQuery } from '../api/forum.api';
import HIDSelectItem from '../../../components/HIDSelectItem';
import {
  ForumThread,
  ForumThreadsSortOrder,
} from '../forum.types';
import useTryNavigateToThread from '../hooks/useTryNavigateToThread';

type PopularThreadsProps = {
  count?: number;
};

const PopularThreads: FC<PopularThreadsProps> = ({ count = 5 }) => {
  const { t } = useTranslation(['forum']);

  const {
    data: { threads: popularThreads = [] } = {},
    isLoading: threadsIsLoading,
  } = useGetForumThreadsQuery({ sort: ForumThreadsSortOrder.USERS_READ_DESC, pageSize: count });

  const threadsList = threadsIsLoading
    ? R.times((n) => ({ id: n.toString(), subject: n.toString() } as ForumThread), count)
    : popularThreads;

  const handleTryNavigateToThread = useTryNavigateToThread();

  return (
    <>
      <Typography sx={{ marginBottom: 1.5 }} variant="h6">
        {t('forum:forum_most_read_threads')}
      </Typography>
      {threadsList.map((thread) => (
        <HIDSelectItem
          isLoading={threadsIsLoading}
          key={thread.id}
          label={thread.subject}
          onClick={() => handleTryNavigateToThread(thread)}
        />
      ))}
    </>
  );
};

export default PopularThreads;
