import {
  useEffect,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { UseFinsharkSyncQueryParams } from '@house-id/houseid-types/dist/finances/recurringExpenses';

import { useRouteQueryParams } from '../../../../../../../utils/routes';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import { SelectRecurringExpensesBankAccountsDialogProps } from '../components/dialogs/SelectRecurringExpensesBankAccountsDialog';
import { StartRecurringExpensesCollectionDialogProps } from '../components/dialogs/StartRecurringExpensesCollectionDialog';

type UseStartRecurringExpenseCollection = (params: {
  recurringExpenseCollectionType: UseFinsharkSyncQueryParams['recurringExpenseCollectionType'],
  collectionKey: UseFinsharkSyncQueryParams['collectionKey'];
}) => () => void;

const useStartRecurringExpenseCollection: UseStartRecurringExpenseCollection = ({
  recurringExpenseCollectionType,
  collectionKey,
}) => {
  const { t } = useTranslation(['api_errors']);
  const [_, setSearchParams] = useSearchParams();
  const {
    authorizationId,
    result,
    sessionId,
    recurringExpenseCollectionType: recurringExpenseCollectionTypeRouteProp,
    collectionKey: collectionKeyTypeRouteProp,
    ...rest
  } = useRouteQueryParams<UseFinsharkSyncQueryParams>();

  const [openStartRecurringExpensesCollectionDialog] = useDialog<StartRecurringExpensesCollectionDialogProps>(
    DialogNames.START_RECURRING_EXPENSES_COLLECTION_DIALOG,
  );
  const [openSelectRecurringExpensesBankAccountsDialog] = useDialog<SelectRecurringExpensesBankAccountsDialogProps>(
    DialogNames.SELECT_RECURRING_EXPENSES_BANK_ACCOUNTS_DIALOG,
  );

  useEffect(() => {
    if (result
      && recurringExpenseCollectionTypeRouteProp === recurringExpenseCollectionType
      && collectionKeyTypeRouteProp === collectionKey
    ) {
      if (authorizationId && sessionId) {
        openSelectRecurringExpensesBankAccountsDialog({
          authorizationId,
          result,
          sessionId,
          recurringExpenseCollectionType,
        });
      } else {
        enqueueSnackbar(t('api_errors:default_error_title'), { variant: 'error' });
      }
      setSearchParams(rest);
    }
  }, [result, authorizationId, sessionId, recurringExpenseCollectionTypeRouteProp, collectionKey, collectionKeyTypeRouteProp]);

  return () => openStartRecurringExpensesCollectionDialog({
    recurringExpenseCollectionType,
    collectionKey,
  });
};

export default useStartRecurringExpenseCollection;
