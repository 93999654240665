import React, {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import {
  Check,
  PriorityHigh,
} from '@mui/icons-material';
import { differenceInHours } from 'date-fns';

import {
  useGetExpertThreadsQuery,
  useGetForumTopicsQuery,
} from '../../api/forum.api';
import { getPartnersForumThreadPath } from '../../navigation/navigation.forum';
import HomeListLayout from '../../../Property/pages/Home/components/HomeListLayout';
import {
  EMPTY_VALUE,
  getUserName,
} from '../../../../utils/string';
import useBreakPointsSizes from '../../../../hooks/useBreakpointsSizes';
import { ForumThread } from '../../forum.types';
import { arrToMap } from '../../../../utils/array';
import { useGetForumAuthorName } from '../../utils/forumAuthor';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../utils/date';
import ManageExpertThreads from './componets/ManageExpertThreads';
import { getNoWrapStyle } from '../../../../utils/style';
import TopicsQuickNavigation from '../../components/TopicsQuickNavigation';

const HOURS_TO_ANSWER = 48;
const REFETCH_QUESTIONS_MS = 60 * 1000;

const getHoursToAnswerLeft = (questionDate: Date) => {
  const difInHours = differenceInHours(new Date(), questionDate);
  return HOURS_TO_ANSWER > difInHours ? HOURS_TO_ANSWER - difInHours : 0;
};

const ForumExpertThreads: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'forms_common', 'forum']);

  const intervalRef = useRef<NodeJS.Timeout>();

  const { isDownLg, isDownMd, isDownSm } = useBreakPointsSizes();

  const [showNotAnsweredOnly, setShowNotAnsweredOnly] = useState(true);

  const {
    data: { threads = [] } = {},
    isLoading: threadsIsLoading,
    refetch: refetchThreads,
  } = useGetExpertThreadsQuery({ notAnswered: showNotAnsweredOnly });
  const { data: topics } = useGetForumTopicsQuery();

  const topicsMap = arrToMap((topic) => ([topic.id, topic.name]), topics || []);

  const getAuthorName = useGetForumAuthorName();

  const formatHours = (hours: number) => `${hours} h`;

  const columns: Array<GridColDef> = [
    {
      field: 'date',
      headerName: t('common:date'),
      flex: isDownMd ? 0.2 : 0.3,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { createdAt } = params?.row as ForumThread;
        return isDownSm
          ? (
            <Stack>
              <Typography noWrap variant="body2">
                {formatDate(new Date(createdAt), DateTimeFormats.DATE_ONLY)}
              </Typography>
              <Typography noWrap variant="body2">
                {formatDate(new Date(createdAt), DateTimeFormats.TIME_ONLY)}
              </Typography>
            </Stack>
          )
          : (
            <Typography sx={getNoWrapStyle(2)} variant="body2">
              {formatDate(new Date(createdAt), DateTimeFormats.DATE_AND_TIME)}
            </Typography>
          );
      },
    },
    {
      field: 'subject',
      headerName: t('forum:forum_question'),
      flex: 0.3,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { subject, topicId } = params?.row as ForumThread;
        return (
          <Stack>
            <Typography noWrap variant="body2">
              {subject || EMPTY_VALUE}
            </Typography>
            <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="caption">
              {topicsMap[topicId] || EMPTY_VALUE}
            </Typography>
          </Stack>
        );
      },
    },
    !isDownMd && {
      field: 'author',
      headerName: t('forms_common:name'),
      flex: isDownLg ? 0.25 : 0.2,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { author } = params?.row as ForumThread;
        return (
          <Typography noWrap variant="body2">
            {getUserName(getAuthorName(author))}
          </Typography>
        );
      },
    },
    {
      field: 'answered',
      headerName: t('forum:forum_answered'),
      flex: isDownLg ? 0.2 : 0.22,
      type: 'string',
      sortable: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => {
        const { lastExpertReply, createdAt } = params?.row as ForumThread;
        const isAnswered = Boolean(lastExpertReply?.timestamp);

        const hoursToAnswerLeft = getHoursToAnswerLeft(new Date(createdAt));

        const Icon = isAnswered
          ? <Check sx={{ color: theme.palette.primary.main }} />
          : <PriorityHigh sx={{ color: hoursToAnswerLeft > 0 ? theme.palette.warning.main : theme.palette.error.main }} />;

        return (
          <Stack alignItems="center">
            <Stack alignItems="center" direction="row">
              {
                !isAnswered && (
                  <Typography>{formatHours(hoursToAnswerLeft)}</Typography>
                )
              }
              {Icon}
            </Stack>
            {isAnswered && lastExpertReply && (
              <Typography sx={{ color: theme.palette.grey[500] }} variant="caption">
                {getUserName(getAuthorName(lastExpertReply.author))}
              </Typography>
            )}
          </Stack>
        );
      },
    },
  ].filter(Boolean) as Array<GridColDef>;

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    const thread = params?.row as ForumThread;
    navigate(getPartnersForumThreadPath({ topicId: thread.topicId, threadId: thread.id }));
  };

  useEffect(() => {
    // NOTE: Automatically refetch threads so experts do not need to refresh the page manually
    intervalRef.current = setInterval(refetchThreads, REFETCH_QUESTIONS_MS);
    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <HomeListLayout
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ManageExpertThreads
              showNotAnsweredOnly={showNotAnsweredOnly}
              onShowNotAnsweredOnlyChange={setShowNotAnsweredOnly}
            />
          </Card>
          <Card sx={{ padding: 2 }}>
            <TopicsQuickNavigation />
          </Card>
        </>
      }
      columns={columns}
      isLoading={threadsIsLoading}
      rows={threads}
      sideDrawerElements={[
        <ManageExpertThreads
          key={ManageExpertThreads.name}
          showNotAnsweredOnly={showNotAnsweredOnly}
          onShowNotAnsweredOnlyChange={setShowNotAnsweredOnly}
        />,
        <TopicsQuickNavigation key={TopicsQuickNavigation.name} />,
      ]}
      title={t('forum:forum_title')}
      onRowClick={handleRowClick}
    />
  );
};

export default ForumExpertThreads;
