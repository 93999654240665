import useDialog from '../../../hooks/useDialog';
import DialogNames from '../../../hooks/useDialog/DialogNames';
import useGetLogAnalyticsEvent from '../../Analytics/hooks/useGetLogAnalyticsEvent';
import {
  AnalyticsEvent,
  PartnersSource,
} from '../../Analytics/types.analytics';
import { TryUseFeatureDialogProps } from '../components/TryUseFeatureDialog';
import {
  FeatureStatus,
  SubscriptionFeature,
} from '../subscriptionPlans.types';
import useGetIsSubscriptionPlanFeatureEnabled from './useGetIsSubscriptionPlanFeatureEnabled';

type UseTryUseFeatureDialogProps = {
  onAction: () => void;
};

type UseTryUseFeatureDialogParams = {
  subscriptionFeature: SubscriptionFeature;
  onClose?: (status: FeatureStatus) => void;
};

type UseTryUseFeatureDialog = (
  params: UseTryUseFeatureDialogParams,
) => [(props: UseTryUseFeatureDialogProps) => void, boolean];

const useTryUseFeatureDialog: UseTryUseFeatureDialog = ({ subscriptionFeature, onClose }) => {
  const logEvent = useGetLogAnalyticsEvent();

  const {
    getIsSubscriptionPlanFeatureEnabled,
    isLoading,
  } = useGetIsSubscriptionPlanFeatureEnabled(subscriptionFeature);

  const [openTryFeatureDialog] = useDialog<TryUseFeatureDialogProps>(DialogNames.TRY_FEATURE_DIALOG);

  const openDialog = ({ onAction }: UseTryUseFeatureDialogProps) => {
    getIsSubscriptionPlanFeatureEnabled()
      .then(
        ({ isEnabled, featureStatus }) => {
          if (isEnabled) {
            onAction();
          } else {
            logEvent({
              event: AnalyticsEvent.OPEN_SUBSCRIPTION_FEATURE_OFFER_MODAL,
              source: PartnersSource.HOUSE_ID,
            });
            openTryFeatureDialog({
              subscriptionFeature,
              featureIsEnabled: isEnabled,
              featureStatus,
              onPerformAction: onAction,
              onBeforeClose: onClose,
            });
          }
        },
      );
  };

  return [openDialog, isLoading];
};

export default useTryUseFeatureDialog;
