import React, { FC } from 'react';
import {
  Card,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useNavigateBackOr } from '../../../../../../utils/routes';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../../../navigation/navigation.property';
import HomeLayout from '../../../../pages/Home/components/HomeLayout';
import { getPathWithPropertyIdOrInit } from '../../../../../Auth/navigation/navigation.auth';
import useGetSellingPropertyChecklist, { SellingPropertyChecklistIds } from '../../hooks/useGetSellingPropertyChecklist';
import SellingPropertyChecklistItem from './components/SellingPropertyChecklistItem';
import { getPropertyProgressPath } from '../../../PropertyProgress/navigation.propertyProgress';
import useDialog from '../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../hooks/useDialog/DialogNames';
import { SellingPropertyChecklistInfoDialogDialogProps } from './components/SellingPropertyChecklistInfoDialog';
import PropertyProgressQuickNavigation from '../../../PropertyProgress/components/PropertyProgressQuickNavigation';
import { PropertyProgressCategoryId } from '@house-id/houseid-types/dist/propertyProgress';

const SellingPropertyChecklist: FC = () => {
  const { t } = useTranslation(['property', 'selling_property']);
  const navigateBackOr = useNavigateBackOr();

  const { data: propertyId } = useGetCurrentPropertyId();

  const items = useGetSellingPropertyChecklist();

  const [openPropertyChecklistInfoDialog] = useDialog<SellingPropertyChecklistInfoDialogDialogProps>(
    DialogNames.SELLING_PROPERTY_CHECKLIST_INFO_DIALOG,
  );

  const handleReadMore = (id: SellingPropertyChecklistIds) => {
    openPropertyChecklistInfoDialog({
      sectionId: id,
    });
  };

  return (
    <HomeLayout
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <PropertyProgressQuickNavigation
            currentPropertyProgressCategoryId={PropertyProgressCategoryId.SALE_CHECKLIST}
            key={PropertyProgressQuickNavigation.name}
          />
        </Card>
      }
      breadcrumbsLinks={[
        {
          link: getPropertyProgressPath({ propertyId: propertyId || '' }),
          name: t('property:property_progress_title'),
        },
      ]}
      sideDrawerElements={[
        <PropertyProgressQuickNavigation
          currentPropertyProgressCategoryId={PropertyProgressCategoryId.SALE_CHECKLIST}
          key={PropertyProgressQuickNavigation.name}
        />,
      ]}
      title={t('selling_property:selling_property_checklist_title')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
    >
      <Stack spacing={2}>
        {
          items.map((item) => (
            <SellingPropertyChecklistItem
              item={item}
              key={item.id}
              onClick={handleReadMore}
            />
          ))
        }
      </Stack>
    </HomeLayout>
  );
};

export default SellingPropertyChecklist;
