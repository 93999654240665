import { useNavigate } from 'react-router';
import { differenceInDays } from 'date-fns';
import RouteNames from '../../../routes/RouteNames';
import { generateRoutePath } from '../../../utils/routes';
import {
  PropertyId,
  PropertyIdNavigation,
} from '../types/property.types';
import { SF_MOVE_SERVICE } from '../../../constants/links';
import useIsSFProperty from '../hooks/useIsSfProperty';
import useGetCurrentProperty from '../hooks/useCurrentProperty';

export const getHomePath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.PROPERTY_HOME,
  { propertyId },
);

export const getPropertySettingsPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.PROPERTY_SETTINGS,
  { propertyId },
);

type GetExternalServicesPath = PropertyId & { id?: string, suggestionKey?: string };
export const getExternalServicesPath = ({ propertyId, id, suggestionKey }: GetExternalServicesPath) => generateRoutePath(
  RouteNames.EXTERNAL_SERVICES,
  { propertyId },
  { id, suggestionKey },
);

export const getPropertyStoragePath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.PROPERTY_STORAGE,
  { propertyId },
);

export const getUserStoragePath = () => generateRoutePath(
  RouteNames.USER_STORAGE,
);

export const getMovePath = () => generateRoutePath(
  RouteNames.MOVE,
);

export const useNavigateToMove = () => {
  const navigate = useNavigate();
  const { data: property } = useGetCurrentProperty();
  const isSfProperty = useIsSFProperty();

  const accessDateMoreThan30days = property?.accessDate
    ? differenceInDays(new Date(), new Date(property.accessDate)) > 30
    : false;

  return () => isSfProperty
    ? accessDateMoreThan30days
      ? navigate(getMovePath())
      : window.open(SF_MOVE_SERVICE, '_blank')
    : navigate(getMovePath());
};
