import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  useGetForumThreadsQuery,
  useGetForumTopicsQuery,
} from '../../../../Forum/api/forum.api';
import ThreadListItem from '../../../../Forum/components/ThreadListItem';
import { getForumPath } from '../../../../Forum/navigation/navigation.forum';
import { arrToMap } from '../../../../../utils/array';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import {
  HomeWidgetType,
  useGetWidgetHeight,
} from '../utils/useGetWidgetHeight';
import HIDLink from '../../../../../components/HIDLink';
import { ForumThreadsSortOrder } from '../../../../Forum/forum.types';
import useTryNavigateToThread from '../../../../Forum/hooks/useTryNavigateToThread';

const LatestForumQuestionsWidget: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation(['forms_common', 'forum']);

  const { isDownXl } = useBreakPointsSizes();

  const { data: topics = [] } = useGetForumTopicsQuery();
  const { data: { threads = [] } = {} } = useGetForumThreadsQuery({ sort: ForumThreadsSortOrder.LAST_UPDATE_DESC, pageSize: 6 });

  const topicsMap = arrToMap(({ id, name }) => ([id, name]), topics);

  const handleTryNavigateToThread = useTryNavigateToThread();

  const latestThreads = threads.slice(0, isDownXl ? 5 : 6);

  const handleGoToForum = () => navigate(getForumPath());

  const widgetHeight = useGetWidgetHeight(HomeWidgetType.FORUM_LATEST_THREADS);

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.common.white,
        borderRadius: '5px',
        height: widgetHeight || 'unset',
      }}
    >
      <Stack spacing={1} sx={{ padding: 2 }}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Typography
            noWrap
            variant="h5"
          >
            {t('forum:forum_latest_in_the_forum')}
          </Typography>
          <HIDLink
            label={t('forms_common:view_all')}
            onClick={handleGoToForum}
          />
        </Stack>
        <Stack>
          {latestThreads?.map((thread, index) => (
            <ThreadListItem
              author={thread.author}
              key={index}
              sx={{
                padding: theme.spacing(1.5, 1.5),
                backgroundColor: theme.palette.common.white,
              }}
              thread={thread}
              threadTopicName={topicsMap[thread.topicId]}
              onClick={() => handleTryNavigateToThread(thread)}
            />
          ))}
        </Stack>
      </Stack>
    </Card>
  );
};

export default LatestForumQuestionsWidget;
