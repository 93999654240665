import qs from 'query-string';
import { useEffect } from 'react';
import * as R from 'ramda';
import { useLocalStorage } from 'usehooks-ts';

import { useRouteQueryParams } from '../../../utils/routes';
import { UtmParameters } from '../types/auth.types';

export const INTRODUCTORY_PARAMS = 'INTRODUCTARY_PARAMS';

type UseIntroductoryParams = () => {
  data?: UtmParameters,
  clear: () => void;
};

export const useIntroductoryParams: UseIntroductoryParams = () => {
  const queryParams = useRouteQueryParams<{
    action?: string;
    utm_campaign?: string;
    utm_source?: string;
    utm_medium?: string;
    utm_term?: string;
    utm_content?: string;
    utm_id?: string;
  }>();

  const [data, setData] = useLocalStorage<UtmParameters | undefined>(INTRODUCTORY_PARAMS, undefined);

  useEffect(() => {
    if (!R.isEmpty(queryParams)) {
      const { action } = queryParams;
      if (action) {
        const decodedActionUrl = action ? decodeURIComponent(action) : '';

        const parsedUrl = decodedActionUrl ? qs.parseUrl(decodedActionUrl) : null;
        if (R.not(R.isEmpty(parsedUrl?.query))) {
          setData(parsedUrl?.query as UtmParameters);
        }
      } else {
        const introductoryParams = {
          utm_campaign: queryParams.utm_campaign,
          utm_source: queryParams.utm_source,
          utm_medium: queryParams.utm_medium,
          utm_term: queryParams.utm_term,
          utm_content: queryParams.utm_content,
          utm_id: queryParams.utm_id,
        };

        if (!R.isEmpty(introductoryParams)) {
          setData(introductoryParams as UtmParameters);
        }
      }
    }
  }, [queryParams]);

  return {
    data,
    clear: () => setData(undefined),
  };
};
