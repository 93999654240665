import {
  HouseIdUserToken,
  UserTokenData,
} from '../types/auth.types';

export const BANK_ID_CLAIM = 'urn:houseid:bankid';

const ADMIN_CLAIM = 'urn:houseid:admin';
const USER_CLAIM = 'urn:houseid:user';
const PROPERTIES_CLAIM = 'urn:houseid:properties';
const MEMBERSHIPS_CLAIM = 'memberships';

export const getDecodedToken = (accessToken: string) => {
  const base64Url = accessToken.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''),
  );

  return JSON.parse(jsonPayload) as HouseIdUserToken;
};

export const getUserId = (decodedToken: HouseIdUserToken) => decodedToken?.[USER_CLAIM]?.id;

export const getUserIdFromToken = (token: string) => getUserId(getDecodedToken(token));

export const getAdminData = (decodedToken: HouseIdUserToken) => decodedToken?.[ADMIN_CLAIM];

export const getIsAdmin = (
  decodedToken: HouseIdUserToken,
) => Boolean(getAdminData(decodedToken)?.personalNumber);

export const getBankIdClaim = (decodedToken: HouseIdUserToken) => decodedToken?.[BANK_ID_CLAIM];

export const getIsVerifiedWithBankID = (decodedToken: HouseIdUserToken) => Boolean(getBankIdClaim(decodedToken)?.personalNumber);

export const getTokenProperties = (decodedToken: HouseIdUserToken) => decodedToken?.[PROPERTIES_CLAIM] || [];
export const getTokenPropertiesFromAccessToken = (accessToken: string) => getTokenProperties(getDecodedToken(accessToken));

export const hasTokenBankIdClaim = (accessToken: string) => {
  const decodedToken = getDecodedToken(accessToken);
  return getIsVerifiedWithBankID(decodedToken);
};

export const hasTokenUserIdClaim = (accessToken: string) => {
  const decodedToken = getDecodedToken(accessToken);
  return Boolean(getUserId(decodedToken));
};

export const hasTokenPartnerUserConsent = (accessToken: string) => {
  const decodedToken = getDecodedToken(accessToken);
  return Boolean(decodedToken?.consent);
};

export const getMemberships = (decodedToken: HouseIdUserToken) => decodedToken[MEMBERSHIPS_CLAIM] || [];

export const isPartner = (tokenData: UserTokenData) => tokenData.project.includes('partner');
