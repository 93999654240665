import React, { FC } from 'react';
import * as R from 'ramda';
import {
  Grid,
} from '@mui/material';
import { useNavigate } from 'react-router';

import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import { getReceiptPath } from '../../../../Content/modules/Receipt/navigation.receipt';
import { Receipt } from '../../../../Content/modules/Receipt/types.receipt';
import PropertyProgressContentItem from '../../../../PropertyProgress/components/PropertyProgressContentItem';

type PropertyDocumentsListProps = {
  propertyReceipts?: Array<Receipt>;
};

const SellingPropertyHandoverReceiptsList: FC<PropertyDocumentsListProps> = ({
  propertyReceipts,
}) => {
  const navigate = useNavigate();
  const { data: propertyId } = useGetCurrentPropertyId();

  const handleNavigateToDocument = (documentId: string) =>
    navigate(getPathWithPropertyIdOrInit(getReceiptPath, { id: documentId, propertyId }));

  return propertyReceipts?.length
    ? (
      <Grid
        container
        spacing={1.5}
        xxs={12}
      >
        {
          R.take(4, propertyReceipts).map((receipt) => (
            <Grid
              item
              key={receipt.id}
              md={6}
              xxs={12}
            >
              <PropertyProgressContentItem
                blob={R.head(receipt.blobs || [])}
                key={receipt.id}
                title={receipt.name}
                onNavigate={() => handleNavigateToDocument(receipt.id)}
              />
            </Grid>
          ))
        }
      </Grid>
    )
    : null;
};
export default SellingPropertyHandoverReceiptsList;
