import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Divider,
  Grid,
  Stack,
  useTheme,
} from '@mui/material';
import { useLocation } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';
import { HIDBlob } from '@house-id/houseid-types/dist/common';

import HomeLayout from '../../../../../pages/Home/components/HomeLayout';
import {
  useGetAllPhotosQuery,
  useCreatePhotosMutation,
  useUpdatePhotosMutation,
  useDeletePhotosMutation,
} from '../api/photo.api';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import {
  Photo,
  PhotosFormFields,
} from '../types.photo';
import PhotoForm, { getPhotoSchema } from '../components/PhotoForm';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import HIDButton from '../../../../../../../components/buttons/HIDButton';
import {
  useNavigateBackOr,
  useRouteQueryParams,
} from '../../../../../../../utils/routes';
import { getPhotosPath } from '../navigation.photo';
import { ImageMimeTypes } from '../../../../../../../constants/mimeTypes';
import { isOdd } from '../../../../../../../utils/number';
import {
  useGetContentFileBlobsByIdsQuery,
} from '../../ContentFile/api/contentFile.api';
import ContentFilePicker from '../../../../../../../components/filePicker/ContentFilePicker';
import { ContentFile } from '../../ContentFile/types.contentFile';
import {
  EntityUId,
  ModificationType,
} from '../../../types/content.type';
import { usePatchEntityConnectionsMutation } from '../../../api/content.api';
import EntityType from '../../../../../../../constants/entityType';
import { AnalyticsEvent } from '../../../../../../Analytics/types.analytics';
import useGetLogAnalyticsEvent from '../../../../../../Analytics/hooks/useGetLogAnalyticsEvent';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';

const CreateUpdatePhotos: FC = () => {
  const searchParams = useRouteQueryParams<{ ids?: string, contentFileIds?: string }>();
  const { t } = useTranslation(['photos', 'forms_common', 'common']);
  const { data: propertyId } = useGetCurrentPropertyId();
  const navigateBackOr = useNavigateBackOr();
  const theme = useTheme();

  const photoIds = searchParams.ids?.split(',');
  const contentFileIds = searchParams.contentFileIds?.split(',');

  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const connections = (location?.state?.connections || []) as Array<EntityUId>;
  const hasConnections = !R.isEmpty(connections);

  const isUpdate = photoIds && photoIds.length > 0;

  const [photosList, setPhotosList] = useState<Array<Photo>>([]);
  const [photoIdsToDelete, setPhotoIdsToDelete] = useState<Array<string>>([]);

  const { isDownLg, isDownSm } = useBreakPointsSizes();

  const { data: photos = [] } = useGetAllPhotosQuery(propertyId ? { propertyId } : skipToken);
  const photoBlobIds = photos.flatMap((photo) => photo.blobs?.map((blob) => blob.id));

  useEffect(() => {
    if (photoIds?.length && !photosList.length && !photoIdsToDelete.length) {
      setPhotosList(photos.filter((photo) => photoIds.includes(photo.id)));
    }
  }, [photoIds, photos]);

  const [
    createPhotos,
    { isLoading: isCreatingPhotos },
  ] = useCreatePhotosMutation();

  const [
    updatePhotos,
    { isLoading: isUpdatingPhotos },
  ] = useUpdatePhotosMutation();

  const [
    deletePhotos,
    { isLoading: isDeletingPhotos },
  ] = useDeletePhotosMutation();

  const logAnalyticsEvent = useGetLogAnalyticsEvent();

  const [patchEntityConnections] = usePatchEntityConnectionsMutation();

  const mapBlobToPhotoModel = (blob: HIDBlob) => ({ name: blob.name, blobs: [blob] });

  const handleCreatePhotos = (blobs: Array<HIDBlob>) => {
    if (!propertyId) {
      return;
    }

    createPhotos({ propertyId, photos: blobs.map(mapBlobToPhotoModel) })
      .unwrap()
      .then((newPhotos) => {
        logAnalyticsEvent({
          event: AnalyticsEvent.CREATE_PHOTO,
          entityType: EntityType.PHOTO,
        });
        setPhotosList([...photosList, ...newPhotos]);
        if (!hasConnections) {
          return Promise.resolve([] as void[]);
        }
        return Promise.all(newPhotos.map((photo) => {
          const data = {
            propertyId,
            entityId: photo.id,
            entityType: EntityType.PHOTO,
            actions: connections.map((entityUid) => ({
              type: entityUid.entityType,
              entityId: entityUid.entityId,
              action: ModificationType.CREATE,
            })),
          };

          return patchEntityConnections(data)
            .unwrap();
        }));
      });
  };

  const handleContentFilesSelected = (contentFiles: Array<ContentFile>) => {
    const newBlobs = contentFiles.map(({ blob }) => blob);
    if (propertyId && newBlobs.length) {
      handleCreatePhotos(newBlobs);
    }
  };

  const { data: contentFileBlobs } = useGetContentFileBlobsByIdsQuery({ propertyId, contentFileIds });

  const contentFileBlobsToCreate = contentFileBlobs
    .filter((blob) => !photoBlobIds.includes(blob.id));

  useEffect(() => {
    if (contentFileBlobsToCreate.length && !isCreatingPhotos) {
      handleCreatePhotos(contentFileBlobsToCreate);
    }
  }, [contentFileBlobsToCreate.length]);

  const schema = Yup.object({
    photos: Yup.array<Photo>()
      .of(getPhotoSchema(t('forms_common:field_mandatory')))
      .optional()
      .nullable(),
  });

  const [touched, setTouched] = useState(false);

  const handleFormSubmit = (values: PhotosFormFields) => {
    if (!propertyId) {
      return;
    }

    updatePhotos({
      propertyId,
      photos: values.photos.map((photo) => ({
        id: photo.id,
        name: photo.name,
        date: photo.date,
        place: photo.place,
      })),
    })
      .then(
        () => photoIdsToDelete.length > 0
          ? deletePhotos({ propertyId, ids: photoIdsToDelete })
          : {},
      )
      .then(() => navigateBackOr(getPhotosPath({ propertyId })));
  };

  const formik = useFormik<PhotosFormFields>({
    initialValues: {
      photos: photosList,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  const handleSave = () => {
    formik.submitForm();
    setTouched(true);
  };

  const handleDelete = (photo: Photo, index: number) => {
    setPhotosList(R.remove(index, 1, photosList));
    setPhotoIdsToDelete([...photoIdsToDelete, photo.id]);
  };

  const loading = isUpdatingPhotos || isCreatingPhotos || isDeletingPhotos;

  return (
    <HomeLayout
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getPhotosPath, { propertyId }),
          name: t('photos:image_gallery'),
        },
      ]}
      title={t('photos:add_new_images')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getPhotosPath, { propertyId }))}
    >
      {(photosList.length > 0 || photoIdsToDelete.length > 0)
        ? (
          <>
            <Grid
              container
              spacing={isDownSm ? 2 : 3}
            >
              {
                photosList
                  .map((photo, index) => (
                    <Grid
                      item
                      key={photo.id}
                      lg={6}
                      xxs={12}
                    >
                      <PhotoForm
                        formik={formik}
                        index={index}
                        key={photo.id}
                        photo={photo}
                        touched={touched}
                        onDelete={() => handleDelete(photo, index)}
                      />
                      {(index + (isDownLg ? 1 : 2)) < photosList.length && (
                        <Divider flexItem orientation="horizontal" sx={{ margin: theme.spacing(1.5, 0) }} />
                      )}
                    </Grid>
                  ))
              }
              <Grid
                container
                item
                alignItems="center"
                justifyContent="center"
                lg={isOdd(photosList.length) ? 6 : 12}
                sx={{ marginTop: 3 }}
                xxs={12}
              >
                <ContentFilePicker
                  loading={loading}
                  mimeTypes={ImageMimeTypes}
                  sx={{
                    minHeight: 200,
                    maxWidth: '100%',
                  }}
                  onContentFilesSelected={handleContentFilesSelected}
                />
              </Grid>
            </Grid>
            <Grid item xxs={12}>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={1}
                sx={{ marginTop: 4 }}
              >
                {isUpdate && (
                  <HIDButton
                    color="secondary"
                    disabled={loading}
                    onClick={() => navigateBackOr(getPathWithPropertyIdOrInit(getPhotosPath, { propertyId }))}
                  >
                    {t('common:cancel')}
                  </HIDButton>
                )}
                <HIDButton
                  disabled={loading}
                  loading={isUpdatingPhotos || isDeletingPhotos}
                  onClick={handleSave}
                >
                  {t('common:save')}
                </HIDButton>
              </Stack>
            </Grid>
          </>
        )
        : (
          <Stack
            alignItems="center"
            justifyContent="center"
          >
            <ContentFilePicker
              loading={loading}
              mimeTypes={ImageMimeTypes}
              sx={{
                minHeight: theme.spacing(32),
                maxWidth: '100%',
              }}
              onContentFilesSelected={handleContentFilesSelected}
            />
          </Stack>
        )}
    </HomeLayout>
  );
};

export default CreateUpdatePhotos;
