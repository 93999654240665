import * as R from 'ramda';
import { useTranslation } from 'react-i18next';

import useGetEntityInfo from '../../../hooks/useGetEntityInfo';
import { ALL_SUGGESTIONS_CATEGORY } from '../contacts.suggestion';
import All from '../../../../../../../components/icons/All';
import { SelectItem } from '../../../../../../../components/HIDInlineSelect';
import EntityType from '../../../../../../../constants/entityType';
import { SuggestionsSummary } from '../types.suggestion';
import { EntityInfo } from '../../../types/content.type';

const useGetSuggestionCategories = (suggestionsSummary?: SuggestionsSummary) => {
  const { t } = useTranslation(['common']);

  const getEntityInfo = useGetEntityInfo();

  const suggestions = Object.entries(suggestionsSummary || {})
    .map(([entityType, { count }]) => ({ info: getEntityInfo(entityType as EntityType), count }))
    .filter(
      ({ count, info }) => info !== undefined && count > 0,
    ) as Array<{ info: EntityInfo, count: number }>;

  const totalCount = R.sum(R.map(({ count }) => count, suggestions));

  const selectItems: Array<SelectItem> = [
    totalCount && {
      id: ALL_SUGGESTIONS_CATEGORY,
      Icon: All,
      label: t('common:all_label'),
      count: totalCount,
    },
    ...suggestions
      .map(({ info: { Icon, name, type }, count }) => ({
        id: type,
        Icon,
        label: name,
        count,
      })),
  ].filter(Boolean);

  return selectItems;
};

export default useGetSuggestionCategories;
