import {
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import * as R from 'ramda';
import {
  useAppDispatch,
  useAppSelector,
} from '../../store/hooks';
import useGetCurrentPropertyId from '../../modules/Property/hooks/useGetCurrentPropertyId';
import {
  ShowDialogItem,
  ShowDialogItemSource,
  ShowDialogItemTargetType,
} from '../../types/dialogs';
import { createGuid } from '../../utils/uuid';
import {
  DialogSettings,
  useGetDialogsSettingsQuery,
  useSetDialogsSettingsMutation,
} from '../../modules/Auth/api/dialogSettings.api';
import { showDialog } from '../../store/dialogsReducer';
import DialogNames from '../useDialog/DialogNames';

type MappedShowDialogItemWithSource = ShowDialogItem & ShowDialogItemSource & { uniqueId: string, sessionId: string };

const sessionId = createGuid();

const getUniqueDialogId = (dialog: ShowDialogItem) => dialog.uniqueId
  ? `${dialog.uniqueId}_web`
  : `${dialog.name}_web`;

const mapDialog = (
  dialogsSettings: DialogSettings,
  dialog: ShowDialogItem,
  propertyId?: string,
): MappedShowDialogItemWithSource => {
  const currentDialog: ShowDialogItemSource = dialogsSettings?.[dialog.target]?.[getUniqueDialogId(dialog)]
    || { disabled: false, shownCount: 0 };

  const propertyPart = dialog.target === ShowDialogItemTargetType.PROPERTY ? `+${propertyId}` : '';
  const dialogSessionId = `${sessionId}+${getUniqueDialogId(dialog)}${propertyPart}`;

  return {
    ...dialog, ...R.omit(['name'], currentDialog), sessionId: dialogSessionId, uniqueId: getUniqueDialogId(dialog),
  };
};

const useShowDialogs = (id: string, dialogs: Array<ShowDialogItem>, maxDialogsToShow = 2): void => {
  const idRef = useRef(id);
  const dispatch = useAppDispatch();
  const { data: propertyId } = useGetCurrentPropertyId();
  const showedDialogIds = useAppSelector(({ dialogs }) => dialogs.showedDialogIds);

  const { data: dialogsSettings, isSuccess } = useGetDialogsSettingsQuery(propertyId ? { propertyId } : skipToken);
  const [setDialogSettings] = useSetDialogsSettingsMutation();

  const dialogIds = useMemo(() => dialogs.map(getUniqueDialogId).join(','), [id, dialogs]);

  useEffect(() => {
    if (dialogs.length && dialogsSettings) {
      const mappedDialogs = R.pipe(
        R.map((d: ShowDialogItem) => mapDialog(dialogsSettings, d, propertyId)),
        R.filter(
          (d: MappedShowDialogItemWithSource) => !d.disabled
            && (d.maxCountToShow === undefined || d.shownCount < d.maxCountToShow)
            && !showedDialogIds.includes(d.sessionId),
        ),
        R.sortBy((d: MappedShowDialogItemWithSource) => d.priority || 0),
        R.take(maxDialogsToShow),
      )(dialogs) as Array<MappedShowDialogItemWithSource>;

      R.forEach((d: MappedShowDialogItemWithSource) => {
        dispatch(showDialog({
          name: d.name as DialogNames,
          id: d.sessionId,
          props: {
            ...d.props,
            onDisable: () => setDialogSettings({
              data: {
                name: d.uniqueId,
                disabled: true,
                target: d.target,
                propertyId,
              },
            }),
            onAfterClose: () => setDialogSettings({
              data: {
                shownCount: Number(d.shownCount) + 1,
                name: d.uniqueId,
                target: d.target,
                propertyId,
              },
            }),
          },
        }));
      })(mappedDialogs);
    }
  }, [propertyId, idRef.current, dialogIds, isSuccess]);
};

export default useShowDialogs;
