import { skipToken } from '@reduxjs/toolkit/query';

import {
  getActiveAuth,
  useGetActiveAuthUser,
} from '../../../../../../../external-services/firebase';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import {
  useSetUserSettingsMutation,
  useGetUserSettingsQuery,
} from '../../../../../../Auth/api/settings.api';
import useTryUseFeatureDialog from '../../../../../../SubscriptionPlans/hooks/useTryUseFeatureDialog';
import {
  FeatureStatus,
  SubscriptionFeature,
} from '../../../../../../SubscriptionPlans/subscriptionPlans.types';
import useDialog from '../../../../../../../hooks/useDialog';
import { ScanReceiptDialogProps } from '../../ContentFile/component/ScanReceiptDialog';
import { HIDDialogProps } from '../../../../../../../types/common';
import { useGetCurrentSubscriptionPlanQuery } from '../../../../../../SubscriptionPlans/api/subscriptionPlans.api';

type ScanReceiptDialogPropsOnly = Omit<ScanReceiptDialogProps, keyof HIDDialogProps>;

type UseOpenAdvancedReceiptReadingOrSubscriptionDialog = () => [
  (props: ScanReceiptDialogPropsOnly) => void,
  boolean,
];

const useOpenScanReceiptOrSubscriptionDialog: UseOpenAdvancedReceiptReadingOrSubscriptionDialog = () => {
  const auth = getActiveAuth();

  const { isPartnerAuth } = useGetActiveAuthUser();
  const {
    data: currentSubscriptionPlan,
  } = useGetCurrentSubscriptionPlanQuery(isPartnerAuth ? skipToken : undefined);

  const featureStatus = currentSubscriptionPlan?.me?.advancedReceiptReading?.status;

  const [setUserSettings] = useSetUserSettingsMutation();

  const [openScanReceiptDialog] = useDialog<ScanReceiptDialogProps>(DialogNames.SCAN_RECEIPT_DIALOG);

  const {
    data: { excludedDialogsToShow = [] } = {},
  } = useGetUserSettingsQuery(
    auth.currentUser?.uid
      ? { userUId: auth.currentUser.uid }
      : skipToken,
  );

  const isExcludedScanReceiptDialog = excludedDialogsToShow.includes(DialogNames.SCAN_RECEIPT_DIALOG);

  const excludedDialogOnClose = (status: FeatureStatus) => {
    if (status === FeatureStatus.DISABLED && !isExcludedScanReceiptDialog) {
      setUserSettings({
        excludedDialogsToShow: [...excludedDialogsToShow, DialogNames.SCAN_RECEIPT_DIALOG],
      });
    }
  };

  const [
    proceedToFeatureOrOpenSubscriptionDialog,
  ] = useTryUseFeatureDialog({
    subscriptionFeature: SubscriptionFeature.ADVANCED_RECEIPT_READING,
    onClose: excludedDialogOnClose,
  });

  return [
    (params: ScanReceiptDialogPropsOnly) => proceedToFeatureOrOpenSubscriptionDialog({ onAction: () => openScanReceiptDialog(params) }),
    isExcludedScanReceiptDialog
      && (featureStatus === undefined || [FeatureStatus.DISABLED, FeatureStatus.ENABLED_BUT_EXHAUSTED].includes(featureStatus)),
  ];
};

export default useOpenScanReceiptOrSubscriptionDialog;
