import React, { FC } from 'react';
import {
  CircularProgress,
  Icon,
  PaletteColor,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import {
  TimelineItem as MuiTimelineItem,
  TimelineConnector as MUITimelineConnector,
  TimelineDot as MUITimelineDot,
  TimelineSeparator,
  TimelineContent,
} from '@mui/lab';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import {
  ChevronRight,
  CheckBox,
  CheckBoxOutlineBlank,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { FCC } from '../../../../../../../../../types/common';
import useBreakPointsSizes from '../../../../../../../../../hooks/useBreakpointsSizes';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../../../utils/date';
import {
  TimelineEvent,
  TimelineEventStatus,
} from '../../../types.timeline';
import HIDClickable from '../../../../../../../../../components/HIDClickable';
import TimelineEventContent from './TimelineEventContent';

export type DateSeparator = {
  label: string;
};

export const isDateSeparator = (item: TimelineEvent | DateSeparator): item is DateSeparator => 'label' in item;

const TimelineDot: FC<{ color?: PaletteColor }> = ({ color }) => {
  const theme = useTheme();

  return (
    <MUITimelineDot
      style={{
        background: color?.main || theme.palette.primary.main,
        borderColor: color?.lighter || theme.palette.primary.lighter,
        boxShadow: 'none',
      }}
      sx={{
        width: theme.spacing(2),
        height: theme.spacing(2),
        borderWidth: '2px',
        margin: theme.spacing(1.25, 0),
      }}
    />
  );
};

// eslint-disable-next-line react/no-multi-comp
const TimelineConnector = () => {
  const theme = useTheme();

  return (
    <MUITimelineConnector
      sx={{
        backgroundColor: theme.palette.grey[300],
      }}
    />
  );
};

type DateLabelProps = {
  label?: string | Date;
};

// eslint-disable-next-line react/no-multi-comp
const DateLabel: FCC<DateLabelProps> = ({
  label = '',
  sx,
}) => {
  const theme = useTheme();

  const { isDownXl } = useBreakPointsSizes();

  const dateFormat = isDownXl ? DateTimeFormats.DATE_ONLY_TEXT_SHORT : DateTimeFormats.DATE_ONLY_TEXT;

  return (
    <Typography
      noWrap
      sx={{
        width: isDownXl ? 100 : 144,
        height: 24,
        color: theme.palette.primary.main,
        ...sx,
      }}
      variant="subtitle1"
    >
      {typeof label === 'string' ? label : formatDate(label, dateFormat)}
    </Typography>
  );
};

type TimelineItemContainerProps = {
  isSeparator?: boolean;
  leftContent?: React.ReactNode,
  rightContent?: React.ReactNode,
  hasConnector?: boolean;
  dotColor?: PaletteColor;
  leftContentSx?: SxProps;
  rightContentSx?: SxProps;
  onClick?: () => void;
};

// eslint-disable-next-line react/no-multi-comp
const TimelineItemContainer: FCC<TimelineItemContainerProps> = ({
  isSeparator,
  leftContent,
  rightContent,
  hasConnector = true,
  dotColor,
  sx,
  leftContentSx,
  rightContentSx,
  style,
  onClick,
}) => {
  const theme = useTheme();
  const clickable = Boolean(onClick);

  const RightContainer = clickable && !isSeparator ? HIDClickable : Stack;

  const notClickableContainerSx = {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.25),
    backgroundColor: theme.palette.grey[50],
  };

  return (
    <MuiTimelineItem
      style={style}
      sx={sx}
    >
      {leftContent && (
        <TimelineOppositeContent sx={leftContentSx}>
          {leftContent}
        </TimelineOppositeContent>
      )}
      <TimelineSeparator>
        <TimelineDot color={dotColor} />
        {hasConnector && (
          <TimelineConnector />
        )}
      </TimelineSeparator>
      <TimelineContent sx={{ paddingRight: 0 }}>
        <RightContainer
          sx={{
            ...(!clickable && !isSeparator ? notClickableContainerSx : {}),
            ...rightContentSx,
          }}
          onClick={onClick}
        >
          {rightContent}
        </RightContainer>
      </TimelineContent>
    </MuiTimelineItem>
  );
};

// eslint-disable-next-line react/no-multi-comp
export const TimelineSeparatorContent: FC<{ label: string | Date }> = ({ label }) => {
  const theme = useTheme();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
        height: theme.spacing(7),
        borderRadius: '10px',
        backgroundColor: theme.palette.primary.lighter,
      }}
    >
      <DateLabel
        label={label}
        sx={{ width: 'unset', color: theme.palette.common.black }}
      />
    </Stack>
  );
};

// eslint-disable-next-line react/no-multi-comp
export const TimelineLoadingItem: FC = () => {
  const { t } = useTranslation(['common']);
  const { isDownSm } = useBreakPointsSizes();

  return (
    <TimelineItemContainer
      isSeparator
      hasConnector={false}
      leftContent={isDownSm ? null : <DateLabel label={t('common:loading')} />}
      rightContent={
        <>
          {isDownSm ? <DateLabel label={t('common:loading')} /> : null}
          <CircularProgress size={24} />
        </>
      }
    />
  );
};

// eslint-disable-next-line react/no-multi-comp
export const TimelineTodayItem: FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const { t } = useTranslation(['common']);
  const { isDownSm } = useBreakPointsSizes();

  return (
    <TimelineItemContainer
      isSeparator
      leftContent={isDownSm ? null : <DateLabel label={t('common:today')} />}
      rightContent={
        isLoading
          ? <CircularProgress size={24} />
          : <TimelineSeparatorContent label={t('common:today')} />
      }
    />
  );
};

type TimelineItemProps = {
  item: TimelineEvent | DateSeparator;
  isLast?: boolean;
  isSelectionMode?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
};

// eslint-disable-next-line react/no-multi-comp
const TimelineItem: FCC<TimelineItemProps> = ({
  item,
  isLast = false,
  isSelectionMode = false,
  isSelected = false,
  style,
  onClick,
}) => {
  const theme = useTheme();
  const isSeparator = isDateSeparator(item);

  const dotColorsMap: Record<TimelineEventStatus, PaletteColor> = {
    [TimelineEventStatus.OK]: theme.palette.primary,
    [TimelineEventStatus.ERROR]: theme.palette.error,
    [TimelineEventStatus.PENDING]: theme.palette.warning,
  };

  const { isDownSm } = useBreakPointsSizes();

  const dateLabel = isSeparator
    ? item.label
    : new Date(item.start);

  const dateContent = <DateLabel label={dateLabel} />;

  const content = isSeparator
    ? <TimelineSeparatorContent label={dateLabel} />
    : (
      <Stack direction="row" justifyContent="space-between" sx={{ position: 'relative' }}>
        <TimelineEventContent event={item} />
        <Stack
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        >
          {
            isSelectionMode
              ? isSelected
                ? <CheckBox color="primary" />
                : <CheckBoxOutlineBlank />
              : onClick
                ? <ChevronRight />
                : <Icon />
          }
        </Stack>
      </Stack>
    );

  const color = !isSeparator && item.status
    ? dotColorsMap[item.status]
    : theme.palette.primary;

  return (
    <TimelineItemContainer
      dotColor={color}
      hasConnector={!isLast}
      isSeparator={isSeparator}
      leftContent={isDownSm ? null : dateContent}
      rightContent={content}
      style={style}
      onClick={isSeparator ? undefined : onClick}
    />
  );
};

export default TimelineItem;
