import React, {
  useCallback,
  useState,
} from 'react';
import * as R from 'ramda';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import {
  GridAlignment,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';

import HomeListLayout from '../../../../../../pages/Home/components/HomeListLayout';
import {
  useGetAllReceiptCategoriesQuery,
  useGetReceiptsSummaryQuery,
  useGetAllReceiptsQuery,
  useDeleteReceiptsMutation,
} from '../../api/receipt.api';
import { ALL_RECEIPTS_CATEGORY } from '../../constants.receipt';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../../utils/date';
import {
  EMPTY_VALUE,
  formatMoney,
} from '../../../../../../../../utils/string';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import { Receipt } from '../../types.receipt';
import ReceiptsSummaryChart from './components/ReceiptsSummaryChart';
import {
  useGetReceiptCategoryName,
  getReceiptsSummaryChartData,
} from '../../utils.receipt';
import ContentImage from '../../../../components/ContentImage';
import ReceiptsQuickNavigation from './components/ReceiptsQuickNavigation';
import {
  getCreateReceiptPath,
  getReceiptCategoriesPath,
  getReceiptPath,
} from '../../navigation.receipt';
import { LIST_CHEVRON_COLUMN_CONFIG } from '../../../../../../../../constants/columns';
import ListEntitiesToolbarActions, { SelectionModeType } from '../../../../components/actions/ListEntitiesToolbarActions';
import ListEntitiesActions from '../../../../components/actions/ListEntitiesActions';
import EntityType from '../../../../../../../../constants/entityType';
import {
  useNavigateBackOr,
  useRouteParams,
} from '../../../../../../../../utils/routes';
import FiltersSection, { ContentFilters } from '../../../../components/sections/FiltersSection';
import { TimeIntervalsGroupingType } from '../../../../types/content.type';
import { getPathWithPropertyIdOrInit } from '../../../../../../../Auth/navigation/navigation.auth';
import useSearch from '../../../../../../../../hooks/useSearch';

const FilterEntityTypes = [
  EntityType.BUILDING,
  EntityType.BUILDING_PART,
  EntityType.ROOM,
  EntityType.OUTDOOR,
  EntityType.TASK,
];

type UseGetReceiptsDataGridColumns = (props: {
  isSelectionMode: boolean;
  totalDeductibleVisible: boolean;
  totalAmountVisible: boolean;
}) => Array<GridColDef>;

export const useGetReceiptsDataGridColumns: UseGetReceiptsDataGridColumns = ({
  isSelectionMode,
  totalDeductibleVisible,
  totalAmountVisible,
}) => {
  const { t } = useTranslation(['common', 'receipts']);
  const theme = useTheme();
  const { isDownSm } = useBreakPointsSizes();

  const columns = [
    {
      field: 'name',
      headerName: t('common:name'),
      flex: isDownSm ? 0.6 : 0.36,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { name, date, blobs } = params?.row as Receipt;
        return (
          <Stack
            alignItems="center"
            direction="row"
            sx={{ minWidth: 0 }}
          >
            <ContentImage
              blobs={blobs}
              imageSx={{ maxHeight: theme.spacing(8) }}
              sx={{ marginRight: theme.spacing(1.5) }}
            />
            <Stack
              direction="column"
              justifyContent="center"
              sx={{ minWidth: 0 }}
            >
              <Stack direction="row" sx={{ minWidth: 0 }}>
                <Typography noWrap variant="subtitle1">
                  {name}
                </Typography>
              </Stack>
              {isDownSm && (
                <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
                  {date ? formatDate(new Date(date), DateTimeFormats.DATE_ONLY) || EMPTY_VALUE : EMPTY_VALUE}
                </Typography>
              )}
            </Stack>
          </Stack>
        );
      },
    },
    !isDownSm && {
      field: 'place',
      headerName: t('receipts:receipts_receipts_place_column'),
      flex: 0.16,
      type: 'string',
      sortable: true,
      valueGetter: (params: GridValueGetterParams<Receipt>) => params.row.place,
      renderCell: (params: GridRenderCellParams<Receipt, string>) => (
        <Typography noWrap variant="body2">
          {params.value || EMPTY_VALUE}
        </Typography>
      ),
    },
    !isDownSm && {
      field: 'date',
      headerName: t('receipts:receipts_receipts_date_column'),
      flex: 0.16,
      type: 'string',
      sortable: true,
      valueGetter: (params: GridValueGetterParams<Receipt>) => params.row.date,
      renderCell: (params: GridRenderCellParams<Receipt, string>) => (
        <Typography noWrap variant="body2">
          {params.value ? formatDate(new Date(params.value), DateTimeFormats.DATE_ONLY) || EMPTY_VALUE : EMPTY_VALUE}
        </Typography>
      ),
    },
    !isDownSm && totalDeductibleVisible && {
      field: 'deductibleAmount',
      headerName: t('receipts:receipts_deductible_label'),
      flex: 0.16,
      type: 'string',
      sortable: true,
      valueGetter: (params: GridValueGetterParams<Receipt>) => {
        const { repairImprovementValue, basicImprovementValue } = params.row;
        return R.isNil(repairImprovementValue) && R.isNil(basicImprovementValue)
          ? undefined
          : (repairImprovementValue || 0) + (basicImprovementValue || 0);
      },
      renderCell: (params: GridRenderCellParams<Receipt, number | undefined>) => (
        <Typography noWrap variant="body2">
          {params.value !== undefined ? formatMoney(params.value) : EMPTY_VALUE}
        </Typography>
      ),
    },
    !isDownSm && totalDeductibleVisible && {
      field: 'amount',
      headerName: t('receipts:receipts_price_label'),
      flex: 0.16,
      type: 'string',
      sortable: true,
      valueGetter: (params: GridValueGetterParams<Receipt>) => params.row.amount,
      renderCell: (params: GridRenderCellParams<Receipt, number | undefined>) => (
        <Typography noWrap variant="subtitle2">
          {params.value !== undefined ? formatMoney(params.value) : EMPTY_VALUE}
        </Typography>
      ),
    },
    isDownSm && (totalAmountVisible || totalDeductibleVisible) && {
      field: 'amount',
      headerName: t('receipts:receipts_price_label'),
      flex: 0.4,
      type: 'string',
      align: 'left' as GridAlignment,
      sortable: true,
      valueGetter: (params: GridValueGetterParams<Receipt>) => {
        const { amount } = params.row;
        return amount;
      },
      renderCell: (params: GridRenderCellParams<Receipt, number | undefined>) => {
        const { amount, deductibleAmount } = params.row;
        return (
          <Stack sx={{ minWidth: 0 }}>
            {totalAmountVisible && (
              <Typography noWrap variant="subtitle1">
                {amount !== undefined ? formatMoney(amount) : EMPTY_VALUE}
              </Typography>
            )}
            {deductibleAmount && (
              <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
                {deductibleAmount !== undefined ? formatMoney(deductibleAmount) : EMPTY_VALUE}
              </Typography>
            )}
          </Stack>
        );
      },
    },
    !isSelectionMode && LIST_CHEVRON_COLUMN_CONFIG,
  ].filter(Boolean);

  return columns;
};

const Receipts = () => {
  const { t } = useTranslation(['receipts']);
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();

  const { displaySearch } = useSearch(EntityType.RECEIPT);

  const { category: categoryId } = useRouteParams<{ category: string }>();

  const { data: propertyId } = useGetCurrentPropertyId();
  const [selectionModeType, setSelectionModeType] = useState<SelectionModeType | undefined>();
  const [selectedReceiptIds, setSelectedReceiptIds] = useState<Array<string>>([]);

  const isSelectionMode = Boolean(selectionModeType);

  const [deleteReceipts, { isLoading: isDeleting }] = useDeleteReceiptsMutation();

  const isAllReceiptsCategory = categoryId === ALL_RECEIPTS_CATEGORY;

  const [filters, setFilters] = useState<ContentFilters>({
    from: undefined,
    to: undefined,
    groupingType: TimeIntervalsGroupingType.Yearly,
    columnsVisibilityMap: {
      totalAmount: { label: t('receipts:receipts_price_label'), visible: true },
      totalDeductible: { label: t('receipts:receipts_deductible_label'), visible: true },
    },
    entityIdsMap: undefined,
  });

  const totalAmountVisible = Boolean(filters.columnsVisibilityMap?.totalAmount?.visible);
  const totalDeductibleVisible = Boolean(filters.columnsVisibilityMap?.totalDeductible?.visible);

  const { data: categories = [] } = useGetAllReceiptCategoriesQuery(propertyId ? { propertyId } : skipToken);

  const { data: receiptsSummary } = useGetReceiptsSummaryQuery(
    propertyId
      ? {
        propertyId,
        categoryId: isAllReceiptsCategory ? undefined : categoryId,
        from: filters.from,
        to: filters.to,
        entityIdsMap: filters.entityIdsMap,
        groupingType: filters.groupingType,
      }
      : skipToken,
  );

  const summary = receiptsSummary?.summary;

  const {
    data: receipts = [],
    isLoading: isLoadingReceipts,
    isFetching: isFetchingReceipts,
  } = useGetAllReceiptsQuery(
    propertyId
      ? {
        propertyId,
        from: filters.from,
        to: filters.to,
        entityIdsMap: filters.entityIdsMap,
      }
      : skipToken,
  );

  const categoryName = useGetReceiptCategoryName(categoryId, categories);

  const categoryReceipts = isAllReceiptsCategory
    ? receipts
    : receipts.filter((receipt) => categoryId && receipt.categoryId === categoryId);

  const columns = useGetReceiptsDataGridColumns({
    isSelectionMode,
    totalAmountVisible,
    totalDeductibleVisible,
  });

  const handleRowClick: GridEventListener<'rowClick'> = useCallback(
    (params) => {
      if (propertyId && !isSelectionMode) {
        const receipt = params?.row as Receipt;
        navigate(getReceiptPath({ propertyId, categoryId, id: receipt.id }));
      }
    },
    [categoryId, propertyId, isSelectionMode, navigate],
  );

  const handleRowSelectionModelChange = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => setSelectedReceiptIds(rowSelectionModel as Array<string>),
    [],
  );

  const handleNavigateToCategories = useCallback(
    () => navigateBackOr(getPathWithPropertyIdOrInit(getReceiptCategoriesPath, { propertyId })),
    [navigateBackOr, propertyId],
  );

  const handleAdd = () => navigate(getPathWithPropertyIdOrInit(getCreateReceiptPath, { propertyId, categoryId }));

  const handleEnterDeleteMode = () => {
    setSelectionModeType(SelectionModeType.DELETE);
    setSelectedReceiptIds([]);
  };

  const handleDelete = () => {
    if (propertyId) {
      deleteReceipts({ propertyId, ids: selectedReceiptIds });
    }

    setSelectionModeType(undefined);
    setSelectedReceiptIds([]);
  };

  return (
    <HomeListLayout
      DataGridToolbar={
        <ListEntitiesToolbarActions
          count={categoryReceipts.length}
          entity={EntityType.RECEIPT}
          isFetching={isFetchingReceipts || isDeleting}
          isLoading={isLoadingReceipts}
          selectedCount={selectedReceiptIds.length}
          selectionModeType={selectionModeType}
          onCancel={() => setSelectionModeType(undefined)}
          onDelete={handleDelete}
        />
      }
      ListHeaderComponent={
        !isSelectionMode
          ? (
            <ReceiptsSummaryChart
              data={getReceiptsSummaryChartData(summary?.values || [], filters.groupingType)}
              isLoading={isLoadingReceipts}
              sx={{ marginBottom: 3 }}
              totalAmount={summary?.totalAmount}
              totalAmountVisible={totalAmountVisible}
              totalDeductible={summary?.totalDeductible}
              totalDeductibleVisible={totalDeductibleVisible}
            />
          )
          : undefined
      }
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ListEntitiesActions
              disabled={isSelectionMode}
              onAdd={handleAdd}
              onDelete={handleEnterDeleteMode}
              onSearch={displaySearch}
            />
          </Card>
          {!isSelectionMode && (
            <Card sx={{ padding: 2 }}>
              <FiltersSection
                showChartGroupingTypeFilers
                showColumnsVisibilityFilters
                showDateFilers
                showEntityTypeFilters
                entityTypes={FilterEntityTypes}
                filters={filters}
                onChange={setFilters}
              />
            </Card>
          )}
          {!isSelectionMode && (
            <Card sx={{ padding: 2 }}>
              <ReceiptsQuickNavigation />
            </Card>
          )}
        </>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getReceiptCategoriesPath, { propertyId }),
          name: t('receipts:receipts_receipts_title'),
        },
      ]}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'name',
              sort: 'asc',
            },
          ],
        },
      }}
      isLoading={isLoadingReceipts || isFetchingReceipts}
      isSelectionMode={isSelectionMode}
      rows={categoryReceipts}
      sideDrawerElements={[
        <ListEntitiesActions
          disabled={isSelectionMode}
          key={ListEntitiesActions.name}
          onAdd={handleAdd}
          onDelete={handleEnterDeleteMode}
          onSearch={displaySearch}
        />,
        !isSelectionMode && (
          <FiltersSection
            showChartGroupingTypeFilers
            showColumnsVisibilityFilters
            showDateFilers
            showEntityTypeFilters
            entityTypes={FilterEntityTypes}
            filters={filters}
            key={FiltersSection.name}
            onChange={setFilters}
          />
        ),
        !isSelectionMode && (
          <ReceiptsQuickNavigation
            key={ReceiptsQuickNavigation.name}
          />
        ),
      ].filter(Boolean)}
      title={categoryName}
      onBack={handleNavigateToCategories}
      onRowClick={handleRowClick}
      onRowSelectionModelChange={handleRowSelectionModelChange}
    />
  );
};

export default Receipts;
