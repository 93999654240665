import { skipToken } from '@reduxjs/toolkit/query';
import {
  SubscriptionPlanId,
} from '../subscriptionPlans.types';

import { QueryHook } from '../../../types/common';
import { useGetActiveAuthUser } from '../../../external-services/firebase';
import { useGetCurrentSubscriptionPlanQuery } from '../api/subscriptionPlans.api';

type CurrentUserSubscriptionPlanId = {
  subscriptionPlanId: SubscriptionPlanId;
  isFree: boolean;
  isBase: boolean;
  isPlus: boolean;
  isBaseOrPlus: boolean;
};

const useGetCurrentUserSubscriptionPlanId: QueryHook<CurrentUserSubscriptionPlanId | undefined> = (_args, options) => {
  const { user: currentUser } = useGetActiveAuthUser();

  // const {
  //   data: tokenData,
  //   isLoading: isTokenDataLoading,
  //   error: tokenDataError,
  //   refetch,
  // } = useGetTokenDataQuery(currentUser && !options?.skip ? {} : skipToken);

  const { data, ...rest } = useGetCurrentSubscriptionPlanQuery(currentUser && !options?.skip ? undefined : skipToken);
  const subscriptionPlanId = data?.plan?.id;

  return {
    data: subscriptionPlanId
      ? {
        subscriptionPlanId,
        isFree: subscriptionPlanId === SubscriptionPlanId.FREE,
        isBase: subscriptionPlanId === SubscriptionPlanId.BASE,
        isPlus: subscriptionPlanId === SubscriptionPlanId.PLUS,
        isBaseOrPlus: subscriptionPlanId === SubscriptionPlanId.BASE || subscriptionPlanId === SubscriptionPlanId.PLUS,
      }
      : undefined,
    ...rest,
  };
};

export default useGetCurrentUserSubscriptionPlanId;
