import { useTranslation } from 'react-i18next';

import {
  ForumAuthor,
  ForumAuthorType,
  ForumExpertAuthor,
  ForumUserAuthor,
} from '../forum.types';

export const useGetForumAuthorName = () => {
  const { t } = useTranslation(['forum']);

  return (author?: ForumAuthor) => {
    const givenName = author?.type === ForumAuthorType.USER
      ? (author as ForumUserAuthor).givenName
      : author?.type === ForumAuthorType.EXPERT
        ? (author as ForumExpertAuthor).expert.name
        : t('forum:forum_anonymous');

    return {
      givenName,
      surname: author?.type === ForumAuthorType.EXPERT
        ? undefined
        : (author as ForumUserAuthor)?.surname,
    };
  };
};
