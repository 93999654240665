import React from 'react';
import * as Sentry from '@sentry/react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import ROUTES_CONFIG from './routes/RoutesConfig';
import HIDFullScreenLoader from './components/HIDFullScreenLoader';
import HIDSnackBarProvider from './components/snackbar/HIDSnackbarProvider';
import useFirebaseSyncActionsInitializer from './hooks/useFirebaseSyncActionsInitializer';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter(ROUTES_CONFIG);

const App = () => {
  useFirebaseSyncActionsInitializer();

  return (
    <HIDSnackBarProvider>
      <RouterProvider
        fallbackElement={<HIDFullScreenLoader />}
        router={router}
      />
    </HIDSnackBarProvider>
  );
};
export default App;
