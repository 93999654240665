import React, { FC } from 'react';
import {
  Card,
  Grid,
  Stack,
} from '@mui/material';
import { match } from 'ts-pattern';

import {
  FileMimeType,
  ImageMimeTypes,
} from '../../../../../constants/mimeTypes';
import HIDImageViewer, { HIDImageViewerRef } from '../../../../../components/image/HIDImageViewer/HIDImageViewer';
import FilesList from './lists/FilesList';
import { ContentFile } from '../modules/ContentFile/types.contentFile';
import {
  ExternalMedia,
  ExternalMediaType,
} from '../modules/Product/types.product';
import { getExternalMediaByType } from '../modules/Product/utils.products';
import useGetPropertyPermissions from '../../../hooks/useGetPropertyPermissions';
import { HIDBlob } from '@house-id/houseid-types/dist/common';

type ContentFileViewerProps = {
  blobs: Array<HIDBlob>;
  variant?: 'inline' | 'side_column' | 'side_drawer';
  showImages?: boolean;
  showFiles?: boolean;
  mimeTypes?: Array<FileMimeType>;
  externalMedia?: Array<ExternalMedia>;
  isLoading: boolean;
  isUpdating?: boolean;
  isUploading?: boolean;
  imageViewerRef?: React.MutableRefObject<HIDImageViewerRef | null>;
  onContentFilesSelected?: (files: Array<ContentFile>) => void;
  onDeleteBlob?: (blobId: string) => void;
  onDeleteExternalMedia?: (url: string) => void;
  onMoveExternalMedia?: (url: string, mediaType: ExternalMediaType) => void;
};

const ContentFileViewer: FC<ContentFileViewerProps> = ({
  blobs,
  variant = 'inline',
  showFiles = false,
  showImages = false,
  mimeTypes,
  externalMedia,
  isLoading,
  isUpdating,
  isUploading,
  imageViewerRef,
  onContentFilesSelected,
  onDeleteBlob,
  onDeleteExternalMedia,
  onMoveExternalMedia,
}) => {
  const { data: { canCreate, canUpdate } = {} } = useGetPropertyPermissions();

  if (!showImages && !showFiles) {
    return null;
  }

  const imageBlobs = blobs
    .filter(
      (blob) => (blob.thumbnailUrlTemplate && (ImageMimeTypes.includes(blob.mime) || blob.mime as FileMimeType === FileMimeType.PDF))
        || (blob.localUrl && ImageMimeTypes.includes(blob.mime)),
    );

  const externalImages = getExternalMediaByType(ExternalMediaType.IMAGE, externalMedia);

  const isVisibleImageSection = Boolean(
    showImages
    && (imageBlobs.length || (canCreate && canUpdate) || isLoading || isUploading),
  );
  const isVisibleFileSection = Boolean(
    showFiles
    && (blobs.length || externalMedia?.length || (canCreate && canUpdate) || isLoading || isUpdating),
  );

  const FileViewerInline = (
    <Grid
      container
      item
      alignItems="center"
      justifyContent="center"
      spacing={3}
    >
      {isVisibleImageSection && (
        <Grid
          item
          sm={(blobs.length > 0) ? 6 : 12}
          sx={{ marginBottom: showFiles ? 3 : 0 }}
          xxs={12}
        >
          <Stack alignItems="center">
            <HIDImageViewer
              externalImages={externalImages}
              images={imageBlobs}
              isLoading={isLoading}
              isUploading={isUploading}
              key={imageBlobs.length}
              mimeTypes={mimeTypes}
              onContentFilesSelected={onContentFilesSelected}
            />
          </Stack>
        </Grid>
      )}
      {isVisibleFileSection && (
        <Grid
          item
          sm={8}
          sx={{ marginBottom: 3 }}
          xxs={12}
        >
          <FilesList
            blobs={blobs}
            externalMedia={externalMedia}
            isLoading={isLoading}
            isUpdating={isUpdating}
            mimeTypes={mimeTypes}
            onContentFilesSelected={onContentFilesSelected}
            onDeleteBlob={onDeleteBlob}
            onDeleteExternalMedia={onDeleteExternalMedia}
            onMoveExternalMedia={onMoveExternalMedia}
          />
        </Grid>
      )}
    </Grid>
  );

  const FileViewerSideColumn = (
    <>
      {isVisibleImageSection && (
        <Card style={{ borderRadius: 8 }}>
          <HIDImageViewer
            externalImages={externalImages}
            images={imageBlobs}
            isLoading={isLoading}
            isUploading={isUploading}
            key={imageBlobs.length}
            mimeTypes={mimeTypes}
            ref={imageViewerRef}
            showBorder={false}
            onContentFilesSelected={onContentFilesSelected}
          />
        </Card>
      )}
      {isVisibleFileSection && (
        <Card sx={{ padding: 2 }}>
          <FilesList
            blobs={blobs}
            externalMedia={externalMedia}
            isLoading={isLoading}
            isUpdating={isUpdating}
            mimeTypes={mimeTypes}
            onContentFilesSelected={onContentFilesSelected}
            onDeleteBlob={onDeleteBlob}
            onDeleteExternalMedia={onDeleteExternalMedia}
            onMoveExternalMedia={onMoveExternalMedia}
          />
        </Card>
      )}
    </>
  );

  const FileViewerSideDrawer = (
    <>
      {isVisibleImageSection && (
        <Card
          key={HIDImageViewer.name}
          style={{ borderRadius: 8 }}
        >
          <HIDImageViewer
            externalImages={externalImages}
            images={imageBlobs}
            isLoading={isLoading}
            isUploading={isUploading}
            key={imageBlobs.length}
            mimeTypes={mimeTypes}
            showBorder={false}
            onContentFilesSelected={onContentFilesSelected}
          />
        </Card>
      )}
      {isVisibleFileSection && (
        <FilesList
          blobs={blobs}
          externalMedia={externalMedia}
          isLoading={isLoading}
          isUpdating={isUpdating}
          key={FilesList.name}
          mimeTypes={mimeTypes}
          onContentFilesSelected={onContentFilesSelected}
          onDeleteBlob={onDeleteBlob}
          onDeleteExternalMedia={onDeleteExternalMedia}
          onMoveExternalMedia={onMoveExternalMedia}
        />
      )}
    </>
  );

  return match(variant)
    .with('inline', () => FileViewerInline)
    .with('side_column', () => FileViewerSideColumn)
    .with('side_drawer', () => FileViewerSideDrawer)
    .exhaustive();
};

export default ContentFileViewer;
