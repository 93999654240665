import React, {
  FC,
  useEffect,
} from 'react';
import {
  Grid,
  Stack,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';

import { PAGE_OUTER_SPACING } from '../../../../constants/layout';
import WelcomeWidget from './components/WelcomeWidget';
import PropertyProgressWidget from './components/PropertyProgressWidget/PropertyProgressWidget';
import { useGetPropertySettingsQuery } from '../../../Auth/api/settings.api';
import { getActiveAuth } from '../../../../external-services/firebase';
import useGetCurrentPropertyId from '../../hooks/useGetCurrentPropertyId';
import MessagesWidget from './components/MessagesWidget';
import RemindersWidget from './components/RemindersWidget';
import TasksWidget from './components/TasksWidget';
import LoansRateWidget from './components/LoansRateWidget';
import AccommodationCostWidget from './components/AccommodationCostWidget';
import InterestRateWidget, { useGetInterestRateWidgetVisible } from './components/InterestRateWidget';
import ValuationWidget from './components/ValuationWidget';
import ReceiptsSummaryWidget from './components/ReceiptsSummaryWidget';
import TotalReceiptsWidget from './components/TotalReceiptsWidget';
import MarketValueWidget from './components/MarketValueWidget';
import RecentlyAddedProductsWidget from './components/RecentlyAddedProductsWidget';
import AddByOthersProductsWidget from './components/AddByOthersProductsWidget';
import SuggestionsWidget from './components/SuggestionsWidget';
import NewFeaturesWidget from './components/NewFeaturesWidget';
import BonusOffersWidget from './components/BonusOffersWidget/BonusOffersWidget';
import ForumExpertWidget from './components/ForumExpertWidget';
import InviteWidget from './components/InviteWidget';
import LatestForumQuestionsWidget from './components/LatestForumQuestionsWidget';
import { useGetPropertyUsersQuery } from '../../api/property.api';
import useGetIsCurrentUserPropertyOwner from '../../hooks/useGetIsCurrentUserPropertyOwner';
import useGetPropertyPermissions from '../../hooks/useGetPropertyPermissions';
import useShowStartTrialPeriodDialog from './hooks/useShowStartTrialPeriodDialog';
import SubscriptionPlanBanner from './components/SubscriptionPlanBanner';
import useGetCurrentUserSubscriptionPlanId from '../../../SubscriptionPlans/hooks/useGetCurrentUserSubscriptionPlanId';
import useGetIsSfSellerSellingProperty from '../../hooks/useGetIsSfSellerSellingProperty';
import SellingPropertyTasksList from './components/SellingPropertyTasksList';
import SellingPropertyOverviewWidget from '../../modules/SellingProperty/components/SellingPropertyOverviewWidget';
import useShowDialogs from '../../../../hooks/useShowDialogs';
import useGetHomeDialogs from './hooks/useGetHomeDialogs';
import RecurringExpensesWidget from './components/RecurringExpensesWidget';
import { useAppDispatch } from '../../../../store/hooks';
import { budgetsApi } from '../../modules/Finances/modules/Budgets/api/budgets.api';

const HOME_PAGE_ID = 'home';

const PropertyHome: FC = () => {
  const auth = getActiveAuth();
  const dispatch = useAppDispatch();

  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: isSellingProperty } = useGetIsSfSellerSellingProperty();

  const { currentData: propertySettingsData } = useGetPropertySettingsQuery(
    auth.currentUser?.uid && propertyId
      ? { userUId: auth.currentUser.uid, propertyId }
      : skipToken,
  );

  const { data: users = [] } = useGetPropertyUsersQuery(propertyId ? { propertyId } : skipToken);
  const isOwner = useGetIsCurrentUserPropertyOwner({ propertyId });

  const { data: { canCreate, canUpdate } = {} } = useGetPropertyPermissions();

  const propertyProgressWidgetVisible = propertySettingsData?.propertySettings?.hideHomePropertyProgressWidget !== false;
  const inviteWidgetVisible = propertySettingsData?.propertySettings?.hideInviteWidget !== false && users.length === 1 && isOwner;
  const { data: { isPlus } = {} } = useGetCurrentUserSubscriptionPlanId();
  const interestRateWidgetVisible = useGetInterestRateWidgetVisible();

  useShowStartTrialPeriodDialog();

  const homeDialogs = useGetHomeDialogs();
  useShowDialogs(HOME_PAGE_ID, homeDialogs, 3);

  useEffect(() => {
    if (propertyId) {
      dispatch(budgetsApi.endpoints.getBudgets.initiate({ propertyId }));
    }
  }, [propertyId]);

  return (
    <Grid
      container
      spacing={2.5}
      sx={{
        padding: PAGE_OUTER_SPACING,
        paddingTop: {
          xl: 4,
        },
      }}
    >
      <Grid
        item
        md={propertyProgressWidgetVisible || isSellingProperty ? 8 : 12}
        xxs={12}
      >
        <WelcomeWidget />
      </Grid>
      {propertyProgressWidgetVisible && !isSellingProperty && (
        <Grid
          item
          md={4}
          xxs={12}
        >
          <PropertyProgressWidget />
        </Grid>
      )}
      {isSellingProperty && (
        <>
          <Grid
            item
            md={4}
            xxs={12}
          >
            <SellingPropertyOverviewWidget />
          </Grid>
          <Grid item xxs={12}>
            <SellingPropertyTasksList />
          </Grid>
          {!isPlus && (
            <Grid item xxs={12}>
              <SubscriptionPlanBanner />
            </Grid>
          )}
          {inviteWidgetVisible && (
            <Grid item xxs={12}>
              <InviteWidget />
            </Grid>
          )}
        </>
      )}
      {canCreate && canUpdate && !isSellingProperty && (
        <>
          <Grid
            item
            md={4}
            sm={6}
            xxs={12}
          >
            <MessagesWidget />
          </Grid>
          <Grid
            item
            md={4}
            sm={6}
            xxs={12}
          >
            <RemindersWidget />
          </Grid>
          <Grid
            item
            md={4}
            sm={12}
            xxs={12}
          >
            <TasksWidget />
          </Grid>
          <Grid item md={6} xxs={12}>
            <AccommodationCostWidget />
          </Grid>
          <Grid item md={6} xxs={12}>
            <LoansRateWidget />
          </Grid>
          {!isPlus && (
            <Grid item xxs={12}>
              <SubscriptionPlanBanner />
            </Grid>
          )}
          {interestRateWidgetVisible && (
            <Grid item md={12}>
              <InterestRateWidget />
            </Grid>
          )}
          <Grid item xl={6} xxs={12}>
            <RecurringExpensesWidget />
          </Grid>
          <Grid item xl={6} xxs={12}>
            <ValuationWidget />
          </Grid>
          <Grid
            item
            lg={7}
            md={6}
            xl={8}
            xxs={12}
          >
            <ReceiptsSummaryWidget />
          </Grid>
          <Grid
            item
            lg={5}
            md={6}
            xl={4}
            xxs={12}
          >
            <Stack spacing={2.5}>
              <TotalReceiptsWidget />
              <MarketValueWidget />
            </Stack>
          </Grid>
          <Grid
            item
            md={4}
            sm={6}
            xxs={12}
          >
            <RecentlyAddedProductsWidget />
          </Grid>
          <Grid
            item
            md={4}
            sm={6}
            xxs={12}
          >
            <AddByOthersProductsWidget />
          </Grid>
          <Grid
            item
            md={4}
            sm={12}
            xxs={12}
          >
            <SuggestionsWidget />
          </Grid>
          <Grid
            item
            md={4}
            sm={6}
            xxs={12}
          >
            <ForumExpertWidget />
          </Grid>
          <Grid
            item
            md={8}
            sm={6}
            xxs={12}
          >
            <LatestForumQuestionsWidget />
          </Grid>
          <Grid item md={6} xxs={12}>
            <NewFeaturesWidget />
          </Grid>
          <Grid item md={6} xxs={12}>
            <BonusOffersWidget />
          </Grid>
          {inviteWidgetVisible && (
            <Grid item xxs={12}>
              <InviteWidget />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default PropertyHome;
