import React, { FC } from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import {
  BuildOutlined,
  CancelOutlined,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import HIDButton from '../../../../../../../components/buttons/HIDButton';
import HIDIconButton from '../../../../../../../components/buttons/HIDIconButton';

export type SuggestionRowProps = {
  suggestionKey: string;
  name: string;
  mode?: 'small' | 'medium';
  onIgnoreSuggestion: (suggestionKey: string) => void;
  onFixSuggestion: (suggestionKey: string) => void;
};

const SuggestionRow: FC<SuggestionRowProps> = ({
  suggestionKey,
  name,
  mode = 'medium',
  onIgnoreSuggestion,
  onFixSuggestion,
}) => {
  const { t } = useTranslation(['suggestion']);

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      key={suggestionKey}
      spacing={1}
    >
      <Typography
        sx={{
          wordBreak: 'break-word',
        }}
        variant="subtitle1"
      >
        &bull; {name}
      </Typography>
      <Stack direction="row" spacing={1}>
        {
          mode === 'small'
            ? (
              <>
                <HIDIconButton
                  Icon={CancelOutlined}
                  size="small"
                  title={t('suggestion:suggestion_ignore')}
                  onClick={() => onIgnoreSuggestion(suggestionKey)}
                />
                <HIDIconButton
                  Icon={BuildOutlined}
                  size="small"
                  title={t('suggestion:suggestion_fix')}
                  onClick={() => onFixSuggestion(suggestionKey)}
                />
              </>
            )
            : (
              <>
                <HIDButton
                  color="secondary"
                  size="small"
                  onClick={() => onIgnoreSuggestion(suggestionKey)}
                >
                  {t('suggestion:suggestion_ignore')}
                </HIDButton>
                <HIDButton
                  size="small"
                  onClick={() => onFixSuggestion(suggestionKey)}
                >
                  {t('suggestion:suggestion_fix')}
                </HIDButton>
              </>
            )
        }
      </Stack>
    </Stack>
  );
};

export default SuggestionRow;
