import { HIDApiTags } from '../../../../../api/HIDApiTags';
import { adminApi } from '../../../api/admin.api';
import {
  Email,
  EmailShortModel,
} from '../types.emails';
import { HIDEntityId } from '../../../../../types/common';
import { provideArrayTags } from '../../../../../api/HIDBaseQuery';

type UploadEmailValues = {
  id: string;
  data: {
    propertyId: string;
    subject: string;
    messageText: string;
    blobIds: Array<string>
    complete: boolean;
  }
};

export const emails = adminApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmails: builder.query<Array<EmailShortModel>, void>({
      query: () => '/admin/emails',
      providesTags: (result) => provideArrayTags(HIDApiTags.EMAIL, result),
      transformResponse: (response: { emails: Array<EmailShortModel> }) => response.emails,
    }),
    getEmail: builder.query<Email, HIDEntityId>({
      query: ({ id }) => `/admin/emails/${id}`,
      providesTags: (email) => [{ type: HIDApiTags.EMAIL as const, id: email?.id }],
    }),
    getEmailAttachmentSignedUrl: builder.query<{ url: string }, { emailId: string, blobId: string }>({
      query: ({ blobId, emailId }) => `admin/emails/${emailId}/blobs/${blobId}/url`,
      providesTags: (_result, _error, arg) => [
        ({ type: HIDApiTags.EMAIL_ATTACHMENT as const, id: arg.blobId }),
      ],
    }),
    uploadEmail: builder.mutation<Email, UploadEmailValues>({
      query: ({ id, data }) => ({
        url: `/admin/emails/${id}/message`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: HIDApiTags.EMAIL as const, id: arg.id }],
    }),
    deleteEmail: builder.mutation<Email, HIDEntityId>({
      query: ({ id }) => ({
        url: `/admin/emails/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: HIDApiTags.EMAIL as const, id: arg.id }],
    }),
  }),
});

export const {
  useGetEmailsQuery,
  useGetEmailQuery,
  useUploadEmailMutation,
  useLazyGetEmailAttachmentSignedUrlQuery,
  useDeleteEmailMutation,
} = emails;
