import { useTranslation } from 'react-i18next';

import EntityType from '../../../../../../../constants/entityType';
import { SuggestionType } from '../types.suggestion';

export type SuggestionMetadata = {
  id: string;
  name: string,
  type: string,
};

const useGetSuggestionMetadata = () => {
  const { t } = useTranslation(['suggestion']);

  const suggestions: Record<string, Record<string, SuggestionMetadata>> = {
    [EntityType.PRODUCT]: {
      [SuggestionType.MISSING_PRICE]: {
        id: SuggestionType.MISSING_PRICE,
        name: t('suggestion:suggestion_product_missing_price'),
        type: t('suggestion:suggestion_product_missing_price_type'),
      },
      [SuggestionType.MISSING_FILE]: {
        id: SuggestionType.MISSING_FILE,
        name: t('suggestion:suggestion_product_missing_file'),
        type: t('suggestion:suggestion_product_missing_file_type'),
      },
      [SuggestionType.MISSING_TYPE]: {
        id: SuggestionType.MISSING_TYPE,
        name: t('suggestion:suggestion_product_missing_type'),
        type: t('suggestion:suggestion_product_missing_type_type'),
      },
      [SuggestionType.MISSING_PLACE]: {
        id: SuggestionType.MISSING_PLACE,
        name: t('suggestion:suggestion_product_missing_place'),
        type: t('suggestion:suggestion_product_missing_place_type'),
      },
      [SuggestionType.MISSING_PURCHASE_DATE]: {
        id: SuggestionType.MISSING_PURCHASE_DATE,
        name: t('suggestion:suggestion_receipt_missing_purchase_date'),
        type: t('suggestion:suggestion_receipt_missing_purchase_date_type'),
      },
      [SuggestionType.MISSING_LIFETIME]: {
        id: SuggestionType.MISSING_LIFETIME,
        name: t('suggestion:suggestion_product_missing_lifetime'),
        type: t('suggestion:suggestion_product_missing_lifetime_type'),
      },
      [SuggestionType.MISSING_WARRANTY]: {
        id: SuggestionType.MISSING_WARRANTY,
        name: t('suggestion:suggestion_product_missing_warranty'),
        type: t('suggestion:suggestion_product_missing_warranty_type'),
      },
      [SuggestionType.MISSING_BRAND]: {
        id: SuggestionType.MISSING_BRAND,
        name: t('suggestion:suggestion_product_missing_brand'),
        type: t('suggestion:suggestion_product_missing_brand_type'),
      },
    },
    [EntityType.RECEIPT]: {
      [SuggestionType.MISSING_AMOUNT]: {
        id: SuggestionType.MISSING_AMOUNT,
        name: t('suggestion:suggestion_receipt_missing_amount'),
        type: t('suggestion:suggestion_receipt_missing_amount_type'),
      },
      [SuggestionType.MISSING_INVOICE_NUMBER]: {
        id: SuggestionType.MISSING_INVOICE_NUMBER,
        name: t('suggestion:suggestion_receipt_missing_invoice_number'),
        type: t('suggestion:suggestion_receipt_missing_invoice_number_type'),
      },
      [SuggestionType.MISSING_DATE]: {
        id: SuggestionType.MISSING_DATE,
        name: t('suggestion:suggestion_receipt_missing_date'),
        type: t('suggestion:suggestion_receipt_missing_date_type'),
      },
      [SuggestionType.MISSING_PLACE]: {
        id: SuggestionType.MISSING_PLACE,
        name: t('suggestion:suggestion_receipt_missing_place'),
        type: t('suggestion:suggestion_receipt_missing_place_type'),
      },
      [SuggestionType.MISSING_FILE]: {
        id: SuggestionType.MISSING_FILE,
        name: t('suggestion:suggestion_receipt_missing_file'),
        type: t('suggestion:suggestion_receipt_missing_file_type'),
      },
    },
    [EntityType.DOCUMENT]: {
      [SuggestionType.MISSING_CLASSIFICATION_TYPE]: {
        id: SuggestionType.MISSING_CLASSIFICATION_TYPE,
        name: t('suggestion:suggestion_document_missing_classification_type'),
        type: t('suggestion:suggestion_document_missing_classification_type_type'),
      },
      [SuggestionType.MISSING_FILE]: {
        id: SuggestionType.MISSING_FILE,
        name: t('suggestion:suggestion_document_missing_file'),
        type: t('suggestion:suggestion_document_missing_file_type'),
      },
    },
    [EntityType.MESSAGE]: {
      [SuggestionType.NOT_SAVED_ATTACHMENTS]: {
        id: SuggestionType.NOT_SAVED_ATTACHMENTS,
        name: t('suggestion:suggestion_message_not_saved_attachments'),
        type: t('suggestion:suggestion_message_not_saved_attachments_type'),
      },
    },
    [EntityType.RECURRING_EXPENSE]: {
      [SuggestionType.ADD_SUGGESTED_EXPENSE]: {
        id: SuggestionType.ADD_SUGGESTED_EXPENSE,
        name: t('suggestion:suggestion_fixed_expense_add_suggested_expense'),
        type: t('suggestion:suggestion_fixed_expense_add_suggested_expense_type'),
      },
    },
    [EntityType.EXTERNAL_SERVICE]: {
      [SuggestionType.CONNECT_SERVICE]: {
        id: SuggestionType.CONNECT_SERVICE,
        name: t('suggestion:suggestions_external_service_connect_service'),
        type: t('suggestion:suggestions_external_service_connect_service_type'),
      },
    },
  };

  const findEntitySuggestionMetadata = (entityType: EntityType | string) => suggestions[entityType];

  const findSuggestionMetadata = (entityType: EntityType | string, suggestionType: SuggestionType | string) =>
    findEntitySuggestionMetadata(entityType)?.[suggestionType];

  return {
    findSuggestionMetadata,
    findEntitySuggestionMetadata,
  };
};

export default useGetSuggestionMetadata;
