import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SubscriptionPlanId } from '../subscriptionPlans.types';
import SubscriptionFreeImage from '../../../assets/images/subscription_free.png';
import SubscriptionBaseImage from '../../../assets/images/subscription_base.png';
import SubscriptionPlusImage from '../../../assets/images/subscription_plus.png';

export type SubscriptionPlanSettings = {
  id: SubscriptionPlanId;
  name: string;
  description: string;
  isPopular: boolean;
  planInfoUrl: string;
  backgroundColor: string;
  textColor: string;
  imageSource?: string;
  salePointsDescription: string;
  salePoints: Array<string>;
  priority: number,
};

type UseGetSubscriptionPlansStaticSettings = () => Record<SubscriptionPlanId, SubscriptionPlanSettings>;

export const useGetSubscriptionPlansStaticSettings: UseGetSubscriptionPlansStaticSettings = () => {
  const theme = useTheme();
  const { t } = useTranslation(['subscriptions']);

  // TODO: Use values for prices from API when it is ready
  const subscriptionPlanStaticSettings = {
    [SubscriptionPlanId.FREE]: {
      id: SubscriptionPlanId.FREE,
      name: t('subscriptions:subscription_plan_free_name'),
      description: t('subscriptions:subscription_plan_free_description'),
      isPopular: false,
      planInfoUrl: 'https://houseid.se/gratis/',
      backgroundColor: theme.palette.primary.lightest,
      textColor: theme.palette.common.black,
      imageSource: SubscriptionFreeImage,
      salePointsDescription: t('subscriptions:subscription_plan_free_features_description'),
      priority: 0,
      salePoints: [
        t('subscriptions:subscription_plan_free_feature_all_info_about_home'),
        t('subscriptions:subscription_plan_free_feature_all_info_in_app'),
        t('subscriptions:subscription_plan_free_feature_document_your_home'),
        t('subscriptions:subscription_plan_free_feature_smart_suggestions'),
        t('subscriptions:subscription_plan_free_feature_timeline'),
        t('subscriptions:subscription_plan_free_feature_mortgages_history'),
        t('subscriptions:subscription_plan_free_feature_reminders'),
        t('subscriptions:subscription_plan_free_feature_maintenance'),
        t('subscriptions:subscription_plan_free_feature_invite_members'),
      ],
    },
    [SubscriptionPlanId.BASE]: {
      id: SubscriptionPlanId.BASE,
      name: t('subscriptions:subscription_plan_base_name'),
      description: t('subscriptions:subscription_plan_base_description'),
      isPopular: false,
      planInfoUrl: 'https://houseid.se/bas/',
      salePointsDescription: t('subscriptions:subscription_plan_base_features_description'),
      backgroundColor: theme.palette.primary.dark,
      textColor: theme.palette.common.white,
      imageSource: SubscriptionBaseImage,
      priority: 1,
      salePoints: [
        t('subscriptions:subscription_plan_base_feature_available_on_all_devices'),
        t('subscriptions:subscription_plan_base_feature_multiple_properties'),
        t('subscriptions:subscription_plan_base_feature_storage_size'),
      ],
    },
    [SubscriptionPlanId.PLUS]: {
      id: SubscriptionPlanId.PLUS,
      name: t('subscriptions:subscription_plan_plus_name'),
      description: t('subscriptions:subscription_plan_plus_description'),
      isPopular: true,
      planInfoUrl: 'https://houseid.se/plus/',
      salePointsDescription: t('subscriptions:subscription_plan_plus_features_description'),
      backgroundColor: theme.palette.common.premium,
      textColor: theme.palette.common.white,
      imageSource: SubscriptionPlusImage,
      priority: 2,
      salePoints: [
        t('subscriptions:subscription_plan_plus_feature_automatic_downloads_info'),
        t('subscriptions:subscription_plan_plus_feature_advanced_receipt_reading'),
        t('subscriptions:subscription_plan_plus_feature_experts_forum'),
        t('subscriptions:subscription_plan_plus_feature_storage_size'),
        t('subscriptions:subscription_plan_plus_feature_two_factor_authentication'),
      ],
    },
  };

  return subscriptionPlanStaticSettings;
};
