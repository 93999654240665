import React from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import {
  Tune,
} from '@mui/icons-material';
import { Property } from '@house-id/houseid-types/dist/property';

import useBreakPointsSizes from '../../../../hooks/useBreakpointsSizes';
import {
  FCC,
} from '../../../../types/common';
import {
  useGetPropertyUsersQuery,
} from '../../api/property.api';
import PropertyUsersSection from '../../components/PropertyUsersSection';
import HIDAvatar from '../../../../components/HIDAvatar';
import PropertyRowAddress from '../../components/PropertyRowAddress';
import HIDIconButton from '../../../../components/buttons/HIDIconButton';
import HandOver from '../../../../components/icons/HandOver';
import useDialog from '../../../../hooks/useDialog';
import DialogNames from '../../../../hooks/useDialog/DialogNames';
import HIDIconButtonContextMenu from '../../../../components/contextMenu/HIDIconButtonContextMenu';
import useGetIsCurrentUserPropertyOwner from '../../hooks/useGetIsCurrentUserPropertyOwner';
import {
  useGetPropertyActions,
  PropertyActionId,
} from '../../hooks/useGetPropertyActions';

type PropertyProgressListItemProps = {
  property: Property;
};

const PropertyListItem: FCC<PropertyProgressListItemProps> = ({
  property,
  sx,
}) => {
  const { t } = useTranslation(['property']);
  const theme = useTheme();
  const { isDownSm } = useBreakPointsSizes();

  const { data: propertyUsers = [], isLoading } = useGetPropertyUsersQuery({ propertyId: property.id });

  const isCurrentUserOwner = useGetIsCurrentUserPropertyOwner({ propertyId: property.id });

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const handleEdit = openMobileAppDialog;

  const propertyActions = useGetPropertyActions({
    propertyId: property.id,
    excludeActions: [PropertyActionId.ADD_PROPERTY],
  });

  return (
    <Card
      sx={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: '10px',
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.common.white,
        ...sx,
      }}
    >
      <Stack padding={2} spacing={1.5}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={1}
          sx={{
            borderBottom: 1,
            paddingBottom: 2,
            borderColor: theme.palette.grey[300],
          }}
        >
          <Stack alignItems="center" direction="row" spacing={1}>
            <HIDAvatar
              blob={R.head(property.blobs || [])}
              givenName={property.displayName || ''}
              size="large"
            />
            <Stack direction="row">
              <PropertyRowAddress propertyId={property.id} />
            </Stack>
          </Stack>
          {
            isDownSm
              ? (
                <HIDIconButtonContextMenu
                  Icon={Tune}
                  listItems={propertyActions}
                  sx={{ padding: 0 }}
                />
              )
              : (
                <Stack alignItems="center" direction="row" spacing={1}>
                  {propertyActions.map((item) => (
                    <HIDIconButton
                      Icon={item.Icon as React.ElementType}
                      key={item.id}
                      title={item.label}
                      onClick={() => item.onClick()}
                    />
                  ))}
                </Stack>
              )
          }
        </Stack>
        <PropertyUsersSection
          key={PropertyUsersSection.name}
          loading={isLoading}
          propertyId={property.id}
          users={propertyUsers}
        />
      </Stack>
      {isCurrentUserOwner && (
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          padding={2}
          sx={{ backgroundColor: theme.palette.primary.lightest }}
          onClick={handleEdit}
        >
          <Typography color="primary" variant="body1">{t('property:hand_over_home')}</Typography>
          <HIDIconButton
            Icon={() => <HandOver iconColor={theme.palette.primary.main} strokeWidth={1} />}
            color="blank"
            onClick={handleEdit}
          />
        </Stack>
      )}
    </Card>
  );
};

export default PropertyListItem;
