import React, { FC } from 'react';
import {
  Add,
  Search,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import HIDActionsSection from '../../../../../../components/HIDActionsSection';
import { HIDListItemProps } from '../../../../../../types/common';
import useGetPropertyPermissions from '../../../../hooks/useGetPropertyPermissions';
import useGetPropertyEntityPermissions from '../../../../hooks/useGetPropertyEntityPermissions';
import EntityType from '../../../../../../constants/entityType';

type ListCategoriesActionsProps = {
  title?: string;
  entityType?: EntityType;
  onAdd?: () => void;
  onSearch?: () => void;
  customActions?: Array<HIDListItemProps>
};

const ListCategoriesActions: FC<ListCategoriesActionsProps> = ({
  title,
  entityType,
  onAdd,
  onSearch,
  customActions,
}) => {
  const { t } = useTranslation(['common']);

  const { data: propertyPermissions } = useGetPropertyPermissions();
  const { getPropertyEntityPermissions: getPropertyEntityAccessRight } = useGetPropertyEntityPermissions();

  const permissions = entityType
    ? getPropertyEntityAccessRight(entityType)
    : propertyPermissions;
  const { canCreate } = permissions || {};

  const actions = [
    onSearch && {
      id: 'search',
      Icon: Search,
      label: t('common:search'),
      onClick: onSearch,
    },
    onAdd && canCreate && {
      id: 'add',
      Icon: Add,
      label: t('common:add_label'),
      onClick: onAdd,
    },
    ...(customActions || []),
  ].filter(Boolean);

  return (
    <HIDActionsSection
      items={actions}
      title={title || t('common:manage')}
    />
  );
};

export default ListCategoriesActions;
