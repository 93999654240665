import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PropertyFinancesOperatingCostSectionData,
  PropertyProgressCategorySectionProps,
} from '../../../types.propertyProgress';
import PropertyProgressCategorySectionContainer from '../../../components/PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from '../../../components/PropertyProgressCategorySectionContent';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import BudgetsOverview from '../../../../Finances/pages/financeOverview/components/BudgetsOverview';

const PropertyFinancesOperatingCostSection: FC<PropertyProgressCategorySectionProps<PropertyFinancesOperatingCostSectionData>> = ({
  section,
}) => {
  const { t } = useTranslation(['forms_common', 'property']);

  const { budget } = section.data;

  const hasBudget = Boolean(budget);

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const handleSeeMore = () => openMobileAppDialog();

  const handleAddOperatingCost = () => openMobileAppDialog();

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('property:property_progress_property_operating_cost_section_add_operating_cost')}
          description={hasBudget ? undefined : t('property:property_progress_property_operating_cost_section_description')}
          viewAllButtonText={t('property:property_progress_property_operating_cost_section_add_operating_see_more')}
          onAddClick={handleAddOperatingCost}
          onViewAllClick={handleSeeMore}
        >
          <BudgetsOverview />
        </PropertyProgressCategorySectionContent>

      }
      key={section.id}
      section={section}
    />
  );
};

export default PropertyFinancesOperatingCostSection;
