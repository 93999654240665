import React, {
  FC,
  useState,
} from 'react';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Add,
  BookmarkBorderOutlined,
  PersonOutline,
} from '@mui/icons-material';
import * as R from 'ramda';

import HomeLayout from '../../Property/pages/Home/components/HomeLayout';
import {
  useGetForumTopicThreadsQuery,
  useGetForumTopicsQuery,
} from '../api/forum.api';
import { useRouteParams } from '../../../utils/routes';
import ThreadListItem from '../components/ThreadListItem';
import {
  getForumPath as getNotPartnersForumPath,
  getPartnersForumPath,
  getMyQuestionsPath,
  getExpertPath as getNotPartnersExpertsPath,
  getPartnersExpertsPath,
  getMyBookmarksPath,
} from '../navigation/navigation.forum';
import TopicsQuickNavigation from '../components/TopicsQuickNavigation';
import ReadMoreAboutExperts from '../components/ReadMoreAboutExperts';
import ListEntitiesActions from '../../Property/modules/Content/components/actions/ListEntitiesActions';
import { createFakeForumThread } from '../utils/fakeForumEntities';
import { useGetActiveAuthUser } from '../../../external-services/firebase';
import { ForumThreadsSortOrder } from '../forum.types';
import ForumThreadsOrderSelect from '../components/ForumThreadsOrderSelect';
import useTryOpenAskForumQuestionDialog from '../hooks/useTryOpenAskForumQuestionDialog';
import useTryNavigateToThread from '../hooks/useTryNavigateToThread';

const ForumTopic: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation(['forum']);

  const { isPartnerAuth } = useGetActiveAuthUser();

  const getForumPath = isPartnerAuth ? getPartnersForumPath : getNotPartnersForumPath;
  const getExpertPath = isPartnerAuth ? getPartnersExpertsPath : getNotPartnersExpertsPath;

  const { topicId } = useRouteParams<{ topicId: string }>();
  const { data: topics = [] } = useGetForumTopicsQuery();

  const [threadsSortOrder, setSortThreadsOrder] = useState<ForumThreadsSortOrder>(ForumThreadsSortOrder.DATE_DESC);

  const selectedTopic = topics.find(({ id }) => id === topicId);
  const { data: { threads = [] } = {}, isLoading: threadsIsLoading } = useGetForumTopicThreadsQuery(
    topicId
      ? { topicId, sort: threadsSortOrder }
      : skipToken,
  );

  const threadsList = threadsIsLoading
    ? R.times((n) => createFakeForumThread(n.toString()), 5)
    : threads;

  const handleTryNavigateToThread = useTryNavigateToThread();

  const [tryOpenAskForumQuestionDialog, isTryOpenAskForumQuestionDialogLoading] = useTryOpenAskForumQuestionDialog();

  const handleGoToMyQuestions = () => navigate(getMyQuestionsPath());
  const handleGoToMyBookmarks = () => navigate(getMyBookmarksPath());
  const handleAskExpert = () => tryOpenAskForumQuestionDialog({ isAskExpert: true, topicId });
  const handleAskForum = () => tryOpenAskForumQuestionDialog({ isAskExpert: false, topicId });

  const handleNavigateToExperts = () => navigate(getExpertPath());

  const customActions = [
    {
      id: 'my_questions',
      Icon: PersonOutline,
      label: t('forum:forum_my_questions'),
      onClick: handleGoToMyQuestions,
    },
    {
      id: 'my_bookmarks',
      Icon: BookmarkBorderOutlined,
      label: t('forum:forum_my_bookmarks'),
      onClick: handleGoToMyBookmarks,
    },
    {
      id: 'ask_forum',
      Icon: Add,
      label: t('forum:forum_ask_the_forum'),
      onClick: handleAskForum,
    },
    selectedTopic?.experts?.length && {
      id: 'ask_expert',
      Icon: Add,
      label: t('forum:forum_ask_the_expert'),
      disabled: isTryOpenAskForumQuestionDialogLoading,
      onClick: handleAskExpert,
    },
  ].filter(Boolean);

  return (
    <HomeLayout
      SideColumn={
        <>
          {!isPartnerAuth && (
            <Card sx={{ padding: 2 }}>
              <ListEntitiesActions customActions={customActions} />
            </Card>
          )}
          <Card sx={{ padding: 2 }}>
            <TopicsQuickNavigation />
          </Card>
          <Card sx={{ padding: 2 }}>
            <ReadMoreAboutExperts onClick={handleNavigateToExperts} />
          </Card>
        </>
      }
      breadcrumbsLinks={[
        {
          link: getForumPath(),
          name: t('forum:forum_title'),
        },
      ]}
      sideDrawerElements={[
        !isPartnerAuth && <ListEntitiesActions customActions={customActions} key={ListEntitiesActions.name} />,
        <TopicsQuickNavigation key={TopicsQuickNavigation.name} />,
        <ReadMoreAboutExperts key={ReadMoreAboutExperts.name} style={{ marginTop: theme.spacing(1) }} onClick={handleNavigateToExperts} />,
      ].filter(Boolean)}
      title={t('forum:forum_questions_in', { topic: selectedTopic?.name || '' })}
    >
      <Stack spacing={1.5}>
        <ForumThreadsOrderSelect
          sx={{
            alignSelf: 'flex-end',
            margin: theme.spacing(2, 0),
          }}
          value={threadsSortOrder}
          onChange={setSortThreadsOrder}
        />
        {threadsList.map((thread) => (
          <ThreadListItem
            author={thread.author}
            isLoading={threadsIsLoading}
            key={thread.id}
            thread={thread}
            onClick={() => handleTryNavigateToThread(thread)}
          />
        ))}
      </Stack>
    </HomeLayout>
  );
};

export default ForumTopic;
