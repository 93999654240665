import { useMemo } from 'react';
import * as R from 'ramda';

import {
  QueryHook,
} from '../../../types/common';
import {
  SubscriptionFeature,
  SubscriptionPlanOfferPeriodType,
  SubscriptionPlanOptionPeriodOfferPrices,
} from '../subscriptionPlans.types';
import {
  getSubscriptionPlanOptionPeriodOfferPrices,
} from '../subscriptionPlans.utils';
import useGetCurrentUserSubscriptionPlanId from './useGetCurrentUserSubscriptionPlanId';
import useGetSubscriptionPlanOffers from './useGetSubscriptionPlanOffers';
import { useGetSubscriptionPlansSettings } from './useGetSubscriptionPlansSettings';

type UseGetLowestPriceSubscriptionPlanOffer =
  QueryHook<SubscriptionPlanOptionPeriodOfferPrices | undefined, { feature?: SubscriptionFeature }>;

const useGetLowestPriceSubscriptionPlanOffer: UseGetLowestPriceSubscriptionPlanOffer = (
  args,
  options,
) => {
  const { data: { subscriptionPlanId } = {}, ...subscriptionPlanRest } = useGetCurrentUserSubscriptionPlanId();

  const {
    data: subscriptionPlanSettings,
    ...subscriptionPlanSettingsRest
  } = useGetSubscriptionPlansSettings(undefined, options);

  const {
    data: subscriptionPlanOffers,
    ...subscriptionPlanOffersRest
  } = useGetSubscriptionPlanOffers(undefined, options);

  const lowestOffers = useMemo(() => {
    const subscriptionPlanOffersValues = subscriptionPlanOffers ? Object.values(subscriptionPlanOffers) : [];
    const subscriptionPlanSettingsValues = subscriptionPlanSettings ? Object.values(subscriptionPlanSettings) : [];

    const minSubscriptionPlanIdsWithRequestedFeature = args?.feature
      ? subscriptionPlanSettingsValues
        .filter(({ features }) => features[args.feature as SubscriptionFeature].enabled)
        .map(({ id }) => id)
      : undefined;

    return subscriptionPlanSettings && subscriptionPlanOffers
      ? R.sortWith(
        [
          R.descend((period) => period.offer ? 1 : 0),
          R.descend((period) =>
            args?.feature
              ? subscriptionPlanSettings[period.planId].priority || 0
              : 0),
          R.ascend(R.prop('currentPricePerMonth')),
        ],
        subscriptionPlanOffersValues
          .filter(({ planId }) => args?.feature ? minSubscriptionPlanIdsWithRequestedFeature?.includes(planId) : true)
          .map((subscriptionPlanOffer) => {
            const yearlyPlanOfferPrices = getSubscriptionPlanOptionPeriodOfferPrices(
              subscriptionPlanSettings[subscriptionPlanOffer.planId],
              SubscriptionPlanOfferPeriodType.YEAR,
              subscriptionPlanOffer,
            );
            const monthlyPlanOfferPrices = getSubscriptionPlanOptionPeriodOfferPrices(
              subscriptionPlanSettings[subscriptionPlanOffer.planId],
              SubscriptionPlanOfferPeriodType.MONTH,
              subscriptionPlanOffer,
            );
            if (yearlyPlanOfferPrices.canActivate && monthlyPlanOfferPrices.canActivate) {
              return yearlyPlanOfferPrices.currentPricePerMonth < monthlyPlanOfferPrices.currentPricePerMonth
                ? yearlyPlanOfferPrices
                : monthlyPlanOfferPrices;
            }
            return yearlyPlanOfferPrices.canActivate
              ? yearlyPlanOfferPrices
              : monthlyPlanOfferPrices.canActivate
                ? monthlyPlanOfferPrices
                : undefined;
          })
          .filter(Boolean),
      )
      // .sort((a, b) => a.currentPricePerMonth - b.currentPricePerMonth)
      : undefined;
  }, [subscriptionPlanOffers, subscriptionPlanSettings, subscriptionPlanId]);

  return {
    data: lowestOffers?.length ? lowestOffers[0] : undefined,
    isLoading: subscriptionPlanSettingsRest.isLoading || subscriptionPlanRest.isLoading || subscriptionPlanOffersRest.isLoading,
    isUninitialized:
      subscriptionPlanSettingsRest.isUninitialized || subscriptionPlanRest.isUninitialized || subscriptionPlanOffersRest.isUninitialized,
    isError: subscriptionPlanSettingsRest.isError || subscriptionPlanRest.isError || subscriptionPlanOffersRest.isError,
    isSuccess: subscriptionPlanSettingsRest.isSuccess || subscriptionPlanRest.isSuccess || subscriptionPlanOffersRest.isSuccess,
    error: subscriptionPlanSettingsRest.error || subscriptionPlanRest.error || subscriptionPlanOffersRest.error,
    isFetching: subscriptionPlanSettingsRest.isFetching || subscriptionPlanRest.isFetching || subscriptionPlanOffersRest.isFetching,
    refetch: () => {
      subscriptionPlanSettingsRest.refetch();
      subscriptionPlanRest.refetch();
      subscriptionPlanOffersRest.refetch();
    },
  };
};

export default useGetLowestPriceSubscriptionPlanOffer;
