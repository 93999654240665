import RouteNames from '../../../../routes/RouteNames';
import { generateRoutePath } from '../../../../utils/routes';
import { PropertyIdNavigation } from '../../types/property.types';

export const getSellingPropertyChecklistPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.SELLING_PROPERTY_CHECKLIST,
  { propertyId },
);

export const getSellingPropertyInformationPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.SELLING_PROPERTY_INFO,
  { propertyId },
);

export const getSellingPropertyArchivePath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.SELLING_PROPERTY_ARCHIVE,
  { propertyId },
);

export const getSellingPropertyHandoverPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.SELLING_PROPERTY_TRANSFER,
  { propertyId },
);
