import { skipToken } from '@reduxjs/toolkit/query';
import { BudgetShortModel } from '@house-id/houseid-types/dist/finances/budgets';

import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { useGetBudgetsQuery } from '../api/budgets.api';
import { QueryHook } from '../../../../../../../types/common';
import { compareByDate } from '../../../../../../../utils/date';

const currentYear = new Date().getFullYear();

export const getCurrentBudget = (budgets: Array<BudgetShortModel> | undefined) => {
  const sortedBudgets = [...(budgets || [])]
    .sort((a, b) => compareByDate(a.createdAt, b.createdAt));

  const thisYearBudgets = sortedBudgets.filter(({ year }) => year === currentYear);

  return thisYearBudgets.length ? thisYearBudgets[0] : sortedBudgets?.[0];
};

const useGetCurrentBudget: QueryHook<BudgetShortModel> = () => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: budgets,
    ...rest
  } = useGetBudgetsQuery(
    propertyId ? { propertyId } : skipToken,
  );

  return {
    data: getCurrentBudget(budgets),
    ...rest,
  };
};

export default useGetCurrentBudget;
