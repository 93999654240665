import React, { FC } from 'react';
import {
  Box,
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';

import { useGetForumTopicsQuery } from '../api/forum.api';
import HomeLayout from '../../Property/pages/Home/components/HomeLayout';
import HIDAvatar from '../../../components/HIDAvatar';
import HIDButton from '../../../components/buttons/HIDButton';
import AskExpert from '../components/AskExpert';
import { arrToMap } from '../../../utils/array';
import TopicsQuickNavigation from '../components/TopicsQuickNavigation';
import { createFakeExpert } from '../utils/fakeForumEntities';
import HIDTypography from '../../../components/HIDTypography';
import { useGetActiveAuthUser } from '../../../external-services/firebase';
import useTryOpenAskForumQuestionDialog from '../hooks/useTryOpenAskForumQuestionDialog';

const ForumExperts: FC = () => {
  const { t } = useTranslation(['forum']);
  const theme = useTheme();

  const { isPartnerAuth } = useGetActiveAuthUser();

  const { data: topics = [], isLoading: expertsIsLoading } = useGetForumTopicsQuery();
  const topicsMap = arrToMap(({ id, name }) => ([id, name]), topics);

  const [tryOpenAskForumQuestionDialog, isTryOpenAskForumQuestionDialogLoading] = useTryOpenAskForumQuestionDialog();
  const handleAskExpert = (topicId?: string) => tryOpenAskForumQuestionDialog({ isAskExpert: true, topicId });

  const experts = topics.flatMap(({ id, experts }) => experts.map((expert) => ({ ...expert, topicId: id })));

  const expertsList = expertsIsLoading
    ? R.times((n) => createFakeExpert(n.toString()), 10)
    : experts;

  return (
    <HomeLayout
      SideColumn={
        <>
          {!isPartnerAuth && (
            <Card>
              <AskExpert onClick={handleAskExpert} />
            </Card>
          )}
          <Card sx={{ padding: 2 }}>
            <TopicsQuickNavigation />
          </Card>
        </>
      }
      sideDrawerElements={[
        !isPartnerAuth && <AskExpert key={AskExpert.name} sx={{ marginTop: 1 }} onClick={handleAskExpert} />,
        <TopicsQuickNavigation key={TopicsQuickNavigation.name} />,
      ].filter(Boolean)}
      title={t('forum:forum_meet_our_experts')}
    >
      <Typography sx={{ marginTop: theme.spacing(2) }}>
        {t('forum:forum_experts_info')}
      </Typography>
      <Box
        display="grid"
        sx={{
          marginTop: theme.spacing(2),
          gridTemplateColumns: {
            lg: 'repeat(2, 1fr)',
            xxs: 'repeat(1, 1fr)',
          },
          gap: 2,
        }}
      >
        {
          expertsList?.map((expert) => (
            <Box key={expert.id + expert.topicId}>
              <Stack
                alignItems="center"
                justifyContent="center"
                spacing={1.5}
                sx={{
                  padding: 3,
                  background: theme.palette.warning.lighter,
                  borderRadius: theme.spacing(1),
                  height: '100%',
                }}
              >
                <HIDAvatar
                  externalImage={expert.avatar}
                  givenName={expert.name}
                  isLoading={expertsIsLoading}
                  size="extraLarge"
                />
                <HIDTypography isLoading={expertsIsLoading} variant="h6">
                  {expert.name}
                </HIDTypography>
                <HIDTypography isLoading={expertsIsLoading}>
                  {topicsMap[expert.topicId]}
                </HIDTypography>
                <HIDTypography
                  isLoading={expertsIsLoading}
                  numberOfSkeletonLines={4}
                  skeletonWidth="100%"
                  sx={{ textAlign: 'center', width: '100%' }}
                >
                  {expert.description}
                </HIDTypography>
                {!isPartnerAuth && (
                  <HIDButton
                    disabled={expertsIsLoading}
                    loading={isTryOpenAskForumQuestionDialogLoading}
                    onClick={() => handleAskExpert(expert.topicId)}
                  >
                    {t('forum:forum_ask_the_expert')}
                  </HIDButton>
                )}
              </Stack>
            </Box>
          ))
        }
      </Box>
    </HomeLayout>
  );
};

export default ForumExperts;
