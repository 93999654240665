import React, { FC } from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import HIDButton from '../../../components/buttons/HIDButton';
import MoveImage from '../../../assets/images/move_expert.jpeg';

type MoveCardProps = {
  onClick: () => void;
};

const MoveCard: FC<MoveCardProps> = ({ onClick }) => {
  const theme = useTheme();
  const { t } = useTranslation(['move']);

  return (
    <Stack
      style={{
        width: '100%',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <div
        style={{
          height: 124,
          width: '100%',
          borderRadius: 5,
          backgroundImage: `url(${MoveImage})`,
          backgroundSize: '300px 124px',
        }}
      >
        <Stack
          alignItems="flex-start"
          direction="column"
          gap={1.5}
          justifyContent="center"
          sx={{
            padding: theme.spacing(2),
            width: 172,
            height: '100%',
          }}
        >
          <Typography style={{ lineHeight: 1.33 }} variant="subtitle1">
            {t('move:get_the_move_done_quickly_and_easily')}
          </Typography>
          <HIDButton
            size="small"
            onClick={(event) => {
              event.preventDefault();
              onClick();
            }}
          >
            {t('move:get_ready_to_move')}
          </HIDButton>
        </Stack>
      </div>
    </Stack>
  );
};

export default MoveCard;
