import React, {
  FC,
  useMemo,
} from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { addMonths } from 'date-fns';
import { skipToken } from '@reduxjs/toolkit/query';

import { Receipt } from '../../../../../components/icons/Icons';
import { hidSpacing } from '../../../../../utils/number';
import {
  HomeWidgetType,
  useGetWidgetHeight,
} from '../utils/useGetWidgetHeight';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { useGetReceiptSummaryTotalQuery } from '../../../modules/Content/modules/Receipt/api/receipt.api';

const TotalReceiptsWidget: FC = () => {
  const { t } = useTranslation(['home', 'common']);
  const theme = useTheme();

  const widgetHeight = useGetWidgetHeight(HomeWidgetType.TOTAL_RECEIPTS);

  const { from, to } = useMemo(() => ({
    from: addMonths(new Date(), -1).toISOString(),
    to: (new Date()).toISOString(),
  }), []);

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: summary } = useGetReceiptSummaryTotalQuery(propertyId ? { propertyId, from, to } : skipToken);

  const count = summary?.total?.count || 0;
  const lastMonthCount = summary?.filtered?.count;

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2.5),
        height: widgetHeight,
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        spacing={1.5}
        sx={{ height: '100%' }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            width: theme.spacing(8),
            height: theme.spacing(8),
            borderRadius: theme.spacing(4),
            backgroundColor: theme.palette.primary.lighter,
            position: 'relative',
          }}
        >
          <Receipt
            iconColor={theme.palette.primary.main}
            size={hidSpacing(4)}
          />
        </Stack>
        <Stack spacing={0.5}>
          <Typography variant="body1">
            {t('home:home_number_of_receipts')}
          </Typography>
          <Typography variant="h4">
            {t('common:items_count', { count })}
          </Typography>
          {lastMonthCount !== undefined && (
            <Typography variant="body2">
              {/* @ts-ignore */}
              {t('home:home_last_month_count', { count: t('common:items_count', { count: lastMonthCount }) })}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

export default TotalReceiptsWidget;
