import React, {
  FC,
  PropsWithChildren,
  Suspense,
  useEffect,
  useState,
} from 'react';
import {
  Navigate,
  generatePath,
  useLocation,
} from 'react-router';
import {
  ScrollRestoration,
  useSearchParams,
} from 'react-router-dom';

import { useGetActiveAuthUser } from '../external-services/firebase';
import useGetCurrentPropertyId from '../modules/Property/hooks/useGetCurrentPropertyId';
import {
  useNavigationParams,
  useRouteQueryParams,
} from '../utils/routes';
import RouteNames from './RouteNames';
import HIDFullScreenLoader from '../components/HIDFullScreenLoader';
import PropertyPermission from '../modules/Property/constants/constants.propertyPermissions';
import usePropertyPermissions from '../modules/Property/hooks/usePropertyPermissions';
import { getPartnersLoginPath } from '../modules/Partners/navigation/navigation.partners';
import useGetSubscriptionPlanTrialPeriodInfo from '../modules/SubscriptionPlans/hooks/useGetSubscriptionTrialPeriodInfo';
import { useGetSignInWithTokenAndKey } from '../modules/Auth/hooks/useGetSignInWithTokenAndKey';
import HIDErrorPage from '../components/HIDErrorPage';
import { useLoginUserWithTokenMutation } from '../modules/Auth/api/auth.api';
import { useGetTokenDataQuery } from '../modules/Auth/api/user.api';
import { getLoginPath } from '../modules/Auth/navigation/navigation.auth';

type ProtectedRouteProps = PropsWithChildren & {
  shouldCheckTrialPeriod?: boolean;
  authRequired?: boolean;
  propertyRequired?: boolean;
  path?: string;
  propertyChangePath?: string;
  permissions?: Array<PropertyPermission>;
};

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  authRequired: authRequiredProp = true,
  propertyRequired = true,
  shouldCheckTrialPeriod = false,
  path,
  propertyChangePath,
  children,
  permissions,
}) => {
  const {
    user: currentUser,
    isLoading: currentUserIsLoading,
    isPartnerAuth,
    error: getActiveAuthUserError,
  } = useGetActiveAuthUser();

  const { data: tokenData } = useGetTokenDataQuery({}, { skip: currentUserIsLoading || !currentUser });

  const {
    routeParams: params,
    queryParams,
  } = useNavigationParams<{ propertyId?: string }, { authRequired?: boolean }>();
  const routePropertyId = params.propertyId;

  const hasCurrentUser = Boolean(currentUser && (tokenData?.userId || tokenData?.partnerId));
  const authRequired = authRequiredProp || queryParams.authRequired;

  const {
    data: propertyId,
    error: getCurrentPropertyIdError,
  } = useGetCurrentPropertyId(undefined, {
    skip: !propertyRequired
      || currentUserIsLoading
      || !hasCurrentUser
      || isPartnerAuth,
  });

  const {
    data: { isEnabled, isExpired } = {},
    isLoading: isLoadingTrialPeriodSubscriptionInfo,
    error: getCurrentSubscriptionPlanError,
  } = useGetSubscriptionPlanTrialPeriodInfo(undefined, {
    skip: !shouldCheckTrialPeriod || currentUserIsLoading
      || !hasCurrentUser
      || isPartnerAuth,
  });

  const hasPermissions = usePropertyPermissions({
    skip: !propertyRequired
      || currentUserIsLoading
      || !hasCurrentUser,
  });

  const location = useLocation();

  const [_, setSearchParams] = useSearchParams();
  const {
    signInWithTokenAndKey: signInWithCustomTokenAndKey,
    error: signInWithCustomTokenAndKeyError,
  } = useGetSignInWithTokenAndKey();

  const [
    loginUserWithToken,
  ] = useLoginUserWithTokenMutation();

  const { token, ...rest } = useRouteQueryParams<{ token: string }>();
  const [isSigningInWithCustomToken, setIsSigningInWithCustomToken] = useState(Boolean(token));

  useEffect(() => {
    if (!hasCurrentUser && token) {
      setIsSigningInWithCustomToken(true);
      loginUserWithToken({ loginToken: token })
        .unwrap()
        .then(({ customToken }) => signInWithCustomTokenAndKey(customToken))
        .then(() => setSearchParams(rest))
        .finally(() => setIsSigningInWithCustomToken(false));
    }
  }, [token]);

  if (getActiveAuthUserError
    || getCurrentPropertyIdError
    || getCurrentSubscriptionPlanError
    || signInWithCustomTokenAndKeyError
  ) {
    return <HIDErrorPage />;
  }

  if (currentUserIsLoading
    || isLoadingTrialPeriodSubscriptionInfo
    || isSigningInWithCustomToken) {
    return <HIDFullScreenLoader />;
  }

  const isPartnersRoute = location.pathname.includes(RouteNames.PARTNERS);

  if (authRequired && shouldCheckTrialPeriod && isEnabled && isExpired) {
    return <Navigate replace state={{ showEndTrialPeriodDialog: true }} to={RouteNames.SUBSCRIPTION_PLANS} />;
  }

  if (authRequired && !hasCurrentUser) {
    return isPartnerAuth || isPartnersRoute
      ? <Navigate replace state={{ from: location }} to={getPartnersLoginPath()} />
      : !currentUser && !currentUserIsLoading
        ? <Navigate replace state={{ from: location, propertyId: routePropertyId }} to={getLoginPath()} />
        : (
          <HIDFullScreenLoader />
        );
  }

  if (
    (!isPartnersRoute && isPartnerAuth)
    || (isPartnersRoute && isPartnerAuth === false)
    || (propertyRequired && (!propertyId || !routePropertyId || !hasPermissions(permissions)))
  ) {
    if (location.pathname !== RouteNames.INIT) {
      return <Navigate replace to={RouteNames.INIT} />;
    }
  }

  if (propertyRequired
    && path
    && propertyChangePath
    && routePropertyId
    && propertyId
    && routePropertyId !== propertyId
    && location.pathname === generatePath(path, params)
  ) {
    const newPath = generatePath(propertyChangePath, { ...params, propertyId });
    return <Navigate replace to={newPath} />;
  }

  return (
    <Suspense fallback={<HIDFullScreenLoader />}>
      {children}
      <ScrollRestoration />
    </Suspense>
  );
};

export default React.memo(
  ProtectedRoute,
  (prevProps, nextProps) => prevProps.path === nextProps.path
    && prevProps.authRequired === nextProps.authRequired
    && prevProps.shouldCheckTrialPeriod === nextProps.shouldCheckTrialPeriod
    && prevProps.propertyRequired === nextProps.propertyRequired,
);
