import * as R from 'ramda';

export const removeEmptyParams = R.reject<Array<string> | string | number | undefined | null>(
  R.anyPass([R.isEmpty, R.isNil]),
);

export const isNotNil = R.compose(R.not, R.isNil);

export const getObjDiffRight = (obj1: Record<string, unknown>, obj2: Record<string, unknown>) => {
  const keys = R.uniq([...Object.keys(obj1), ...Object.keys(obj2)]);

  return R.reduce(
    (acc, key) => {
      if (obj1[key] !== obj2[key]) {
        const obj1Item = obj1[key] as Record<string, unknown>;
        const obj2Item = obj2[key] as Record<string, unknown>;

        const isNestedObj1 = isNotNil(obj1Item) && R.is(Object, obj1Item) && !Array.isArray(obj1Item);
        const isNestedObj2 = isNotNil(obj2Item) && R.is(Object, obj2Item) && !Array.isArray(obj2Item);

        if (isNestedObj1) {
          const diff = isNestedObj2
            ? getObjDiffRight(obj1Item, obj2Item)
            : isNotNil(obj2Item) ? obj2Item : null;

          if (!R.isEmpty(diff)) {
            acc[key] = diff;
          }
        } else {
          acc[key] = isNotNil(obj2Item) ? obj2Item : null;
        }
      }

      return acc;
    },
    {} as Record<string, unknown>,
    keys,
  );
};

export const getIsTypeOf = <TType>(propName: keyof TType) => (obj: any): obj is TType =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  obj && typeof obj === 'object' && propName in obj;

export const isTypeOf = <TType>(obj: any, propName: keyof TType): obj is TType => getIsTypeOf<TType>(propName)(obj);

export const nullify = (obj: object) => R.map(
  (propValue) => propValue === undefined ? null : propValue,
  obj,
);

export const isObject = (value: any) =>
  value !== null && typeof value === 'object';
