import EntityType from '../../../../../constants/entityType';

import useGetEntitiesInfo from './useGetEntitiesInfo';
import { EntityInfo } from '../types/content.type';

type UseGetEntityInfoReturnType = (entityType: EntityType) => EntityInfo;

const useGetEntityInfo = (): UseGetEntityInfoReturnType => {
  const entitiesMap = useGetEntitiesInfo();

  const getSingleEntityInfo = (entityType: EntityType) => entitiesMap[entityType];

  return getSingleEntityInfo;
};

export default useGetEntityInfo;
