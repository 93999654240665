import React, {
  FC,
  useMemo,
} from 'react';
import {
  Card,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  PropertyProgressCategoryId,
  PropertyProgressSection,
} from '@house-id/houseid-types/dist/propertyProgress';

import { useNavigateBackOr } from '../../../../../../utils/routes';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import {
  getHomePath,
} from '../../../../navigation/navigation.property';
import HomeLayout from '../../../../pages/Home/components/HomeLayout';
import {
  useGetPropertyProgressSpecificationQuery,
} from '../../api/propertyProgress.api';
import PropertyProgressPageContent from '../../components/PropertyProgressPageContent';
import PropertyProgressQuickNavigation from '../../components/PropertyProgressQuickNavigation';
import {
  PropertySpecificationData,
} from '../../types.propertyProgress';
import PropertySpecificationSection from './components/PropertySpecificationSection';
import { getPropertyProgressPath } from '../../navigation.propertyProgress';
import PropertyExternalService from '../../../../constants/constants.externalServices';
import { BoughtSfPropertyYearAgoBanner } from '../../components/sf/BoughtSfPropertyYearAgoBanner';
import PropertyExternalServicesSection from '../../../../components/PropertyExternalServicesSection';
import useGetPropertyProgressCategoryContentText from '../../hooks/useGetPropertyProgressCategoryContentText';
import { getPropertyProgressPercent } from '../../utils/utils.propertyProgress';
import { getPathWithPropertyIdOrInit } from '../../../../../Auth/navigation/navigation.auth';
import InfoBox from '../../../../../../components/InfoBox';
import useGetPropertyPermissions from '../../../../hooks/useGetPropertyPermissions';

const PropertySpecification: FC = () => {
  const { t } = useTranslation(['property', 'property_progress']);
  const navigateBackOr = useNavigateBackOr();

  const { data: propertyId } = useGetCurrentPropertyId();
  const {
    data: propertySpecification,
    isLoading: isPropertySpecificationLoading,
  } = useGetPropertyProgressSpecificationQuery(propertyId ? { propertyId } : skipToken);

  const { data: { canCreate, canUpdate } = {} } = useGetPropertyPermissions();

  const sections = useMemo(() => propertySpecification?.sections || [], [propertySpecification]);

  const getPropertyProgressCategoryContentDescription = useGetPropertyProgressCategoryContentText();
  const description = getPropertyProgressCategoryContentDescription({
    percent: getPropertyProgressPercent(propertySpecification?.progress),
    categoryId: PropertyProgressCategoryId.SPECIFICATIONS,
  });

  const renderSection = (section: PropertyProgressSection<PropertySpecificationData>) => {
    const description = getPropertyProgressCategoryContentDescription({
      percent: getPropertyProgressPercent(propertySpecification?.progress),
      categoryId: PropertyProgressCategoryId.SPECIFICATIONS,
      sectionId: section.id,
    });

    return (
      <PropertySpecificationSection
        key={section.id}
        section={{ ...section, description: description || section.description }}
      />

    );
  };

  return (
    <HomeLayout
      SideColumn={
        <Stack spacing={2}>
          <Card sx={{ padding: 2 }}>
            <PropertyProgressQuickNavigation
              currentPropertyProgressCategoryId={PropertyProgressCategoryId.SPECIFICATIONS}
              key={PropertyProgressQuickNavigation.name}
            />
          </Card>
          <BoughtSfPropertyYearAgoBanner />
          <PropertyExternalServicesSection
            externalServicesIds={[PropertyExternalService.UC_PROPERTY_INFORMATION_DATA, PropertyExternalService.ENERGY_DECLARATION]}
            key={PropertyExternalServicesSection.name}
          />
        </Stack>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getPropertyProgressPath, { propertyId }),
          name: t('property:property_progress_title'),
        },
      ]}
      sideDrawerElements={[
        <PropertyProgressQuickNavigation
          currentPropertyProgressCategoryId={PropertyProgressCategoryId.SPECIFICATIONS}
          key={PropertyProgressQuickNavigation.name}
        />,
        <BoughtSfPropertyYearAgoBanner key={BoughtSfPropertyYearAgoBanner.name} />,
        <PropertyExternalServicesSection
          externalServicesIds={[PropertyExternalService.UC_PROPERTY_INFORMATION_DATA, PropertyExternalService.ENERGY_DECLARATION]}
          key={PropertyExternalServicesSection.name}
        />,
      ]}
      title={t('property:property_specification_title')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
    >
      <PropertyProgressPageContent
        description={description}
        loading={isPropertySpecificationLoading}
        progress={propertySpecification?.progress}
        renderSectionContent={renderSection}
        sections={sections}
      >
        {!canCreate && !canUpdate && (
          <InfoBox
            message={t('property_progress:property_progress_housing_facts_read_only_warning')}
            sx={{ marginBottom: 2 }}
          />
        )}
      </PropertyProgressPageContent>
    </HomeLayout>
  );
};

export default PropertySpecification;
