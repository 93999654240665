import { HIDUser } from '@house-id/houseid-types/dist/common';

import { HIDApiTags } from '../../../api/HIDApiTags';
import {
  ConnectUserFormValues,
  UserStatus,
  UserTokenData,
  UserDetails,
  UserActivityId,
} from '../types/auth.types';
import {
  ResponseData,
} from '../../../types/common';
import { getDecodedToken } from '../utils/token';
import { getTokenData } from '../utils/userTokenAttibutes';
import { getActiveAuth } from '../../../external-services/firebase';
import { propertyApi } from '../../Property/api/property.api';
import { UserStorageUsage } from '../../Property/types/property.types';
import { subscriptionPlansApi } from '../../SubscriptionPlans/api/subscriptionPlans.api';
import { userCommonApi } from './user.common.api';

export const userApi = userCommonApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<HIDUser, void>({
      query: () => 'users/me',
      transformResponse: (response: ResponseData<HIDUser> & { jws: string }) => ({ ...response.data, jws: response.jws }),
      providesTags: () => [HIDApiTags.USER],
    }),
    getCurrentUserStatus: builder.query<UserStatus, void>({
      query: () => 'users/me/status',
      providesTags: () => [HIDApiTags.USER_STATUS],
    }),
    getCustomToken: builder.query<string, void>({
      query: () => 'users/me/token',
      transformResponse: (response: { customToken: string }) => response.customToken,
    }),
    getUserStorageUsage: builder.query<UserStorageUsage, void>({
      query: () => 'users/me/storage/usage',
      providesTags: () => [HIDApiTags.USER_STORAGE],
    }),
    getTokenData: builder.query<UserTokenData | undefined, object>({
      queryFn: async () => {
        try {
          const user = getActiveAuth()?.currentUser;
          if (user) {
            const token = await user.getIdToken();
            const decodedToken = getDecodedToken(token);
            const tokenData = getTokenData(decodedToken);
            if (tokenData) {
              return { data: tokenData };
            }
          }
          return { error: { data: undefined, status: 401 } };
        } catch (e) {
          return { error: { data: e, status: 401 } };
        }
      },
      providesTags: () => [HIDApiTags.USER_TOKEN_DATA],
      serializeQueryArgs: ({ endpointName }) => getActiveAuth()?.currentUser?.uid || endpointName,
    }),
    createUserWithToken: builder.mutation<HIDUser, ConnectUserFormValues>({
      query: (data: ConnectUserFormValues) => ({
        url: 'users/register/connect',
        method: 'POST',
        body: data,
      }),
      transformErrorResponse: (response) => response.data,
    }),
    updateUserDetails: builder.mutation<HIDUser, UserDetails>({
      query: (data) => ({
        url: 'users/me',
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: () => [HIDApiTags.USER],
    }),
    deleteAccount: builder.mutation<void, void>({
      query: () => ({
        url: 'users/me',
        method: 'DELETE',
      }),
    }),
    postUserActivity: builder.mutation<void, { activityId: UserActivityId }>({
      query: (context) => ({
        url: 'users/me/activity',
        method: 'POST',
        body: context,
      }),
      invalidatesTags: () => [HIDApiTags.SUBSCRIPTION_PLAN_OFFER],
    }),
    reloadTokenData: builder.mutation<object, void>({
      queryFn: async (_, { dispatch }) => {
        try {
          const user = getActiveAuth()?.currentUser;
          if (user) {
            await user.reload();
            await user.getIdToken(true);
            await Promise.allSettled([
              dispatch(userApi.endpoints.getTokenData.initiate({}, { forceRefetch: true })),
              dispatch(propertyApi.endpoints.getProperties.initiate(undefined, { forceRefetch: true })),
              dispatch(subscriptionPlansApi.endpoints.getCurrentSubscriptionPlan.initiate(undefined, { forceRefetch: true })),
            ]);
          }
          return { error: { data: undefined, status: 401 } };
        } catch (e) {
          return { error: { data: e, status: 401 } };
        }
      },
    }),
  }),
});

export const {
  useGetCustomTokenQuery,
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useLazyGetCustomTokenQuery,
  useGetUserStorageUsageQuery,
  useGetTokenDataQuery,
  useCreateUserWithTokenMutation,
  useUpdateUserDetailsMutation,
  useDeleteAccountMutation,
  useReloadTokenDataMutation,
  usePostUserActivityMutation,
} = userApi;
