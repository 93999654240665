import React, {
  FC,
  useState,
} from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useUpdateEffect } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  getBonusOffersPath,
  getMyTotalBonusPointsPath,
  getBonusOffersLogsPath,
  getMoveInBonusOffersPath,
} from '../navigation.bonusOffers';
import {
  Truck,
  Bonus,
  FileDoc,
  TwoStars,
} from '../../../../../components/icons/Icons';
import HIDInlineSelect, { SelectItem } from '../../../../../components/HIDInlineSelect';
import { BonusOffersQuickNavigationCategories } from '../types.bonusOffers';
import { PropertyIdNavigation } from '../../../types/property.types';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import useGetBonusOffersCategoryAvailability from '../hooks/useGetBonusOffersCategoryAvailability';
import ListStructureLoaderSkeleton from '../../../../../components/skeletonLoaders/ListStructureLoaderSkeleton';
import { toggleSideDrawerOpen } from '../../../../../store/layoutReducer';

const BonusOffersQuickNavigationCategoriesMap: Record<BonusOffersQuickNavigationCategories, (params: PropertyIdNavigation) => string> = {
  [BonusOffersQuickNavigationCategories.HOUSE_ID_BONUS_OFFERS]: getBonusOffersPath,
  [BonusOffersQuickNavigationCategories.MY_BONUS_POINTS]: getMyTotalBonusPointsPath,
  [BonusOffersQuickNavigationCategories.BONUS_OFFERS_LOGS]: getBonusOffersLogsPath,
  [BonusOffersQuickNavigationCategories.MOVE_IN_BONUS_OFFERS]: getMoveInBonusOffersPath,
};

type BonusOffersQuickNavigationProps = {
  currentCategory?: BonusOffersQuickNavigationCategories;
};

const BonusOffersQuickNavigation: FC<BonusOffersQuickNavigationProps> = ({
  currentCategory,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['bonus_offers']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const [selectedCategory, setSelectedCategory] = useState<BonusOffersQuickNavigationCategories | undefined>();

  const {
    data: { hasMoveInBonusOffers },
    isLoading: isLoadingCategories,
  } = useGetBonusOffersCategoryAvailability();

  const selectItems: Array<SelectItem> = [
    hasMoveInBonusOffers && {
      id: BonusOffersQuickNavigationCategories.MOVE_IN_BONUS_OFFERS,
      label: t('bonus_offers:bonus_offers_offers_from_move_in'),
      Icon: Truck,
    },
    {
      id: BonusOffersQuickNavigationCategories.HOUSE_ID_BONUS_OFFERS,
      label: t('bonus_offers:bonus_offers_bonus_offers'),
      Icon: Bonus,
    },
    {
      id: BonusOffersQuickNavigationCategories.MY_BONUS_POINTS,
      label: t('bonus_offers:bonus_offers_my_points'),
      Icon: TwoStars,
    },
    {
      id: BonusOffersQuickNavigationCategories.BONUS_OFFERS_LOGS,
      label: t('bonus_offers:bonus_offers_logs'),
      Icon: FileDoc,
    },
  ].filter(Boolean);

  useUpdateEffect(() => {
    if (selectedCategory) {
      const getPath = BonusOffersQuickNavigationCategoriesMap[selectedCategory];
      if (typeof getPath === 'function' && propertyId) {
        dispatch(toggleSideDrawerOpen(false));
        navigate(getPath({ propertyId }), { replace: true });
      }
    }
  }, [selectedCategory, propertyId]);

  return (
    <ListStructureLoaderSkeleton isLoading={isLoadingCategories}>
      <Stack spacing={1}>
        <Typography variant="h6">
          {t('bonus_offers:bonus_offers_categories')}
        </Typography>
        <HIDInlineSelect
          items={selectItems}
          value={currentCategory}
          onChange={(id) => setSelectedCategory(id as BonusOffersQuickNavigationCategories)}
        />
      </Stack>
    </ListStructureLoaderSkeleton>
  );
};

export default BonusOffersQuickNavigation;
