import React from 'react';
import {
  Skeleton,
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import HIDButton from '../../../../../../../components/buttons/HIDButton';
import { FCC } from '../../../../../../../types/common';
import {
  BonusOfferEntity,
} from '../../../types.bonusOffers';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import useDialog from '../../../../../../../hooks/useDialog';
import { ExternalLink } from '../../../../../../../components/icons/Icons';
import {
  useActivateBonusOfferMutation,
} from '../../../api/api.bonusOffers';
import { PropertyId } from '../../../../../types/property.types';
import HIDTypography from '../../../../../../../components/HIDTypography';
import useNavigateFromLink from '../../../../../hooks/useNavigateFromLink';
import useGetOpenUrlWithAnalytics from '../../../../../../Analytics/hooks/useGetOpenUrlWithAnalytics';
import {
  AnalyticsEvent,
  PartnersSource,
} from '../../../../../../Analytics/types.analytics';
import EntityType from '../../../../../../../constants/entityType';
import useGetLogAnalyticsEvent from '../../../../../../Analytics/hooks/useGetLogAnalyticsEvent';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';

type BonusOfferActivationBoxProps = {
  offer: BonusOfferEntity;
  isLoading: boolean;
  availablePoints: number;
} & PropertyId;

const BonusOfferActivationBox: FCC<BonusOfferActivationBoxProps> = ({
  sx,
  offer,
  isLoading,
  availablePoints,
  propertyId,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['bonus_offers']);

  const { isDownSm } = useBreakPointsSizes();

  const [openOfferActivatedModal] = useDialog(DialogNames.BONUS_OFFER_ACTIVATED_DIALOG);

  const [activateBonusOffer, { isLoading: isUpdating }] = useActivateBonusOfferMutation();

  const openModalWithForm = useNavigateFromLink(offer.action?.form ? { form: offer.action?.form } : undefined);

  const openUrlWithAnalytics = useGetOpenUrlWithAnalytics();
  const logAnalyticsEvent = useGetLogAnalyticsEvent();

  const {
    title, subtitle, description, url, form, buttonText, source,
  } = offer.action || {};

  const { shareAction } = offer;

  const handleActivateBonusOffer = () => {
    if (form) {
      openModalWithForm();
    } else {
      activateBonusOffer({ propertyId, bonusOfferId: String(offer.id) })
        .then(() => openOfferActivatedModal());
    }
    logAnalyticsEvent({
      event: AnalyticsEvent.REDEEM_OFFER,
      source: source || PartnersSource.HOUSE_ID,
      entityType: EntityType.BONUS_OFFER,
      params: { ...form, bonusOfferId: offer.id },
    });
  };

  const canActivate = availablePoints >= (offer.points || 0);

  // TODO: type field is legacy data, delete when no longer needed
  const textData = offer.action?.type === 'activate'
    ? {
      title: t('bonus_offers:bonus_offer_activation_box_title'),
      description: t('bonus_offers:bonus_offer_activation_box_description'),
    }
    : {
      title,
      description,
    };

  const handleOpenUrl = (url: string) => {
    openUrlWithAnalytics({
      url,
      event: AnalyticsEvent.OPEN_OFFER_LINK,
      source: source || PartnersSource.HOUSE_ID,
      entityType: EntityType.BONUS_OFFER,
      params: { ...form, bonusOfferId: offer.id },
    });
  };

  const handleShareUrl = () => {
    if (shareAction) {
      openUrlWithAnalytics({
        url: shareAction.url,
        event: AnalyticsEvent.OPEN_OFFER_LINK,
        source: shareAction?.url || PartnersSource.HOUSE_ID,
        entityType: EntityType.BONUS_OFFER,
        params: { bonusOfferId: offer.id },
      });
    }
  };

  return (
    <Stack
      alignItems="center"
      sx={{
        padding: theme.spacing(2.5),
        backgroundColor: theme.palette.primary.lightest,
        borderRadius: '5px',
        ...sx,
      }}
    >
      <Stack alignItems="center" spacing={3} sx={{ maxWidth: '600px', width: '100%' }}>
        {Boolean(textData.title) && (
          <HIDTypography isLoading={isLoading} sx={{ textAlign: 'center' }} variant="h4">
            {textData.title}
          </HIDTypography>
        )}
        {Boolean(subtitle) && (
          <HIDTypography isLoading={isLoading} sx={{ textAlign: 'center' }} variant="subtitle1">
            {subtitle}
          </HIDTypography>
        )}
        {Boolean(textData.description) && (
          <HIDTypography
            isLoading={isLoading}
            numberOfSkeletonLines={2}
            sx={{ textAlign: 'center' }}
            variant="body1"
          >
            <ReactMarkdown components={{ p: 'span' }}>
              {textData.description}
            </ReactMarkdown>
          </HIDTypography>
        )}
        {isLoading
          ? (
            <Skeleton
              height={40}
              sx={{ borderRadius: 20 }}
              variant="rounded"
            />
          )
          : (
            <Stack
              flex={1}
              flexDirection="row"
              flexWrap="wrap"
              gap={2}
              justifyContent="center"
            >
              {shareAction !== undefined && (
                <HIDButton
                  Icon={ExternalLink}
                  color="secondary"
                  fullWidth={isDownSm}
                  onClick={() => handleShareUrl()}
                >
                  {shareAction.buttonText}
                </HIDButton>
              )}
              {url
                ? (
                  <HIDButton
                    Icon={ExternalLink}
                    fullWidth={isDownSm}
                    loading={isLoading}
                    onClick={() => handleOpenUrl(url)}
                  >
                    {buttonText || t('bonus_offers:bonus_offers_activate_offer')}
                  </HIDButton>
                )
                : (
                  <HIDButton
                    disabled={!canActivate && !form}
                    fullWidth={isDownSm}
                    loading={isUpdating || isLoading}
                    onClick={handleActivateBonusOffer}
                  >
                    {
                      canActivate || form
                        ? buttonText || t('bonus_offers:bonus_offers_activate_offer')
                        : t('bonus_offers:bonus_offers_no_points')
                    }
                  </HIDButton>
                )}
            </Stack>
          )}
      </Stack>
    </Stack>
  );
};

export default BonusOfferActivationBox;
