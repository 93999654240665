import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  CircularProgress,
  Skeleton,
  Stack,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import {
  Add,
  Remove,
  ChevronLeft,
  ChevronRight,
  Fullscreen,
  FullscreenExit,
} from '@mui/icons-material';
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchContentRef,
} from 'react-zoom-pan-pinch';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import './HIDImageViewer.css';
import { HIDBlob } from '@house-id/houseid-types/dist/common';

import HIDIconButton from '../../buttons/HIDIconButton';
import {
  FCC,
  FCCProps,
} from '../../../types/common';
import { resolveImageUrl } from '../../../utils/env';
import {
  ThumbnailSize,
  getImageThumbnailUrl,
} from '../../../utils/image';
import { RootState } from '../../../store/store';
import { FileMimeType } from '../../../constants/mimeTypes';
import { hidSpacing } from '../../../utils/number';
import useBreakPointsSizes from '../../../hooks/useBreakpointsSizes';
import ContentFilePicker from '../../filePicker/ContentFilePicker';
import { ContentFile } from '../../../modules/Property/modules/Content/modules/ContentFile/types.contentFile';
import { ExternalMedia } from '../../../modules/Property/modules/Content/modules/Product/types.product';
import { HIDFilePickerRef } from '../../filePicker/HIDFilePicker';
import { useGetSideBarWidth } from '../../../modules/Property/utils/utils.home';
import useGetPropertyPermissions from '../../../modules/Property/hooks/useGetPropertyPermissions';
import { getIsTypeOf } from '../../../utils/object';

const getIsFile = getIsTypeOf<File>('arrayBuffer');

type ReactImageGalleryItemWithIndex = ReactImageGalleryItem & { index: number, isLocalFile: boolean; };

export type HIDImageViewerRef = {
  fullScreen: (index?: number) => void;
  exitFullScreen: () => void;
  slideToIndex: (index: number) => void;
  openFilePicker: () => void;
};

type HIDImageViewerPropsWithRef = FCCProps<HIDImageViewerProps, HIDImageViewerRef>;

type HIDImageViewerProps = {
  images: Array<HIDBlob | File>;
  externalImages?: Array<ExternalMedia>;
  isFullScreen?: boolean;
  showSkeleton?: boolean;
  showBorder?: boolean;
  showThumbnails?: boolean;
  mimeTypes?: Array<FileMimeType>;
  isLoading?: boolean;
  isUploading?: boolean;
  imageWidth?: number;
  skeletonSx?: SxProps<Theme>;
  filePickerSx?: SxProps<Theme>;
  onContentFilesSelected?: (files: Array<ContentFile>) => void;
  onScreenChange?: (fullScreen: boolean) => void;
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const HIDImageViewer: FCC<HIDImageViewerProps, HIDImageViewerRef> = forwardRef<HIDImageViewerRef, HIDImageViewerPropsWithRef>((
  {
    images: localImagesAndBlobs,
    externalImages = [],
    isFullScreen: isFullScreenDefault = false,
    showSkeleton = true,
    showBorder = true,
    showThumbnails = false,
    mimeTypes,
    isLoading = false,
    isUploading = false,
    imageWidth: imageWidthProp,
    skeletonSx,
    filePickerSx,
    sx,
    onContentFilesSelected,
    onScreenChange,
  },
  ref,
) => {
  const theme = useTheme();

  const { isDownSm } = useBreakPointsSizes();

  const filePickerRef = useRef<HIDFilePickerRef | undefined>();
  const imageGalleryRef = useRef<ImageGallery | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const transformRefs = useRef<Array<ReactZoomPanPinchContentRef | null>>([]);

  const { data: { canCreate, canUpdate } = {} } = useGetPropertyPermissions();

  const [isFullScreen, setIsFullScreen] = useState(isFullScreenDefault);

  useImperativeHandle(ref, () => ({
    fullScreen: (index = 0) => {
      imageGalleryRef.current?.fullScreen();

      if (index > 0) {
        const setCurrentIndex = () => imageGalleryRef.current?.setState((prev) => ({ ...prev, currentIndex: index }));
        setCurrentIndex();
        setTimeout(setCurrentIndex, 100);
      }
    },
    exitFullScreen: () => imageGalleryRef.current?.exitFullScreen(),
    slideToIndex: (index: number) => imageGalleryRef.current?.slideToIndex(index),
    openFilePicker: () => filePickerRef.current?.open(),
  }));

  const hasImages = localImagesAndBlobs.length || externalImages?.length;

  const [firstImageLoading, setFirstImageLoading] = useState(showSkeleton && (hasImages || isLoading));

  useEffect(() => {
    if (firstImageLoading && !isLoading) {
      setFirstImageLoading(false);
    }
  }, [isLoading]);

  const handleLocalFileOnLoad = (url: string) => URL.revokeObjectURL(url);
  const handleOnLoad = () => setFirstImageLoading(false);
  const handleOnError = () => setFirstImageLoading(false);

  const { isMenuOpened } = useSelector((state: RootState) => state.layout);
  const sideBarWidth = useGetSideBarWidth(isMenuOpened);
  const imageWidth = imageWidthProp || sideBarWidth || 260;
  const imageHeight = imageWidth * Math.sqrt(2);

  const images = !isUploading
    ? localImagesAndBlobs.map((localImageOrBlob, index) => {
      if (getIsFile(localImageOrBlob)) {
        return {
          index,
          thumbnail: URL.createObjectURL(localImageOrBlob),
          original: URL.createObjectURL(localImageOrBlob),
        };
      }
      const blob = localImageOrBlob;

      return {
        index,
        thumbnail: blob.localUrl || resolveImageUrl(getImageThumbnailUrl(blob.thumbnailUrlTemplate, ThumbnailSize.MAX_500)),
        original: blob.mime as FileMimeType === FileMimeType.PDF
          ? resolveImageUrl(getImageThumbnailUrl(blob.thumbnailUrlTemplate, ThumbnailSize.MAX_2600))
          : blob.localUrl
            ? blob.localUrl
            : blob.mime as FileMimeType === FileMimeType.HEIC
              ? resolveImageUrl(getImageThumbnailUrl(blob.thumbnailUrlTemplate, ThumbnailSize.JPEG))
              : resolveImageUrl(blob.downloadUrl),
      };
    })
    : [];

  const imagesWithExternal = [
    ...images,
    ...externalImages
      .map((externalMedia, index) => ({
        index: index + images.length,
        thumbnail: externalMedia?.thumbnailUrl,
        original: externalMedia?.url,
      })),
  ];

  const zoomButtonStep = 1;
  const zoomWheelStep = 0.2;

  const getCurrentIndex = (): number => (imageGalleryRef.current?.getCurrentIndex() || 0);

  const firstImageLoadingOrUploading = firstImageLoading || isUploading;

  const handleOnScreenChange = (fullScreen: boolean) => {
    setIsFullScreen(fullScreen);
    if (onScreenChange) {
      onScreenChange(fullScreen);
    }
  };

  if (!hasImages && !firstImageLoadingOrUploading && canCreate && canUpdate) {
    return (
      <ContentFilePicker
        mimeTypes={mimeTypes}
        ref={filePickerRef}
        sx={{
          width: imageWidth,
          height: imageHeight,
          ...filePickerSx,
        }}
        onContentFilesSelected={onContentFilesSelected}
      />
    );
  }

  return (
    <Stack
      position="relative"
      sx={{
        ...(
          isFullScreen
            ? {}
            : {
              width: imageWidth,
              height: imageHeight,
              overflow: 'hidden',
            }
        ),
        ...sx,
      }}
    >
      {firstImageLoadingOrUploading && (
        <Stack>
          <Skeleton
            sx={{
              borderRadius: theme.spacing(1),
              width: imageWidth,
              height: imageHeight,
              ...skeletonSx,
            }}
            variant="rectangular"
          />
          {isUploading && (
            <CircularProgress
              size={hidSpacing(8)}
              sx={{
                position: 'absolute',
                top: `calc(50% - ${hidSpacing(4)}px)`,
                left: `calc(50% - ${hidSpacing(4)}px)`,
              }}
            />
          )}
        </Stack>
      )}
      <ImageGallery
        additionalClass={isFullScreen ? undefined : 'preview-image-gallery-slides'}
        items={imagesWithExternal as Array<ReactImageGalleryItem>}
        ref={imageGalleryRef}
        renderCustomControls={() => (!isFullScreen && !firstImageLoadingOrUploading && (
          <>
            <HIDIconButton
              Icon={Add}
              color="alternate"
              sx={{
                position: 'absolute',
                right: theme.spacing(1),
                bottom: theme.spacing(1 + 0.5 + 5 + 0.5 + 5),
                zIndex: 1,
              }}
              onClick={() => transformRefs.current[getCurrentIndex()]?.zoomIn?.(zoomButtonStep)}
            />
            <HIDIconButton
              Icon={Remove}
              color="alternate"
              sx={{
                position: 'absolute',
                right: theme.spacing(1),
                bottom: theme.spacing(1 + 0.5 + 5),
                zIndex: 1,
              }}
              onClick={() => {
                transformRefs.current[getCurrentIndex()]?.zoomOut?.(zoomButtonStep);
              }}
            />
          </>
        ))}
        renderFullscreenButton={
          (onClick: React.MouseEventHandler<HTMLElement>, isFullScreen) => !firstImageLoadingOrUploading && (
            <HIDIconButton
              Icon={isFullScreen ? FullscreenExit : Fullscreen}
              color="alternate"
              sx={{
                position: 'absolute',
                right: theme.spacing(isFullScreen ? 1.5 : 1),
                bottom: theme.spacing(isFullScreen ? isDownSm ? 6 : 4 : 1),
              }}
              onClick={onClick}
            />
          )
        }
        renderItem={(item: ReactImageGalleryItem) => {
          const { index, isLocalFile } = (item as ReactImageGalleryItemWithIndex);
          const showSkeletonForFirst = showSkeleton && index === 0;

          return (
            isFullScreen
              ? (
                <img
                  className="image-gallery-image"
                  src={item.original}
                  onLoad={isLocalFile ? () => handleLocalFileOnLoad(item.original) : undefined}
                />
              )
              : (
                <TransformWrapper
                  initialPositionX={(imgRef.current?.width || 0) / 2}
                  initialPositionY={(imgRef.current?.height || 0) / 2}
                  initialScale={1}
                  ref={(element) => {
                    transformRefs.current[index] = element;
                  }}
                  wheel={{ step: zoomWheelStep }}
                >
                  <TransformComponent>
                    <img
                      className="image-gallery-image"
                      ref={imgRef}
                      src={item.original}
                      style={{
                        borderRadius: theme.spacing(1),
                        width: imageWidth,
                        height: imageHeight,
                        borderStyle: showBorder ? 'solid' : 'none',
                        borderWidth: 1,
                        borderColor: theme.palette.grey[300],
                      }}
                      onError={showSkeletonForFirst ? handleOnError : undefined}
                      onLoad={() => {
                        if (isLocalFile) {
                          handleLocalFileOnLoad(item.original);
                        }
                        if (showSkeletonForFirst) {
                          handleOnLoad();
                        }
                      }}
                    />
                  </TransformComponent>
                </TransformWrapper>
              )
          );
        }}
        renderLeftNav={(onClick: React.MouseEventHandler<HTMLElement>, disabled: boolean) => (
          <HIDIconButton
            Icon={ChevronLeft}
            color="alternate"
            disabled={disabled}
            sx={{
              position: 'absolute',
              left: theme.spacing(isFullScreen ? 1.5 : 1),
              bottom: theme.spacing(isFullScreen ? isDownSm ? 6 : 4 : 1),
              zIndex: 1,
            }}
            onClick={onClick}
          />
        )}
        renderRightNav={(onClick: React.MouseEventHandler<HTMLElement>, disabled: boolean) => (
          <HIDIconButton
            Icon={ChevronRight}
            color="alternate"
            disabled={disabled}
            sx={{
              position: 'absolute',
              left: theme.spacing(isFullScreen ? 1 + 5 + 1.5 + 0.5 : 1 + 5 + 0.5),
              bottom: theme.spacing(isFullScreen ? isDownSm ? 6 : 4 : 1),
              zIndex: 1,
            }}
            onClick={onClick}
          />
        )}
        showIndex={!firstImageLoadingOrUploading}
        showPlayButton={false}
        showThumbnails={showThumbnails}
        slideDuration={0}
        onScreenChange={handleOnScreenChange}
      />
    </Stack>
  );
});

HIDImageViewer.displayName = 'HIDImageViewer';

export default HIDImageViewer;
