import React, { FC } from 'react';
import {
  Badge,
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';

import HIDButton from '../../../../../components/buttons/HIDButton';
import { useGetPropertyStatusQuery } from '../../../api/property.api';
import SuggestionsExampleImage from '../../../../../assets/images/suggestions_example.png';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { getSuggestionCategoriesPath } from '../../../modules/Content/modules/Suggestion/navigation.suggestion';
import { getPathWithPropertyIdOrInit } from '../../../../Auth/navigation/navigation.auth';

const SuggestionsWidget: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation(['home']);

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: propertyStatus } = useGetPropertyStatusQuery(propertyId ? { propertyId } : skipToken);
  const handleNavigateToSuggestions = () => navigate(getPathWithPropertyIdOrInit(getSuggestionCategoriesPath, { propertyId }));

  return (
    <Card
      sx={{ height: 330 }}
    >
      <Stack style={{ height: '100%' }}>
        <Stack
          sx={{
            flex: 1,
            backgroundColor: theme.palette.warning.main,
            paddingTop: 1,
          }}
        >
          <Stack
            sx={{
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${SuggestionsExampleImage})`,
              height: '100%',
            }}
          />
        </Stack>
        <Stack sx={{ padding: theme.spacing(2.5) }}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{ width: '100%' }}
          >
            <Typography sx={{ marginRight: 2 }} variant="h5">
              {t('home:home_smart_suggestions_to_complete')}
            </Typography>
            {Boolean(propertyStatus?.suggestions) && (
              <Badge
                badgeContent={propertyStatus?.suggestions}
                color="error"
                sx={{ marginRight: theme.spacing(2) }}
              />
            )}
          </Stack>
          {Boolean(propertyStatus?.suggestions) && (
            <Typography>
              {t('home:home_you_currently_have_suggestions_waiting_for_you_to_complete_them')}
            </Typography>
          )}
          <HIDButton
            style={{ marginTop: theme.spacing(2) }}
            sx={{ alignSelf: 'center' }}
            onClick={handleNavigateToSuggestions}
          >
            {t('home:home_lets_go')}
          </HIDButton>
        </Stack>
      </Stack>
    </Card>
  );
};

export default SuggestionsWidget;
