import React, {
  useEffect,
  useState,
} from 'react';
import * as R from 'ramda';

import { useTranslation } from 'react-i18next';

import {
  Stack,
  Grid,
  Card,
  Typography,
} from '@mui/material';
import {
  FormikHelpers,
  useFormik,
} from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { CopyAllOutlined } from '@mui/icons-material';
import HIDTextField from '../../../../../components/HIDTextField';
import HIDButton from '../../../../../components/buttons/HIDButton';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import {
  HIDEntityId,
} from '../../../../../types/common';
import { getHandleSetField } from '../../../../../utils/form';
import { useRouteParams } from '../../../../../utils/routes';

import HomeLayout from '../../../../Property/pages/Home/components/HomeLayout';

import {
  getEmailsPath,
  getUploadEmailPath,
} from '../navigation.emails';
import {
  useDeleteEmailMutation,
  useGetEmailQuery,
  useUploadEmailMutation,
} from '../api/emails.api';
import {
  Email,
  HIDBlobHierarchyItem,
} from '../types.emails';
import SignedUrlFilesList, {
  getFolderIdsFromHierarchy,
  mapSelectedBlobHierarchyItems,
} from '../components/SignedUrlFilesList';
import HIDIconButton from '../../../../../components/buttons/HIDIconButton';
import { copyToClipboard } from '../../../../../utils/clipboard';

type EmailFormValues = Email & { complete: boolean; propertyId?: string };

const UploadEmail = () => {
  const navigate = useNavigate();

  const { t } = useTranslation(['common', 'admin', 'forms_common']);

  const { isDownMd } = useBreakPointsSizes();

  const { id: emailId } = useRouteParams<HIDEntityId>();

  const { data: email } = useGetEmailQuery({ id: emailId });

  const [uploadEmail, { isLoading: isEmailUploading }] = useUploadEmailMutation();

  const handleGoBack = () => navigate(getEmailsPath(), { replace: true });

  const [selectedBlobIds, setSelectedBlobIds] = useState<Record<string, boolean>>({});

  const handleFormSubmit = (values: EmailFormValues, formikHelpers: FormikHelpers<EmailFormValues>) => {
    if (values.propertyId) {
      const folderIds = getFolderIdsFromHierarchy(email?.blobHierarchy || []);

      const blobIds = R.pipe(
        // @ts-ignore
        R.toPairs,
        R.filter(([key, value]: [string, unknown]) => Boolean(value) && !folderIds.includes(key)),
        R.map(([key]) => key),
      )(selectedBlobIds) as Array<string>;

      uploadEmail({
        id: emailId,
        data: {
          propertyId: values.propertyId,
          subject: values.subject,
          messageText: values.messageText,
          complete: values.complete,
          blobIds,
        },
      }).then(() => {
        if (values.complete) {
          handleGoBack();
        } else {
          navigate(getUploadEmailPath(emailId));
        }
        if (email) {
          formikHelpers.resetForm({
            values: {
              ...email,
              complete: false,
              propertyId: '',
              subject: t('admin:email_default_subject'),
            },
          });
        }
      });
    }
  };

  const schema = Yup.object<EmailFormValues>({
    id: Yup.string().optional(),
    documentNumber: Yup.string().optional().nullable(),
    sender: Yup.string().optional().nullable(),
    propertyId: Yup.string().required(t('forms_common:field_mandatory')),
    subject: Yup.string().optional().nullable(),
    snippet: Yup.string().optional().nullable(),
    messageText: Yup.string().required(t('forms_common:field_mandatory')),
    complete: Yup.boolean().optional(),
  });

  const formik = useFormik<EmailFormValues>({
    initialValues: ({
      ...email,
      complete: false,
      subject: t('admin:email_default_subject'),
    }) as unknown as EmailFormValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit: (values, helpers) => handleFormSubmit(values, helpers),
  });
  const handleSetField = getHandleSetField<EmailFormValues>(formik);

  useEffect(() => {
    if (email) {
      const values = R.map((item: HIDBlobHierarchyItem) => mapSelectedBlobHierarchyItems(item, true))(email.blobHierarchy || []);
      const one: Array<[string, boolean]> = R.reduce(R.concat, [])(values) as unknown as Array<[string, boolean]>;
      const object = R.fromPairs(one);
      setSelectedBlobIds(object);
    }
  }, [email]);

  const handleSendAndRemove = () => {
    formik.setFieldValue('complete', true);
    formik.submitForm();
  };

  const handleSend = () => {
    formik.setFieldValue('complete', false);
    formik.submitForm();
  };

  const handleGmailFilterCopyToClipboard = () => {
    if (email?.gmailMessageFilter) {
      copyToClipboard(email.gmailMessageFilter);
    }
  };

  const [deleteEmail, { isLoading: isEmailDeleting }] = useDeleteEmailMutation();

  const handleDelete = () => {
    deleteEmail({ id: emailId })
      .then(() => handleGoBack());
  };

  return (
    <HomeLayout
      SideColumn={
        <Card sx={{ paddingX: 0.5, paddingY: 2 }}>
          <SignedUrlFilesList
            blobHierarchy={email?.blobHierarchy || []}
            blobs={email?.blobs || []}
            emailId={emailId}
            selectedItemIds={selectedBlobIds}
            onChange={setSelectedBlobIds}
          />
        </Card>
      }
      breadcrumbsLinks={[
        {
          link: getEmailsPath(),
          name: t('admin:emails_title'),
        },
      ]}
      title={t('admin:upload_email_title')}
      onBack={handleGoBack}
    >
      <Grid
        container
        columnSpacing={2.5}
        justifyContent="flex-end"
        rowSpacing={2}
      >

        {isDownMd && (
          <Grid item xxs={12}>
            <SignedUrlFilesList
              blobHierarchy={email?.blobHierarchy || []}
              blobs={email?.blobs || []}
              emailId={emailId}
              selectedItemIds={selectedBlobIds}
              onChange={setSelectedBlobIds}
            />
          </Grid>
        )}
        <Grid item xxs={12}>
          <HIDTextField
            disabled
            error={Boolean(formik.touched.sender && formik.errors.sender)}
            helperText={(formik.touched.sender) ? formik.errors.sender : undefined}
            id="sender"
            label={t('common:sender')}
            value={formik.values.sender}
            onBlur={formik.handleBlur('sender')}
            onChange={handleSetField('sender')}
          />
        </Grid>
        <Grid item xxs={12}>
          <HIDTextField
            disabled
            endAdornment={
              <HIDIconButton
                Icon={CopyAllOutlined}
                sx={{
                  marginBottom: 0.5,
                }}
                onClick={handleGmailFilterCopyToClipboard}
              />
            }
            id="gmail_filter_id"
            label={t('admin:upload_email_gmail_filter_id')}
            value={email?.gmailMessageFilter}
          />
        </Grid>
        <Grid item xxs={12}>
          <Typography sx={{ marginBottom: 1 }} variant="subtitle1">
            {t('admin:upload_email_subject')}
          </Typography>
          <HIDTextField
            disabled
            multiline
            id="email_subject"
            minRows={3}
            placeholder={t('common:description')}
            value={email?.subject}
          />
        </Grid>
        <Grid item xxs={12}>
          <Typography sx={{ marginBottom: 1 }} variant="subtitle1">
            {t('admin:upload_email_snippet')}
          </Typography>
          <HIDTextField
            disabled
            multiline
            error={Boolean(formik.touched.snippet && formik.errors.snippet)}
            helperText={(formik.touched.snippet) ? formik.errors.snippet : undefined}
            id="snippet"
            minRows={3}
            placeholder={t('common:description')}
            value={formik.values.snippet}
            onBlur={formik.handleBlur('snippet')}
            onChange={handleSetField('snippet')}
          />
        </Grid>
        <Grid item xxs={6}>
          <HIDTextField
            required
            error={Boolean(formik.touched.propertyId && formik.errors.propertyId)}
            helperText={(formik.touched.propertyId) ? formik.errors.propertyId : undefined}
            id="propertyId"
            label={t('admin:upload_email_property_id')}
            value={formik.values.propertyId}
            onBlur={formik.handleBlur('propertyId')}
            onChange={handleSetField('propertyId')}
          />
        </Grid>
        <Grid item xxs={6}>
          <HIDTextField
            required
            error={Boolean(formik.touched.subject && formik.errors.subject)}
            helperText={(formik.touched.subject) ? formik.errors.subject : undefined}
            id="subject"
            label={t('admin:upload_email_subject')}
            value={formik.values.subject}
            onBlur={formik.handleBlur('subject')}
            onChange={handleSetField('subject')}
          />
        </Grid>
        <Grid item xxs={12}>
          <Typography sx={{ marginBottom: 1 }} variant="subtitle1">
            {t('admin:upload_email_message_text')}
          </Typography>
          <HIDTextField
            multiline
            required
            error={Boolean(formik.touched.messageText && formik.errors.messageText)}
            helperText={(formik.touched.messageText) ? formik.errors.messageText : undefined}
            id="messageText"
            minRows={3}
            placeholder={t('common:description')}
            value={formik.values.messageText}
            onBlur={formik.handleBlur('messageText')}
            onChange={handleSetField('messageText')}
          />
        </Grid>

      </Grid>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="flex-end"
        spacing={2}
      >
        <HIDButton color="secondary" size="medium" onClick={handleGoBack}>
          {t('common:cancel')}
        </HIDButton>
        <HIDButton
          color="red"
          loading={isEmailDeleting}
          size="medium"
          onClick={handleDelete}
        >
          {t('common:delete_label')}
        </HIDButton>
        <HIDButton
          loading={!formik.values.complete && isEmailUploading}
          size="medium"
          onClick={handleSend}
        >
          {t('common:send')}
        </HIDButton>
        <HIDButton
          color="primary"
          loading={formik.values.complete && isEmailUploading}
          size="medium"
          onClick={handleSendAndRemove}
        >
          {t('admin:upload_email_send_and_remove')}
        </HIDButton>
      </Stack>
    </HomeLayout>
  );
};

export default UploadEmail;
