import React from 'react';
import {
  Collapse,
  Fade,
  Stack,
  useTheme,
} from '@mui/material';
import {
  Add,
  ChevronLeft,
  ChevronRight,
} from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { PropertyStageType } from '@house-id/houseid-types/dist/property';

import HIDButton from '../../../../../components/buttons/HIDButton';
import HIDIconButton from '../../../../../components/buttons/HIDIconButton';
import Image from '../../../../../components/icons/Image';
import Product from '../../../../../components/icons/Product';
import Suggestion from '../../../../../components/icons/Suggestion';
import {
  COLLAPSED_MENU_WIDTH,
  HEADER_HEIGHT,
  MENU_WIDTH,
} from '../../../../../constants/layout';
import MenuItem from './MenuItem';
import {
  Receipt,
  Document,
  Layers,
  Home,
  Note,
  Forum,
  Truck,
  PieChart,
  Bonus,
} from '../../../../../components/icons/Icons';
import HIDLogo from '../../../../../components/HIDLogo';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import HomeWithProgress from '../../../../../components/icons/HomeWithProgress';
import {
  FCC,
  MenuProps,
} from '../../../../../types/common';
import Timeline from '../../../../../components/icons/Timeline';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { getDocumentCategoriesPath } from '../../../modules/Content/modules/Document/navigation.document';
import { getReceiptCategoriesPath } from '../../../modules/Content/modules/Receipt/navigation.receipt';
import { getPhotosPath } from '../../../modules/Content/modules/Photo/navigation.photo';
import { getContentFilesPath } from '../../../modules/Content/modules/ContentFile/navigation.contentFile';
import { getNotesPath } from '../../../modules/Content/modules/Note/navigation.note';
import { useGetPropertyStatusQuery } from '../../../api/property.api';
import { getProductsPath } from '../../../modules/Content/modules/Product/navigation.product';
import { getTimelinePath } from '../../../modules/Content/modules/Timeline/navigation.timeline';
import { getSuggestionCategoriesPath } from '../../../modules/Content/modules/Suggestion/navigation.suggestion';
import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import { useGetPropertyProgressQuery } from '../../../modules/PropertyProgress/api/propertyProgress.api';
import { getPropertyProgressPercent } from '../../../modules/PropertyProgress/utils/utils.propertyProgress';
import { getPropertyProgressPath } from '../../../modules/PropertyProgress/navigation.propertyProgress';
import {
  getHomePath,
  useNavigateToMove,
} from '../../../navigation/navigation.property';
import { getForumPath } from '../../../../Forum/navigation/navigation.forum';
import { getPathWithPropertyIdOrInit } from '../../../../Auth/navigation/navigation.auth';
import useGetPropertyPermissions from '../../../hooks/useGetPropertyPermissions';
import MoveCard from '../../../../Move/components/MoveCard';
import useGetIsSfSellerSellingProperty from '../../../../../hooks/useGetIsSfSellerSellingProperty';
import useGetCurrentProperty from '../../../hooks/useCurrentProperty';
import { getFinancesPath } from '../../../modules/Finances/navigation.finances';
import { getBonusOffersPath } from '../../../modules/BonusOffers/navigation.bonusOffers';

type HomeMenuItem = {
  Icon: React.ElementType;
  label: string;
  path?: string;
  onClick?: () => void;
  count?: number;
};

type UseGetMenuItems = () => Array<HomeMenuItem>;

const useGetMenuItems: UseGetMenuItems = () => {
  const { t } = useTranslation(['home']);

  const { data: isSellingProperty } = useGetIsSfSellerSellingProperty();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: { stage } = {} } = useGetCurrentProperty();
  const { data: propertyStatus } = useGetPropertyStatusQuery(propertyId ? { propertyId } : skipToken);
  const { data: { canUpdate, canCreate } = {} } = useGetPropertyPermissions();

  const navigateToMove = useNavigateToMove();

  return [
    {
      Icon: Layers,
      label: t('home:home_menu_files_label'),
      path: getPathWithPropertyIdOrInit(getContentFilesPath, { propertyId }),
      count: propertyStatus?.files,
    },
    canUpdate && {
      Icon: Suggestion,
      label: t('home:home_menu_suggestions_label'),
      path: getPathWithPropertyIdOrInit(getSuggestionCategoriesPath, { propertyId }),
      count: propertyStatus?.suggestions,
    },
    {
      Icon: Timeline,
      label: t('home:home_menu_timeline_label'),
      path: getPathWithPropertyIdOrInit(getTimelinePath, { propertyId }),
    },
    {
      Icon: PieChart,
      label: t('home:home_menu_finances_label'),
      path: getPathWithPropertyIdOrInit(getFinancesPath, { propertyId }),
    },
    {
      Icon: Document,
      label: t('home:home_menu_documents_label'),
      path: getPathWithPropertyIdOrInit(getDocumentCategoriesPath, { propertyId }),
    },
    {
      Icon: Receipt,
      label: t('home:home_menu_receipts_label'),
      path: getPathWithPropertyIdOrInit(getReceiptCategoriesPath, { propertyId }),
    },
    {
      Icon: Product,
      label: t('home:home_menu_products_label'),
      path: getPathWithPropertyIdOrInit(getProductsPath, { propertyId }),
    },
    {
      Icon: Image,
      label: t('home:home_menu_images_label'),
      path: getPathWithPropertyIdOrInit(getPhotosPath, { propertyId }),
    },
    {
      Icon: Note,
      label: t('home:home_menu_notes_label'),
      path: getPathWithPropertyIdOrInit(getNotesPath, { propertyId }),
    },
    // {
    //   Icon: Task,
    //   label: t('home:home_menu_tasks_label'),
    //   path: getPathWithPropertyIdOrInit(getHomePath, { propertyId }),
    //   count: propertyStatus?.activeTasks,
    // },
    canUpdate && canCreate && {
      Icon: Forum,
      label: t('home:home_menu_forum_label'),
      path: getForumPath(),
    },
    isSellingProperty && stage !== PropertyStageType.PREVIOUS_OWNED && {
      Icon: Truck,
      label: t('home:home_menu_move'),
      onClick: navigateToMove,
    },
    {
      Icon: Bonus,
      label: t('home:home_menu_offer'),
      path: getPathWithPropertyIdOrInit(getBonusOffersPath, { propertyId }),
    },
  ].filter(Boolean) as Array<HomeMenuItem>;
};

const Menu: FCC<MenuProps> = ({
  open,
  sx,
  onToggleOpen,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation(['common', 'home']);

  const [openAddContentDialog] = useDialog(DialogNames.ADD_CONTENT_DIALOG);
  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: { stage } = {} } = useGetCurrentProperty();
  const { data: propertyProgress } = useGetPropertyProgressQuery(propertyId ? { propertyId } : skipToken);
  const propertyProgressPercent = getPropertyProgressPercent(propertyProgress?.progress);
  const { data: { canCreate } = {} } = useGetPropertyPermissions();

  const animationDuration = 600;

  const { isDownMd } = useBreakPointsSizes();

  const handleAdd = () => {
    onToggleOpen(!open);
    openAddContentDialog();
  };

  const menuItems = useGetMenuItems();

  const { data: isSellingProperty } = useGetIsSfSellerSellingProperty();

  const navigateToMove = useNavigateToMove();

  const handleMoveCardClick = () => {
    onToggleOpen(!open);
    navigateToMove();
  };

  return (
    <Collapse
      collapsedSize={isDownMd ? 0 : COLLAPSED_MENU_WIDTH}
      in={open}
      key={isDownMd.toString()}
      orientation="horizontal"
      sx={{
        ...sx,
        flexShrink: 0,
        height: '100%',
        backgroundColor: theme.palette.common.white,
        top: isDownMd ? HEADER_HEIGHT : 0,
        position: isDownMd ? 'absolute' : 'unset',
        borderRightColor: theme.palette.grey[200],
        borderRightStyle: 'solid',
        borderRightWidth: isDownMd ? 0 : 1,
      }}
    >
      <Stack
        sx={{
          width: MENU_WIDTH,
          height: '100%',
        }}
      >
        <Fade unmountOnExit in={open} timeout={animationDuration}>
          <Stack
            flex={1}
            justifyContent="space-between"
            sx={{ height: '100%' }}
          >
            <Stack
              spacing={0.5}
              sx={{
                padding: theme.spacing(2),
                minHeight: 0,
                overflow: 'auto',
              }}
            >
              <HIDLogo
                style={{
                  marginLeft: theme.spacing(0.5),
                  marginBottom: theme.spacing(2.5),
                }}
                onClick={() => {
                  navigate(getPathWithPropertyIdOrInit(getHomePath, { propertyId }));
                  onToggleOpen(!open);
                }}
              />
              {canCreate && (
                <HIDButton
                  Icon={Add}
                  color="yellow"
                  size="medium"
                  style={{
                    marginTop: theme.spacing(0.5),
                    marginBottom: theme.spacing(1.5),
                  }}
                  sx={{ height: theme.spacing(5) }}
                  onClick={handleAdd}
                >
                  {t('common:add_label')}
                </HIDButton>
              )}
              <MenuItem
                Icon={Home}
                label={t('common:my_property')}
                progress={isSellingProperty ? undefined : propertyProgressPercent}
                onClick={() => {
                  navigate(getPathWithPropertyIdOrInit(getPropertyProgressPath, { propertyId }));
                  onToggleOpen(!open);
                }}
              />
              {
                menuItems
                  .map(({
                    Icon,
                    label,
                    path,
                    count,
                    onClick,
                  }) => (
                    <MenuItem
                      Icon={Icon}
                      count={count}
                      key={label}
                      label={label}
                      onClick={() => {
                        if (onClick) {
                          onClick();
                        } else if (path) {
                          navigate(path);
                        }
                        onToggleOpen(!open);
                      }}
                    />
                  ))
              }
            </Stack>
            <Stack
              sx={{
                alignItems: 'flex-start',
                backgroundColor: theme.palette.common.white,
                padding: theme.spacing(2),
                borderTopColor: theme.palette.grey[200],
                borderTopStyle: 'solid',
                borderTopWidth: 1,
                gap: theme.spacing(2),
              }}
            >
              {!isSellingProperty && stage !== PropertyStageType.PREVIOUS_OWNED && (
                <MoveCard onClick={handleMoveCardClick} />
              )}
              <HIDButton
                fullWidth
                Icon={ChevronLeft}
                color="secondary"
                sx={{
                  alignSelf: 'stretch',
                  width: `calc(${MENU_WIDTH}px - ${theme.spacing(4)})`,
                  height: '40px',
                }}
                onClick={() => onToggleOpen(!open)}
              >
                {t('common:close')}
              </HIDButton>
            </Stack>
          </Stack>
        </Fade>
        <Fade
          unmountOnExit
          in={!open}
          timeout={animationDuration}
        >
          <Stack
            justifyContent="space-between"
            sx={{
              position: 'absolute',
              display: isDownMd ? 'none' : 'flex',
              height: '100%',
            }}
          >
            <Stack
              spacing={0.5}
              sx={{
                alignItems: 'flex-start',
                minHeight: 0,
                padding: theme.spacing(2),
                overflow: 'auto',
              }}
            >
              <HIDLogo
                size="small"
                style={{
                  marginLeft: theme.spacing(0.5),
                  marginBottom: theme.spacing(2.5),
                }}
                onClick={() => navigate(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
              />
              {canCreate && (
                <HIDIconButton
                  Icon={Add}
                  color="yellow"
                  style={{
                    marginTop: theme.spacing(0.5),
                    marginBottom: theme.spacing(1.5),
                  }}
                  title={t('common:add_label')}
                  titlePlacement="right"
                  onClick={handleAdd}
                />
              )}
              <HIDIconButton
                Icon={() => <HomeWithProgress progress={propertyProgressPercent} />}
                sx={{ padding: 0 }}
                title={t('common:my_property')}
                titlePlacement="right"
                onClick={() => navigate(getPathWithPropertyIdOrInit(getPropertyProgressPath, { propertyId }))}
              />
              {
                menuItems
                  .map(({
                    Icon,
                    count,
                    label,
                    path,
                    onClick,
                  }) => (
                    <HIDIconButton
                      Icon={Icon}
                      badgeColor="warning"
                      badgeCount={count}
                      key={label}
                      title={label}
                      titlePlacement="right"
                      onClick={() => {
                        if (onClick) {
                          onClick();
                        } else if (path) {
                          navigate(path);
                        }
                      }}
                    />
                  ))
              }
            </Stack>
            <Stack
              sx={{
                alignItems: 'flex-start',
                width: open ? MENU_WIDTH : COLLAPSED_MENU_WIDTH,
                backgroundColor: theme.palette.common.white,
                padding: theme.spacing(2),
                position: 'relative',
                borderColor: theme.palette.grey[300],
                borderStyle: 'solid',
                borderWidth: '1px 1px 0 0',
              }}
            >
              <HIDIconButton
                Icon={ChevronRight}
                color="secondary"
                title={t('common:close')}
                titlePlacement="right"
                onClick={() => onToggleOpen(!open)}
              />
            </Stack>
          </Stack>
        </Fade>
      </Stack>
    </Collapse>
  );
};

export default Menu;
