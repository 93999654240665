import React from 'react';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { getRecurringExpensesCategoriesPath } from '../../../modules/RecurringExpenses/navigation.recurringExpenses';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import FinanceOverviewWidget from './FinanceOverviewWidget';
import {
  useGetRecurringExpensesBankAccountsQuery,
  useGetRecurringExpensesFiltrationQuery,
} from '../../../modules/RecurringExpenses/api/recurringExpenses.api';
import { TimeIntervalsGroupingType } from '../../../../Content/types/content.type';
import ExpensesComparisonChart from '../../../modules/RecurringExpenses/components/ExpensesComparisonChart';
import { ChartDateLength } from '../../../utils.finances';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import {
  getFromToFullMonthDateRange,
  getLastSyncedFromBankAccounts,
} from '../../../modules/RecurringExpenses/utils.recurringExpenses';
import HIDButton from '../../../../../../../components/buttons/HIDButton';
import Wallet from '../../../../../../../components/icons/Wallet';
import { FCC } from '../../../../../../../types/common';

type RecurringExpensesOverviewProps = {
  showActions?: boolean;
  showIcons?: boolean;
  minHeight?: number;
  headerInfo?: React.ReactNode;
};

const RecurringExpensesOverview: FCC<RecurringExpensesOverviewProps> = ({
  showActions = false,
  showIcons = false,
  minHeight = 200,
  headerInfo,
  sx,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'finances']);
  const theme = useTheme();

  const { isDownXl } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();

  const { from: fromDateInitial, to: toDateInitial } = getFromToFullMonthDateRange(6);

  const {
    data: filtration,
    isLoading,
    isFetching,
  } = useGetRecurringExpensesFiltrationQuery({
    from: fromDateInitial.toISOString(),
    to: toDateInitial.toISOString(),
    grouping: TimeIntervalsGroupingType.Monthly,
    includeIncompleteMonths: true,
  });

  const { data: bankAccounts = [] } = useGetRecurringExpensesBankAccountsQuery();
  const lastSyncedAt = getLastSyncedFromBankAccounts(bankAccounts);

  const handleNavigateToExpenseCategories = () => navigate(getPathWithPropertyIdOrInit(getRecurringExpensesCategoriesPath, { propertyId }));

  const actions = (
    <HIDButton
      disabled={isLoading}
      sx={{ minWidth: 120 }}
      onClick={handleNavigateToExpenseCategories}
    >
      {t('common:view')}
    </HIDButton>
  );

  return filtration?.expenses?.length || isLoading || isFetching
    ? (
      <ExpensesComparisonChart
        data={filtration?.chart || []}
        dateLength={isDownXl ? ChartDateLength.TINY : ChartDateLength.LONG}
        footerInfo={showActions ? actions : undefined}
        groupingType={TimeIntervalsGroupingType.Monthly}
        headerInfo={headerInfo}
        isLoading={isLoading || isFetching}
        lastSyncedAt={lastSyncedAt}
        minHeight={minHeight}
        primaryLabel={t('finances:fixed_expenses')}
        showIcons={showIcons}
        sx={sx}
      />
    )
    : (
      <FinanceOverviewWidget
        Icon={Wallet}
        action={t('finances:add_your_costs')}
        description={t('finances:add_fixed_expenses_to_get_an_overview')}
        iconBackgroundColor={theme.palette.primary.lighter}
        iconColor={theme.palette.primary.dark}
        sx={sx}
        title={t('finances:what_do_your_fixed_expenses_look_like')}
        widgetBackground={theme.palette.primary.lightest}
        onClick={showActions ? handleNavigateToExpenseCategories : undefined}
      />
    );
};

export default RecurringExpensesOverview;
