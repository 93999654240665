import React, {
  useEffect,
  useMemo,
} from 'react';
import {
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import {
  PaymentTypeBasedComponentProps,
  PaymentInterval,
  CreateUpdateBudgetValues,
} from '@house-id/houseid-types/dist/finances/budgets';

import useBreakPointsSizes from '../../../../../../../../../hooks/useBreakpointsSizes';
import HIDFormSelect from '../../../../../../../../../components/HIDFormSelect';
import { CurrencyAdornment } from '../../../../../../../../../components/HIDTextField';
import useGetEnum from '../../../../../../../../../hooks/useGetEnum';
import MonthlyAllocatedBudget from './MonthlyAllocatedBudget';
import DebouncedTextField from './DebouncedTextField';
import {
  EnumType,
  FCC,
} from '../../../../../../../../../types/common';
import getMonthsList from '../utils/getMonthsList';
import getPaymentMonthsForQuarter from '../utils/getPaymentMonthsForQuarter';
import {
  getPaymentMonthsDataPath,
  getSubcategoryDataPath,
} from '../utils/formikFieldDataPath.utils';

const BudgetAverageTypeForm: FCC<PaymentTypeBasedComponentProps> = ({
  data,
  categoryId,
  subcategoryIndex,
}) => {
  const {
    amount,
    paymentMonths,
    paymentInterval,
    firstPaymentMonth,
  } = data;

  const { t } = useTranslation(['finances']);

  const { setFieldValue } = useFormikContext<CreateUpdateBudgetValues>();

  const subcategoryPath = getSubcategoryDataPath(categoryId, subcategoryIndex);
  const paymentMonthsFieldPath = getPaymentMonthsDataPath(categoryId, subcategoryIndex);

  useEffect(() => {
    if (amount !== undefined && paymentMonths?.length) {
      setFieldValue(paymentMonthsFieldPath, paymentMonths.map(({ month }) => ({ month, amount })));
    }
  }, [amount]);

  const { data: ExpensePaymentInterval } = useGetEnum(EnumType.ExpensePaymentInterval);

  const months = getMonthsList();

  const { isDownSm } = useBreakPointsSizes();

  const paymentIntervals = useMemo(
    () => Object.entries(ExpensePaymentInterval || {}).map(([id, name]) => ({ id, name })),
    [ExpensePaymentInterval],
  );

  const setPaymentMonths = (value: number | undefined, firstMonth: number | undefined) => {
    if (Number.isInteger(firstMonth)) {
      const paymentMonths = getPaymentMonthsForQuarter(value, firstMonth as number);
      setFieldValue(paymentMonthsFieldPath, paymentMonths);
    }
  };

  const handleChangePaymentInterval = (event: SelectChangeEvent) => {
    setFieldValue(`${subcategoryPath}.paymentInterval`, event.target.value);

    if (event.target.value === PaymentInterval.QUARTERLY) {
      setPaymentMonths(amount, firstPaymentMonth);
    }
  };

  const handleChangeFirstPaymentMonth = (event: SelectChangeEvent) => {
    const { value } = event.target;

    const firstMonth = Number(value);

    setFieldValue(`${subcategoryPath}.firstPaymentMonth`, firstMonth);
    setPaymentMonths(amount, firstMonth);
  };

  return (
    <Stack spacing={1.5}>
      <DebouncedTextField
        isRequired
        endAdornment={<CurrencyAdornment />}
        fieldPath={`${subcategoryPath}.amount`}
        inputProps={{
          min: 0,
          step: 0.1,
        }}
        label={t('finances:budgets_payment_cost_label')}
        type="number"
      />
      <Stack
        direction={isDownSm ? 'column' : 'row'}
        justifyContent={isDownSm ? 'center' : 'flex-end'}
        spacing={isDownSm ? 2.5 : 2}
      >
        <HIDFormSelect
          items={paymentIntervals}
          label={t('finances:budgets_payment_frequency_label')}
          showHelperText={false}
          value={paymentInterval}
          variant="outlined"
          onChange={handleChangePaymentInterval}
        />
        <HIDFormSelect
          items={months}
          label={t('finances:budgets_payment_first_month_label')}
          showHelperText={false}
          value={String(firstPaymentMonth)}
          variant="outlined"
          onChange={handleChangeFirstPaymentMonth}
        />
      </Stack>
      {paymentInterval === PaymentInterval.QUARTERLY && (
        <MonthlyAllocatedBudget
          categoryId={categoryId}
          inputsPerRow={4}
          paymentMonths={paymentMonths}
          subcategoryIndex={subcategoryIndex}
        />
      )}
    </Stack>
  );
};

export default BudgetAverageTypeForm;
