import * as Sentry from '@sentry/react';
import { HIDUser } from '@house-id/houseid-types/dist/common';

import { UserTokenData } from '../modules/Auth/types/auth.types';
import {
  clearGAUserValues,
  initGoogleAnalytics,
  setGAUserValues,
} from '../modules/Analytics/external-services/googleAnalytics';
import { initI18n } from './i18n';
import {
  clearSentryTags,
  initSentry,
  setSentryTags,
} from './sentry';

const initHouseIdData = () => {
  window.houseId = {
    pullRequestMessage: import.meta.env.VITE_APP_PULL_REQUEST_MESSAGE,
    pullRequestSha: import.meta.env.VITE_APP_PULL_REQUEST_SHA,
  };
};

export const initExternalServices = () => {
  try {
    initI18n();
    initSentry();
    initHouseIdData();
    initGoogleAnalytics();
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const setExternalServicesUserData = (tokenData: UserTokenData, user: HIDUser) => {
  setSentryTags(tokenData, user);
  setGAUserValues(tokenData);
};

export const clearExternalServicesUserData = () => {
  clearSentryTags();
  clearGAUserValues();
};
