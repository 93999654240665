import { HIDBlob } from '@house-id/houseid-types/dist/common';

import {
  NavigationLink,
} from '../../../../../../types/common';
import { ForumThreadTarget } from '../../../../../Forum/forum.types';
import { DynamicInputField } from '../DynamicContent/types/types.dynamicContent';
import { Property } from '@house-id/houseid-types/dist/property';

export type MessageSender = {
  name?: string;
  type: string;
};

export enum MessageType {
  USER_REMOVAL_REQUEST = 'user-removal-request',
  INBOUND_EMAIL = 'inbound-email',
  SYSTEM = 'system',
  ADMIN_MESSAGE = 'admin-message',
}

export type BannerMessageData = {
  imageUrl: string;
  title: string;
  body: string;
};

export type MessageInAppForumDataNavigation = {
  topicId: string;
  threadId: string;
  target: ForumThreadTarget;
};

export type MessageInAppDataNavigation = MessageInAppForumDataNavigation;

export type MessageInAppNavigation = {
  label: string;
  data: MessageInAppDataNavigation;
};

export type DefaultMessageData = {
  navigation: NavigationLink;
  inAppNavigation?: MessageInAppNavigation;
  banner?: BannerMessageData;
};

export type Message<TData = DefaultMessageData> = {
  id: string;
  type: MessageType;
  createdAt: string;
  propertyId: string;
  read: boolean;
  sender: MessageSender;
  subject: string;
  htmlBody: string;
  blobs?: Array<HIDBlob>;
  data?: TData
};

export type UserRemovalRequestMessageData = DefaultMessageData & {
  userRemovalRequest: {
    id: string;
    property: {
      address: Property['address'],
      propertyId: string;
      propertyDisplayName: string;
    };
    createdBy: {
      name: string;
      userId: string;
    },
    propertyId: string;
  };
};

export enum MessageReadStatus {
  READ = 'read',
  UNREAD = 'unread',
}

export type ExpertCommunicationFormData = {
  expertId: string;
  header: string;
  formFields: Array<DynamicInputField>;
  terms: string;
};
