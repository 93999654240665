import React, {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  FormControlLabel,
  FormGroup,
  ListItemButton,
  ListItemText,
  Stack,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import {
  isValid,
} from 'date-fns';
import {
  useDebounce,
  useUpdateEffect,
} from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  CheckBox,
  CheckBoxOutlineBlank,
} from '@mui/icons-material';

import { setMidday } from '../../../../../../../../../utils/date';
import {
  FILTER_DEBOUNCE_TIME,
} from '../../../../../../../../../constants/layout';
import HIDFilterDatePicker from '../../../../../../../../../components/datePicker/HIDFilterDatePicker';
import useScrollWithShadow from '../../../../../../../../../hooks/useScrollWithShadow';
import { useGetEventTypesQuery } from '../../../api/timeline.api';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import { arrToMap } from '../../../../../../../../../utils/array';
import HIDFiltersContainer from '../../../../../../../../../components/HIDFiltersContainer';

const hasUncheckedFilters = (eventTypesMap: Record<string, boolean>) => eventTypesMap
  && Object.values(eventTypesMap).some((value) => value === false);

export type TimelineFilters = {
  from?: string;
  to?: string;
  eventTypesMap?: Record<string, boolean>
};

type FiltersSectionProps = {
  filters: TimelineFilters,
  onChange: ({
    from,
    to,
    eventTypesMap,
  }: TimelineFilters) => void;
};

const TimelineFiltersSection: FC<FiltersSectionProps> = ({
  filters,
  onChange,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'timeline']);

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: eventTypes } = useGetEventTypesQuery(propertyId ? { propertyId } : skipToken);

  const [fromDate, setFromDate] = useState<Date | undefined>(filters.from ? new Date(filters.from) : undefined);
  const [toDate, setToDate] = useState<Date | undefined>(filters.to ? new Date(filters.to) : undefined);
  const [filtersMap, setFiltersMap] = useState(filters.eventTypesMap);
  const [allChecked, setAllChecked] = useState<boolean>(filters.eventTypesMap ? hasUncheckedFilters(filters.eventTypesMap) : true);

  useEffect(() => {
    if (!filtersMap) {
      setFiltersMap(filters.eventTypesMap);
    }

    if (filters.eventTypesMap) {
      setAllChecked(!hasUncheckedFilters(filters.eventTypesMap));
    }
  }, [filters.eventTypesMap]);

  const filtersObject = useMemo(
    () => ({
      from: fromDate,
      to: toDate,
      filtersMap,
    }),
    [
      fromDate,
      toDate,
      filtersMap,
    ],
  );

  const debouncedFilters = useDebounce(
    filtersObject,
    FILTER_DEBOUNCE_TIME,
  );

  useUpdateEffect(() => {
    if ((!debouncedFilters.from || isValid(debouncedFilters.from))
      && (!debouncedFilters.to || isValid(debouncedFilters.to))
    ) {
      onChange({
        from: debouncedFilters.from ? setMidday(debouncedFilters.from).toISOString() : undefined,
        to: debouncedFilters.to ? setMidday(debouncedFilters.to).toISOString() : undefined,
        eventTypesMap: filtersMap,
      });
    }
  }, [debouncedFilters]);

  const handleChangeColumnVisibility = (id: string, checked: boolean) => {
    const nextFiltersMap = {
      ...filtersMap,
      [id]: checked,
    };

    const nextAllChecked = Object.values(nextFiltersMap).every((value) => value !== false);

    setFiltersMap(nextFiltersMap);
    setAllChecked(nextAllChecked);
  };

  const handleAllCheckedChange = (checked: boolean) => {
    setAllChecked(checked);

    if (eventTypes !== undefined) {
      setFiltersMap(arrToMap((eventType) => [eventType.id, checked], eventTypes));
    }
  };

  const { boxShadow, scrollContainerRef, onScrollHandler } = useScrollWithShadow();

  return (
    <Stack>
      <Typography variant="h6">{t('common:filter')}</Typography>
      <HIDFilterDatePicker
        label={t('common:from')}
        value={fromDate}
        onChange={setFromDate}
      />
      <HIDFilterDatePicker
        label={t('common:to')}
        value={toDate}
        onChange={setToDate}
      />
      <FormGroup sx={{ marginTop: 2 }}>
        <Typography variant="subtitle1">
          {`${t('timeline:events')}`}
        </Typography>
        <HIDFiltersContainer
          ref={scrollContainerRef}
          sx={{ boxShadow }}
          onScroll={onScrollHandler}
        >
          {eventTypes?.length && (
            <>
              <FormControlLabel
                control={<Switch checked={allChecked} />}
                label={t('common:all_label')}
                labelPlacement="start"
                sx={{ justifyContent: 'space-between' }}
                onChange={() => handleAllCheckedChange(!allChecked)}
              />
              <div
                style={{
                  width: '100%',
                  borderStyle: 'solid',
                  margin: theme.spacing(0.5, 0),
                  borderColor: theme.palette.grey[300],
                  borderWidth: 0,
                  borderTopWidth: 1,
                }}
              />
              {
                eventTypes.map(
                  (eventType) => (
                    // TODO: Create HIDCheckbox component
                    <ListItemButton
                      key={eventType.id}
                      onClick={() => handleChangeColumnVisibility(eventType.id, !filtersMap?.[eventType.id])}
                    >
                      <ListItemText primary={eventType.displayName} />
                      {
                        filtersMap?.[eventType.id] !== false
                          ? <CheckBox sx={{ color: theme.palette.primary.main }} />
                          : <CheckBoxOutlineBlank />
                      }
                    </ListItemButton>
                  ),
                )
              }
            </>
          )}
        </HIDFiltersContainer>
      </FormGroup>
    </Stack>
  );
};

export default TimelineFiltersSection;
