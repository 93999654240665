import React, {
  FC,
} from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import { ExternalService } from '../../../types/common';
import { useGetPropertyExternalServicesQuery } from '../api/property.api';
import PropertyExternalService from '../constants/constants.externalServices';
import useGetCurrentPropertyId from '../hooks/useGetCurrentPropertyId';
import ExternalServiceCard from '../pages/ExternalServices/components/ExternalServiceCard';

type PropertyExternalServicesSectionProps = {
  externalServicesIds: Array<PropertyExternalService>;
};

const PropertyExternalServicesSection: FC<PropertyExternalServicesSectionProps> = ({
  externalServicesIds,
}) => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: externalServices } = useGetPropertyExternalServicesQuery(
    propertyId ? { propertyId } : skipToken,
    {
      selectFromResult: ({ currentData: externalServices, isLoading, isSuccess }) => ({
        data: externalServices?.filter((externalService: ExternalService) =>
          externalServicesIds.includes(externalService.serviceId)) || [],
        isLoading,
        isSuccess,
      }),
    },
  );

  return (
    <>
      {externalServices.map((service) => (
        <ExternalServiceCard externalService={service} key={service.id} />
      ))}
    </>
  );
};

export default PropertyExternalServicesSection;
