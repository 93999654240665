import React from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';

import { FCC } from '../types/common';
import HIDTypography from './HIDTypography';

type HIDSectionProps = {
  title: string;
};

const HIDSection: FCC<HIDSectionProps> = ({
  title,
  children,
}) => {
  const theme = useTheme();

  return (
    <Stack
      direction="column"
      sx={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
        borderRadius: '10px',
        padding: 2.5,
      }}
    >
      <HIDTypography sx={{ mb: 2.5 }} variant="subtitle1">
        {title}
      </HIDTypography>
      {children}
    </Stack>
  );
};

export default HIDSection;
