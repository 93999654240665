import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import { PropertyProgressCategoryId } from '@house-id/houseid-types/dist/propertyProgress';

type UseGetPropertyProgressCategoryTextParams = {
  categoryId: string;
  percent: number;
};

type GetTextByPercentOrDefaultParams = {
  percent: number;
  moreThanZeroText: string;
  defaultText: string;
};
const getTextByPercentOrDefault = ({
  percent,
  moreThanZeroText,
  defaultText,
}: GetTextByPercentOrDefaultParams) => match(percent)
  .when((percent) => percent > 0, () => moreThanZeroText)
  .otherwise(() => defaultText);

type UseGetPropertyProgressCategoryText = () => (params: UseGetPropertyProgressCategoryTextParams) => string;

const useGetPropertyProgressCategoryText: UseGetPropertyProgressCategoryText = () => {
  const { t } = useTranslation(['property_progress']);

  const texts: Record<string, ({ percent }: { percent: number }) => string> = {
    all: ({ percent }) => percent > 5
      ? t('property_progress:property_progress_all_categories_description_more_than_five')
      : t('property_progress:property_progress_all_categories_description_default'),
    [PropertyProgressCategoryId.BLUEPRINTS]: ({ percent }) => getTextByPercentOrDefault({
      percent,
      moreThanZeroText: t('property_progress:photos_and_blueprints_category_description_more_than_zero'),
      defaultText: t('property_progress:photos_and_blueprints_category_description_default'),
    }),
    [PropertyProgressCategoryId.PLANNING]: ({ percent }) => getTextByPercentOrDefault({
      percent,
      moreThanZeroText: t('property_progress:planning_category_description_more_than_zero'),
      defaultText: t('property_progress:planning_category_description_default'),
    }),
    [PropertyProgressCategoryId.FINANCE]: ({ percent }) => getTextByPercentOrDefault({
      percent,
      moreThanZeroText: t('property_progress:finance_category_description_more_than_zero'),
      defaultText: t('property_progress:finance_category_description_default'),
    }),
    [PropertyProgressCategoryId.INSURANCE_AND_SAFETY]: ({ percent }) => getTextByPercentOrDefault({
      percent,
      moreThanZeroText: t('property_progress:insurance_and_safety_category_description_more_than_zero'),
      defaultText: t('property_progress:insurance_and_safety_category_description_default'),
    }),
    [PropertyProgressCategoryId.SETTINGS]: ({ percent }) => getTextByPercentOrDefault({
      percent,
      moreThanZeroText: t('property_progress:property_settings_category_description_more_than_zero'),
      defaultText: t('property_progress:property_settings_category_description_default'),
    }),
    [PropertyProgressCategoryId.SPECIFICATIONS]: ({ percent }) => getTextByPercentOrDefault({
      percent,
      moreThanZeroText: t('property_progress:property_specification_category_description_more_than_zero'),
      defaultText: t('property_progress:property_specification_category_description_default'),
    }),
    [PropertyProgressCategoryId.PRODUCTS]: ({ percent }) => getTextByPercentOrDefault({
      percent,
      moreThanZeroText: t('property_progress:property_products_category_description_more_than_zero'),
      defaultText: t('property_progress:property_products_category_description_default'),
    }),
    [PropertyProgressCategoryId.DOCUMENTS]: ({ percent }) => getTextByPercentOrDefault({
      percent,
      moreThanZeroText: t('property_progress:property_documentation_category_description_more_than_zero'),
      defaultText: t('property_progress:property_documentation_category_description_default'),
    }),
    [PropertyProgressCategoryId.PURCHASE]: ({ percent }) => getTextByPercentOrDefault({
      percent,
      moreThanZeroText: t('property_progress:property_purchase_category_description_more_than_zero'),
      defaultText: t('property_progress:property_purchase_category_description_default'),
    }),
    [PropertyProgressCategoryId.OFFERS]: () => t('property_progress:property_offers_category_description_default'),
  };

  return (params: UseGetPropertyProgressCategoryTextParams) => texts[params.categoryId] ? texts[params.categoryId](params) || '' : '';
};

export default useGetPropertyProgressCategoryText;
