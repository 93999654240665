import { PropertyProgress } from '@house-id/houseid-types/dist/propertyProgress';

import { HIDApiTags } from '../../../../../api/HIDApiTags';
import { propertyApi } from '../../../api/property.api';
import { PropertyId } from '../../../types/property.types';
import {
  PropertyProgressCategoryDetails,
  PropertySpecificationData,
  PropertyPurchaseData,
  PropertyProductTypesSectionData,
  PropertyBlueprintSectionData,
  PropertyPlanningSectionData,
  PropertyDocumentationData,
  PropertyFinancesSectionData,
  PropertyInsuranceAndSafetySectionData,
} from '../types.propertyProgress';

type PatchPropertyProgressSpecificationValues = PropertyId & {
  data: Record<string, any>;
};

export const propertyProgressApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getPropertyProgress: builder.query<PropertyProgress, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/progress`,
      providesTags: [HIDApiTags.PROPERTY_PROGRESS],
    }),
    getPropertyProgressSpecification: builder.query<PropertyProgressCategoryDetails<PropertySpecificationData>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/progress/property-specification`,
      providesTags: [HIDApiTags.PROPERTY_PROGRESS_SPECIFICATION],
    }),
    getPropertyProgressPropertyPurchase: builder.query<PropertyProgressCategoryDetails<PropertyPurchaseData>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/progress/property-purchase`,
      providesTags: [HIDApiTags.PROPERTY_PURCHASE],
    }),
    getPropertyProgressProducts: builder.query<PropertyProgressCategoryDetails<PropertyProductTypesSectionData>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/progress/products`,
      providesTags: [HIDApiTags.PROPERTY_PRODUCTS],
    }),
    getPropertyProgressBlueprints: builder.query<PropertyProgressCategoryDetails<PropertyBlueprintSectionData>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/progress/photos-and-blueprints`,
      providesTags: [HIDApiTags.PROPERTY_BLUEPRINTS],
    }),
    getPropertyProgressPlanning: builder.query<PropertyProgressCategoryDetails<PropertyPlanningSectionData>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/progress/planning`,
      providesTags: [HIDApiTags.PROPERTY_PLANNING],
    }),
    getPropertyProgressDocumentation: builder.query<PropertyProgressCategoryDetails<PropertyDocumentationData>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/progress/property-documentation`,
      providesTags: [HIDApiTags.PROPERTY_DOCUMENTATION],
    }),
    getPropertyProgressFinance: builder.query<PropertyProgressCategoryDetails<PropertyFinancesSectionData>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/progress/finance`,
      providesTags: [HIDApiTags.PROPERTY_FINANCE],
    }),
    getPropertyProgressInsurance: builder.query<PropertyProgressCategoryDetails<PropertyInsuranceAndSafetySectionData>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/progress/insurance-and-safety`,
      providesTags: [HIDApiTags.PROPERTY_INSURANCE],
    }),
    patchPropertyProgressSpecification:
      builder.mutation<PropertyProgressCategoryDetails<PropertySpecificationData>, PatchPropertyProgressSpecificationValues>({
        query: ({ propertyId, data }) => ({
          url: `/properties/${propertyId}/progress/property-specification`,
          method: 'PATCH',
          body: data,
        }),
        invalidatesTags: [HIDApiTags.PROPERTY_PROGRESS_SPECIFICATION, HIDApiTags.PROPERTY_PROGRESS],
      }),
    patchPropertyProgressInsurance:
      builder.mutation<PropertyProgressCategoryDetails<PropertyInsuranceAndSafetySectionData>, PatchPropertyProgressSpecificationValues>({
        query: ({ propertyId, data }) => ({
          url: `/properties/${propertyId}/progress/insurance-and-safety`,
          method: 'PATCH',
          body: data,
        }),
        invalidatesTags: [HIDApiTags.PROPERTY_PROGRESS_SPECIFICATION, HIDApiTags.PROPERTY_PROGRESS],
      }),
    patchPropertyProgressFinance:
      builder.mutation<PropertyProgressCategoryDetails<PropertyFinancesSectionData>, PatchPropertyProgressSpecificationValues>({
        query: ({ propertyId, data }) => ({
          url: `/properties/${propertyId}/progress/finance`,
          method: 'PATCH',
          body: data,
        }),
        invalidatesTags: [HIDApiTags.PROPERTY_FINANCE, HIDApiTags.PROPERTY_PROGRESS],
      }),
  }),
});

export const {
  useGetPropertyProgressQuery,
  useGetPropertyProgressSpecificationQuery,
  useGetPropertyProgressPropertyPurchaseQuery,
  useGetPropertyProgressProductsQuery,
  useGetPropertyProgressBlueprintsQuery,
  useGetPropertyProgressPlanningQuery,
  useGetPropertyProgressDocumentationQuery,
  useGetPropertyProgressFinanceQuery,
  useGetPropertyProgressInsuranceQuery,
  usePatchPropertyProgressSpecificationMutation,
  usePatchPropertyProgressInsuranceMutation,
  usePatchPropertyProgressFinanceMutation,
} = propertyProgressApi;
