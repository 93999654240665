import React, { FC } from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';

import { EMPTY_VALUE } from '../../../../../../../../../utils/string';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../../../utils/date';
import { useGetAllReceiptCategoriesQuery } from '../../../api/receipt.api';
import BasicInfoSection from '../../../../../components/sections/BasicInfoSection';
import { Receipt } from '../../../types.receipt';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';

type ReceiptBasicInfoSectionProps = {
  receipt: Receipt;
};

const ReceiptBasicInfoSection: FC<ReceiptBasicInfoSectionProps> = ({
  receipt,
}) => {
  const { t } = useTranslation(['common', 'receipts']);

  const receiptDateFormatted = receipt.date
    ? formatDate(new Date(receipt.date), DateTimeFormats.DATE_ONLY)
    : undefined;

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: categories = [] } = useGetAllReceiptCategoriesQuery(propertyId ? { propertyId } : skipToken);

  const categoriesMap: Record<string, string> = R.fromPairs(
    R.map((category) => ([category.id, category.name]), categories),
  );

  const fields = [
    { label: t('receipts:receipts_receipt_invoice_number'), value: receipt.invoiceNumber || EMPTY_VALUE },
    { label: t('common:category'), value: categoriesMap[receipt.categoryId] || EMPTY_VALUE },
    { label: t('receipts:receipts_receipts_date_column'), value: receiptDateFormatted || EMPTY_VALUE },
    { label: t('receipts:receipts_receipts_place_column'), value: receipt.place || EMPTY_VALUE },
  ];

  return (
    <BasicInfoSection items={fields} />
  );
};

export default ReceiptBasicInfoSection;
