/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { getPathWithPropertyIdOrInit } from '../../Auth/navigation/navigation.auth';
import {
  getCreateDocumentPath,
  getDocumentPath,
} from '../modules/Content/modules/Document/navigation.document';
import { getHomePath } from '../navigation/navigation.property';
import useGetCurrentPropertyId from './useGetCurrentPropertyId';

export enum NavigationAction {
  CREATE_DOCUMENT = 'createDocument',
  VIEW_DOCUMENT = 'viewDocument',
  VIEW_HOME = 'viewHome',
  // TODO: Add actions when modules are added
  CREATE_BUILDING = 'createBuilding',
  CREATE_OUTDOOR = 'createOutdoor',
  EDIT_BUILDING = 'editBuilding',
  DELETE_BUILDING = 'deleteBuilding',
  VIEW_BUILDING_PARTS = 'viewBuildingParts',
  VIEW_BUILDING_ROOMS = 'viewBuildingRooms',
  VIEW_OUTDOOR = 'viewOutdoor',
  VIEW_OUTDOORS = 'viewOutdoors',
  VIEW_INTEREST_RATE = 'viewInterestRate',
  VIEW_INTEREST_RATE_CHECK = 'viewInterestRateCheck',
  VIEW_FIXED_EXPENSES_CANDIDATES = 'viewFixedExpensesCandidates',
  VIEW_ACCOMMODATION_COST = 'viewAccommodationCost',
  VIEW_SUBSCRIPTIONS = 'viewSubscriptions',
  VIEW_FINANCE_OVERVIEW = 'viewFinanceOverview',
  VIEW_PROPERTY_CHECK_TASK_GROUP = 'viewPropertyCheckTasksGroup',
  VIEW_MOVESTA_VALUATION_HISTORY = 'viewMovestaValuationHistory',
  VIEW_GOOD_TO_DO_TASKS = 'viewGoodToDoTasks',
}

const useGetNavigationAction = () => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const navigationActions: Record<string, (params: any) => string> = {
    [NavigationAction.VIEW_DOCUMENT]: (params: any) => getPathWithPropertyIdOrInit(getDocumentPath, { propertyId, ...params }),
    [NavigationAction.CREATE_DOCUMENT]: (params: any) => getPathWithPropertyIdOrInit(getCreateDocumentPath, { propertyId, ...params }),
    [NavigationAction.VIEW_HOME]: (params: any) => getPathWithPropertyIdOrInit(getHomePath, { propertyId, ...params }),
  };

  return (action: NavigationAction) => navigationActions[action];
};

export default useGetNavigationAction;
