import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  Grid,
  Stack,
  useTheme,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';
import * as R from 'ramda';

import { HEADER_HEIGHT } from '../../../../../../../constants/layout';
import HomeLayout from '../../../../../pages/Home/components/HomeLayout';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import {
  useLocationState,
  useNavigateBackOr,
  useRouteQueryParams,
} from '../../../../../../../utils/routes';
import EntityType from '../../../../../../../constants/entityType';
import SearchResultsLoaderSkeleton from '../components/SearchResultsLoaderSkeleton';
import useGetEntityInfo from '../../../hooks/useGetEntityInfo';
import SearchResultsListItem from '../components/SearchResultsListItem';
import SearchResultsQuickNavigation from '../components/SearchResultsQuickNavigation';
import useGetSearchResultsCategoriesInfo from '../hooks/useGetSearchResultsCategoriesInfo';
import NoDataPlaceholder from '../../../../../../../components/NoDataPlaceholder';
import useSearch from '../../../../../../../hooks/useSearch';
import { ALL_SEARCH_RESULTS_CATEGORY } from '../constants.search';
import { useGetSearchResultsQuery } from '../api/search.api';

const SearchResults: FC = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const { t, i18n: { language } } = useTranslation(['search']);

  const navigateBackOr = useNavigateBackOr();

  const getEntityInfo = useGetEntityInfo();

  const [selectedCategoryId, setSelectedCategoryId] = useState<string>(ALL_SEARCH_RESULTS_CATEGORY);

  const getSearchFilterTypes = (entityType?: EntityType, typeFilters?: Record<EntityType, Record<string, string>>) => {
    if (entityType && ALL_SEARCH_RESULTS_CATEGORY !== entityType as string) {
      return { [entityType]: {} };
    }

    if (!R.isEmpty(typeFilters)) {
      return typeFilters;
    }

    return undefined;
  };

  const typeFiltersState = useLocationState<Record<EntityType, Record<string, string>> | undefined>();

  const { entityType, query } = useRouteQueryParams<{
    entityType?: EntityType;
    query?: string;
  }>();

  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: searchResultData, isLoading } = useGetSearchResultsQuery(
    (query || typeFiltersState || entityType) && propertyId
      ? {
        propertyId,
        types: getSearchFilterTypes(entityType, typeFiltersState),
        query,
        lang: language,
      }
      : skipToken,
    {
      selectFromResult: ({ data: currentData, isLoading }) => ({
        data: (currentData || []).filter((item) => item.hits.length && Boolean(getEntityInfo(item.type)?.isSearchable)),
        isLoading,
      }),
    },
  );

  const searchState = useSearch();

  useEffect(() => {
    if (entityType !== searchState.entityType) {
      searchState.setSearchState({ entityType });
    }
  }, [entityType]);

  const categoriesInfo = useGetSearchResultsCategoriesInfo(searchResultData);

  const getTitle = (type: EntityType, count: number) => {
    const info = getEntityInfo(type);

    if (!info) {
      return `${type} (${count})`;
    }

    return `${count > 1 ? info.namePlural : info.name} (${count})`;
  };

  const handleClick = (id: string, entityType: EntityType) => {
    const entityInfo = getEntityInfo(entityType);
    if (entityInfo?.getViewLink && propertyId) {
      navigate(entityInfo.getViewLink({ propertyId, id }));
    }
  };

  const isAllSelected = selectedCategoryId === ALL_SEARCH_RESULTS_CATEGORY;
  const currentData = (
    isAllSelected
      ? searchResultData
      : searchResultData?.filter((item) => item.type === selectedCategoryId)
  )
    || [];
  const currentCategoryTotalCounter = categoriesInfo.counters[selectedCategoryId as keyof typeof categoriesInfo.counters] || 0;

  return (
    <HomeLayout
      BodyLoaderSkeleton={SearchResultsLoaderSkeleton}
      SideColumn={
        currentCategoryTotalCounter > 0 || isLoading
          ? (
            <Card sx={{ padding: 2 }}>
              <SearchResultsQuickNavigation
                categories={categoriesInfo.categories}
                isLoading={isLoading}
                selectedCategoryId={selectedCategoryId}
                onSetSelectedCategoryId={setSelectedCategoryId}
              />
            </Card>
          )
          : <div />
      }
      isLoading={isLoading}
      title={query ? `${t('search:result_title')} "${query}"` : t('search:title')}
      onBack={navigateBackOr}
    >
      <NoDataPlaceholder hasData={currentCategoryTotalCounter > 0}>
        <Stack spacing={1.5}>
          <Typography variant="subtitle2">
            {`${currentCategoryTotalCounter} ${t('search:hits')}`}
          </Typography>
          {
            currentData.map((item) => (
              <Stack key={item.type} spacing={0.5}>
                <Typography
                  sx={{
                    position: 'sticky',
                    top: HEADER_HEIGHT,
                    backgroundColor: theme.palette.common.white,
                    padding: theme.spacing(1.5, 0),
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    zIndex: 1,
                  }}
                  variant="h5"
                >
                  {getTitle(item.type, item.hits.length)}
                </Typography>
                <Box sx={{ flexGrow: 1, paddingTop: theme.spacing(1.5) }}>
                  <Grid
                    container
                    spacing={{ xxs: 1.5, xs: 2, md: 2.5 }}
                  >
                    {
                      item.hits.map((entity) => (
                        <Grid
                          item
                          key={entity.entity.id}
                          lg={4}
                          sm={4}
                          xl={3}
                          xs={6}
                          xxs={6}
                        >
                          <SearchResultsListItem
                            entityType={item.type}
                            thumbnailUrl={entity.entity.image?.thumbnailUrl}
                            title={entity.entity.name}
                            onClick={() => handleClick(entity.entity.id, item.type)}
                          />
                        </Grid>
                      ))
                    }
                  </Grid>
                </Box>
              </Stack>
            ))
          }
        </Stack>
      </NoDataPlaceholder>
    </HomeLayout>
  );
};

export default SearchResults;
