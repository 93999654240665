import EntityType from '../../../../../constants/entityType';

export type EntityConnectionsCommonRouteState<TInitialData = Record<string, string>> = {
  connections?: EntityUId[];
  initialData?: TInitialData
};

export type EntityContentConnectionsMap = Record<EntityType, Array<EntityContentConnection>>;

export type EntityContent<TImage = {
  url: string;
  thumbnailUrl: string;
  thumbnailUrlTemplate: string;
}> = {
  id: string;
  propertyId?: string;
  type: EntityType;
  name: string;
  image?: TImage;
};

// TODO: this model should be extended with EntityContent
export type EntityContentConnection = {
  type: string;
  entity: {
    id: string;
    name: string;
    image?: {
      id: string;
      url: string;
      mime: string;
      thumbnailUrl: string;
      thumbnailUrlTemplate: string;
    };
    details: {
      order: number;
    };
  };
  details: {
    data: any;
    createdAt: string;
    updatedAt: string;
    classifications: Array<string>;
  };
  connectionType: string;
};

export type EntityUId = {
  entityType: EntityType;
  entityId: string;
};

export type EntityContentConnectionAction = {
  type: EntityType,
  action: ModificationType,
  entityId: string;
};

export type DeleteContentParams = {
  propertyId: string;
  ids: Array<string>;
};

export type PurchasePlace = {
  id: string,
  name: string,
};

export enum TimeIntervalsGroupingType {
  Yearly = 'year',
  Quarterly = 'quarter',
  Monthly = 'month',
}

export enum ModificationType {
  CREATE = 'create',
  DELETE = 'delete',
}

export type EntityInfo = {
  Icon: React.ElementType;
  type: EntityType;
  name: string;
  isSearchable: boolean;
  namePlural: string;
  getViewLink?: ({ propertyId, id }: { propertyId: string, id: string }) => string;
  getUpdateLink?: ({ propertyId, id, suggestionKey }: { propertyId: string, id: string, suggestionKey?: string }) => string;
  getListLink?: ({ propertyId }: { propertyId: string }) => string;
};
