import React from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import {
  EmailOutlined,
} from '@mui/icons-material';
import { useNavigate } from 'react-router';
import HIDInlineSelect, { SelectItem } from '../../../components/HIDInlineSelect';
import { toggleSideDrawerOpen } from '../../../store/layoutReducer';
import { FCC } from '../../../types/common';
import useDialog from '../../../hooks/useDialog';
import DialogNames from '../../../hooks/useDialog/DialogNames';
import {
  Database,
  Home,
  Upload,
} from '../../../components/icons/Icons';
import {
  getExternalServicesPath,
  getPropertySettingsPath,
  getPropertyStoragePath,
} from '../navigation/navigation.property';
import useGetCurrentPropertyId from '../hooks/useGetCurrentPropertyId';
import useGetCurrentProperty from '../hooks/useCurrentProperty';
import { getPathWithPropertyIdOrInit } from '../../Auth/navigation/navigation.auth';
import useGetIsCurrentUserPropertyOwner from '../hooks/useGetIsCurrentUserPropertyOwner';

export enum PropertySettingsActionId {
  PROPERTY_SETTINGS = 'propertySettings',
  NOTIFICATION_SETTINGS = 'notificationSettings',
  STORAGE = 'storage',
  EXTERNAL_SERVICES = 'externalServices',
  PROPERTY_INBOUND_EMAIL = 'propertyInboundEmail',
  SMART_SUGGESTION_SETTINGS = 'smart_suggestion_settings',
}

type PropertySettingsQuickNavigationProps = {
  currentPropertySettingsActionId: PropertySettingsActionId;
};

const PropertySettingsQuickNavigation: FCC<PropertySettingsQuickNavigationProps> = ({
  currentPropertySettingsActionId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: property } = useGetCurrentProperty();

  const isCurrentUserOwner = useGetIsCurrentUserPropertyOwner({ propertyId });

  const { t } = useTranslation(['property']);

  const [openChangeInboundEmailDialog] = useDialog(DialogNames.CHANGE_INBOUND_EMAIL_NAME_DIALOG);

  const customActions: Record<string, SelectItem & { onClick: () => void; visible: boolean }> = {
    [PropertySettingsActionId.PROPERTY_SETTINGS]: {
      id: PropertySettingsActionId.PROPERTY_SETTINGS as const,
      label: t('property:property_settings_properties_action'),
      Icon: Home,
      visible: true,
      onClick: () => navigate(getPathWithPropertyIdOrInit(getPropertySettingsPath, { propertyId })),
    },
    [PropertySettingsActionId.STORAGE]: {
      id: PropertySettingsActionId.STORAGE,
      label: t('property:property_settings_storage_action'),
      Icon: Upload,
      visible: isCurrentUserOwner,
      onClick: () => navigate(getPathWithPropertyIdOrInit(getPropertyStoragePath, { propertyId })),
    },
    [PropertySettingsActionId.EXTERNAL_SERVICES]: {
      id: PropertySettingsActionId.EXTERNAL_SERVICES,
      label: t('property:property_settings_external_services_action'),
      Icon: Database,
      visible: Boolean(property?.isVerified),
      onClick: () => navigate(getPathWithPropertyIdOrInit(getExternalServicesPath, { propertyId })),
    },
    [PropertySettingsActionId.PROPERTY_INBOUND_EMAIL]: {
      id: PropertySettingsActionId.PROPERTY_INBOUND_EMAIL,
      label: t('property:property_settings_inbound_email_action'),
      Icon: EmailOutlined,
      visible: true,
      onClick: openChangeInboundEmailDialog,
    },
  };

  const handleCategoryChange = (categoryId: string) => {
    const currentCategory = customActions[categoryId];
    if (currentCategory?.onClick) {
      currentCategory.onClick();
      dispatch(toggleSideDrawerOpen(false));
    }
  };

  const mappedCategories = R.values(customActions)
    .filter(({ visible }) => visible)
    .map((action) => ({
      id: action.id,
      label: action.label,
      Icon: action.Icon,
    }));

  return (
    <Stack>
      <Typography sx={{ marginBottom: 1 }} variant="h6">
        {t('property:property_settings_actions_title')}
      </Typography>
      <HIDInlineSelect
        items={mappedCategories}
        value={currentPropertySettingsActionId}
        onChange={handleCategoryChange}
      />
    </Stack>
  );
};

export default PropertySettingsQuickNavigation;
