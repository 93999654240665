import React, { FC } from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

import { LIST_CHEVRON_COLUMN_CONFIG } from '../../../../constants/columns';
import useBreakPointsSizes from '../../../../hooks/useBreakpointsSizes';
import { useNavigateBackOr } from '../../../../utils/routes';
import useGetCurrentPropertyId from '../../hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../navigation/navigation.property';
import HomeListLayout from '../Home/components/HomeListLayout';
import { StorageSummaryItem } from '../../types/property.types';
import { formatBytes } from '../../../../utils/string';
import useGetEntityInfo from '../../modules/Content/hooks/useGetEntityInfo';
import StorageWidget from '../UserStorage/components/StorageWidget';
import { getPathWithPropertyIdOrInit } from '../../../Auth/navigation/navigation.auth';
import { useGetUserStorageUsageQuery } from '../../../Auth/api/user.api';
import PropertyStorageQuickNavigation from './components/PropertyStorageQuickNavigation';
import { useGetPropertiesQuery } from '../../api/property.api';

const PropertyStorage: FC = () => {
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();
  const theme = useTheme();
  const { t } = useTranslation(['common', 'property']);
  const { isDownSm } = useBreakPointsSizes();
  const { data: propertyId } = useGetCurrentPropertyId();

  const getEntityInfo = useGetEntityInfo();

  const { data: userStorageUsage, isLoading } = useGetUserStorageUsageQuery();
  const { data: properties = [] } = useGetPropertiesQuery();

  const propertyName = properties.find(({ id }) => id === propertyId)?.displayName || t('property:unverified_property');

  const storageUsage = userStorageUsage?.properties?.find((propertyStorage) => propertyStorage.propertyId === propertyId);

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: t('common:name'),
      flex: isDownSm ? 0.5 : 0.6,
      type: 'string',
      sortable: !isDownSm,
      renderCell: (params: GridRenderCellParams) => {
        const { _meta, type } = params?.row as StorageSummaryItem;
        const entityInfo = getEntityInfo(_meta?.entity?.type || type);
        const Icon = entityInfo?.Icon || FolderOutlinedIcon;

        return (
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{ minWidth: 0 }}
          >
            <Icon />
            <Typography noWrap variant="subtitle1">
              {entityInfo?.namePlural || entityInfo?.name}
            </Typography>
          </Stack>
        );
      },
    },
    !isDownSm && {
      field: 'count',
      headerName: t('common:amount'),
      flex: 0.2,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { count } = params?.row as StorageSummaryItem;

        return (
          <Typography color={theme.palette.grey[500]} variant="subtitle1">
            {t('common:items_count', { count })}
          </Typography>
        );
      },
    } as GridColDef,
    {
      field: 'totalSize',
      headerName: t('common:total_size'),
      flex: isDownSm ? 0.5 : 0.2,
      type: 'number',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { totalSize } = params?.row as StorageSummaryItem;
        return (
          <Typography color={theme.palette.grey[500]} variant="subtitle1">
            {formatBytes(totalSize)}
          </Typography>
        );
      },
    } as GridColDef,
    LIST_CHEVRON_COLUMN_CONFIG,
  ].filter(Boolean);

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    const { _meta, type } = params?.row as StorageSummaryItem;
    const listPathGetter = getEntityInfo(_meta?.entity?.type || type)?.getListLink;
    if (propertyId && listPathGetter) {
      navigate(listPathGetter({ propertyId }));
    }
  };

  const storageWidgetItems = storageUsage?.summary
    ?.map((item) => ({
      totalSize: item.totalSize,
      // eslint-disable-next-line no-underscore-dangle
      name: getEntityInfo(item._meta?.entity?.type || item.type)?.namePlural || item.type,
    }));

  return (
    <HomeListLayout
      ListHeaderComponent={(
        <StorageWidget
          isLoading={isLoading}
          quota={userStorageUsage?.quota || 0}
          storageItems={storageWidgetItems || []}
        />
      )}
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <PropertyStorageQuickNavigation />
        </Card>
      }
      columns={columns}
      getRowId={(row: StorageSummaryItem) => row.type}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'name',
              sort: 'asc',
            },
          ],
        },
      }}
      isLoading={isLoading}
      rows={storageUsage?.summary || []}
      sideDrawerElements={[
        <PropertyStorageQuickNavigation key={PropertyStorageQuickNavigation.name} />,
      ]}
      title={t('property:property_settings_my_storage_at_property', { property: propertyName })}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
      onRowClick={handleRowClick}
    />
  );
};

export default PropertyStorage;
