import RouteNames from '../../../../routes/RouteNames';
import { generateRoutePath } from '../../../../utils/routes';
import { PropertyIdNavigation } from '../../types/property.types';
import { BonusOffersCategoryId } from './types.bonusOffers';

export const getBonusOffersPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.BONUS_OFFERS,
  { propertyId },
);

export const getMyTotalBonusPointsPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.BONUS_OFFERS_MY_BONUS_POINTS,
  { propertyId },
);

export const getBonusOffersLogsPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.BONUS_OFFERS_LOGS,
  { propertyId },
);

export const getMoveInBonusOffersPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.BONUS_OFFERS_FROM_MOVE_IN,
  { propertyId },
);

type BonusOfferDetailsPathParams = PropertyIdNavigation & {
  bonusOfferId: string;
  categoryId: BonusOffersCategoryId;
};

export const getBonusOfferDetailsPath = ({ propertyId, bonusOfferId, categoryId }: BonusOfferDetailsPathParams) =>
  generateRoutePath(RouteNames.BONUS_OFFER_DETAILS, { propertyId, bonusOfferId, categoryId });
