import { generateRoutePath } from '../../../utils/routes';
import AdminRouteNames from './AdminRouteNames';

export const getAdminHomePath = () => generateRoutePath(
  AdminRouteNames.ADMIN_HOME,
);

export const getImitateAccountPath = () => generateRoutePath(
  AdminRouteNames.IMITATE_ACCOUNT,
);
