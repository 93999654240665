import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
} from '@mui/material';
import * as R from 'ramda';
import { PropertyProgressCategoryId } from '@house-id/houseid-types/dist/propertyProgress';

import PropertyProgressContentItem from '../../../components/PropertyProgressContentItem';
import {
  PropertyPlanningRoomsSectionData,
  PropertyProgressCategorySectionProps,
} from '../../../types.propertyProgress';
import PropertyProgressCategorySectionContainer from '../../../components/PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from '../../../components/PropertyProgressCategorySectionContent';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import useGetPropertyProgressCategoryContentText from '../../../hooks/useGetPropertyProgressCategoryContentText';
import { getPropertyProgressPercent } from '../../../utils/utils.propertyProgress';

const PropertyPlanningRoomsSection: FC<PropertyProgressCategorySectionProps<PropertyPlanningRoomsSectionData>> = ({
  section,
}) => {
  const { t } = useTranslation(['property', 'common']);

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const handleViewPropertyRooms = () => openMobileAppDialog();
  const handleAddRoom = () => openMobileAppDialog();
  const handleViewRoom = () => openMobileAppDialog();

  const rooms = section.data.rooms || [];

  const getPropertyProgressCategoryContentDescription = useGetPropertyProgressCategoryContentText();
  const description = getPropertyProgressCategoryContentDescription({
    percent: getPropertyProgressPercent(section.progress),
    categoryId: PropertyProgressCategoryId.PLANNING,
    sectionId: section.id,
  });

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('property:property_progress_property_planning_add_room')}
          viewAllButtonText={t('property:property_progress_property_planning_show_all_my_rooms')}
          onAddClick={handleAddRoom}
          onViewAllClick={handleViewPropertyRooms}
        >
          <Grid
            container
            spacing={1.5}
            xxs={12}
          >
            {rooms
              ?.map((room) => (
                <Grid
                  item
                  key={room.id}
                  md={6}
                  xxs={12}
                >
                  <PropertyProgressContentItem
                    blob={R.head(room.blobs || [])}
                    description={room.area ? `${room.area} ${t('common:m2')}` : undefined}
                    key={room.id}
                    title={room.name}
                    onNavigate={() => handleViewRoom()}
                  />
                </Grid>
              ))}
          </Grid>
        </PropertyProgressCategorySectionContent>
      }
      section={{ ...section, description }}
    />
  );
};

export default PropertyPlanningRoomsSection;
